import React, {useRef, useState} from 'react';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import { errorToMessage } from 'src/utils/error';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import { FileRow } from 'shared/types/files';
import {Upload} from 'react-feather';

interface FileUploadButtonProps {
  file: FileRow;
  onUploadSuccess?: (newFileRow: FileRow) => void;
}

export default function FileUploadButton (props: FileUploadButtonProps) {
  const { file:fileRow, onUploadSuccess } = props;

  const notyf = useNotyf();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onChangeFile: React.ChangeEventHandler<HTMLInputElement> = async ev => {
    ev.preventDefault();
    setIsSubmitting(true);

    const file = fileRef.current?.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const newFileRow = await api.request({
        url: `/file/${fileRow.id}/upload`,
        method: 'POST',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'},
      });
      onUploadSuccess?.(newFileRow);
    } catch (err) {
      notyf.error(errorToMessage(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const fileRef = useRef<HTMLInputElement | null>(null);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = ev => {
    if (!fileRef.current) return;
    fileRef.current.click();
  };

  return (
    <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
      <input
        className="d-none"
        ref={fileRef}
        type="file"
        name="file"
        onChange={onChangeFile}
      />
      <ButtonSpinner
        type="button"
        className="d-flex gap-1"
        disabled={isSubmitting}
        variant="outline-success"
        isLoading={isSubmitting}
        onClick={onClick}
      >
        <Upload size={16} />
        {' '}Ladda upp
      </ButtonSpinner>
    </div>
  );
}
