import React, {useId} from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import * as miscFilters from 'shared/filter/misc';
import * as constants from 'shared/constants';
import * as customerFilters from 'shared/filter/customer';
import moment from 'moment';
import useAuth from 'src/hooks/useAuth';
import {Button, Form, InputGroup} from 'react-bootstrap';
import ModalOpeningButton from 'src/buttons/ModalOpeningButton';
import {Search, Trash2} from 'react-feather';
import UserPickModal from 'src/user/UserPickModal';
import {useField} from 'formik';

export function FirstName (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Förnamn"
      name="first_name"
      minLength={0}
      maxLength={255}
      {...props}
    />
  );
}

export function LastName (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Efternamn"
      name="last_name"
      minLength={0}
      maxLength={255}
      {...props}
    />
  );
}

export function TelephoneMobile (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Mobiltelefon"
      name="telephone_mobile"
      minLength={0}
      maxLength={255}
      {...props}
    />
  );
}

export function Email (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="E-postadress"
      type="email"
      name="email"
      minLength={0}
      maxLength={255}
      {...props}
    />
  );
}

export function PreferredLanguage (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Föredraget språk"
      name="preferred_language"
      required
      {...props}
    >
      {constants.languages.map(language => (
        <option key={language} value={language}>{miscFilters.language(language)}</option>
      ))}
    </FormikFormControls.FormGroupSelect>
  );
}

interface ChangeStatusProps extends Partial<FormikFormControls.FormGroupSelectProps> {
  currentStatus: string;
}

export function ChangeStatus (props: ChangeStatusProps) {
  const { currentStatus, ...restOfProps } = props;
  const auth = useAuth();

  const options = constants.customer.status.filter(status => {
    if (currentStatus === status) return false;
    if (auth.hasAllPermissions('customer_update:status:all')) return true;
    if (
      auth.hasAllPermissions('customer_update:status:limited')
    && constants.customer.status_limited.includes(status)
    && !constants.customer.status_privileged.includes(currentStatus)
    ) return true;
    return false;
  });

  return (
    <FormikFormControls.FormGroupSelect
      label="Ändra status"
      name="change_status"
      {...restOfProps}
    >
      <option value="">Ange en ny status</option>
      {options.map(status => (
        <option key={status} value={status}>{customerFilters.status(status)}</option>
      ))}
    </FormikFormControls.FormGroupSelect>
  );
}

export function UserId (props: any) {
  const auth = useAuth();
  const disabled = !auth.hasAllPermissions('customer_update:user_id');

  const [field,, helpers] = useField({
    name: 'user_id',
  });

  const onPick = (userId: string) => {
    helpers.setValue(userId);
    helpers.setTouched(true);
  };

  const onChooseSelf = () => {
    helpers.setValue(auth.userId);
    helpers.setTouched(true);
  };

  const onEmpty = () => {
    helpers.setValue(null);
    helpers.setTouched(true);
  };

  const modalProps = {
    onPick,
    excludeUserIds: field.value ? [field.value] : [],
  };

  const id = useId();

  return (
    <Form.Group>
      <Form.Label className="mb-1 mt-3 d-flex gap-1 align-items-center" htmlFor={id}>
        Ägare
      </Form.Label>
      <InputGroup>
        <Form.Control
          value={field.value ? [field.value] : '(Ingen ägare)'}
          id={id}
          name="user_id"
          disabled
        />
        <Button
          variant="outline-primary"
          onClick={onChooseSelf}
          disabled={disabled || field.value === auth.userId}
        >
          Ta över själv
        </Button>
        <ModalOpeningButton
          Modal={UserPickModal}
          modalProps={modalProps}
          className="px-2 d-inline-flex gap-1 align-items-center"
          title="Välj användare"
          variant="outline-primary"
          disabled={disabled}
        >
          <Search size={14} />
          Välj användare
        </ModalOpeningButton>
        <Button
          variant="outline-danger"
          onClick={onEmpty}
          disabled={disabled || !field.value}
          className="px-2"
        >
          <Trash2 size={14} />
        </Button>
      </InputGroup>
    </Form.Group>
  );
}

export function Risk (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Risk"
      name="risk"
      required
      {...props}
    >
      {constants.customer.risk.map(risk => (
        <option key={risk} value={risk}>{customerFilters.risk(risk)}</option>
      ))}
    </FormikFormControls.FormGroupSelect>
  );
}

export function AddressStreet (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Gata"
      name="address_street"
      minLength={0}
      maxLength={255}
      {...props}
    />
  );
}

export function AddressPostcode (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Postnummer"
      name="address_postcode"
      minLength={5}
      maxLength={5}
      pattern="^\d{5}$"
      {...props}
    />
  );
}

export function AddressCo (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="C/O"
      name="address_co"
      minLength={0}
      maxLength={64}
      {...props}
    />
  );
}

export function AddressCity (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Stad"
      name="address_city"
      minLength={0}
      maxLength={64}
      {...props}
    />
  );
}

export function BankClearingNumber (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Clearingnummer"
      name="bank_clearing_number"
      minLength={0}
      maxLength={10}
      {...props}
    />
  );
}

export function BankAccountNumber (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Kontonummer"
      name="bank_account_number"
      minLength={0}
      maxLength={20}
      {...props}
    />
  );
}

export function PortalAllowLogin (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Inloggning till portal"
      name="portal_allow_login"
      required
      {...props}
    >
      <option value="false">Ej tillåten</option>
      <option value="true">Tillåten</option>
    </FormikFormControls.FormGroupSelect>
  );
}

export function PortalPassword (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Ändra lösenord till kundportal"
      name="portal_password"
      explainer="Lämna fältet tomt för att inte byta lösenord"
      {...props}
    />
  );
}

export function ContactOptIn (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Kontakt godkänd"
      type="date"
      max={moment().format('YYYY-MM-DD')}
      name="contact_opt_in_at"
      {...props}
    />
  );
}

export function SuspectedNotes (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      as="textarea"
      label="Anteckningar om terroristen/penningtvättaren"
      name="suspected_notes"
      minLength={0}
      rows={6}
      {...props}
    />
  );
}
