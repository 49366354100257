import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import IdProvider from 'src/components/IdProvider';
import { UserRow } from 'shared/types/user';
import {useQuery} from '@tanstack/react-query';
import ErrorAlert from 'src/alerts/ErrorAlert';

export interface UserPickModalProps {
  show: boolean;
  excludeUserIds?: string[];
  onHide: () => void;
  onPick: (userId: string) => void;
  onExited: () => void;
}

export default function UserPickModal (props: UserPickModalProps) {
  const {
    excludeUserIds = [],
    show,
    onHide,
    onPick,
    onExited,
  } = props;

  const [userId, setUserId] = useState<string>('');

  const query = useQuery<UserRow[], Error>({
    queryKey: ['/user/search', {params: {is_active: true, order: {name: 'asc'}}}],
  });

  const onChange: React.ChangeEventHandler<HTMLSelectElement> = ev => {
    setUserId(ev.target.value);
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = ev => {
    ev.stopPropagation();
    ev.preventDefault();
    onHide();
    onPick(userId);
  };

  const users = (query.data ?? []).filter(user => !excludeUserIds.includes(user.id));

  return (
    <Modal show={show} onHide={onHide} onExited={onExited}>
      <Modal.Header>
        <Modal.Title>
          Välj användare
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorAlert error={query.error} />
        <Form onSubmit={onSubmit}>
          <IdProvider>
            {id => (
              <Form.Group>
                <Form.Select
                  name="user_id"
                  value={userId}
                  required
                  id={id}
                  onChange={onChange}
                >
                  <option value="">Välj en användare</option>
                  {users.map(user => (
                    <option key={user.id} value={user.id}>{user.name ?? user.id}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
          </IdProvider>
          <div className="d-flex flex-wrap gap-2 mt-2">
            <Button type="submit" variant="outline-secondary" disabled={!userId}>Välj</Button>
            <Button onClick={onHide} variant="outline-primary">Avbryt</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
