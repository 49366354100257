import React from 'react';
import { Modal } from 'react-bootstrap';
import PostponeTaskForm from 'src/task/PostponeTaskForm';

interface PostponeTaskModalProps {
  taskId: string;
  show: boolean;
  onHide: () => void;
}

const PostponeTaskModal: React.FC<PostponeTaskModalProps> = React.memo(function PostponeTaskModal (props: PostponeTaskModalProps) {
  const {
    show,
    taskId,
    onHide,
  } = props;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          Skjut upp återkomst {taskId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-2">
        <PostponeTaskForm />
      </Modal.Body>
    </Modal>
  );
});
export default PostponeTaskModal;
