import React from 'react';
import ModalAnchor, {ModalAnchorProps} from 'src/anchors/ModalAnchor';
import WorkScheduledModalBody from 'src/workScheduled/WorkScheduledModalBody';
import { Name } from 'src/workScheduled/WorkScheduledFormatters';
import { WorkScheduledRow } from 'shared/types/work_scheduled';

interface WorkScheduledAnchorProps extends Partial<ModalAnchorProps> {
  workScheduled: WorkScheduledRow;
}

export default function WorkScheduledAnchor (props: WorkScheduledAnchorProps) {
  const { workScheduled, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={workScheduled} />}
      name={<>Schemalagt jobb #<Name value={workScheduled} /></>}
    >
      <WorkScheduledModalBody id={workScheduled.id} />
    </ModalAnchor>
  );
}
