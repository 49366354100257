import React from 'react';
import * as api from 'src/api';
import { useQuery, useMutation } from '@tanstack/react-query';
import { WidgetGroups, WidgetList } from 'src/widgets/types';
import OverlaySpinner, { OverlaySpinnerContainer } from 'src/spinners/OverlaySpinner';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import DateTime from 'src/formatters/DateTime';
import UserAnchor from 'src/user/UserAnchor';
import { WidgetLoader, WidgetTimeline, WidgetPropertyListTable } from 'src/widgets/CommonWidgets';
import { WidgetListCard } from 'src/cards/CardHelpers';
import { CustomerRow } from 'shared/types/customer';
import * as customerLogFilters from 'shared/filter/customer_log';
import * as customerFilters from 'shared/filter/customer';
import { CustomerLogRow } from 'shared/types/customer_log';
import CustomerNoteItem from 'src/customer/CustomerNoteItem';
import { Button, Collapse, Form, Dropdown } from 'react-bootstrap';
import { RefreshCw } from 'react-feather';
import {Link} from 'react-router-dom';

interface CustomerWidgetProps {
  customer: CustomerRow;
}

export const widgetGroups: WidgetGroups = [
  {id: 'properties', title: 'Egenskaper'},
];

export const allWidgets: WidgetList = {
  properties: {
    id: 'properties',
    Component: Properties,
    title: 'Egenskaper',
    group: 'properties',
  },
  notes: {
    id: 'notes',
    Component: Notes,
    title: 'Anteckningar',
    group: 'properties',
  },
  log: {
    id: 'log',
    Component: Log,
    title: 'Logg',
    group: 'properties',
  },
};

const NOTES_SHOW_MAX = 2;
export function Notes (props) {
  // TODO confirm deletions
  const { customer, controls:baseControls, ...otherProps } = props;
  const customerId = customer?.id;

  const query = useQuery({
    queryKey: ['customerNotes', {customerId}],
    queryFn: () => api.request({
      url: `/customer/${customerId}/notes`,
    }),
  });

  const mutationCreate = useMutation({
    mutationFn: (data: any) => api.request({
      method: 'post',
      url: `/customer/${customerId}/notes`,
      data,
    }),
    onSuccess: async () => {
      await query.refetch();
    },
  });

  const onReload = () => {
    query.refetch();
  };

  const handleSubmit = ev => {
    ev.preventDefault();
    const data = {text};
    mutationCreate.mutateAsync(data).then(() => {
      setText('');
      setShowControls(false);
    });
  };

  const [showAllNotes, setShowAllNotes] = React.useState(false);
  const notes = (query?.data || []);
  const hasMore = notes.length > NOTES_SHOW_MAX;

  const [showControls, setShowControls] = React.useState(false);
  const [text, setText] = React.useState('');

  const textInputRef = React.useRef<HTMLInputElement>(null);
  const getTemplateSetter = (text: string) => () => {
    setText(text);
    const el = textInputRef.current;
    if (el) {
      let firstLineEnd = text.indexOf('\n');
      if (firstLineEnd === -1) firstLineEnd = text.length;
      el.focus();
      el.setSelectionRange(0, firstLineEnd);
    }
  };

  const controls = (
    <>
      <Dropdown>
        <Dropdown.Toggle className="p-0 px-1 me-1" variant="" size="sm">
          Infoga mall
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            as="button"
            type="button"
            onClick={getTemplateSetter('Ej svar')}
          >
            Ej svar
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            type="button"
            onClick={getTemplateSetter('Nuvarande: \nBehov/önskemål: \nÖvrigt: ')}
          >
            Behovsanalys
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ButtonSpinner variant="" size="sm" className="btn-icon p-0">
        <RefreshCw size={16} />
      </ButtonSpinner>
      {baseControls}
    </>
  );

  return (
    <WidgetListCard {...otherProps} controls={controls}>
      <OverlaySpinnerContainer>
        <OverlaySpinner isLoading={query.isLoading} />
        {query.isSuccess && (
          <>
            <NewNoteForm
              onChangeText={setText}
              onSubmit={handleSubmit}
              isSubmitting={mutationCreate.isPending}
              text={text}
              inputRef={textInputRef}
              showControls={showControls}
              setShowControls={setShowControls}
            />
            <div className="notes pt-2">
              {!notes.length && (
                <p className="text-center mb-2">Det finns inga anteckningar här</p>
              )}
              {(showAllNotes ? notes : notes.slice(0, NOTES_SHOW_MAX)).map(note => (
                <CustomerNoteItem
                  className="mx-2 mb-2"
                  customerId={customer.id}
                  key={note.id}
                  note={note}
                  onReload={onReload}
                />
              ))}
              {hasMore && !showAllNotes && (
                <div className="text-center mb-2">
                  <Button
                    className="rounded"
                    size="sm"
                    variant="outline-primary"
                    onClick={() => setShowAllNotes(true)}
                  >
                    Visa övriga ({notes.length - NOTES_SHOW_MAX}) anteckningar
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </OverlaySpinnerContainer>
    </WidgetListCard>
  );
}

function NewNoteForm (props) {
  const {
    text,
    onChangeText,
    onSubmit,
    inputRef,
    isSubmitting,
    showControls,
    setShowControls,
  } = props;

  const handleChange = ev => {
    onChangeText(ev.target.value);
  };

  const handleBlur = () => {
    if (!text) setShowControls(false);
  };

  const handleFocus = () => {
    setShowControls(true);
  };

  return (
    <Form className="border-bottom px-2 pt-2" onSubmit={onSubmit}>
      <Form.Group className="mb-2">
        <Form.Control
          as="textarea"
          placeholder="Skriv en anteckning här"
          rows={text.split(/\n/g).length}
          name="text"
          className="w-100"
          value={text}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          ref={inputRef}
          style={{resize: 'none'}}
        />
      </Form.Group>
      <Collapse in={showControls}>
        <div className="text-center">
          <ButtonSpinner
            type="submit"
            className="rounded mb-2"
            size="sm"
            variant="success"
            isLoading={isSubmitting}
            disabled={!text || isSubmitting}
          >
            Skapa
          </ButtonSpinner>
        </div>
      </Collapse>
    </Form>
  );
}

export function Log (props: CustomerWidgetProps) {
  const { customer } = props;

  const query = useQuery<CustomerLogRow[], Error>({
    queryKey: [`/customer/${customer.id}/log`, {params: {limit: 5}}],
  });

  return (
    <WidgetListCard {...props}>
      <WidgetLoader isLoading={query.isLoading} error={query.error}>
        {query.isSuccess && (
          <WidgetTimeline
            className="m-3"
            list={query.data}
            description={customerLogFilters.description}
            by={(item: any) => item.user_id ? <UserAnchor user={item.User} /> : 'System'}
          />
        )}
        <div className="d-flex justify-content-center mb-2 pt-2 border-top">
          <Link className="btn btn-outline-primary btn-sm" to={`/customer/${customer.id}/log`}>
            Visa hela loggen
          </Link>
        </div>
      </WidgetLoader>
    </WidgetListCard>
  );
}

export function Properties (props: CustomerWidgetProps) {
  const { customer } = props;
  const list = [
    {label: 'Personnummer', value: customerFilters.indexField(customer, 'ssn')},
    {label: 'Ålder', value: <>{customerFilters.indexField(customer, 'age')} år</>},
    {label: 'Föredraget språk', value: customerFilters.indexField(customer, 'preferred_language')},
    {label: 'Skapad', value: <DateTime value={customer.created_at} />},
    {label: 'Uppdaterad', value: <DateTime value={customer.updated_at} />},
    {label: 'Statusuppdatering', value: <DateTime value={customer.status_updated_at} />},
  ];
  return (
    <WidgetListCard {...props}>
      <WidgetPropertyListTable list={list} />
    </WidgetListCard>
  );
}
