import React from 'react';
import classNames from 'classnames';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';

export interface ButtonReplacedSpinnerProps extends ButtonProps {
  isLoading?: boolean;
  spinnerSize?: 'sm';
}

// like a ButtonSpinner but replaces the children with a Spinner when loading
// good for square icon buttons being swapped into a spinner
export default function ButtonReplacedSpinner (props: ButtonReplacedSpinnerProps) {
  const {
    isLoading,
    children,
    size,
    spinnerSize = 'sm',
    className,
    ...buttonProps
  } = props;
  const classes = classNames(className, 'd-inline-flex align-items-center gap-1');
  return (
    <Button {...buttonProps} size={size} className={classes}>
      {isLoading ? (
        <Spinner animation="border" size={spinnerSize} variant="default" />
      ) : children}
    </Button>
  );
}
