import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import AuthLoaderGuard from 'src/guards/AuthLoaderGuard';
import ApplicationsTablePage from 'src/application/ApplicationsTablePage';
import ApplicationPage, {
  ApplicationPageTabContact,
  ApplicationPageTabForm,
  ApplicationPageTabLog,
  ApplicationPageTabTriggers,
} from 'src/application/ApplicationPage';
import ApplicationTriggerTablePage from 'src/applicationTrigger/ApplicationTriggerTablePage';
import BankPage from 'src/bank/BankPage';
import BankCreatePage from 'src/bank/BankCreatePage';
import BankProcessesTablePage from 'src/bankProcess/BankProcessesTablePage';
import BanksTablePage from 'src/bank/BanksTablePage';
import ComplaintPage from 'src/complaint/ComplaintPage';
import ComplaintsTablePage from 'src/complaint/ComplaintsTablePage';
import CustomerPage from 'src/customer/CustomerPage';
import CustomersTablePage from 'src/customer/CustomersTablePage';
import CustomerConsentPrintPage from 'src/customerConsent/CustomerConsentPrintPage';
import DashboardPage from 'src/dashboard/DashboardPage';
import EmailTablePage from 'src/email/EmailTablePage';
import FilesTablePage from 'src/file/FilesTablePage';
import CustomerConsentTablePage from 'src/customerConsent/CustomerConsentTablePage';
import CustomerCloudInsurancePage from 'src/cloudInsurance/CloudInsurancePage';
import CustomerJaycomInsurancePage from 'src/jaycomInsurance/JaycomInsurancePage';
import CustomerCloudInsuranceTablePage from 'src/cloudInsurance/CloudInsuranceTablePage';
import CustomerJaycomInsuranceTablePage from 'src/jaycomInsurance/JaycomInsuranceTablePage';
import LetterTablePage from 'src/letter/LetterTablePage';
import PepQueryTablePage from 'src/pepQuery/PepQueryTablePage';
import ProspectPage from 'src/prospect/ProspectPage';
import ProspectsTablePage from 'src/prospect/ProspectsTablePage';
import ProspectTriggerTablePage from 'src/prospectTrigger/ProspectTriggerTablePage';
import SettingsPage from 'src/settings/SettingsPage';
import SmsTablePage from 'src/sms/SmsTablePage';
import StatisticsAdvisorPage from 'src/statistics/StatisticsAdvisorPage';
import StatisticsBanksPage from 'src/statistics/StatisticsBanksPage';
import StatisticsDisbursedPage from 'src/statistics/StatisticsDisbursedPage';
import StatisticsSalesmanPage from 'src/statistics/StatisticsSalesmanPage';
import TaskLotteryPage from 'src/taskLottery/TaskLotteryPage';
import TaskTablePage from 'src/task/TaskTablePage';
import TemplatePage from 'src/template/TemplatePage';
import UcTablePage from 'src/uc/UcTablePage';
import UserTablePage from 'src/user/UserTablePage';
import UserPage from 'src/user/UserPage';
import WorkScheduledTablePage from 'src/workScheduled/WorkScheduledTablePage';
import WorkTablePage from 'src/work/WorkTablePage';
import WorkTriggerTemplateTablePage from 'src/workTriggerTemplate/WorkTriggerTemplateTablePage';
import LoginPage from 'src/login/LoginPage';
import { NotFoundAuthedPage, NotFoundPage } from 'src/pages/NotFoundPages';

import { WebSocketProvider } from 'src/contexts/WebSocketContext';
import AppError from 'src/ui/AppError';
import { ErrorBoundary } from 'react-error-boundary';
import Wrapper from 'src/ui/Wrapper';
import Sidebar from 'src/sidebar/Sidebar';
import ApplicationPageTabProcess from 'src/application/ApplicationPageTabProcess';
import GroupTablePage from 'src/group/GroupTablePage';


export default function Router () {
  return (
    <Routes>
      <Route path="/login/:tab" element={<LoginPage />} />
      <Route element={<AuthenticatedPageWrapper />}>
        <Route path="/" element={<DashboardPage />}/>
        <Route path="/applications/all/:initialStateId?" element={<ApplicationsTablePage type="all" />} />
        <Route path="/applications/own/:initialStateId?" element={<ApplicationsTablePage type="own" title="Mina ansökningar" pageTitle="Mina ansökningar" />} />
        <Route path="/application/:applicationId/:tab?" element={<ApplicationPage />} />
        <Route path="/application/:applicationId" element={<ApplicationPage />}>
          <Route path="form" index element={<ApplicationPageTabForm />} />
          <Route path="bankProcess/:bankProcessId?/:bankProcessTab?" element={<ApplicationPageTabProcess />} />
          <Route path="log" element={<ApplicationPageTabLog />} />
          <Route path="contact" element={<ApplicationPageTabContact />} />
          <Route path="triggers" element={<ApplicationPageTabTriggers />} />
        </Route>
        <Route path="/application_triggers/:initialStateId?" element={<ApplicationTriggerTablePage />} />
        <Route path="/banks" element={<BanksTablePage />} />
        <Route path="/bank/create" element={<BankCreatePage />} />
        <Route path="/bank/:bankId/:tab?" element={<BankPage />} />
        <Route path="/complaint/:complaintId" element={<ComplaintPage />} />
        <Route path="/customer_cloud_insurance/:insuranceId" element={<CustomerCloudInsurancePage />} />
        <Route path="/customer_jaycom_insurance/:insuranceId/:tab?" element={<CustomerJaycomInsurancePage />} />
        <Route path="/files" element={<FilesTablePage />} />
        <Route path="/prospects/:initialStateId?" element={<ProspectsTablePage />} />
        <Route path="/prospect/:prospectId/:tab?" element={<ProspectPage />} />
        <Route path="/prospect_triggers/:initialStateId?" element={<ProspectTriggerTablePage />} />
        <Route path="/uc/:initialStateId?" element={<UcTablePage />} />
        <Route path="/customer_consent/:initialStateId?" element={<CustomerConsentTablePage />} />
        <Route path="/customers/:initialStateId?" element={<CustomersTablePage />} />
        <Route path="/customer/:customerId/:tab?" element={<CustomerPage />} />
        <Route path="/customer_consent/:customerConsentId/print" element={<CustomerConsentPrintPage />} />
        <Route path="/complaints/:initialStateId?" element={<ComplaintsTablePage />} />
        <Route path="/customer_cloud_insurances/:initialStateId?" element={<CustomerCloudInsuranceTablePage />} />
        <Route path="/customer_jaycom_insurances/:initialStateId?" element={<CustomerJaycomInsuranceTablePage />} />
        <Route path="/bank_processes/:initialStateId?" element={<BankProcessesTablePage />} />
        <Route path="/sms/:initialStateId?" element={<SmsTablePage />} />
        <Route path="/email/:initialStateId?" element={<EmailTablePage />} />
        <Route path="/letter/:initialStateId?" element={<LetterTablePage />} />
        <Route path="/sms_template/:templateId?" element={<TemplatePage type="sms" />} />
        <Route path="/email_template/:templateId?" element={<TemplatePage type="email" />} />
        <Route path="/letter_template/:templateId?" element={<TemplatePage type="letter" />} />
        <Route path="/groups" element={<GroupTablePage />} />
        <Route path="/users/:initialStateId?" element={<UserTablePage />} />
        <Route path="/user/:userId/:tab?" element={<UserPage />} />
        <Route path="/statistics/banks" element={<StatisticsBanksPage />} />
        <Route path="/statistics/disbursed" element={<StatisticsDisbursedPage />} />
        <Route path="/statistics/advisors" element={<StatisticsAdvisorPage />} />
        <Route path="/statistics/salesmen" element={<StatisticsSalesmanPage />} />
        <Route path="/pep_query/:initialStateId?" element={<PepQueryTablePage />} />
        <Route path="/task/:initialStateId?" element={<TaskTablePage />} />
        <Route path="/task_lottery" element={<TaskLotteryPage />} />
        <Route path="/work/:initialStateId?" element={<WorkTablePage />} />
        <Route path="/work_scheduled/:initialStateId?" element={<WorkScheduledTablePage />} />
        <Route path="/work_trigger_templates/:initialStateId?" element={<WorkTriggerTemplateTablePage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="*" element={<NotFoundAuthedPage />}/>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

function AuthenticatedPageWrapper () {
  return (
    <AuthLoaderGuard>
      <WebSocketProvider>
        <Wrapper>
          <Sidebar />
          <ErrorBoundary FallbackComponent={AppError}>
            <Outlet />
          </ErrorBoundary>
        </Wrapper>
      </WebSocketProvider>
    </AuthLoaderGuard>
  );
}
