import React from 'react';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import * as api from 'src/api';
import { Helmet } from 'react-helmet-async';
import {useMutation, useQuery} from '@tanstack/react-query';
import { ProspectRow } from 'shared/types/prospect';
import * as ProspectFormatters from 'src/prospect/ProspectFormatters';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import BlockSpinner from 'src/spinners/BlockSpinner';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { Card, Tab, Nav, Row, Col } from 'react-bootstrap';
import { CardTitleIconStatus } from 'src/cards/CardHelpers';
import { Activity, Box, Cpu, Edit2, Mail, Wind } from 'react-feather';
import * as IconButtons from 'src/buttons/IconButtons';
import {GuardPermission} from 'src/guards/AuthGuards';
import Breadcrumbs from 'src/components/Breadcrumbs';
import ProspectTriggerTable from 'src/prospect/ProspectTriggerTable';
import ProspectAssociatedObjectList from 'src/prospect/ProspectAssociatedObjectList';
import useNotyf from 'src/hooks/useNotyf';
import EmailContactControlCard from 'src/email/EmailContactControlCard';
import SmsContactControlCard from 'src/sms/SmsContactControlCard';
import * as ProspectBlocks from 'src/prospect/ProspectBlocks';

interface ProspectPageParams {
  prospectId: string;
  tab?: string;
}

export default function ProspectPage () {
  const { prospectId, tab = 'overview' } = useParams() as unknown as ProspectPageParams;

  const notyf = useNotyf();
  const navigate = useNavigate();

  const readQuery = useQuery<ProspectRow, Error>({
    queryKey: [`/prospect/${prospectId}`],
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: () => api.request({
      url: `/prospect/${prospectId}`,
      method: 'DELETE',
    }),
    onSuccess: () => {
      navigate('/prospects');
      notyf.success({type: 'danger', message: 'Prospekten raderades'});
    },
  });

  const onClickDelete = () => {
    return deleteMutation.mutateAsync();
  };

  const prospect = readQuery.data;

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Prospekt" />
        <h1>Prospekt</h1>
        <Breadcrumbs
          crumbs={[
            ['/prospects', 'Prospekter'],
            [`/prospect/${prospectId}/overview`, prospect?.id ?? null],
          ]}
        />
        <BlockSpinner isLoading={readQuery.isLoading} />
        <ErrorAlertCardBody error={readQuery.error} className="mx-0 mt-0 mb-3" />
        {prospect && (
          <Card className="border">
            <Card.Header className="border-bottom bg-light">
              <CardTitleIconStatus
                as="h2"
                title={<ProspectFormatters.Name value={prospect} />}
                Status={<ProspectFormatters.ResultLabel value={prospect.result} className="px-3" />}
                Icon={<Wind size={16} />}
                spinning={readQuery.isRefetching}
              >
                <GuardPermission permission="prospect_delete">
                  <IconButtons.ConfirmModalDeleteButton
                    onConfirm={onClickDelete}
                    message="Är du säker på att du vill radera den här prospekten permanent?"
                  />
                </GuardPermission>
                <IconButtons.InspectObjectModalButton object={prospect} />
                <IconButtons.CopyObjectIdButton id={prospect.id} />
                <IconButtons.CopyCurrentUrlButton />
                <IconButtons.RefreshObjectButton
                  disabled={readQuery.isRefetching}
                  onClick={() => readQuery.refetch()}
                />
              </CardTitleIconStatus>
            </Card.Header>
            {prospect && (
              <ProspectAssociatedObjectList prospect={prospect} />
            )}
            <Tab.Container
              activeKey={tab}
              transition={false}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              <Nav className="nav-tabs pt-3 px-3">
                <Nav.Item>
                  <NavLink className="nav-link" to={`/prospect/${prospectId}/overview`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Edit2 size={12} />{' '}
                      Översikt
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/prospect/${prospectId}/contact`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Mail size={12} />{' '}
                      Kontakt
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/prospect/${prospectId}/triggers`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Activity size={12} />{' '}
                      Händelsetriggers
                    </span>
                  </NavLink>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="overview">
                  <Row className="px-4 pt-4">
                    <Col xl={6}>

                      <Card className="border">
                        <Card.Header className="border-bottom bg-light">
                          <CardTitleIcon
                            title="Formulär (hemsida steg 1)"
                            Icon={<Box size={18} />}
                          >
                          </CardTitleIcon>
                        </Card.Header>
                        <Card.Body className="pb-2">
                          <ProspectBlocks.DataDisabledForm prospect={prospect} />
                        </Card.Body>
                      </Card>

                      <Card className="border">
                        <Card.Header className="border-bottom bg-light">
                          <CardTitleIcon
                            title="Formulär (portal steg 2)"
                            Icon={<Box size={18} />}
                          >
                          </CardTitleIcon>
                        </Card.Header>
                        <Card.Body className="pt-3">
                          <ProspectBlocks.FormInfoTable prospect={prospect} />
                        </Card.Body>
                        <Card.Footer className="border-top">
                          <ProspectBlocks.FormExternaLink prospect={prospect} />
                        </Card.Footer>
                      </Card>

                    </Col>
                    <Col xl={6}>
                      <Card className="border">
                        <Card.Header className="border-bottom bg-light">
                          <CardTitleIcon
                            title="Egenskaper"
                            Icon={<Cpu size={18} />}
                          >
                          </CardTitleIcon>
                        </Card.Header>
                        <Card.Body className="pt-3">
                          <ProspectBlocks.PropertiesTable prospect={prospect} />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="contact">
                  <Card.Body>
                    <SmsContactControlCard
                      className="mb-3"
                      createPermission="sms_create_prospect"
                      searchParams={{prospect_id: prospectId}}
                      searchTemplateParams={{category: 'prospect', is_archived: 'false'}}
                      objectType="prospect"
                      objectId={prospectId}
                    />
                    <EmailContactControlCard
                      className="mb-0"
                      createPermission="email_create_prospect"
                      searchParams={{prospect_id: prospectId}}
                      searchTemplateParams={{category: 'prospect', is_archived: 'false'}}
                      objectType="prospect"
                      objectId={prospectId}
                    />
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="triggers">
                  <Card.Body>
                    <ProspectTriggerTable prospectId={prospectId} className="mb-0" />
                  </Card.Body>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
        )}
      </Content>
    </Main>
  );
}
