import React from 'react';
import * as tableHelpers from 'src/tables/helpers';
import { TableColumnDefinitionMap } from 'src/tables/Table';
import { TableSelectedRows, TableStateColumns, TableRow } from 'src/tables/types';

interface TableFootProps {
  columnDefinitionsById: TableColumnDefinitionMap;
  selectedRows?: TableSelectedRows;
  columns?: TableStateColumns;
  rows: TableRow[];
}

const TableFoot: React.FC<TableFootProps> = React.memo(function TableFoot (props: TableFootProps) {
  const {
    columnDefinitionsById,
    columns,
    selectedRows,
    rows,
  } = props;

  const columnOrder = Array.isArray(columns) ? columns : Object.keys(columnDefinitionsById);

  const hasContent = columnOrder.some(columnId => columnDefinitionsById[columnId]?.Footer);
  if (!hasContent) {
    return null;
  }

  const allRowsSelected = tableHelpers.allRowsSelected(rows, selectedRows);
  const anyRowsSelected = tableHelpers.anyRowsSelected(rows, selectedRows);

  return (
    <tfoot className="border-bottom">
      <tr>
        {columnOrder.map(columnId => (
          <TableFootTh
            key={columnId}
            columnDefinition={columnDefinitionsById[columnId]}
            rows={rows}
            selectedRows={selectedRows}
            allRowsSelected={allRowsSelected}
            anyRowsSelected={anyRowsSelected}
          />
        ))}
      </tr>
    </tfoot>
  );
});
export default TableFoot;

function TableFootTh (props) {
  const {
    columnDefinition,
    rows,
    selectedRows,
    allRowsSelected,
    anyRowsSelected,
  } = props;
  if (!columnDefinition) return null;

  const {
    Footer,
    footerProps = {},
  } = columnDefinition;

  return (
    <th>
      {Footer && (
        <Footer
          rows={rows}
          selectedRows={selectedRows}
          allRowsSelected={allRowsSelected}
          anyRowsSelected={anyRowsSelected}
          {...footerProps}
        />
      )}
    </th>
  );
}
