import React from 'react';
import { map, groupBy, sortBy } from 'lodash';
import FileRole from 'src/formatters/FileRole';
import classNames from 'classnames';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import { useQuery } from '@tanstack/react-query';
import {
  Badge,
  Card,
  Accordion,
} from 'react-bootstrap';
import {
  File,
  Inbox,
  Archive,
} from 'react-feather';
import { FileRow } from 'shared/types/files';

interface FilesResponse {
  files: FileRow[];
}

export default function FilesCard () {

  const query = useQuery<FilesResponse>({
    queryKey: ['/dashboard/files'],
  });

  return (
    <Card className="flex-fill w-100 border">
      <Card.Header>
        <CardTitleIcon title="Filer" Icon={<Archive size={18} />} spinning={query.isRefetching}>
          <RefreshObjectButton
            onClick={() => query.refetch()}
            disabled={query.isRefetching}
          />
        </CardTitleIcon>
      </Card.Header>
      <Card.Body className="pt-0 position-relative">
        <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={query.isLoading} />
        {query.isSuccess && query.data.files && (
          <FilesAccordion files={query.data.files} />
        )}
      </Card.Body>
    </Card>
  );
}

interface FilesAccordionProps {
  files: FileRow[];
}

function FilesAccordion (props: FilesAccordionProps) {
  const { files } = props;

  const byFolder = groupBy(files, 'folder');

  const list = sortBy(map(byFolder, (files, folder) => {
    return {folder, files};
  }), o => o.folder.toLowerCase());

  return (
    <Accordion>
      {list.map(({folder, files}) => (
        <Accordion.Item key={folder} eventKey={folder} className="bg-white">
          <Accordion.Header>
            <Inbox className="me-1 feather" />
            {folder === 'null' ? '(Övrigt)' : folder}
            {' '}
            <Badge bg="light" className="ms-1 text-secondary border">
              {files.length === 1 ? '1 fil' : `${files.length} filer`}
            </Badge>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <table className="table table-condensed mb-0">
              <tbody>
                {files.map((file, index) => (
                  <tr key={file.id}>
                    <td className={classNames('pe-1 ps-3', {'border-bottom-0': index === files.length - 1})}>
                      <File className="feather" />
                    </td>
                    <td className={classNames('w-100', {'border-bottom-0': index === files.length - 1})}>
                      <a
                        className="d-block"
                        href={`/api/file/${file.id}/open`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.name}{file.ext}
                      </a>
                      <small className="d-block">
                        {file.description || ''}{' '}
                        (Behörighet: <FileRole value={file.role} />)
                      </small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
