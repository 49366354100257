import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { SmsRow } from 'shared/types/sms';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import * as SmsFormFields from 'src/sms/SmsFormFields';
import * as formUtils from 'src/utils/form';
import {Trash} from 'react-feather';

export interface SmsEditFormValues {
  gsm: string;
  body: string;
}

interface SmsEditFormProps {
  className?: string;
  initialValues: SmsEditFormValues;
  onSubmit: (values: SmsEditFormValues, helpers: FormikHelpers<SmsEditFormValues>) => Promise<any>;
  onDelete?: () => void;
  submitError?: Error | null;
  disabled?: boolean;
}

export default function SmsEditForm (props: SmsEditFormProps) {
  const { initialValues, submitError, onDelete, onSubmit, disabled, className } = props;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} className={className}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="mx-3">
            <SmsFormFields.Gsm disabled={disabled} />
            <SmsFormFields.Body disabled={disabled} />
          </div>

          <div className="mt-3 p-3 border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap align-items-center justify-content-between">
              {onDelete ? (
                <ConfirmActionModalButton
                  type="button"
                  className="rounded d-flex gap-1"
                  variant="danger"
                  disabled={!formProps.isValid || formProps.isSubmitting}
                  onConfirm={onDelete}
                  message="Är du säker på att du vill radera SMS:et?"
                  title="Radera det här SMS:et"
                >
                  <Trash size={18} />
                  Radera
                </ConfirmActionModalButton>
              ) : <div></div>}
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export function smsToFormValues (sms: SmsRow): SmsEditFormValues {
  const { gsm, body } = sms;
  return {
    gsm: gsm || '',
    body: body || '',
  };
}

export function formValuesToUpdate (initialValues: SmsEditFormValues, values: SmsEditFormValues): Partial<SmsEditFormValues> {
  const changes = formUtils.changes(initialValues, values);
  return changes;
}
