import React from 'react';

import { THEME, Theme } from 'src/constants';
import useSettingsState from 'src/hooks/useSettingsState';

interface ThemeContextState {
  theme: Theme;
  setTheme: (newTheme: Theme) => void;
}

const initialState: ThemeContextState = {
  theme: THEME.LIGHT,
  setTheme: () => {},
};

const ThemeContext = React.createContext<ThemeContextState>(initialState);

function ThemeProvider ({ children }) {
  const [theme, setTheme] = useSettingsState('theme', THEME.LIGHT);
  const value: ThemeContextState = {theme, setTheme};
  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
