import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { useQuery } from '@tanstack/react-query';
import {ProspectRow} from 'shared/types/prospect';
import StatusLabelContainer from 'src/components/StatusLabelContainer';
import { ResultLabel } from  'src/prospect/ProspectFormatters';
import WidgetList from 'src/widgets/WidgetList';
import useWidgetList from 'src/hooks/useWidgetList';
import { allWidgets } from 'src/prospect/ProspectWidgets';

interface ProspectQuicklookProps {
  id: string;
}

const ProspectQuicklook: React.FC<ProspectQuicklookProps> = React.memo(function ProspectQuicklook (props: ProspectQuicklookProps) {
  const { id } = props;

  const query = useQuery<ProspectRow, Error>({
    queryKey: [`/prospect/${id}`],
  });

  const [state, dispatch] = useWidgetList('prospectQuicklook', [
    'properties',
  ]);

  const prospect = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3 my-0" />
      <BlockSpinner isLoading={query.isLoading} className="mx-3" center />
      {prospect && (
        <>
          <StatusLabelContainer title="Avslutskod">
            <ResultLabel value={prospect.result} />
          </StatusLabelContainer>
          <WidgetList
            allWidgets={allWidgets}
            state={state}
            dispatch={dispatch}
            widgetProps={{prospect}}
          />
        </>
      )}
    </div>
  );
});
export default ProspectQuicklook;
