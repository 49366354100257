import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import { InputGroup, Form, Badge } from 'react-bootstrap';
import constants from 'shared/constants';
import translations from 'shared/translations';
import moment from 'moment';
import HelperTooltip from 'src/components/HelperTooltip';
import IdProvider from 'src/components/IdProvider';

export function LoanAmountNow (props: Partial<FormikFormControls.InputGroupControlProps>) {
  return (
    <FormikFormControls.InputGroupControl
      label="Lån att lösa"
      type="number"
      step={1}
      min={0}
      max={800000}
      after={<InputGroup.Text>kr</InputGroup.Text>}
      emptyValue={0}
      required
      {...props}
      name="loan.amount.now"
    />
  );
}

export function LoanAmountNew (props: Partial<FormikFormControls.InputGroupControlProps>) {
  return (
    <FormikFormControls.InputGroupControl
      label="Nylån"
      type="number"
      step={1}
      min={0}
      max={800000}
      after={<InputGroup.Text>kr</InputGroup.Text>}
      emptyValue={0}
      required
      {...props}
      name="loan.amount.new"
    />
  );
}

interface LoanAmountTotalProps {
  value: number;
}

export function LoanAmountTotal (props: LoanAmountTotalProps) {
  const { value } = props;
  return (
    <IdProvider>
      {id => (
        <Form.Group>
          <Form.Label className="mb-1 mt-3" htmlFor={id}>Låneansökan</Form.Label>
          <InputGroup>
            <Form.Control value={value} disabled />
            <InputGroup.Text>kr</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      )}
    </IdProvider>
  );
}

export function LoanRepaymentYears (props: Partial<FormikFormControls.InputGroupControlProps>) {
  return (
    <FormikFormControls.InputGroupControl
      label="Återbetalningstid"
      type="number"
      step={1}
      min={1}
      max={20}
      required
      emptyValue={1}
      after={<InputGroup.Text>år</InputGroup.Text>}
      {...props}
      name="loan.repayment_years"
    />
  );
}

export function LoanPurpose (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Lånesyfte"
      required
      {...props}
      name="loan.purpose"
    >
      <option value="">Välj lånesyfte</option>
      {constants.application.loan_purposes.map((purpose: string) => (
        <option key={purpose} value={purpose}>
          {translations.application.loan_purposes(purpose)}
        </option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

interface ApplicantFirstNameProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantFirstName (props: ApplicantFirstNameProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.first_name' : 'co_applicant.first_name';
  return (
    <FormikFormControls.FormGroupControl
      label={<>Förnamn <UCBadge /></>}
      minLength={1}
      maxLength={255}
      required
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantLastNameProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantLastName (props: ApplicantLastNameProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.last_name' : 'co_applicant.last_name';
  return (
    <FormikFormControls.FormGroupControl
      label={<>Efternamn <UCBadge /></>}
      minLength={1}
      maxLength={255}
      required
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantEmailProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantEmail (props: ApplicantEmailProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.email' : 'co_applicant.email';
  return (
    <FormikFormControls.FormGroupControl
      label="E-postadress"
      type="email"
      minLength={1}
      maxLength={255}
      required
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantTelephoneMobileProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantTelephoneMobile (props: ApplicantTelephoneMobileProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.telephone_mobile' : 'co_applicant.telephone_mobile';
  // TODO validate telephone
  return (
    <FormikFormControls.FormGroupControl
      label="Telefon (mobil)"
      type="tel"
      inputMode="tel"
      minLength={1}
      maxLength={10}
      required
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantAddressStreetProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantAddressStreet (props: ApplicantAddressStreetProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.address.street' : 'co_applicant.address.street';
  return (
    <FormikFormControls.FormGroupControl
      label={<>Gatuadress <UCBadge /></>}
      minLength={1}
      maxLength={255}
      required
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantAddressCityProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantAddressCity (props: ApplicantAddressCityProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.address.city' : 'co_applicant.address.city';
  return (
    <FormikFormControls.FormGroupControl
      label={<>Stad <UCBadge /></>}
      minLength={1}
      maxLength={255}
      required
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantAddressPostcodeProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantAddressPostcode (props: ApplicantAddressPostcodeProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.address.postcode' : 'co_applicant.address.postcode';
  return (
    <FormikFormControls.FormGroupControl
      label={<>Postnummer <UCBadge /></>}
      minLength={5}
      maxLength={5}
      pattern="^\d{5}$"
      required
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantAddressCoProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantAddressCo (props: ApplicantAddressCoProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.address.co' : 'co_applicant.address.co';
  return (
    <FormikFormControls.FormGroupControl
      label={<>C/O <UCBadge /></>}
      minLength={0}
      maxLength={255}
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantStatusProps extends Partial<FormikFormControls.FormGroupSelectProps> {
  co?: boolean;
}

export function ApplicantStatus (props: ApplicantStatusProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.status' : 'co_applicant.status';
  return (
    <FormikFormControls.FormGroupSelect
      label="Civilstånd"
      required
      name={name}
      {...restOfProps}
    >
      <option value="">Välj civilstånd</option>
      {constants.application.applicant_statuses.map((value: string) => (
        <option key={value} value={value}>
          {translations.application.applicant_statuses(value)}
        </option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

interface ApplicantAddressTypeProps extends Partial<FormikFormControls.FormGroupSelectProps> {
  co?: boolean;
}

export function ApplicantAddressType (props: ApplicantAddressTypeProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.address.type' : 'co_applicant.address.type';
  return (
    <FormikFormControls.FormGroupSelect
      label="Boendeform"
      required
      name={name}
      {...restOfProps}
    >
      <option value="">Välj boendeform</option>
      {constants.application.address_types.map((value: string) => (
        <option key={value} value={value}>
          {translations.application.address_types(value)}
        </option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

interface ApplicantChildrenProps extends Partial<FormikFormControls.InputGroupControlProps> {
  co?: boolean;
}

export function ApplicantChildren (props: ApplicantChildrenProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.children' : 'co_applicant.children';
  const label = (
    <>
      Antal barn{' '}
      <HelperTooltip>
        Antal barn med försörjningsplikt den sökande har.
        Om den sökande har delad vårdnad om barnen så ska bara vartannat barn räknas med här, dock lägst ett barn.
      </HelperTooltip>
    </>
  );
  return (
    <FormikFormControls.InputGroupControl
      label={label}
      type="number"
      step={1}
      min={0}
      max={20}
      emptyValue={0}
      required
      after={<InputGroup.Text>st</InputGroup.Text>}
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantAddressSinceProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantAddressSince (props: ApplicantAddressSinceProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.address.since' : 'co_applicant.address.since';
  return (
    <FormikFormControls.FormGroupControl
      label={<>Bosatt på adress sedan <UCBadge /></>}
      type="date"
      max={moment().format('YYYY-MM-DD')}
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantHabitationCostProps extends Partial<FormikFormControls.InputGroupControlProps> {
  co?: boolean;
}

export function ApplicantHabitationCost (props: ApplicantHabitationCostProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.habitation_cost' : 'co_applicant.habitation_cost';
  const label = (
    <>
      Del av boendekostnad{' '}
      <HelperTooltip>
        Sökandes andel av avgift/hyra och driftskostnader. Ska ej inkludera kostnad för bolån.
      </HelperTooltip>
    </>
  );
  return (
    <FormikFormControls.InputGroupControl
      label={label}
      type="number"
      step={1}
      min={0}
      max={50000}
      emptyValue={0}
      required
      after={<InputGroup.Text>kr/mån</InputGroup.Text>}
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantEmploymentTypeProps extends Partial<FormikFormControls.FormGroupSelectProps> {
  co?: boolean;
}

export function ApplicantEmploymentType (props: ApplicantEmploymentTypeProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.employment_type' : 'co_applicant.employment_type';
  return (
    <FormikFormControls.FormGroupSelect
      label="Sysselsättning"
      required
      name={name}
      {...restOfProps}
    >
      <option value="">Välj sysselsättning</option>
      {constants.application.employment_types.map((value: string) => (
        <option key={value} value={value}>
          {translations.application.employment_types(value)}
        </option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

interface ApplicantIncomeProps extends Partial<FormikFormControls.InputGroupControlProps> {
  co?: boolean;
}

export function ApplicantIncome (props: ApplicantIncomeProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.income' : 'co_applicant.income';
  return (
    <FormikFormControls.InputGroupControl
      label="Inkomst före skatt"
      type="number"
      step={1}
      min={0}
      emptyValue={0}
      required
      after={<InputGroup.Text>kr/mån</InputGroup.Text>}
      name={name}
      {...restOfProps}
    />
  );
}

interface ApplicantEmployerSinceProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantEmployerSince (props: ApplicantEmployerSinceProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.employer_since' : 'co_applicant.employer_since';
  return (
    <FormikFormControls.FormGroupControl
      label="Sysselsättning från"
      type="date"
      max={moment().format('YYYY-MM-DD')}
      name={name}
      required
      {...restOfProps}
    />
  );
}

interface ApplicantEmployerUntilProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantEmployerUntil (props: ApplicantEmployerUntilProps) {
  const { co, ...restOfProps } = props;
  const name = !co ? 'main_applicant.employer_until' : 'co_applicant.employer_until';
  return (
    <FormikFormControls.FormGroupControl
      label="Sysselsättning till"
      type="date"
      min={moment().format('YYYY-MM-DD')}
      name={name}
      required
      {...restOfProps}
    />
  );
}

interface ApplicantEmployerNameProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantEmployerName (props: ApplicantEmployerNameProps) {
  const { co, ...restOfProps } = props;
  // TODO autocomplete to find telephone number
  const name = !co ? 'main_applicant.employer_name' : 'co_applicant.employer_name';
  return (
    <FormikFormControls.FormGroupControl
      label="Arbetsgivarens namn"
      name={name}
      required
      {...restOfProps}
    />
  );
}

interface ApplicantEmployerContactProps extends Partial<FormikFormControls.FormGroupControlProps> {
  co?: boolean;
}

export function ApplicantEmployerContact (props: ApplicantEmployerContactProps) {
  const { co, ...restOfProps } = props;
  const name = co ? 'co_applicant.employer_contact' : 'main_applicant.employer_contact';
  // TODO validate telephone
  return (
    <FormikFormControls.FormGroupControl
      label="Telefonnummer"
      type="tel"
      name={name}
      required
      {...restOfProps}
    />
  );
}

export function IncludeCoApplicant (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <FormikFormControls.FormGroupCheck
      label="Inkludera medsökande"
      type="checkbox"
      name="include_co_applicant"
      {...props}
    />
  );
}

function UCBadge () {
  return (
    <Badge bg="info" style={{paddingTop: '2px', paddingBottom: '2px'}}>
      <abbr className="text-decoration-none" title="Fältet hämtas från UC">
        UC
      </abbr>
    </Badge>
  );
}
