import React from 'react';
import insuranceFilter from 'shared/filter/customer_jaycom_insurance';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';

export default function CustomerJaycomInsuranceTablePage () {
  return (
    <TablePage
      title="Försäkringar (Jaycom)"
      pageTitle="Försäkringstabell (Jaycom)"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/customer_jaycom_insurance"
      baseQueryUrl="/customer_jaycom_insurance/table"
      baseQueryKey="CustomerJaycomInsuranceTable"
      queryResultRowsKey="insurances"
    />
  );
}

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.uuid({id: 'id'}),
  commonFilterDefs.jaycomInsuranceStatus({id: 'status'}),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
  commonFilterDefs.dateRange({id: 'status_updated_at'}),
  commonFilterDefs.dateRelative({id: 'status_updated_at_relative'}),
  commonFilterDefs.uuid({id: 'customer_id'}),
  commonFilterDefs.string({id: 'jaycom_customer_id'}),
  commonFilterDefs.string({id: 'jaycom_insurance_id'}),
  commonFilterDefs.userId({
    id: 'created_by_user_id',
    permission: 'customer_jaycom_insurance_table:read_others',
  }),
].map(filterDefinition => ({
  title: insuranceFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.customerJaycomInsuranceId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.customerId({
      id: 'customer_id',
      cellProps: {objKey: 'Customer', idKey: 'customer_id'},
    }),
    commonColumnDefs.applicationId({
      id: 'application_id',
      cellProps: {objKey: 'Application', idKey: 'application_id'},
    }),
    commonColumnDefs.userId({
      id: 'created_by_user_id',
      cellProps: {objKey: 'Creator', idKey: 'created_by_user_id'},
    }),
    commonColumnDefs.basic({id: 'jaycom_customer_id'}),
    commonColumnDefs.basic({id: 'jaycom_insurance_id'}),
    commonColumnDefs.basic({id: 'insured_amount'}),
    commonColumnDefs.basic({id: 'form.monthly_costs'}),
    commonColumnDefs.basic({id: 'form.clearing_no'}),
    commonColumnDefs.basic({id: 'form.account_no'}),
    commonColumnDefs.basic({id: 'cancelled_reason'}),
    commonColumnDefs.basic({
      id: 'status',
      Cell: TableCells.CustomerJaycomInsuranceStatusTableCell,
    }),
    commonColumnDefs.datetime({
      id: 'status_updated_at',
      sortable: true,
    }),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
    }),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = insuranceFilter.indexColumnName(id);
    const cellValue = insurance => insuranceFilter.indexField(insurance, id);
    return {
      ...columnDefinition,
      Cell: columnDefinition.Cell ?? (({value}) => <>{value}</>),
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRow,
  ]),
];
