import React from 'react';
import {
  TableSelectedRows,
  TableStateOrder,
  TableStateColumns,
  TableHeaderGroups,
  TableRow,
} from 'src/tables/types';
import { TableColumnDefinitionMap, TTableParams } from 'src/tables/Table';
import * as tableHelpers from 'src/tables/helpers';
import TableHeadTh from 'src/tables/TableHeadTh';
import flatten from 'lodash/flatten';

interface TableHeadProps {
  columnDefinitionsById: TableColumnDefinitionMap;
  columns?: TableStateColumns;
  order?: TableStateOrder;
  setOrder?: (newOrder: TableStateOrder) => void;
  selectedRows?: TableSelectedRows;
  setSelectedRows?: (rows: TableSelectedRows) => void;
  rows: TableRow[];
  params?: TTableParams;
  headerGroups?: TableHeaderGroups;
}

const TableHead: React.FC<TableHeadProps> = React.memo(function TableHead (props: TableHeadProps) {
  const {
    columnDefinitionsById,
    columns,
    order,
    setOrder,
    selectedRows,
    setSelectedRows,
    rows,
    params,
    headerGroups,
  } = props;

  let columnOrder = Array.isArray(columns) ? columns : Object.keys(columnDefinitionsById);
  if (headerGroups) {
    // when there are header groups, columns can't be toggled and are always shown
    // in the order and according to their header group
    // if a column is not part of a header group, it is not shown at all
    columnOrder = flatten(headerGroups.map(group => group.columns));
  }

  const hasContent = columnOrder.some(columnId => {
    const def = columnDefinitionsById[columnId];
    return def?.Header || def?.title;
  });

  const allRowsSelected = tableHelpers.allRowsSelected(rows, selectedRows);
  const anyRowsSelected = tableHelpers.anyRowsSelected(rows, selectedRows);

  const handleChangeAllSelected = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    if (!setSelectedRows) return;
    const value = ev.target.checked;
    const newSelectedRows = tableHelpers.selectAllRows(rows, selectedRows, value);
    setSelectedRows(newSelectedRows);
  }, [setSelectedRows, rows, selectedRows]);

  if (!hasContent) {
    return null;
  }

  return (
    <thead className="border-bottom">
      {headerGroups && (
        <tr>
          {headerGroups.map(group => (
            <th key={group.id} colSpan={group.columns.length}>
              {group.title}
            </th>
          ))}
        </tr>
      )}
      <tr>
        {columnOrder.map(columnId => (
          <TableHeadTh
            key={columnId}
            columnDefinition={columnDefinitionsById[columnId]}
            order={order}
            setOrder={setOrder}
            allRowsSelected={allRowsSelected}
            anyRowsSelected={anyRowsSelected}
            onChangeAllSelected={handleChangeAllSelected}
            params={params}
          />
        ))}
      </tr>
    </thead>
  );
});
export default TableHead;
