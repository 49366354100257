import React from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from 'src/api';

interface BankSelectOption {
  value: string;
  label: string;
}

interface BankSelectOptionsProps {
  extendQueryWith?: Record<string, string>;
}

export default function BankSelectOptions (props: BankSelectOptionsProps) {
  const { extendQueryWith = {} } = props;
  return (
    <BankSelectOptionsProvider extendQueryWith={extendQueryWith}>
      {options => (
        <>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </>
      )}
    </BankSelectOptionsProvider>
  );
}

interface BankSelectOptionsProviderProps {
  extendQueryWith?: Record<string, any>;
  children: (options: BankSelectOption[]) => React.ReactNode;
}

export function BankSelectOptionsProvider (props: BankSelectOptionsProviderProps) {
  const { children, extendQueryWith = {} } = props;

  const query = useQuery<BankSelectOption[]>({
    queryKey: ['bankSelectOptions', extendQueryWith],
    queryFn: async () => {
      const list = await api.request({
        url: '/bank/selectOptions',
        params: {is_active: true, ...extendQueryWith},
      });
      const options = list.map(bank => ({value: bank.id, label: bank.name ?? bank.id}));
      return options;
    },
  });

  if (!Array.isArray(query.data)) {
    return null;
  }

  return children(query.data);
}
