import React from 'react';
import ErrorAlert from 'src/alerts/ErrorAlert';
import OverlaySpinner, { OverlaySpinnerContainer } from 'src/spinners/OverlaySpinner';
import { ErrorType } from 'src/utils/error';
import DateTime from 'src/formatters/DateTime';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import {Table} from 'react-bootstrap';
import BlockSpinner from '../spinners/BlockSpinner';

// a simple helper showing a spinner and an error for wired widgets
interface WidgetLoaderProps extends React.PropsWithChildren {
  isLoading: boolean;
  error?: ErrorType | null;
}
export const WidgetLoader: React.FC<WidgetLoaderProps> = React.memo(function WidgetLoader (props: WidgetLoaderProps) {
  const { children, isLoading, error } = props;
  return (
    <div>
      <ErrorAlert error={error} className="mx-3 mt-3 mb-2" />
      <BlockSpinner isLoading={isLoading} className="m-3" />
      {children}
    </div>
  );
});

// most log rows should satisfy this interface
interface WidgetLogItem {
  id: string;
  date: string | Date;
  data: Record<string, any>;
}

interface WidgetTimelineProps<T extends WidgetLogItem = WidgetLogItem> {
  className?: string;
  list: T[];
  description: (item: T) => string;
  by?: (item: T) => React.ReactNode;
}
export const WidgetTimeline: React.FC<WidgetTimelineProps> = React.memo(function WidgetTimeline (props: WidgetTimelineProps) {
  const { className, list, description, by } = props;
  return (
    <div className={className}>
      <ul className="timeline">
        {list.map(item => (
          <li key={item.id} className="timeline-item">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <strong><DateTime value={item.date} /></strong>
              <div className="d-flex gap-1 align-items-center justify-content-between">
                {by && (
                  <span className="text-muted text-sm">{by(item)}</span>
                )}
                <InspectObjectModalButton object={item} size="sm" className="px-0" />
              </div>
            </div>
            <p>
              {description(item)}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
});

type PropertyItem = {
  label: string;
  value: string | React.ReactNode;
};

interface WidgetPropertyListTableProps {
  list: PropertyItem[];
}

export function WidgetPropertyListTable (props: WidgetPropertyListTableProps)  {
  const { list } = props;
  return (
    <Table size="sm" className="mb-0" striped>
      <tbody>
        {list.map((item, index) => (
          <tr key={index}>
            <th>{item.label}</th>
            <td>{item.value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
