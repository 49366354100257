import React from 'react';
import { WidgetGroups, WidgetList } from 'src/widgets/types';
import DateTime from 'src/formatters/DateTime';
import * as complaintFilter from 'shared/filter/complaint';
import { WidgetPropertyListTable } from 'src/widgets/CommonWidgets';
import { WidgetListCard } from 'src/cards/CardHelpers';
import { ComplaintRow } from 'shared/types/complaint';

interface ComplaintWidgetProps {
  complaint: ComplaintRow;
}

export const widgetGroups: WidgetGroups = [
  {id: 'properties', title: 'Egenskaper'},
];

export const allWidgets: WidgetList = {
  properties: {
    id: 'properties',
    Component: Properties,
    title: 'Egenskaper',
  },
};

export function Properties (props: ComplaintWidgetProps) {
  const { complaint } = props;
  const list = [
    {label: 'Prioritet', value: <>{complaintFilter.priority(complaint.priority)}</>},
    {label: 'Skapad', value: <DateTime value={complaint.created_at} />},
    {label: 'Uppdaterad', value: <DateTime value={complaint.updated_at} />},
  ];
  return (
    <WidgetListCard {...props}>
      <WidgetPropertyListTable list={list} />
    </WidgetListCard>
  );
}
