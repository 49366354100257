import React from 'react';
import workFilter from 'shared/filter/work';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';

export default function WorkTablePage () {
  return (
    <TablePage
      title="Jobblogg"
      pageTitle="Jobbloggstabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/work"
      baseQueryUrl="/work/table"
      baseQueryKey="WorkTable"
    />
  );
}

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.workStatus({id: 'status'}),
  commonFilterDefs.string({id: 'id'}),
  commonFilterDefs.workTask({id: 'task'}),
  commonFilterDefs.string({id: 'unique_key'}),
  commonFilterDefs.json({id: 'match'}),
  commonFilterDefs.dateTimeRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateTimeRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
  commonFilterDefs.dateTimeRange({id: 'started_at'}),
  commonFilterDefs.dateRelative({id: 'started_at_relative'}),
  commonFilterDefs.dateTimeRange({id: 'finished_at'}),
  commonFilterDefs.dateRelative({id: 'finished_at_relative'}),
].map(filterDefinition => ({
  title: workFilter.columnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: TableColumnDefinition[] = [
  ...([
    commonColumnDefs.workId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.cell('task'),
    commonColumnDefs.cell('unique_key'),
    commonColumnDefs.cell('msg', TableCells.JSONStringifyTableCell, {cellKey: 'msg'}),
    commonColumnDefs.cell('error', TableCells.JSONStringifyTableCell, {cellKey: 'error'}),
    commonColumnDefs.cell('log', TableCells.JSONStringifyTableCell, {cellKey: 'log'}),
    commonColumnDefs.cell('status', TableCells.WorkStatusTableCell),
    commonColumnDefs.cell('retry_remaining'),
    commonColumnDefs.cell('retry_delay'),
    commonColumnDefs.cell('notify', TableCells.JSONStringifyTableCell, {cellKey: 'notify'}),
    commonColumnDefs.cell('result', TableCells.JSONStringifyTableCell, {cellKey: 'result'}),
    commonColumnDefs.wrapSearch(commonColumnDefs.cell('first_work_id', TableCells.BasicCell, {idKey: 'first_work_id'})),
    commonColumnDefs.wrapSearch(commonColumnDefs.cell('prev_work_id', TableCells.BasicCell, {idKey: 'prev_work_id'})),
    commonColumnDefs.datetime({
      id: 'started_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'finished_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.interval({id: 'time_elapsed'}),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
  ].map((columnDefinition: any) => {
    const { id } = columnDefinition;
    const header = workFilter.columnName(id);
    const cellValue = work => workFilter.field(work, id);
    return {
      ...columnDefinition,
      Cell: columnDefinition.Cell ?? (({value}) => <>{value}</>),
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRow,
  ]),
];
