import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { CustomerRow } from 'shared/types/customer';
import AccountNumberValidatorCard from 'src/cards/AccountNumberValidatorCard';
import * as CustomerFormFields from 'src/customer/CustomerFormFields';
import {useFormikContext} from 'formik';
import FormikSubmitButton from 'src/buttons/FormikSubmitButton';
import {Form} from 'formik';
import * as formUtils from 'src/utils/form';
import CustomerUpdateFormCardBuilder from 'src/customer/CustomerUpdateFormCardBuilder';

type Fields =
  | 'first_name'
  | 'last_name'
  | 'telephone_mobile'
  | 'email'
  | 'risk'
  | 'preferred_language'
  | 'address_city'
  | 'address_street'
  | 'address_postcode'
  | 'address_co'
  | 'bank_account_number'
  | 'bank_clearing_number'
;

type FormValues = Pick<CustomerRow, Fields>;

interface CustomerPersonalInfoFormCardProps {
  customer: CustomerRow;
}

export default function CustomerPersonalInfoFormCard (props: CustomerPersonalInfoFormCardProps) {
  const { customer } = props;

  return (
    <CustomerUpdateFormCardBuilder
      title="Kunduppgifter"
      customer={customer}
      customerToFormValues={customerToFormValues}
      formValuesToUpdate={formValuesToUpdate}
    >
      <Form placeholder="LOL">
        <Card.Body className="py-0 mb-3">
          <FormFields />
        </Card.Body>
        <Card.Footer className="border-top d-flex align-items-center gap-2">
          <FormikSubmitButton>
            Spara kunduppgifter
          </FormikSubmitButton>
        </Card.Footer>
      </Form>
    </CustomerUpdateFormCardBuilder>
  );
}

function FormFields () {
  const formProps = useFormikContext<FormValues>();
  return (
    <div>
      <Row>
        <Col>
          <CustomerFormFields.FirstName />
        </Col>

        <Col>
          <CustomerFormFields.LastName />
        </Col>
      </Row>

      <Row>
        <Col>
          <CustomerFormFields.TelephoneMobile />
        </Col>
        <Col>
          <CustomerFormFields.Email />
        </Col>
      </Row>

      <Row>
        <Col>
          <CustomerFormFields.PreferredLanguage />
        </Col>
        <Col>
          <CustomerFormFields.Risk />
        </Col>
      </Row>

      <h3 className="mb-0 mt-4">Adressuppgifter</h3>
      <hr className="mb-0 mt-1" />

      <Row>
        <Col>
          <CustomerFormFields.AddressStreet />
        </Col>
        <Col>
          <CustomerFormFields.AddressPostcode />
        </Col>
      </Row>

      <Row>
        <Col>
          <CustomerFormFields.AddressCo />
        </Col>
        <Col>
          <CustomerFormFields.AddressCity />
        </Col>
      </Row>

      <h3 className="mb-0 mt-4">Konto för utbetalning</h3>
      <hr className="mb-0 mt-1" />

      <Row>
        <Col>
          <CustomerFormFields.BankClearingNumber />
        </Col>
        <Col>
          <CustomerFormFields.BankAccountNumber />
        </Col>
      </Row>

      <AccountNumberValidatorCard
        className="mt-3 mb-0"
        clearingNumber={formProps.values.bank_clearing_number}
        accountNumber={formProps.values.bank_account_number}
      />


    </div>
  );
}

function customerToFormValues (customer: CustomerRow): FormValues {
  const {
    first_name,
    last_name,
    telephone_mobile,
    email,
    preferred_language,
    risk,
    address_city,
    address_street,
    address_postcode,
    address_co,
    bank_account_number,
    bank_clearing_number,
  } = customer;
  return {
    first_name: first_name || '',
    last_name: last_name || '',
    telephone_mobile: telephone_mobile || '',
    email: email || '',
    preferred_language: preferred_language || 'sv',
    risk: risk || 'medium',
    address_city: address_city || '',
    address_street: address_street || '',
    address_postcode: address_postcode || '',
    address_co: address_co || '',
    bank_account_number: bank_account_number || '',
    bank_clearing_number: bank_clearing_number || '',
  };
}

function formValuesToUpdate (initialValues: FormValues, values: FormValues): Partial<CustomerRow> {
  const changes = formUtils.changes(initialValues, values);
  const update: Partial<CustomerRow> = {};
  if (typeof changes.first_name !== 'undefined') update.first_name = changes.first_name || null;
  if (typeof changes.last_name !== 'undefined') update.last_name = changes.last_name || null;
  if (typeof changes.email !== 'undefined') update.email = changes.email || null;
  if (typeof changes.telephone_mobile !== 'undefined') update.telephone_mobile = changes.telephone_mobile || null;
  if (typeof changes.risk !== 'undefined') update.risk = changes.risk;
  if (typeof changes.preferred_language !== 'undefined') update.preferred_language = changes.preferred_language;
  if (typeof changes.address_city !== 'undefined') update.address_city = changes.address_city || null;
  if (typeof changes.address_street !== 'undefined') update.address_street = changes.address_street || null;
  if (typeof changes.address_postcode !== 'undefined') update.address_postcode = changes.address_postcode || null;
  if (typeof changes.address_co !== 'undefined') update.address_co = changes.address_co || null;
  if (typeof changes.bank_account_number !== 'undefined') update.bank_account_number = changes.bank_account_number || null;
  if (typeof changes.bank_clearing_number !== 'undefined') update.bank_clearing_number = changes.bank_clearing_number || null;
  return update;
}
