import React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { SIDEBAR_BEHAVIOR, THEME } from 'src/constants';
import {
  PieChart,
  Settings,
  Tool,
  LogOut,
  Sun,
  Moon,
  Minimize2,
  Maximize2,
} from 'react-feather';
import useSidebar from 'src/hooks/useSidebar';
import useTheme from 'src/hooks/useTheme';
import useAuth from 'src/hooks/useAuth';
import { AvatarImg } from 'src/user/UserFormatters';

export default function NavbarUser () {
  const { behavior, setBehavior } = useSidebar();
  const { theme, setTheme } = useTheme();
  const { user, logout } = useAuth();

  const onCycleTheme = () => {
    const nextValue = theme === THEME.DARK ? THEME.LIGHT : THEME.DARK;
    setTheme(nextValue);
  };

  const onCycleBehavior = () => {
    const nextValue = behavior === SIDEBAR_BEHAVIOR.STICKY ? SIDEBAR_BEHAVIOR.COMPACT : SIDEBAR_BEHAVIOR.STICKY;
    setBehavior(nextValue);
  };

  const themeId = React.useId();
  const behaviorId = React.useId();

  const onClickLogout = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    logout();
  };

  return (
    <Dropdown className="nav-item" align="end" autoClose="outside">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <AvatarImg
            user={user}
            className="avatar me-1"
          />
          <span className="text-dark">{user?.name}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        <Dropdown.Item>
          <Tool size={18} className="align-middle me-2" />
          Inställningar
        </Dropdown.Item>
        <Dropdown.Item>
          <PieChart size={18} className="align-middle me-2" />
          Min statistik
        </Dropdown.Item>
        <Dropdown.Divider />
        <div className="px-2">
          <Form.Switch
            id={themeId}
            label={<><Sun size={15} /> / <Moon size={15} /> Tema</>}
            name="theme"
            checked={theme === THEME.DARK}
            onChange={onCycleTheme}
          />
        </div>
        <Dropdown.Divider />
        <div className="px-2">
          <Form.Switch
            id={behaviorId}
            label={<><Minimize2 size={15} /> / <Maximize2 size={15} /> Meny</>}
            name="behavior"
            checked={behavior === SIDEBAR_BEHAVIOR.STICKY}
            onChange={onCycleBehavior}
          />
        </div>
        <Dropdown.Divider />
        <Dropdown.Item as="button" onClick={onClickLogout} type="button">
          <LogOut size={18} className="align-middle me-2" />
          Logga ut
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
