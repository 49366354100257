import React, {useMemo} from 'react';
import UserAnchor from 'src/user/UserAnchor';
import { ComplaintNote } from 'shared/types/complaint';
import DateTime from 'src/formatters/DateTime';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import * as ComplaintFormatters from 'src/complaint/ComplaintFormatters';
import * as complaintFilter from 'shared/filter/complaint';
import { nl2br } from 'shared/format';
import striptags from 'striptags';

interface ComplaintNoteItemProps {
  className?: string;
  note: ComplaintNote;
}

export default function ComplaintNoteItem (props: ComplaintNoteItemProps) {
  const { className, note } = props;

  const textAsHtml = useMemo(() => {
    return striptags(nl2br(note.text), {allowedTags: new Set(['br'])});
  }, [note.text]);

  return (
    <Card className={classNames(className, 'border')}>
      <Card.Header className="border-bottom p-2 d-flex justify-content-between">
        <div className="d-flex gap-2 align-items-center">
          {note.type === 'created' ? (
            <span>Klagomål skapat av</span>
          ) : (
            <span>Uppdatering av</span>
          )}
          {note.user_id && (
            <UserAnchor user={{id: note.user_id}} />
          )}
          <DateTime className="small" value={note.date} />
        </div>
      </Card.Header>
      {note.text && (
        <Card.Body className="p-2 border-bottom" dangerouslySetInnerHTML={{__html: textAsHtml}} />
      )}
      {(note.status || note.priority) && (
        <Card.Footer className="d-flex flex-wrap gap-2 align-items-center p-2">
          {note.status && (
            <span><strong>Ny status:</strong> <ComplaintFormatters.StatusLabel className="d-inline-flex" short value={note.status} /></span>
          )}
          {note.priority && (
            <span><strong>Ny prioritet:</strong> {complaintFilter.priority(note.priority)}</span>
          )}
        </Card.Footer>
      )}
    </Card>
  );
}
