import React, {useState} from 'react';
import classNames from 'classnames';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ApplicationLogRowWithRelations } from 'shared/types/application_log';
import * as bankProcessLogFilters from 'shared/filter/bank_process_log';
import { TableStateOrder  } from 'src/tables/types';
import { Card } from 'react-bootstrap';
import { Activity } from 'react-feather';
import CardBodyTable from 'src/tables/CardBodyTable';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';

interface BankProcessLogCardProps {
  className?: string;
  bankProcessId: string;
}

export default function BankProcessLogCard (props: BankProcessLogCardProps) {
  const { bankProcessId, className } = props;

  const [order, setOrder] = useState<TableStateOrder>({date: 'desc'});

  const query = useQuery<{logs: ApplicationLogRowWithRelations[]}, Error>({
    queryKey: [`/bank_process/${bankProcessId}/log`, {params: {order}}],
    placeholderData: keepPreviousData,
  });

  const rows = query.data?.logs ?? [];

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="bg-light">
        <CardTitleIcon
          title="Processlogg"
          Icon={<Activity size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <CardBodyTable
        striped
        order={order}
        columnDefinitions={columnDefs}
        setOrder={setOrder}
        rows={rows}
        isFetched={query.isFetched}
      />
    </Card>
  );
}

const columnDefs = [
  commonColumnDefs.datetime({
    id: 'date',
    title: 'Datum',
    sortable: true,
  }),
  commonColumnDefs.userId({
    id: 'user_id',
    title: 'Utförare',
    sortable: true,
    cellProps: {objKey: 'User', value: 'System'},
  }),
  commonColumnDefs.basic({
    id: 'description',
    title: 'Beskrivning',
    Cell: props => <>{bankProcessLogFilters.description(props.row)}</>,
  }),
  commonColumnDefs.inspect(),
];
