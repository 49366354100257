const settings = {
  loanClamps: {
    minYears: 1,
    maxYears: 12,
    minTotal: 20000,
    maxTotal: 350000,
  },
  accept: {
    canHaveCoApplicant: true,
    accountNoInput: true,
  },
  change: {
    changedByResubmitting: true,
    amountNewInput: true,
  },
};

export default settings;
