exports.indexColumnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case 'id': return 'ID';
    case 'name': return 'Namn';
    case 'is_active': return 'Aktiv';
    case 'created_at': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
  }
};

exports.status = function (isActive) {
  return isActive ? 'Aktiv' : 'Inaktiv';
};

exports.statusValue = function (isActive) {
  return isActive ? 'active' : 'inactive';
};
