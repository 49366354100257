import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import * as api from 'src/api';
import ErrorAlert from 'src/alerts/ErrorAlert';
import StatusLabelContainer from  'src/components/StatusLabelContainer';
import { StatusLabel } from  'src/email/EmailFormatters';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EmailRow } from 'shared/types/email';
import * as formUtils from 'src/utils/form';
import EmailEditForm, { EmailEditFormValues, emailToFormValues, formValuesToUpdate } from 'src/email/EmailEditForm';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useEventModelEmitDeletes, useEventModelEmitUpdates } from 'src/hooks/useEventModels';

interface EmailModalBodyProps {
  id: string;
}

export default function EmailModalBody (props: EmailModalBodyProps) {
  const { id } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();

  const emitUpdate = useEventModelEmitUpdates<EmailRow>('email');
  const emitDelete = useEventModelEmitDeletes<EmailRow>('email');

  const query = useQuery<EmailRow, Error>({
    queryKey: [`/email/${id}`],
  });

  const email = query.data;

  const updateMutation = useMutation<EmailRow, Error, Partial<EmailEditFormValues>>({
    mutationFn: async vars => {
      const email = await api.request({
        url: `/email/${id}`,
        method: 'PATCH',
        data: vars,
      });

      queryClient.setQueryData([`/email/${id}`], email);
      notyf.success({type: 'default', message: 'E-postmeddelandet uppdaterades'});
      modalStateContext.onHide();
      emitUpdate(email);

      return email;
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: async () => {
      await api.request({
        url: `/email/${id}`,
        method: 'DELETE',
      });

      notyf.success({type: 'danger', message: 'E-postmeddelandet raderades'});
      if (query.data) emitDelete(query.data);
      modalStateContext.onHide();
    },
  });

  const onDelete = async () => {
    return deleteMutation.mutateAsync();
  };

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<EmailRow, EmailEditFormValues, Partial<EmailEditFormValues>>({
    queryDataToFormValues: emailToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(emailToFormValues(email), values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading} />
      {email && (
        <>
          <StatusLabelContainer className="mt-3">
            <StatusLabel value={email.status} />
          </StatusLabelContainer>
          <EmailEditForm
            className="mt-0"
            disabled={email.status !== 'new'}
            initialValues={emailToFormValues(email)}
            onSubmit={formCycleHelpers.onSubmit}
            submitError={updateMutation.error}
            onDelete={email.status === 'new' ? onDelete : undefined}
          />
        </>
      )}
    </div>
  );
}
