import React from 'react';
import insuranceFilter from 'shared/filter/customer_cloud_insurance';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';

export default function CustomerCloudInsuranceTablePage () {
  return (
    <TablePage
      title="Försäkringar (Cloud)"
      pageTitle="Försäkringstabell (Cloud)"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/customere_cloud_insurance"
      baseQueryUrl="/customer_cloud_insurance/table"
      baseQueryKey="CustomerCloudInsuranceTable"
      queryResultRowsKey="insurances"
    />
  );
}

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.insuranceStatus({id: 'status'}),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
  commonFilterDefs.dateRange({id: 'status_updated_at'}),
  commonFilterDefs.dateRelative({id: 'status_updated_at_relative'}),
  commonFilterDefs.uuid({id: 'customer_id'}),
  commonFilterDefs.string({id: 'cloudinsurance_id'}),
  commonFilterDefs.string({id: 'cloudinsurance_quote_id'}),
  commonFilterDefs.string({id: 'cloudinsurance_token'}),
  commonFilterDefs.userId({
    id: 'created_by_user_id',
    permission: 'customer_cloud_insurance_table:read_others',
  }),
].map(filterDefinition => ({
  title: insuranceFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.customerCloudInsuranceId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.customerId({
      id: 'customer_id',
      cellProps: {objKey: 'Customer', idKey: 'customer_id'},
    }),
    commonColumnDefs.applicationId({
      id: 'application_id',
      cellProps: {objKey: 'Application', idKey: 'application_id'},
    }),
    commonColumnDefs.userId({
      id: 'created_by_user_id',
      cellProps: {objKey: 'Creator', idKey: 'created_by_user_id'},
    }),
    commonColumnDefs.basic({id: 'cloudinsurance_id'}),
    commonColumnDefs.basic({id: 'cloudinsurance_token'}),
    commonColumnDefs.basic({id: 'cloudinsurance_url'}),
    commonColumnDefs.basic({id: 'cloudinsurance_quote_id'}),
    commonColumnDefs.basic({
      id: 'status',
      Cell: TableCells.CustomerCloudInsuranceStatusTableCell,
    }),
    commonColumnDefs.datetime({
      id: 'status_updated_at',
      sortable: true,
    }),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
    }),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = insuranceFilter.indexColumnName(id);
    const cellValue = customer => insuranceFilter.indexField(customer, id);
    return {
      ...columnDefinition,
      Cell: columnDefinition.Cell ?? (({value}) => <>{value}</>),
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRow,
  ]),
];
