import React from 'react';
import { ComplaintRowWithRelations } from 'shared/types/complaint';
import AssociatedObjectList from 'src/components/AssociatedObjectList';
import {oldCrmUrl} from 'src/misc';

interface ComplaintAssociatedObjectListProps {
  complaint: ComplaintRowWithRelations;
}

function ComplaintAssociatedObjectList (props: ComplaintAssociatedObjectListProps) {
  const { complaint } = props;

  const associatedObjects = [
    complaint.customer_id ? {type: 'customer', value: complaint.Customer ?? {id: complaint.customer_id}, label: 'Kund'} : null,
  ].filter(v => v);

  const externalLinks = [
    {url: oldCrmUrl(`/complaint/${complaint.id}`), label: 'Gamla CRM'},
  ].filter(v => v);

  return (
    <AssociatedObjectList
      associatedObjects={associatedObjects}
      externalLinks={externalLinks}
    />
  );
}
export default React.memo(ComplaintAssociatedObjectList);
