import React from 'react';
import applicationTriggerFilter from 'shared/filter/application_trigger';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';

export default function ApplicationTriggerTablePage () {
  return (
    <TablePage
      title="Triggerkörningar (ansökning)"
      pageTitle="Triggerkörningar (ansökning)"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/application_triggers"
      baseQueryUrl="/application_trigger/table"
      baseQueryKey="ApplicationTriggerTable"
    />
  );
}

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.uuid({id: 'id'}),
  commonFilterDefs.uuid({id: 'work_trigger_template_id'}),
  commonFilterDefs.applicationTriggerStatus({id: 'status'}),
  commonFilterDefs.applicationId({id: 'application_id'}),
  commonFilterDefs.string({id: 'work_id'}),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'scheduled_at'}),
  commonFilterDefs.dateRelative({id: 'scheduled_at_relative'}),
  commonFilterDefs.dateRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
].map(filterDefinition => ({
  title: applicationTriggerFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: TableColumnDefinition[] = [
  ...([
    commonColumnDefs.cell('id', TableCells.IdShortTableCell),
    commonColumnDefs.cell('application_id', TableCells.ApplicationTableCell, {idKey: 'application_id'}),
    commonColumnDefs.wrapSearch(commonColumnDefs.cell('work_trigger_template_id', TableCells.WorkTriggerTemplateTableCell, {idKey: 'work_trigger_template_id'})),
    commonColumnDefs.cell('work_id', TableCells.WorkTableCell, {
      idKey: 'work_id',
      objKey: 'Work',
      showStatus: true,
    }),
    commonColumnDefs.cell('task'),
    commonColumnDefs.cell('description'),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'scheduled_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.cell('status', TableCells.ApplicationTriggerStatusTableCell),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = applicationTriggerFilter.indexColumnName(id);
    const cellValue = row => applicationTriggerFilter.indexField(row, id);
    return {
      ...columnDefinition,
      Cell: columnDefinition.Cell ?? (({value}) => <>{value}</>),
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRow,
  ]),
];
