import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import { BankProcessRow } from 'shared/types/bank_process';
import { InputGroup } from 'react-bootstrap';
import constants from 'shared/constants';
import * as bankProcessFilter from 'shared/filter/bank_process';

export function CreatedAt (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      type="date"
      label="Skapad"
      name="created_at"
      required
      disabled
      {...props}
    />
  );
}

export function ClosedAt (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      type="date"
      label="Stängd"
      name="closed_at"
      {...props}
    />
  );
}

export function AcceptedAt (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      type="date"
      label="Accepterad"
      name="accepted_at"
      {...props}
    />
  );
}

interface DisbursedProps extends Partial<FormikFormControls.InputGroupControlProps> {
  bankProcessStatus?: BankProcessRow['status'];
}

export function Disbursed (props: DisbursedProps) {
  const { bankProcessStatus:status, ...restOfProps } = props;
  let label = 'Summa';
  if (status === 'disbursed') label = 'Utbetald summa';
  if (status === 'accepted') label = 'Accepterad summa';
  return (
    <FormikFormControls.InputGroupControl
      label={label}
      type="number"
      step={1}
      min={0}
      name="disbursed"
      after={<InputGroup.Text>kr</InputGroup.Text>}
      {...restOfProps}
    />
  );
}

export function ClosedReason (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Stängningsorsak"
      name="closed_reason"
      {...props}
    >
      <option value="">{props.disabled ? 'Ej applicerbart' : 'Ingen orsak angiven'}</option>
      {constants.bank_process.closed_reasons.map((reason: string) => (
        <option key={reason} value={reason}>{bankProcessFilter.closedReason(reason)}</option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}
