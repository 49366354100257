import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';

export function FirstName (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Mottagarens förnamn"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="first_name"
    />
  );
}

export function LastName (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Mottagarens efternamn"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="last_name"
    />
  );
}

export function Street (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Gatuadress"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="street"
    />
  );
}

export function CO (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="C/O"
      minLength={1}
      maxLength={255}
      {...props}
      name="co"
    />
  );
}

export function Postcode (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Postnummer"
      minLength={1}
      maxLength={255}
      inputMode="numeric"
      pattern="^\d+$"
      {...props}
      name="postcode"
    />
  );
}

export function City (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Stad"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="city"
    />
  );
}

export function Body (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Meddelande"
      as="textarea"
      rows={10}
      minLength={0}
      maxLength={10000}
      required
      {...props}
      name="body"
    />
  );
}
