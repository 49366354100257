import React from 'react';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import FormControlSpinner from 'src/spinners/FormControlSpinner';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import ApplicationAnchor from 'src/application/ApplicationAnchor';
import BankProcessAnchor from 'src/bankProcess/BankProcessAnchor';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import { useQuery, useMutation } from '@tanstack/react-query';
import * as api from 'src/api';
import { StatusLabel as ApplicationStatusLabel } from 'src/application/ApplicationFormatters';
import { StatusLabel as BankProcessStatusLabel } from 'src/bankProcess/BankProcessFormatters';
import { StatusLabel as CustomerStatusLabel } from 'src/customer/CustomerFormatters';
import {
  Card,
  Table,
  Form,
} from 'react-bootstrap';
import {
  Heart,
  Repeat,
  Box,
} from 'react-feather';
import { Formik, Field } from 'formik';
import { AccountFavoriteRowListed, FavoriteType } from 'shared/types/account_favorite';
import CustomerAnchor from 'src/customer/CustomerAnchor';

export default function FavoritesCard () {

  const query = useQuery<AccountFavoriteRowListed[]>({
    queryKey: ['/account_favorite'],
  });

  const rows = query.data ?? [];

  return (
    <Card className="flex-fill w-100 border">
      <Card.Header className="pb-1">
        <CardTitleIcon title="Favoriter" Icon={<Heart size={18} />} spinning={query.isRefetching}>
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <Card.Body className="p-0 position-relative">
        <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={query.isLoading} />
        <div className="table-responsive">
          <Table striped className="my-0 border-bottom">
            <thead>
              <tr>
                <th>Typ</th>
                <th>Länk</th>
                <th className="d-none d-lg-table-cell">Status</th>
                <th>Eget notat</th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && rows.map(row => (
                <FavoriteRow key={row.id} row={row} />
              ))}
              {!query.data?.length && (
                <tr>
                  <td colSpan={4}>Det finns inga favoriter än</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
}

interface FavoriteRowProps {
  row: AccountFavoriteRowListed;
}

function FavoriteRow (props: FavoriteRowProps) {
  const { row:favorite } = props;
  return (
    <tr key={favorite.id}>
      <td>
        <FavoriteTypeIcon type={favorite.type} />
      </td>
      <td>
        {favorite.type === 'application' && (
          <ApplicationAnchor application={favorite.props} />
        )}
        {favorite.type === 'bank_process' && (
          <BankProcessAnchor bankProcess={favorite.props} />
        )}
        {favorite.type === 'customer' && (
          <CustomerAnchor customer={favorite.props as any} />
        )}
      </td>
      <td className="d-none d-lg-table-cell">
        {favorite.type === 'application' && (
          <ApplicationStatusLabel
            short
            value={favorite.props.status}
            hasAcceptedProcess={favorite.props.has_accepted_process}
          />
        )}
        {favorite.type === 'bank_process' && (
          <BankProcessStatusLabel
            short
            value={favorite.props.status}
          />
        )}
        {favorite.type === 'customer' && (
          <CustomerStatusLabel
            short
            value={favorite.props.status}
          />
        )}
      </td>
      <td>
        <FavoriteNoteForm
          id={favorite.id}
          note={favorite.note}
        />
      </td>
    </tr>
  );
}

function FavoriteNoteForm (props) {
  const { id, note } = props;

  const mutation = useMutation({
    mutationFn: data => api.request({
      method: 'patch',
      url: `/account_favorite/${id}`,
      data,
    }),
  });

  const initialValues = {note};

  const onSubmit = async (data, formProps) => {
    const result = await mutation.mutateAsync(data);
    formProps.resetForm({values: {note: result.note}});
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="position-relative">
            <Field name="note">
              {fieldProps => (
                <Form.Control
                  {...fieldProps.field}
                  maxLength="200"
                  onBlur={ev => {
                    fieldProps.field.onBlur(ev);
                    if (formProps.dirty) {
                      formProps.submitForm();
                    }
                  }}
                />
              )}
            </Field>
            <FormControlSpinner isLoading={formProps.isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  );
}

interface FavoriteTypeIconProps {
  type: FavoriteType;
}
function FavoriteTypeIcon (props: FavoriteTypeIconProps) {
  const { type } = props;
  return (
    <abbr title={favoriteTypeTitle(type)}>
      {type === 'application' && <Box size={16} />}
      {type === 'bank_process' && <Repeat size={16} />}
      {type === 'customer' && <Heart size={16} />}
    </abbr>
  );
}

function favoriteTypeTitle (type: FavoriteType): string {
  switch (type) {
    default: return '';
    case 'application': return 'Ansökan';
    case 'bank_process': return 'Process';
    case 'customer': return 'Kund';
  }
}
