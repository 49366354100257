import React, {useState} from 'react';
import ButtonReplacedSpinner, {ButtonReplacedSpinnerProps} from 'src/spinners/ButtonReplacedSpinner';
import ConfirmActionModal, {ConfirmActionModalProps} from 'src/modals/ConfirmActionModal';
import { TableColumnCellProps } from 'src/tables/Table';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import {useTableContext} from 'src/tables/TableContext';
import {TableUpdateRowMutationVars} from 'src/tables/types';

export const InspectRow: React.FC<TableColumnCellProps> = React.memo(function InspectRow (props: TableColumnCellProps) {
  return (
    <InspectObjectModalButton size="sm" object={props.row} />
  );
});

interface ExecuteButtonProps extends TableColumnCellProps {
  variant?: string;
  title?: string;
  mutateVars: TableUpdateRowMutationVars;
  buttonProps?: Partial<ButtonReplacedSpinnerProps>;
}

export const ExecuteButton: React.FC<ExecuteButtonProps> = React.memo(function ExecuteButton (props: ExecuteButtonProps) {
  const {
    mutateVars,
    variant = 'outline-primary',
    title,
    children,
    buttonProps = {},
  } = props;

  const tableContext = useTableContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mutateAsync = tableContext.updateRowMutation.mutateAsync;

  const onClick = React.useCallback(() => {
    if (!tableContext.updateRowMutation) return null;
    setIsLoading(true);
    tableContext.updateRowMutation.mutateAsync(mutateVars).finally(() => {
      setIsLoading(false);
    });
  }, [setIsLoading, mutateVars, tableContext.updateRowMutation]);

  if (!mutateAsync) return null;

  return (
    <ButtonReplacedSpinner
      className="py-0 px-1"
      isLoading={isLoading}
      onClick={onClick}
      variant={variant}
      title={title}
      {...buttonProps}
    >
      {children}
    </ButtonReplacedSpinner>
  );
});

interface ExecuteModalConfirmButtonProps extends TableColumnCellProps {
  variant?: string;
  title?: string;
  mutateVars: TableUpdateRowMutationVars;
  modalProps?: Partial<ConfirmActionModalProps>;
  buttonProps?: Partial<ButtonReplacedSpinnerProps>;
}

export const ExecuteModalConfirmButton: React.FC<ExecuteModalConfirmButtonProps> = React.memo(function ExecuteModalConfirmButton (props: ExecuteModalConfirmButtonProps) {
  const {
    variant = 'outline-info',
    modalProps,
    buttonProps = {},
    mutateVars,
    title,
    children,
  } = props;

  const tableContext = useTableContext();

  const [showModal, setShowModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const onClick = React.useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const onDecline = React.useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const onConfirm = React.useCallback(() => {
    setShowModal(false);
    if (!tableContext.updateRowMutation) return null;
    setIsLoading(true);
    tableContext.updateRowMutation.mutateAsync(mutateVars).finally(() => {
      setIsLoading(false);
    });
  }, [setIsLoading, mutateVars, tableContext.updateRowMutation, setShowModal]);

  return (
    <>
      <ButtonReplacedSpinner
        {...buttonProps}
        className="py-0 px-1"
        isLoading={isLoading}
        onClick={onClick}
        variant={variant}
        title={title}
      >
        {children}
      </ButtonReplacedSpinner>
      <ConfirmActionModal
        message="Är du säker på att du vill utföra åtgärden?"
        show={showModal}
        onDecline={onDecline}
        onConfirm={onConfirm}
        {...modalProps}
      />
    </>
  );
});
