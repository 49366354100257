import React from 'react';
import classNames from 'classnames';

interface BooleanProps extends React.HTMLAttributes<HTMLSpanElement> {
  value?: boolean;
  canBeIndetermined?: boolean;
  colored?: boolean;
}

export default function Boolean (props: BooleanProps) {
  const {
    value,
    className:outerClassName,
    colored = false,
    canBeIndetermined = false,
    ...spanProps
  } = props;

  if (canBeIndetermined && typeof value !== 'boolean') {
    return null;
  }

  const className = classNames(outerClassName, {
    'text-danger': colored && value === false,
    'text-success': colored && value === true,
  });

  if (value) return <span {...spanProps} className={className}>Ja</span>;
  return <span {...spanProps} className={className}>Nej</span>;
}
