import React, {useState} from 'react';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import * as IconButtons from 'src/buttons/IconButtons';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { AtSign } from 'react-feather';
import { EmailRow } from 'shared/types/email';
import {Card} from 'react-bootstrap';
import TemplateQuickCreateForm from 'src/template/TemplateQuickCreateForm';
import {GuardPermission} from 'src/guards/AuthGuards';
import {PermissionDefinition} from 'src/contexts/AuthContext';
import { TableStateOrder  } from 'src/tables/types';
import { TableContextProvider } from 'src/tables/TableContext';
import CardBodyTable from 'src/tables/CardBodyTable';
import * as TableCells from 'src/tables/TableCells';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as commonEmailTableColumnDefs from 'src/email/commonEmailTableColumnDefinitions';
import {useTableUpdateRowMutation} from 'src/hooks/useTableUtils';
import emailFilter from 'shared/filter/email';
import { useEventModelSubscribeToQuery } from 'src/hooks/useEventModels';
import classNames from 'classnames';

interface EmailContactControlCardProps {
  className?: string;
  createPermission?: PermissionDefinition;
  searchParams: Record<string, any>;
  searchTemplateParams: Record<string, any>;
  objectType: string;
  objectId: string;
}

export default function EmailContactControlCard (props: EmailContactControlCardProps) {
  const { className, searchParams, searchTemplateParams, createPermission, objectId, objectType } = props;

  const [order, setOrder] = useState<TableStateOrder>({date: 'desc'});

  const queryKey = ['/email/search', {params: {...searchParams, order}}];
  const query = useQuery<EmailRow[], Error>({
    queryKey,
    placeholderData: keepPreviousData,
  });

  useEventModelSubscribeToQuery({queryKey: [queryKey[0]]}, 'email');

  const tableUpdateRowMutation = useTableUpdateRowMutation({
    queryKey,
    onSuccess: () => {
      query.refetch();
    },
  });

  const reload = () => {
    query.refetch();
  };

  const rows = query.data ?? [];

  return (
    <Card className={classNames('flex-fill w-100 border', className)}>
      <Card.Header className="border-bottom bg-light">
        <CardTitleIcon
          title="E-postmeddelanden"
          Icon={<AtSign size={18} />}
          spinning={query.isRefetching}
        >
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            onClick={reload}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <TableContextProvider updateRowMutation={tableUpdateRowMutation}>
        <CardBodyTable
          striped
          order={order}
          columnDefinitions={columnDefs}
          setOrder={setOrder}
          rows={rows}
          isFetched={query.isFetched}
        />
      </TableContextProvider>
      {createPermission && (
        <GuardPermission permission={createPermission}>
          <Card.Footer className="position-relative">
            <TemplateQuickCreateForm
              objectType={objectType}
              objectId={objectId}
              category="email"
              searchParams={searchTemplateParams}
              onCreated={reload}
            />
          </Card.Footer>
        </GuardPermission>
      )}
    </Card>
  );
}

const columnDefs = [
  commonColumnDefs.emailId({
    id: 'id',
    title: 'E-post',
    cellProps: {rowAsObject: true},
  }),
  commonColumnDefs.basic({
    id: 'status',
    title: 'Status',
    Cell: TableCells.EmailStatusTableCell,
  }),
  commonColumnDefs.basic({
    id: 'to',
    title: 'Till',
    cellValue: row => emailFilter.indexField(row, 'to'),
  }),
  commonColumnDefs.datetime({
    id: 'status_at',
    title: 'Statustid',
    sortable: true,
    cellProps: {emptyValue: '-'},
  }),
  commonEmailTableColumnDefs.actions(),
];
