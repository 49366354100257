import React from 'react';
import * as complaintFilter from 'shared/filter/complaint';
import { Table } from 'react-bootstrap';
import { ComplaintRow } from 'shared/types/complaint';
import DateTime from 'src/formatters/DateTime';

interface PropertiesTableProps {
  complaint: ComplaintRow;
}

export function PropertiesTable (props: PropertiesTableProps) {
  const { complaint } = props;
  return (
    <div className="position-relative overflow-auto">
      <Table size="sm" className="mb-0">
        <tbody>
          <tr>
            <th>Prioritet</th>
            <td>{complaintFilter.priority(complaint.priority)}</td>
          </tr>
          <tr>
            <th>Skapad</th>
            <td><DateTime value={complaint.created_at} /></td>
          </tr>
          <tr>
            <th>Uppdaterad</th>
            <td><DateTime value={complaint.created_at} /></td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
