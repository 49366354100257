import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import { CustomerConsentRowWithRelations } from 'shared/types/customer_consent';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as CustomerConsentFormatters from 'src/customerConsent/CustomerConsentFormatters';
import BlockSpinner from 'src/spinners/BlockSpinner';

export default function CustomerConsentPrintPage () {
  const { customerConsentId } = useParams();

  const query = useQuery<CustomerConsentRowWithRelations, Error>({
    queryKey: [`/customer_consent/${customerConsentId}`],
  });

  return (
    <div>
      <Helmet title="Samtycke" />
      <ErrorAlert error={query.error} />
      <BlockSpinner className="m-3" isLoading={query.isLoading || query.isRefetching} />
      {query.data && (
        <CustomerConsentFormatters.ConsentDump consent={query.data} />
      )}
    </div>
  );
}
