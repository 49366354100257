import React from 'react';

interface IdShortProps {
  value: string;
}

export default function IdShort (props: IdShortProps) {
  const { value } = props;
  if (typeof value !== 'string') return null;
  const groups = value.split('-');
  return (
    <span className="font-monospace" title={value}>
      {groups[0]}
    </span>
  );
}
