import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { StatusLabel } from  'src/cloudInsurance/CloudInsuranceFormatters';
import StatusLabelContainer from 'src/components/StatusLabelContainer';
import { useQuery } from '@tanstack/react-query';

// TODO finish

interface CustomerCloudInsuranceQuicklookProps {
  id: string;
}

const CustomerCloudInsuranceQuicklook: React.FC<CustomerCloudInsuranceQuicklookProps> = React.memo(function CustomerCloudInsuranceQuicklook (props: CustomerCloudInsuranceQuicklookProps) {
  const { id } = props;
  const query = useQuery({
    queryKey: [`/customer_cloud_insurance/${id}`],
  });

  const insurance = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3 my-0" />
      <BlockSpinner isLoading={query.isLoading} className="mx-3" center />
      {insurance && (
        <>
          <StatusLabelContainer>
            <StatusLabel value={(insurance as any).status} />
          </StatusLabelContainer>
          <pre className="border m-3 p-3">
            {JSON.stringify(insurance, null, 2)}
          </pre>
        </>
      )}
    </div>
  );
});
export default CustomerCloudInsuranceQuicklook;
