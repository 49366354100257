import React, {useCallback} from 'react';
import bankProcessFilter from 'shared/filter/bank_process';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import { TableBatchDefinition, TableBatchActionSubForm } from 'src/tables/types';
import TablePage from 'src/tables/TablePage';
import {Form} from 'react-bootstrap';
import IdProvider from 'src/components/IdProvider';
import * as constants from 'shared/constants';

export default function BankProcessesTablePage () {
  return (
    <TablePage
      title="Processer"
      pageTitle="Processtabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/bank_processes"
      baseQueryUrl="/bank_process/table"
      baseQueryKey="BankProcessTable"
      queryResultRowsKey="processes"
      batchDefinition={batchDefinition}
    />
  );
}

const batchDefinition: TableBatchDefinition = {
  url: '/bank_process/batch',
  permission: 'bank_process_batch',
  formToData: batchActionToData,
  SubForm: BankProcessTableBatchActionSubForm,
  actions: [
    {
      value: 'submit',
      label: 'Skicka in ansökan',
      permission: 'bank_process_execute:invoke:submit',
    },
    {
      value: 'set_status_open',
      label: 'Ändra status till "Öppen"',
      permission: 'bank_process_execute:invoke:set_status_open',
    },
    {
      value: 'set_status_closed',
      label: 'Ändra status till "Stängd"',
      permission: 'bank_process_execute:invoke:set_status_closed',
    },
    {
      value: 'set_status_new',
      label: 'Ändra status till "Nyskapad"',
      permission: 'bank_process_execute:invoke:set_status_new',
    },
    {
      value: 'set_status_waiting',
      label: 'Ändra status till "Bud finns"',
      permission: 'bank_process_execute:invoke:set_status_waiting',
    },
    {
      value: 'set_status_accepted',
      label: 'Ändra status till "Accepterad"',
      permission: 'bank_process_execute:invoke:set_status_accepted',
    },
    {
      value: 'set_status_disbursed',
      label: 'Ändra status till "Utbetald"',
      permission: 'bank_process_execute:invoke:set_status_disbursed',
    },
  ],
};

function batchActionToData (action: string, ids: string[], formData: Record<string, string>) {
  const data = {method: action, action: 'invoke', ids} as Record<string, any>;
  if (formData.closed_reason) data.closed_reason = formData.closed_reason;
  return data;
}

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.bankProcessStatus({id: 'status'}),
  commonFilterDefs.uuid({
    id: 'id',
    filterProps: {placeholder: 'Process-ID'},
  }),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'closed_at'}),
  commonFilterDefs.dateRelative({id: 'closed_at_relative'}),
  commonFilterDefs.dateRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
  commonFilterDefs.dateRange({id: 'accepted_at'}),
  commonFilterDefs.dateRelative({id: 'accepted_at_relative'}),
  commonFilterDefs.dateRangeOnField({id: 'date'}, [
    {value: 'created_at', label: 'Skapad'},
    {value: 'closed_at', label: 'Stängd'},
    {value: 'updated_at', label: 'Uppdaterad'},
    {value: 'accepted_at', label: 'Accepterad'},
  ]),
  commonFilterDefs.dateRangeOnField({id: 'month', filterProps: {type: 'month'}}, [
    {value: 'created_at', label: 'Skapad'},
    {value: 'closed_at', label: 'Stängd'},
    {value: 'updated_at', label: 'Uppdaterad'},
    {value: 'accepted_at', label: 'Accepterad'},
  ]),
  commonFilterDefs.booleanNull({
    id: 'customer_co_excluded',
  }),
  commonFilterDefs.boolean({
    id: 'flag_status_ever_waiting',
  }),
  commonFilterDefs.boolean({
    id: 'flag_status_ever_accepted',
  }),
  commonFilterDefs.boolean({
    id: 'portal_is_from',
    permission: 'application_search:portal',
  }),
  commonFilterDefs.bankProcessSubStatus({
    id: 'sub_status',
  }),
  commonFilterDefs.bankId({id: 'bank_id'}),
  commonFilterDefs.string({
    id: 'their_id',
  }),
  commonFilterDefs.string({
    id: 'our_id',
  }),
  commonFilterDefs.string({
    id: 'bank_adapter',
  }),
  commonFilterDefs.bankProcessClosedReason({id: 'closed_reason'}),
  commonFilterDefs.intOp({
    id: 'disbursed',
  }),
  commonFilterDefs.uuid({id: 'customer_id'}),
].map(filterDefinition => ({
  title: bankProcessFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.bankProcessId({
      id: 'id',
      cellProps: {rowAsObject: true},
    }),
    commonColumnDefs.applicationId({
      id: 'application_id',
      cellProps: {idKey: 'application_id'},
    }),
    commonColumnDefs.customerId({
      id: 'customer_id',
      cellProps: {idKey: 'customer_id'},
    }),
    commonColumnDefs.bankId({
      id: 'bank_id',
      cellProps: {objKey: 'Bank', idKey: 'bank_id'},
    }),
    commonColumnDefs.basic({
      id: 'sub_bank',
    }),
    commonColumnDefs.basic({
      id: 'status',
      Cell: TableCells.BankProcessStatusTableCell,
    }),
    commonColumnDefs.basic({
      id: 'bank_adapter',
    }),
    commonColumnDefs.datetime({
      sortable: true,
      id: 'updated_at',
    }),
    commonColumnDefs.datetime({
      sortable: true,
      id: 'accepted_at',
    }),
    commonColumnDefs.datetime({
      sortable: true,
      id: 'closed_at',
    }),
    commonColumnDefs.datetime({
      sortable: true,
      id: 'created_at',
    }),
    commonColumnDefs.basic({
      id: 'ssn',
    }),
    commonColumnDefs.basic({
      id: 'ssn_co',
    }),
    commonColumnDefs.basic({
      id: 'sub_status',
    }),
    commonColumnDefs.basic({
      id: 'our_id',
    }),
    commonColumnDefs.basic({
      id: 'their_id',
    }),
    commonColumnDefs.sumCurrency({
      id: 'disbursed',
      rowKey: 'disbursed',
    }),
    commonColumnDefs.basic({id: 'closed_reason'}),
    commonColumnDefs.boolean({
      id: 'customer_co_excluded',
      cellProps: {strict: true},
    }),
    commonColumnDefs.boolean({
      id: 'flag_status_ever_waiting',
    }),
    commonColumnDefs.boolean({
      id: 'flag_status_ever_accepted',
    }),
    commonColumnDefs.boolean({
      id: 'portal_is_from',
      permission: 'application_search:portal',
    }),
  ].map((columnDefinition: any) => {
    const { id } = columnDefinition;
    const header = bankProcessFilter.indexColumnName(id);
    const cellValue = application => bankProcessFilter.indexField(application, id);
    return {
      ...columnDefinition,
      Cell: columnDefinition.Cell ?? (({value}) => <>{value}</>),
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRow,
  ]),
];

function BankProcessTableBatchActionSubForm (props: TableBatchActionSubForm) {
  const { action } = props;

  const [value, setValue] = React.useState('');

  const onChange = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(ev.currentTarget.value);
  }, [setValue]);

  if (action !== 'set_status_closed') {
    return null;
  }

  return (
    <Form.Group className="flex-grow-1 w-100">
      <IdProvider>
        {id => (
          <Form.Select
            id={id}
            required
            name="closed_reason"
            value={value}
            onChange={onChange}
          >
            <option value="">Välj stängningsorsak</option>
            {constants.bank_process.closed_reasons.map(reason => (
              <option key={reason} value={reason}>{bankProcessFilter.closedReason(reason)}</option>
            ))}
          </Form.Select>
        )}
      </IdProvider>
    </Form.Group>
  );
}
