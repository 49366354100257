import React, {useRef, useState} from 'react';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import { errorToMessage } from 'src/utils/error';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import {Link} from 'react-router-dom';

export default function SettingUploadSanctionListForm () {
  const notyf = useNotyf();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async ev => {
    ev.preventDefault();
    setIsSubmitting(true);

    const file = fileRef.current.files[0];

    const formData = new FormData();
    formData.append('file', file);

    try {
      await api.request({
        url: '/setting/sanction_list/file',
        method: 'POST',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'},
      });
      ev.currentTarget.reset();
    } catch (err) {
      notyf.error(errorToMessage(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const fileRef = useRef<HTMLInputElement | null>(null);

  const [hasFileSelected, setHasFileSelected] = useState<boolean>(false);

  const onChangeFile: React.ChangeEventHandler<HTMLInputElement> = () => {
    setHasFileSelected(() => {
      if (!fileRef.current) return false;
      return Array.from(fileRef.current.files).length > 0;
    });
  };

  return (
    <form name="form" onSubmit={onSubmit}>
      <div className="form-group">
        <p>
          <input ref={fileRef} type="file" name="sanction_list" onChange={onChangeFile} required />
          <ButtonSpinner
            type="submit"
            disabled={!hasFileSelected || isSubmitting}
            variant="success"
            isLoading={isSubmitting}
          >
            Ladda upp
          </ButtonSpinner>
        </p>
        <p className="help-block">
          Eventuellt tidigare uppladdad fil hittas under <Link to="/files">Filer</Link> och har namnet <strong>Sanktionslista</strong>.
        </p>
        <p className="help-block">
          Uppdaterad fil kan laddas hem här:{' '}
          <a href="https://data.europa.eu/euodp/en/data/dataset/consolidated-list-of-persons-groups-and-entities-subject-to-eu-financial-sanctions" rel="noopener noreferrer" target="_blank">
            Consolidated Financial Sanctions File 1.0 (XML)
          </a>
        </p>
      </div>
    </form>
  );
}
