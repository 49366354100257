import React, {useRef} from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { CustomerRow } from 'shared/types/customer';
import FormikSubmitButton from 'src/buttons/FormikSubmitButton';
import {Form} from 'formik';
import * as api from 'src/api';
import * as formUtils from 'src/utils/form';
import CustomerUpdateFormCardBuilder from 'src/customer/CustomerUpdateFormCardBuilder';
import * as CustomerFormFields from 'src/customer/CustomerFormFields';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';

interface CustomerPortalSettingsFormCardProps {
  customer: CustomerRow;
}

interface PortalLoginResponse {
  url: string;
  ssn: string;
  password: string;
}

export default function CustomerPortalSettingsFormCard (props: CustomerPortalSettingsFormCardProps) {
  const { customer } = props;

  const portalLoginFormRef = useRef<HTMLFormElement>(null);

  const onConfirmPortalLogin: () => Promise<void> = async () => {
    if (!portalLoginFormRef.current) return;

    const response = await api.request<PortalLoginResponse>({
      url: `/customer/${customer.id}/portalLogin`,
      method: 'POST',
    });

    const form = portalLoginFormRef.current;
    form.action = response.url;
    (form.querySelector('[name="ssn"]') as HTMLInputElement).value = response.ssn;
    (form.querySelector('[name="password"]') as HTMLInputElement).value = response.password;
    form.submit();
  };

  return (
    <CustomerUpdateFormCardBuilder
      title="Portalinställningar"
      customer={customer}
      customerToFormValues={customerToFormValues}
      formValuesToUpdate={formValuesToUpdate}
    >
      <div>
        <Form placeholder="LOL">
          <Card.Body className="py-0 mb-3">
            <Row>
              <Col>
                <CustomerFormFields.PortalAllowLogin />
              </Col>
              <Col>
                <CustomerFormFields.ContactOptIn />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomerFormFields.PortalPassword />
              </Col>
              <Col></Col>
            </Row>
          </Card.Body>
          <Card.Footer className="border-top d-flex align-items-center gap-2">
            <FormikSubmitButton>
              Spara portalinställningar
            </FormikSubmitButton>
            <ConfirmActionModalButton
              variant="secondary"
              message="Är du säker på att du vill låsa upp portalen för kund, byta lösenord på kundens konto och automatiskt logga in i portalen som kunden?"
              onConfirm={onConfirmPortalLogin}
            >
              Logga in som kunden i portalen
            </ConfirmActionModalButton>
          </Card.Footer>
        </Form>
        <form className="d-none" ref={portalLoginFormRef} method="post" encType="application/x-www-form-urlencoded">
          <input type="hidden" name="ssn" value="" />
          <input type="hidden" name="password" value="" />
        </form>
      </div>
    </CustomerUpdateFormCardBuilder>
  );
}

type FormValues = {
  portal_password: string;
  portal_allow_login: string;
  contact_opt_in_at: string;
}

function customerToFormValues (customer: CustomerRow): FormValues {
  const {
    portal_password,
    portal_allow_login,
    contact_opt_in_at,
  } = customer;
  return {
    portal_password: portal_password || '',
    portal_allow_login: String(portal_allow_login),
    contact_opt_in_at: contact_opt_in_at ? String(contact_opt_in_at) : '',
  };
}

function formValuesToUpdate (initialValues: FormValues, values: FormValues): Partial<CustomerRow> {
  const changes = formUtils.changes(initialValues, values);
  const update: Partial<CustomerRow> = {};
  if (typeof changes.portal_password !== 'undefined') update.portal_password = changes.portal_password || null;
  if (typeof changes.portal_allow_login !== 'undefined') update.portal_allow_login = changes.portal_allow_login === 'true';
  if (typeof changes.contact_opt_in_at !== 'undefined') update.contact_opt_in_at = changes.contact_opt_in_at || null;
  return update;
}
