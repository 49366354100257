import React from 'react';
import mitt, { Emitter } from 'mitt';
import useWebSocket, {
  ReadyState,
  SendMessage,
} from 'react-use-websocket';
import * as websocketUtils from 'src/utils/websocket';
import last from 'lodash/last';
import delay from 'delay';
import useAuth from 'src/hooks/useAuth';

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL || '';

interface WebSocketContextState {
  readyState: ReadyState;
  sendMessage?: SendMessage;
  sendJsonMessage?: any;
  callMethod: (methodName: string, data: any) => Promise<any>;
  lastJsonMessage: object | null;
  methodEmitter: Emitter<any>;
}

interface MethodCall {
  type: number;
  data: [string, object];
}

const outstandingCallsById = {};

const WebSocketContext = React.createContext<WebSocketContextState>({
  readyState: ReadyState.UNINSTANTIATED,
  callMethod: async () => {},
  lastJsonMessage: null,
  methodEmitter: mitt<any>(),
});

const WebSocketProvider: React.FC<React.PropsWithChildren> = React.memo(function WebSocketProvider (props: React.PropsWithChildren) {
  const { children } = props;
  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    readyState,
  } = useWebSocket(WEBSOCKET_URL, {
    shouldReconnect: () => true,
  });

  const { sessionId } = useAuth();

  const callMethod = async function (methodName: string, data: any) {
    const msg = websocketUtils.primus.callMethod(methodName, data);
    const { requestId } = msg;
    const promise = Promise.race([
      delay(5000).then(() => Promise.reject(`timeout for call to ${methodName}`)),
      new Promise(resolve => {
        outstandingCallsById[requestId] = resolve;
      }),
    ]).finally(() => {
      delete outstandingCallsById[requestId];
    });
    sendJsonMessage(msg);
    return promise;
  };

  const [lastJsonMessage, setLastJsonMessage] = React.useState<object | null>(null);

  React.useEffect(() => {
    if (readyState === ReadyState.OPEN && sessionId) {
      sendJsonMessage(websocketUtils.primus.hello(sessionId));
    }
  }, [readyState, sessionId, sendJsonMessage]);
  
  React.useEffect(() => {
    if (!lastMessage) return;
    const { data } = lastMessage;
    if (typeof data === 'string' && data.startsWith('"primus::ping::')) {
      const ts = (last(data.split('::')) as string).replace(/\D/g, '');
      const pong = websocketUtils.primus.pong(ts) ;
      sendMessage(pong);
    } else {
      const data = JSON.parse(lastMessage.data);
      const { responseId } = data;
      setLastJsonMessage(data);
      if (responseId && outstandingCallsById[responseId]) {
        const result = data.data.result;
        outstandingCallsById[responseId](result);
      }

      if (data.type === 0) {
        const methodCall = data as MethodCall;
        const methodName = methodCall.data[0];
        const methodArgs = methodCall.data[1];
        context.methodEmitter.emit(methodName, methodArgs);
      }

    }
  }, [lastMessage, sendMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const context = {
    sendMessage,
    sendJsonMessage,
    callMethod,
    readyState,
    lastJsonMessage,
    methodEmitter: mitt<any>(),
    // subscribe,
    // unsubscribe,
  };

  return (
    <WebSocketContext.Provider value={context}>
      {children}
    </WebSocketContext.Provider>
  );
});

export { WebSocketContext, WebSocketProvider };
