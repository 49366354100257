import React from 'react';
import { ProspectRow } from 'shared/types/prospect';
import { WidgetListCard } from 'src/cards/CardHelpers';
import { WidgetList } from 'src/widgets/types';
import * as ProspectBlocks from 'src/prospect/ProspectBlocks';

interface ProspectWidgetProps {
  prospect: ProspectRow;
}

export const allWidgets: WidgetList = {
  properties: {
    id: 'properties',
    Component: wrap(ProspectBlocks.PropertiesTable),
    title: 'Egenskaper',
  },
  dataDisabledForm: {
    id: 'dataDisabledForm',
    Component: wrap(ProspectBlocks.DataInfoTable),
    title: 'Formulär (hemsida steg 1)',
  },
  formInfo: {
    id: 'formInfo',
    Component: wrap(ProspectBlocks.FormInfoTable),
    title: 'Formulär (portal steg 2)',
  },
};

function wrap (Component: React.ComponentType<ProspectWidgetProps>) {
  return function ProspectWidgetWrap (props: ProspectWidgetProps) {
    const { prospect } = props;
    return (
      <WidgetListCard {...props}>
        <Component prospect={prospect} />
      </WidgetListCard>
    );
  };
}
