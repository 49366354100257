import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import {Settings} from 'react-feather';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import { SettingRow } from 'shared/types/setting';
import {keyBy} from 'lodash';
import SettingSingleForm from 'src/settings/SettingSingleForm';
import SettingUploadSanctionListForm from 'src/settings/SettingUploadSanctionListForm';

export default function SettingsPage () {

  const query = useQuery<{settings: SettingRow[]}, Error>({
    queryKey: ['/setting'],
    placeholderData: keepPreviousData,
  });

  const onReload = async () => {
    await query.refetch();
  };

  const settings = query.data?.settings ?? [];

  const settingsByKey = keyBy(settings, 'id');

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Systeminställningar" />
        <Container fluid className="p-0">
          <Card className="border mb-1">
            <Card.Header>
              <CardTitleIcon
                title="Systeminställningar"
                Icon={<Settings size={16} />}
              >
                <RefreshObjectButton
                  disabled={query.isRefetching}
                  onClick={onReload}
                />
              </CardTitleIcon>
            </Card.Header>
            <Card.Body className="pt-1">
              <Row>
                <Col lg={4} md={6}>
                  <h4 className="mt-3">Lotteri</h4>
                  <SettingSingleForm
                    settingId="lottery_subscribers"
                    setting={settingsByKey['lottery_subscribers']}
                    description="Anmälda till lotteriet"
                    onReload={onReload}
                    inputGroupProps={{as: 'textarea', rows: 3}}
                  />
                  <h4 className="mt-3">Norwegian senaste hämtning av uppdateringar</h4>
                  <SettingSingleForm
                    settingId="norwegian"
                    setting={settingsByKey['norwegian']}
                    onReload={onReload}
                    inputGroupProps={{as: 'textarea', rows: 6}}
                  />
                </Col>
                <Col lg={4} md={6}>
                  <h4 className="mt-3">Snabbkontakt</h4>
                  <SettingSingleForm
                    settingId="email_template_quickcontact_offers_with_portal_link"
                    setting={settingsByKey['email_template_quickcontact_offers_with_portal_link']}
                    description="Mallar till 'Erbjudanden och portallänk (e-post)'"
                    onReload={onReload}
                  />
                  <SettingSingleForm
                    settingId="email_template_quickcontact_offers"
                    setting={settingsByKey['email_template_quickcontact_offers']}
                    description="Mallar till 'Erbjudanden (e-post)'"
                    onReload={onReload}
                  />
                  <SettingSingleForm
                    settingId="sms_template_quickcontact_offers_with_portal_link"
                    setting={settingsByKey['sms_template_quickcontact_offers_with_portal_link']}
                    description="Mallar till 'Erbjudanden och portallänk (SMS)'"
                    onReload={onReload}
                  />
                  <SettingSingleForm
                    settingId="sms_template_quickcontact_unreachable"
                    setting={settingsByKey['sms_template_quickcontact_unreachable']}
                    description="Mallar till 'Kontaktförsök (SMS)'"
                    onReload={onReload}
                  />
                  <SettingSingleForm
                    settingId="sms_template_quickcontact_offer_sign_with_portal_link"
                    setting={settingsByKey['sms_template_quickcontact_offer_sign_with_portal_link']}
                    description="Mallar till signeringslänk portal (SMS)"
                    onReload={onReload}
                  />
                </Col>
                <Col lg={4} md={6}>
                  <h4 className="mt-3">Portalinställningar</h4>
                  <SettingSingleForm
                    settingId="portal_prepare_and_submit_auto"
                    setting={settingsByKey['portal_prepare_and_submit_auto']}
                    onReload={onReload}
                    description="Nya portalansökningar skickas in automatiskt. true/false"
                  />
                  <h4 className="mt-3">Sanktionslista</h4>
                  <SettingUploadSanctionListForm />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </Content>
    </Main>
  );
}
