import React from 'react';
import { Card } from 'react-bootstrap';
import { CustomerRow } from 'shared/types/customer';
import FormikSubmitButton from 'src/buttons/FormikSubmitButton';
import {Form} from 'formik';
import * as formUtils from 'src/utils/form';
import * as api from 'src/api';
import CustomerUpdateFormCardBuilder from 'src/customer/CustomerUpdateFormCardBuilder';
import * as CustomerFormFields from 'src/customer/CustomerFormFields';
import {GuardPermission} from 'src/guards/AuthGuards';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import DateTime from 'src/formatters/DateTime';
import {useQueryClient} from '@tanstack/react-query';
import useNotyf from 'src/hooks/useNotyf';

interface CustomerTerroristFormCardProps {
  customer: CustomerRow;
}

export default function CustomerTerroristFormCard (props: CustomerTerroristFormCardProps) {
  const { customer } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();

  const onConfirmNotATerrorist = async () => {
    const updatedCustomer = await api.request<CustomerRow>({
      url: `/customer/${customer.id}`,
      method: 'PATCH',
      data: {suspected_terrorist: false},
    });
    queryClient.setQueryData([`/customer/${updatedCustomer.id}`], updatedCustomer);
    notyf.success({type: 'default', message: 'Kunden uppdaterades'});
  };

  return (
    <CustomerUpdateFormCardBuilder
      title="Flaggad kund"
      customer={customer}
      customerToFormValues={customerToFormValues}
      formValuesToUpdate={formValuesToUpdate}
    >
      <div>
        <Form placeholder="LOL">
          <Card.Body className="border-bottom py-2">
            <p className="text-danger mb-0">Kunden flaggades <DateTime value={customer.suspected_at} />.</p>
          </Card.Body>
          <Card.Body className="py-0 mb-3">
            <CustomerFormFields.SuspectedNotes className="mt-0" />
          </Card.Body>
          <Card.Footer className="border-top d-flex align-items-center gap-2">
            <FormikSubmitButton>
              Spara
            </FormikSubmitButton>
            <GuardPermission permission="customer_update:suspected_terrorist">
              <ConfirmActionModalButton
                variant="secondary"
                message='Är du säker på att kunden inte är en penningtvättande terrorist? Anteckningarna kommer att tas bort.'
                onConfirm={onConfirmNotATerrorist}
              >
                Kunden har slutat med penningtvätt och terrorism
              </ConfirmActionModalButton>
            </GuardPermission>
          </Card.Footer>
        </Form>
      </div>
    </CustomerUpdateFormCardBuilder>
  );
}

type FormValues = {
  suspected_notes: string;
}

function customerToFormValues (customer: CustomerRow): FormValues {
  return {
    suspected_notes: customer.suspected_notes || '',
  };
}

function formValuesToUpdate (initialValues: FormValues, values: FormValues): Partial<CustomerRow> {
  const changes = formUtils.changes(initialValues, values);
  const update: Partial<CustomerRow> = {};
  if (typeof changes.suspected_notes !== 'undefined') update.suspected_notes = changes.suspected_notes;
  return update;
}

