import React from 'react';
import ModalAnchor, {ModalAnchorProps} from 'src/anchors/ModalAnchor';
import LetterModalBody from 'src/letter/LetterModalBody';
import { Name } from 'src/letter/LetterFormatters';
import {LetterRow} from 'shared/types/letter';

interface LetterAnchorProps extends Partial<ModalAnchorProps> {
  letter: Pick<LetterRow, 'id'>;
}

export default function LetterAnchor (props: LetterAnchorProps) {
  const { letter, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={letter} />}
      name="Brev"
    >
      <LetterModalBody id={letter.id} />
    </ModalAnchor>
  );
}

