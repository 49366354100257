import React from 'react';
import * as api from 'src/api';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import PlaceholderCard from 'src/cards/PlaceholderCard';

// TODO finish

interface TemplatePageProps {
  type: 'email' | 'letter' | 'sms';
}

const TemplatePage: React.FC<TemplatePageProps> = React.memo(function TemplatePage (props: TemplatePageProps) {
  const { type } = props;
  const { templateId } = useParams();
  const queryKey = ['template', type, {templateId}];
  const queryFn = () => api.request({url: `/template/${type}/${templateId}`});
  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title={`Mall (${type})`} />
        <PlaceholderCard
          queryKey={queryKey}
          queryFn={queryFn}
        />
      </Content>
    </Main>
  );
});
export default TemplatePage;
