import React from 'react';
import ModalAnchor, {ModalAnchorProps} from 'src/anchors/ModalAnchor';
import SmsModalBody from 'src/sms/SmsModalBody';
import { Name } from 'src/sms/SmsFormatters';
import {SmsRow} from 'shared/types/sms';

interface SmsAnchorProps extends Partial<ModalAnchorProps> {
  sms: Pick<SmsRow, 'id'>;
}

export default function SmsAnchor (props: SmsAnchorProps) {
  const { sms, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={sms} />}
      name="SMS"
    >
      <SmsModalBody id={sms.id} />
    </ModalAnchor>
  );
}
