import React from 'react';
import TableSavedStateForm from 'src/tables/TableSavedStateForm';
import TableFilterForm from 'src/tables/TableFilterForm';
import TableColumnForm from 'src/tables/TableColumnForm';
import BasicSpinner from 'src/spinners/BasicSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import omit from 'lodash/omit';
import qs from 'qs';
import {
  Filter,
  Columns,
  RefreshCw,
  Link,
  Minimize,
  Maximize,
} from 'react-feather';
import { Col, Row, Card, Button, Collapse, ButtonGroup } from 'react-bootstrap';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import { TableState } from 'src/tables/types';
import {UseQueryResult} from '@tanstack/react-query';

interface TableControlPanelHeadProps {
  title: string;
  error?: Error;
  columnDefinitions: TableColumnDefinition[];
  filterDefinitions: TableFilterDefinition[];
  defaultState: TableState;
  onSwapState: (state: TableState) => void;
  onSaveNewState: (state: TableState) => Promise<TableState>;
  onDeleteState: (state: TableState) => Promise<TableState>;
  setSavedStates: (states: TableState[]) => void;
  savedStates: TableState[];
  setState: (state: TableState) => void;
  updateState: (state: TableState) => void;
  state: TableState;
  routeUrl: string;
  refetch: UseQueryResult['refetch'];
  isLoading: boolean;
  [key: string]: any;
}

const TableControlPanelHead: React.FC<TableControlPanelHeadProps> = React.memo(function TableControlPanelHead (props: TableControlPanelHeadProps) {
  const {
    columnDefinitions,
    filterDefinitions,
    defaultState,
    error,
    isLoading,
    onSaveNewState,
    onSwapState,
    onDeleteState,
    savedStates,
    setSavedStates,
    setState,
    state,
    title,
    refetch,
    updateState,
    routeUrl,
  } = props;

  // TODO possibly this state should be elevated to <Table>
  const [showFilterSettings, setShowFilterSettings] = React.useState(false);
  const [showColumnSettings, setShowColumnSettings] = React.useState(false);

  const filterCount = state.filter?.length;
  const hasFilters = Object.keys(filterDefinitions).length > 0;

  return (
    <div>
      <Card.Header className="rounded-0">
        <Row>
          <Col xs={12} sm={2} className="d-flex align-items-center">
            <Card.Title className="mb-0 d-flex align-items-center">
              {title}
              <BasicSpinner
                className="d-inline-block position-relative ms-2"
                isLoading={isLoading}
                style={{top: '2px'}}
              />
            </Card.Title>
          </Col>
          <Col className="mt-2 mt-md-0 ">
            <TableSavedStateForm
              state={state}
              savedStates={savedStates}
              setState={setState}
              defaultState={defaultState}
              onSwapState={onSwapState}
              onSaveNewState={onSaveNewState}
              onDeleteState={onDeleteState}
              setSavedStates={setSavedStates}
              filterDefinitions={filterDefinitions}
            />
          </Col>
          <Col className="d-flex mt-2 mt-md-0 justify-content-start justify-content-md-end">
            <Button
              className="px-2 ms-md-2 me-2"
              variant="outline-primary"
              title="Ladda om tabellen"
              disabled={isLoading}
              onClick={() => refetch({throwOnError: true, cancelRefetch: false})}
            >
              <RefreshCw size={16} />
            </Button>
            <ButtonGroup className="me-2">
              <Button
                className="px-2"
                variant={state.size === 'sm' ? 'secondary' : 'outline-secondary'}
                title="Kompakta rader"
                onClick={() => updateState({size: 'sm'})}
              >
                <Minimize size={18} />
              </Button>
              <Button
                className="px-2"
                variant={state.size !== 'sm' ? 'secondary' : 'outline-secondary'}
                title="Stora rader"
                onClick={() => updateState({size: 'lg'})}
              >
                <Maximize size={18} />
              </Button>
            </ButtonGroup>
            <CopyTableLinkButton
              state={state}
              routeUrl={routeUrl}
            />
            {hasFilters ? (
              <Button
                className="px-2 ms-2"
                variant={showFilterSettings ? 'secondary' : 'outline-secondary'}
                onClick={() => setShowFilterSettings(!showFilterSettings)}
                title={showFilterSettings ? 'Dölj inställningar för alla filter' : 'Visa inställningar för alla filter'}
              >
                <Filter size={16} />{' '}
                <span className="d-none d-md-inline-block">
                  Filter ({filterCount})
                </span>
              </Button>
            ) : null}
            <Button
              className="px-2 ms-2"
              variant={showColumnSettings ? 'secondary' : 'outline-secondary'}
              onClick={() => setShowColumnSettings(!showColumnSettings)}
              title={showColumnSettings ? 'Dölj inställningar för alla kolumner' : 'Visa inställningar för alla kolumner'}
            >
              <Columns size={16} />{' '}
              <span className="d-none d-md-inline-block">
                Kolumner
              </span>
            </Button>
          </Col>
        </Row>
        <ErrorAlert error={error} className="mt-3 mb-0" />
      </Card.Header>
      <div style={{maxHeight: '40vh', overflowY: 'auto'}}>
        <Collapse in={showColumnSettings} unmountOnExit>
          <div>
            <Card.Body className="p-0">
              <TableColumnForm
                columnDefinitions={columnDefinitions}
                stateColumns={state.columns as any}
                updateState={updateState}
              />
            </Card.Body>
          </div>
        </Collapse>
        {hasFilters ? (
          <>
            <Collapse in={showFilterSettings} unmountOnExit>
              <div>
                <Card.Body className="p-0">
                  <TableFilterForm
                    showSettings
                    filterDefinitions={filterDefinitions}
                    stateFilter={state.filter as any}
                    stateFilterVisible={state.filterVisible as any}
                    stateTitleAuto={state.titleAuto as any}
                    updateState={updateState}
                  />
                </Card.Body>
              </div>
            </Collapse>
            <Collapse in={!showFilterSettings} unmountOnExit className="d-none d-lg-block">
              <div>
                <Card.Body className="p-0">
                  <TableFilterForm
                    onlyShowVisibleFilters
                    filterDefinitions={filterDefinitions}
                    stateFilter={state.filter as any}
                    stateFilterVisible={state.filterVisible as any}
                    stateTitleAuto={state.titleAuto as any}
                    updateState={updateState}
                  />
                </Card.Body>
              </div>
            </Collapse>
          </>
        ) : null}
      </div>
    </div>
  );
});
export default TableControlPanelHead;

function CopyTableLinkButton (props) {
  const { state, routeUrl } = props;

  const handleClick = () => {
    const url = new URL(window.location.toString());
    url.pathname = routeUrl;
    url.search = qs.stringify({overrideSearch: JSON.stringify(omit(state, 'id'))}, {strictNullHandling: true});
    const finalUrl = url.toString();
    navigator.clipboard.writeText(finalUrl);
  };

  return (
    <Button
      className="px-2 ms-md-2"
      variant="outline-secondary"
      title="Kopiera en länk till tabellen"
      onClick={handleClick}
    >
      <Link size={16} />
    </Button>
  );
}
