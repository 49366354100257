import React from 'react';
import { Form, Card, Row, Col } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { BankRow } from 'shared/types/bank';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import * as BankFormFields from 'src/bank/BankFormFields';
import * as formUtils from 'src/utils/form';

export interface BankEditFormValues extends BankRow {

}

interface BankEditFormProps {
  initialValues: BankEditFormValues;
  onSubmit: (values: BankEditFormValues, helpers: FormikHelpers<BankEditFormValues>) => Promise<any>;
  submitError?: Error | null;
}

const BankEditForm: React.FC<BankEditFormProps> = React.memo(function BankEditForm (props: BankEditFormProps) {
  const { initialValues, submitError, onSubmit } = props;
  const colProps = {md: 6, lg: 4};
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <Card.Body className="pt-2">
            <Row>

              <Col {...colProps}>
                <BankFormFields.Id disabled />
              </Col>

              <Col {...colProps}>
                <BankFormFields.Name />
              </Col>

              <Col {...colProps}>
                <BankFormFields.Adapter />
              </Col>

            </Row>

            <hr className="mt-4 mb-1" />

            <Row>
              <Col>
                <BankFormFields.IsActive />
              </Col>
            </Row>

            <Row>
              <Col>
                <BankFormFields.IsHidden />
              </Col>
            </Row>

            {formProps.values.adapter === 'kkn' && (
              <>
                <hr className="mt-4 mb-1" />
                <Row>
                  <Col>
                    <h5 className="mt-3 mb-0">Mottagande bank</h5>
                    <BankFormFields.SettingsKknSubBank />
                  </Col>
                </Row>
              </>
            )}

            {formProps.values.adapter === 'svea' && (
              <>
                <hr className="mt-4 mb-1" />
                <Row>
                  <Col>
                    <h5 className="mt-3 mb-0">Mottagande bank</h5>
                    <BankFormFields.SettingsSveaSubBank />
                  </Col>
                </Row>
              </>
            )}

            {formProps.values.adapter === 'northmill' && (
              <>
                <hr className="mt-4 mb-1" />
                <Row>
                  <Col>
                    <h5 className="mt-3 mb-0">Mottagande bank</h5>
                    <BankFormFields.SettingsNorthmillSubBank />
                  </Col>
                </Row>
              </>
            )}

            <hr className="mt-3 mb-1" />

            <Row>
              <Col>
                <h5 className="mt-3 mb-0">Förhindra ny ansökan till långivaren</h5>
                <BankFormFields.SettingsCreateProcessDisabled />
              </Col>
            </Row>

            <hr className="mt-3 mb-1" />

            <Row>
              <Col>
                <h5 className="mt-3 mb-0">Fördröjd inskickning</h5>
                <BankFormFields.SettingsSubmitDelayActive />
                <BankFormFields.SettingsSubmitDelayMinutes />
                <BankFormFields.SettingsSubmitDelayOnMinimumInterestRate />
                <BankFormFields.SettingsSubmitDelayUnlessAmountBid />
              </Col>
            </Row>

            <hr className="mt-4 mb-1" />

            <Row>

              <Col {...colProps}>
                <BankFormFields.SettingsExpireProcessDays />
              </Col>

              <Col {...colProps}>
                <BankFormFields.SettingsExternalBidCallbackType />
              </Col>

            </Row>

            <hr className="mt-4 mb-1" />

            <BankFormFields.RepresentativeExample />

            <hr className="mt-4 mb-1" />

            <BankFormFields.Note />

          </Card.Body>
          <Card.Footer className="border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap">
              <InspectObjectModalButton object={formProps} />
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
});
export default BankEditForm;

export function bankToFormValues (bank: BankRow): BankEditFormValues {
  return {
    ...formUtils.rowToFormValues(bank) as BankEditFormValues,
  };
}

export function formValuesToUpdate (initialValues: BankEditFormValues, values: BankEditFormValues): Partial<BankRow> {
  const changes = formUtils.changes(initialValues, values);
  return changes;
}
