/* eslint-disable no-redeclare */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

interface RequestProps extends AxiosRequestConfig {
  returnData?: boolean;
}

export async function request<ReturnDataType = any> (
  props: (RequestProps & { returnData: true }) | (Omit<RequestProps, 'returnData'>)
): Promise<ReturnDataType>;

export async function request<ReturnDataType = any> (
  props: RequestProps & { returnData: false }
): Promise<AxiosResponse<ReturnDataType>>;

export async function request<ReturnDataType = any> (
  props: RequestProps,
): Promise<ReturnDataType | AxiosResponse<ReturnDataType>> {
  const { returnData = true, ...otherProps } = props;
  
  const response = await axios<ReturnDataType>({
    method: 'get',
    timeout: 60000,
    baseURL: '/api',
    ...otherProps,
    headers: {
      Accept: 'application/json',
      ...(otherProps.headers || {}),
    },
  });

  if (returnData) {
    return response.data as ReturnDataType;
  }

  return response as AxiosResponse<ReturnDataType>;
}

// see crmServer/routes/auth "limitedInfoErrorMiddleware"
export type ErrorLimited = {
  error: string;
  ok: false;
};
