import React from 'react';
import Pluralizer from 'src/formatters/Pluralizer';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import { selectedRowsToArray } from 'src/tables/helpers';
import classNames from 'classnames';
import useAuth from 'src/hooks/useAuth';
import { formToData } from 'src/misc';
import { Form } from 'react-bootstrap';
import { TableBatchDefinition, TableSelectedRows, TableBatchActionMutationDate } from 'src/tables/types';

interface TableBatchActionFormProps {
  selectedRows: TableSelectedRows;
  className?: string;
  batchDefinition: TableBatchDefinition;
  batchActionMutateAsync: (data: TableBatchActionMutationDate) => Promise<any>;
}

const TableBatchActionForm: React.FC<TableBatchActionFormProps> = React.memo(function TableBatchActionForm (props: TableBatchActionFormProps) {
  const {
    selectedRows,
    className,
    batchDefinition,
    batchActionMutateAsync,
  } = props;

  const { hasAllPermissions } = useAuth();

  const [action, setAction] = React.useState('');

  const selectedRowsArray = selectedRowsToArray(selectedRows);

  const eligibleBatchActions = batchDefinition.actions.filter(action => {
    const { permission } = action;
    return permission ? hasAllPermissions(permission) : true;
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (!ev.currentTarget.reportValidity()) return;
    const formData = formToData<TableBatchActionMutationDate>(ev.currentTarget);
    setIsSubmitting(true);
    formData.onSuccessNotyf = {message: 'Batchåtgärden utfördes', type: 'default'};
    batchActionMutateAsync(formData).finally(() => {
      setIsSubmitting(false);
      setAction('');
    });
  };

  const htmlSelectId = React.useId();

  const { SubForm } = batchDefinition;

  return (
    <Form
      className={classNames('d-flex justify-content-between flex-grow-1 gap-2', className)}
      onSubmit={handleSubmit}
    >
      <label
        className="d-inline-flex align-items-center small flex-shrink-0"
        htmlFor={htmlSelectId}
      >
        <Pluralizer
          count={selectedRowsArray.length}
          one="1 vald rad:"
          otherwise="%% valda rader:"
        />
      </label>
      <Form.Group className="flex-grow-1 w-100">
        <Form.Select
          id={htmlSelectId}
          name="action"
          className="d-block"
          value={action}
          onChange={e => setAction(e.target.value)}
          disabled={isSubmitting}
          required
        >
          <option value="">Välj åtgärd</option>
          {eligibleBatchActions.map(action => (
            <option key={action.value} value={action.value}>{action.label}</option>
          ))}
        </Form.Select>
      </Form.Group>
      {SubForm && <SubForm action={action} />}
      <ButtonSpinner
        className="rounded"
        variant={!selectedRowsArray.length || !action || isSubmitting ? 'outline-secondary' : 'secondary'}
        type="submit"
        disabled={!selectedRowsArray.length || !action || isSubmitting}
        isLoading={isSubmitting}
      >
        Utför
      </ButtonSpinner>
    </Form>
  );
});
export default TableBatchActionForm;
