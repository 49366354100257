

const settings = {
  loanClamps: {
    minYears: 1,
    maxYears: 15,
    minTotal: 30000,
    maxTotal: 600000,
    minTotalClamp: false,
  },
  accept: {
  },
  change: {
    amountNewInput: true,
  },
};
export default settings;
