/**
 * WARNING: This file is used clientside
 */

const moment = require('moment');
const get = require('shared/get');
const { indexColumnName, status } = require('shared/filter/bank_process');
const { fieldChange:logFieldChange } = require('shared/filter/log');
const { reason:validateReason } = require('shared/filter/validate');
const { errorLogDiagnose } = require('shared/filter/misc');

// description for log messages created by the Internal adapter
// (which all other adapters inherit from, so it is a fallback)
exports.internalDescription = function (log) {
  const data = log.data || {};
  if (data.error) {
    return errorLogDiagnose(data);
  }

  if (data.retry) {
    // retry_remaining/delay old keys of previous version
    // retry_remaining/retry_delay new keys
    const { count, retry_remaining, delay, retry_delay } = data.retry;
    const rightDelay = retry_delay || retry_delay === 0 ? retry_delay : delay;
    const rightRemaining = retry_remaining || retry_remaining === 0 ? retry_remaining : count;
    const delaySeconds = moment.duration(rightDelay).as('seconds');
    const msg = `Ett fel inträffade. Försöker igen om ${delaySeconds} sekunder.`;
    if (typeof count === 'number') {
      return msg + ` (${count + 1}/${rightRemaining})`;
    }
    return msg + ` (${rightRemaining} försök kvar)`;
  }

  switch (data.type) {
    default: 
      return '(Okänd beskrivning)';

    case 'submit':
      switch (data.result) {
        default: return '(Okänt resultat)';
        case 'ok': return 'Ansökan inlagd';
        case 'delayed': return 'Fördröjd inskickning';
        case 'published': return 'Ansökan skickad till långivare';
      }

    case 'accept':
      switch (data.result) {
        default: break;
        case 'published': return 'Accept skickad till långivare';
      }
      break;

    case 'change':
      switch (data.result) {
        default: break;
        case 'published': return 'Förändring skickad till långivare';
      }
      break;

    case 'respond':
      switch (data.result) {
        default: return '(Okänt resultat)';
        case 'reject': return 'Ansökan nekad';
        case 'offer': return 'Erbjudande inlagt';
        case 'confirm_accept': return 'Accept bekräftad';
        case 'open': return 'Återtagen';
        case 'disbursed': return 'Utbetald';
      }

    case 'decision':
      switch (data.result) {
        default: return '(Okänt resultat)';
        case 'reject': return 'Erbjudande nekat';
        case 'change': return 'Förändring begärd';
        case 'approve': // <- backwards compatible
        case 'accept': return 'Erbjudande accepterat';
      }

  }
};

exports.sevendayDescription = function (log) {
  const data = log.data || {};
  switch (data.type) {
    default: break;

    case 'submit':
      switch (data.result) {
        default: return maybeGetError(data);
        case 'published': return 'Ansökan skickad till Sevenday';
      }

    case 'result_submit': // backwards compatible
    case 'submitResult':
      switch (data.result) {
        default: return maybeGetError(data);
        case 'successful': return 'Ansökan mottagen';
        case 'denied': return 'Automatiskt avslag';
        case 'aborted': return [
          'Fel i ansökan (Avbruten)',
          maybeGetError(data),
        ].filter(v => v).join('\n');
        case 'error': return '(Okänt fel)'; // TODO vad blev fel?
      }

    case 'result_decision': // backwards compatible
    case 'decisionResult':
      switch (data.result) {
        default: return maybeGetError(data);
        case 'successful': return 'Beslut mottaget';
        case 'aborted': return [
          'Fel i beslut (Avbruten)',
          maybeGetError(data),
        ].filter(v => v).join('\n');
        case 'error': return '(Okänt fel)'; // TODO Vad blev fel?
      }

    case 'status':
      switch (data.result) {
        default: return maybeGetError(data);
        case 'unchanged': return 'Ansökan oförändrad';
        case 'processing': return 'Ansökan behandlas';
        case 'approved': return 'Ansökan beviljad';
        case 'denied': return 'Avslag på ansökan';
        case 'complement': return 'Komplettera ansökan';
        case 'pn_sent': return 'Skuldebrev skickat';
        case 'pn_received': return 'Skuldebrev mottaget';
        case 'disbursed': return 'Utbetald';
      }
  }

  return exports.internalDescription(log);

  // gets an error
  function maybeGetError (container) {
    if (Array.isArray(container.errors)) {
      return container.errors.map(error => {
        return [error.msg, error.code ? '(' + error.code + ')' : null].filter(v => v).join(' ');
      }).join('\n');
    }
    if (!container.error) return '';
    if (container.error.message) return 'Fel: ' + container.error.message;
    if (!container.result) return '(Okänt fel)';
    if (typeof container.result === 'string') return container.result;
    if (typeof container.result.reason === 'string') return container.result.reason;
    return '';
  }
};

// deprecated
exports.marginalenDescription = function (log) {
  const type = get(log, ['data', 'type']);
  const result = get(log, ['data', 'result']);

  switch (type) {
    default: break;

    case 'accept_offer': // <- backwards compatible
    case 'accept':
      switch (result) {
        default: break;
        case 'approve': // <- backwards compatible
        case 'accept': return 'Accept skickad till Marginalen';
      }
      break;

    case 'acceptResult':
    case 'result_accept_offer': // <- backwards compatible
    case 'result_accept': // <- backwards compatible
      switch (result) {
        default: break;
        case 'successful': return 'Accept mottagen';
      }
      break;

    case 'submit':
      switch (result) {
        default: break;
        case 'published': return 'Ansökan skickad till Marginalen';
      }
      break;

    case 'result_submit': // <- backwards compatible
    case 'submitResult':
      switch (result) {
        default: break;
        case 'successful': return 'Ansökan mottagen';
        case 'pending': return 'Pröv krävs';
        case 'reject': return 'Automatiskt avslag';
      }
      break;

    case 'update':
      switch (result) {
        default: break;
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
      break;

  }

  return exports.internalDescription(log);
};

exports.collectorRestDescription = function (log) {
  const data = log.data;

  switch (data.type) {
    default: break;

    case 'submit':
      switch (data.result) {
        default: break;
        case 'published': return 'Ansökan skickad till Collector';
      }
      break;

    case 'result_submit': // backwards compatible
    case 'submitResult':
      switch (data.result) {
        default: break;
        case 'reject': return 'Automatiskt avslag';
        case 'successful': return 'Ansökan mottagen';
      }
      break;

    case 'decision':
      switch (data.result) {
        default: break;
        case 'change-submit': return 'Förändring via omskickning begärd';
        case 'approve': // bw compat
        case 'accept': 'Accept skickad';
      }
      break;

    case 'accept':
      switch (data.result) {
        default: break;
        case 'successful': return 'Accept skickad till Collector';
      }
      break;

    case 'acceptResult':
    case 'result_accept': // backwards compatible
      switch (data.result) {
        default: break;
        case 'successful': return 'Accept godkänd';
      }
      break;

    case 'update':
      switch (data.result) {
        default: break;
        case 'signed': return 'Kund har undertecknat';
        case 'signingstarted': return 'Kund har påbörjat påskrivning';
        case 'accepted': {
          const verificationsAdded = data.has_added_verifications === true;
          if (verificationsAdded) return 'Kund har accepterat erbjudandet (verifikationer inlagda på budet)';
          return 'Kund har accepterat erbjudandet';
        }
        case 'disbursed': return 'Utbetalning påbörjad';
        case 'reject': {
          let str = 'Avslag';
          let extension;
          switch (data.deniedReason) {
            default: break;
            case 'None':
              extension = 'Ingen orsak';
              break;
            case 'ChangeOfMind':
              extension = 'Kunden har ändrat sig';
              break;
            case 'RejectionOnReevaluation':
              extension = 'Nekad vid utvärdering';
              break;
            case 'IncorrectInformation':
              extension = 'Ogiltig information';
              break;
            case 'Fraud':
              extension = 'Bedrägeri';
              break;
            case 'Other':
              extension = 'Annan orsak';
              break;
          }
          if (extension) str += ' (' + extension + ')';
          return str;
        }
      }
  }

  return exports.internalDescription(log);
};

exports.nystartfinansDescription = function (log) {
  const errorMessage = get(log, 'data.diagnose.message');
  const errorStatusCode = get(log, 'data.diagnose.statusCode');

  if (log.data.result === 'error') {
    if (errorMessage) {
      return `Fel: ${errorMessage} (${errorStatusCode})`;
    }
    return errorLogDiagnose(log.data);
  }

  switch (log.data.type) {
    default: break;

    case 'submit':
      switch (log.data.result) {
        default: break;
        case 'published': return 'Ansökan skickad till Nystart Finans';
      }
      break;

    case 'submitResult':
      switch (log.data.result) {
        default: break;
        case 'successful': return 'Ansökan mottagen';
        case 'closed': return 'Direktavslag';
      }
      break;

    case 'closeResult':
      switch (log.data.result) {
        default: break;
        case 'successful': return 'Nekande mottaget';
      }
      break;

    case 'acceptUpdateResult':
      switch (log.data.result) {
        default: break;
        case 'successful': return 'Uppdatering innan accept mottagen';
      }
      break;

    case 'acceptResult':
      switch (log.data.result) {
        default: break;
        case 'successful': return 'Accept mottagen';
      }
      break;

    case 'changeResult':
      switch (log.data.result) {
        default: break;
        case 'successful': return 'Begäran om förändring mottagen';
      }
      break;

    case 'callback':
      switch (log.data.result) {
        default: return 'Okänd uppdatering (' + log.data.result + ')';
        case 'offer': return 'Buduppdatering';
        case 'withdrawOffer': return 'Bud tillbakadraget';
        case 'closed': return 'Avslagsuppdatering';
        case 'closedAfterWithdrawOffer': return 'Avslagsuppdatering efter bud tillbakadraget';
        case 'comments': return 'Kommentar: ' + get(log, 'data.comment', '(tom)');
      }
  }

  return exports.internalDescription(log);
};

exports.ikanoDescription = function (log) {
  const errorMessage = get(log, 'data.diagnose.message');
  const errorStatusCode = get(log, 'data.diagnose.statusCode');

  if (log.data.result === 'error') {
    if (errorMessage) {
      return `Fel: ${errorMessage} (${errorStatusCode})`;
    }
    return errorLogDiagnose(log.data);
  }

  switch (log.data.type) {
    default: break;

    case 'submit':
      switch (log.data.result) {
        default: break;
        case 'published': return 'Ansökan skickad till Ikano';
      }
      break;

    case 'submitResult':
      switch (log.data.result) {
        default: break;
        case 'successful': return 'Ansökan mottagen';
      }
      break;

    case 'acceptResult':
      switch (log.data.result) {
        default: break;
        case 'successful': return 'Accept mottagen';
      }
      break;

    case 'fetch':
      switch (log.data.result) {
        default: break;
        case 'published': return 'Statushämtning publicerad';
      }
      break;

    case 'decision':
      switch (log.data.result) {
        default: break;
        case 'change': return 'Förändring via omskickning till Ikano';
      }
      break;

    case 'fetchResult':
      switch (log.data.result) {
        default: return 'Uppdateringskod: ' + log.data.result;
        case 'offer': return 'Buduppdatering';
        case 'pendingApproval': return 'Inväntar godkännande';
        case 'pn_sent': return 'Skuldebrevsuppdatering';
        case 'closed': return 'Avslagsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.medmeraDescription = function (log) {
  const data = log.data;
  const result = data.result;

  if (data.error || result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submit':
      switch (data.result) {
        default: break;
        case 'published': return 'Ansökan skickad till Medmera';
      }
      break;

    case 'submitResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Ansökan mottagen';
      }
      break;

    case 'closeResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Nekande mottaget';
      }
      break;

    case 'acceptResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Accept mottagen';
      }
      break;

    case 'changeResult':
      switch (data.result) {
        default: break;
        case 'reject-successful': return 'Nekande av tidigare bud mottaget';
      }
      break;


    case 'progressResult':
      switch (data.result) {
        default: return 'Okänd uppdatering (' + data.result + ')';
        case 'closed': return 'Avslag';
        case 'pending': return 'Manuell hantering';
        case 'offer': return 'Bud finns';
        case 'disbursed': return 'Utbetalning inledd';
        case 'awaitingDocuments': return 'Inväntar kompletteringar från kund';
        case 'eSignAssignmentCreated': return 'Inväntar e-signering av avtalet';
      }
  }

  return exports.internalDescription(log);
};

exports.lsbDescription = function (log) {
  const errorMessage = get(log, 'data.diagnose.message');
  const errorStatusCode = get(log, 'data.diagnose.statusCode');

  if (log.data.result === 'error') {
    if (errorMessage) {
      return `Fel: ${errorMessage} (${errorStatusCode})`;
    }
    return errorLogDiagnose(log.data);
  }

  switch (log.data.type) {
    default: break;

    case 'submit':
      switch (log.data.result) {
        default: break;
        case 'published': return 'Ansökan skickad till LSB';
      }
      break;

    case 'submitResult':
      switch (log.data.result) {
        default: break;
        case 'successful': return 'Ansökan mottagen';
        case 'statusError':
          switch (String(errorStatusCode)) {
            default: return commonErrorStatusCode();
            case '10': return 'Huvudsökande har redan en pågående ansökan';
            case '11': return 'Medsökande har redan en pågående ansökan';
            case '12': return 'Det kan bara finnas ett kundnummer';
            case '13': return 'Personnummer felaktigt formatterat';
            case '14': return 'Mobiltelefonnummer felaktigt formatterat';
            case '15': return 'E-postadress felaktigt formatterad';
            case '16': return 'E-postadress eller mobiltelefon måste anges';
          }
      }
      break;

    case 'closeResult':
      switch (log.data.result) {
        default: break;
        case 'successful': return 'Nekande mottaget';
        case 'statusError':
          switch (String(errorStatusCode)) {
            default: return commonErrorStatusCode();
            case '30': return 'Ansökan kan ej nekas i detta läge';
          }
      }
      break;

    case 'acceptResult':
      switch (log.data.result) {
        default: break;
        case 'successful': return 'Accept mottagen';
        case 'statusError':
          switch (String(errorStatusCode)) {
            default: return commonErrorStatusCode();
            case '30': return 'Ansökan kan ej accepteras i detta läge';
          }
      }
      break;

    case 'changeResult':
      switch (log.data.result) {
        default: break;
        case 'successful': return 'Begäran om förändring mottagen';
        case 'statusError':
          switch (String(errorStatusCode)) {
            default: return commonErrorStatusCode();
            case '30': return 'Ansökan kan ej uppdateras i detta läge';
          }
      }
      break;

    case 'callback':
      switch (log.data.result) {
        default: return 'Okänd uppdatering (' + log.data.result + ')';
        case 'offer': return 'Buduppdatering';
        case 'documents_sent': return 'Skuldebrev skickat till kunden';
        case 'pn_signed': return 'Kunden har undertecknat skuldebrevet';
        case 'disbursed': return 'Utbetalningsuppdatering';
        case 'applicationDeny': return 'Avslag på ansökan (makulerad)';
        case 'offerWithdraw': return 'Avslag på ansökan (makulerad)';
        case 'closed': return 'Avslagsuppdatering';
      }
  }

  return exports.internalDescription(log);

  function commonErrorStatusCode () {
    switch (String(errorStatusCode)) {
      default: return `Generellt fel ${errorStatusCode}: ${errorMessage}`;
      case '20': return 'Referensnummer finns ej';
      case '2': return 'Felaktigt broker-ID';
    }
  }
};

exports.komplettDescription = function (log) {
  const data = log.data;

  if (data.error || data.result === 'error') {
    const errorMessage = get(log, 'data.error') || get(log, 'data.diagnose.body.Message');
    const statusCode = get(log, 'data.diagnose.statusCode') || 'Okänd kod';
    if (errorMessage) {
      return `Fel: ${errorMessage} (${statusCode})`;
    }
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submit':
      switch (data.result) {
        default: break;
        case 'published': return 'Ansökan skickad till Morrow Bank';
      }
      break;

    case 'submitResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Ansökan mottagen';
      }
      break;

    case 'change':
      switch (data.result) {
        default: break;
        case 'published': return 'Förändring begärd';
      }
      break;

    case 'changeResult': // deprecated 2021-02-23
      switch (data.result) {
        default: break;
        case 'successful': return 'Förändring mottagen';
      }
      break;

    case 'changeCloseResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Stängning innan omskickning mottagen';
      }
      break;

    case 'accept':
      switch (data.result) {
        default: break;
        case 'published': return 'Accept skickad';
      }
      break;

    case 'acceptUpdate':
      switch (data.result) {
        default: break;
        case 'published': return 'Uppdatering innan accept skickad';
      }
      break;

    case 'acceptUpdateResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Uppdatering innan accept mottagen';
      }
      break;

    case 'acceptResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Accept mottagen';
      }
      break;

    case 'close':
      switch (data.result) {
        default: break;
        case 'published': return 'Nekande skickat';
      }
      break;

    case 'closeResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Nekande mottaget';
      }
      break;

    case 'fetchResult':
      switch (data.result) {
        default: return 'Okänd uppdatering (' + data.result + ')';
        case 'unknown': return data.message || '(Okänd statuskod)';
        case 'A.Est': return 'Ansökan godkänd (behandling pågår)';
        case 'A.AgentC': return 'Ansökan godkänd (kontrakt redo)';
        case 'A.CSign': return 'Ansökan godkänd (väntar på kundens signatur)';
        case 'A.CSignDoc': return 'Ansökan godkänd (väntar på kundens dokument och signatur)';
        case 'A.CDoc': return 'Väntar på kundens kompletteringar';
        case 'A.BudgetChk': return 'Budgetkontroll';
        case 'A.DocChk': return 'Dokumentkontroll';
        case 'A.Chk': return 'Väntar på formalitetskontroll';
        case 'A.PinSel': return 'Väntar på att kunden ska skapa en PIN-kod';
        case 'A.Acc': return 'Kundens konto har skapats';
        case 'offer': return 'Bud';
        case 'closed': return 'Avslagen/Nekad';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.wastgotaDescription = function (log) {
  const data = log.data;

  if (data.error || data.result === 'error') {
    const errorMessage = get(log, 'data.error') || get(log, 'data.diagnose.body.Message');
    const statusCode = get(log, 'data.diagnose.statusCode') || 'Okänd kod';
    if (errorMessage) {
      return `Fel: ${errorMessage} (${statusCode})`;
    }
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submit':
      switch (data.result) {
        default: break;
        case 'published': return 'Ansökan skickad till Wästgöta';
      }
      break;

    case 'submitResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Ansökan mottagen';
      }
      break;

    case 'changeCloseResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Stängning innan omskickning mottagen';
      }
      break;

    case 'acceptResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Accept mottagen';
      }
      break;

    case 'closeResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Nekande mottaget';
      }
      break;

    case 'callback':
      switch (data.result) {
        default: return 'Okänd uppdatering (' + data.result + ')';
        case 'unknown': return data.message || '(Okänd statuskod)';
        case 'offer': return 'Bud';
        case 'closed': return 'Stängd';
        case 'closed-customer-reject': return 'Nekad av kund';
        case 'closed-other-reject': return 'Stängd (annan orsak)';
        case 'closed-credit-reject': return 'Avslag';
        case 'accepted': return 'Accepterad/Skuldebrev skickat';
        case 'await_signing': return 'Väntar på kundens underskrift';
        case 'hold': return 'Pausad';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.nordaxSoapDescription = function (log) {
  const data = log.data;

  if (data.error || data.result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submit':
      if (data.result === 'published') return 'Ansökan skickad till Nordax';
      break;

    case 'submitResult':
      if (data.result === 'successful') return 'Ansökan mottagen';
      if (data.result === 'closed') return 'Direktavslag';
      break;

    case 'closeResult':
      if (data.result === 'successful') return 'Stängning mottagen';
      break;

    case 'changeResult':
      if (data.result === 'successful') return 'Förändring mottagen';
      break;

    case 'acceptResult':
      if (data.result === 'successful') return 'Accept mottagen';
      break;

    case 'callback':
      switch (data.result) {
        default: return 'Okänd uppdatering (' + data.result + ')';
        case 'offer': return 'Buduppdatering';
        case 'unknown': return `Okänd status: "${data.diagnose.status}"`;
        case 'info': {
          const reason = data.reason;
          switch (data.reason) {
            default: return `Okänd info: ${reason}`;
            case 'completion_information_required': return 'Kompletterande info behövs från kunden';
            case 'completion_information_received': return 'Kompetterande info från kunden mottaget';
            case 'documents_received': return 'Kunden har signerat skuldebrevet';
            case 'documents_in_progress': return 'Kompletterande info behövs från kunden';
            case 'documents_in_progress2': return 'Kompletterande info från kunden under behandling';
          }
        }
        case 'closed': {
          if (data.reason === 'verification_reject') return 'Avslagsuppdatering (dokumentverifikation)';
          return 'Avslagsuppdatering';
        }
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.resursDescription = function (log) {
  const data = log.data;

  if (data.error || data.result === 'error') {
    const errorMessage = get(log, 'data.error') || get(log, 'data.diagnose.body.Message');
    const statusCode = get(log, 'data.diagnose.statusCode') || 'Okänd kod';
    if (errorMessage) {
      return `Fel: ${errorMessage} (${statusCode})`;
    }
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submit':
      if (data.result === 'published') return 'Ansökan skickad till Resurs Bank';
      break;

    case 'submitResult':
      if (data.result === 'pending') return 'Manuell hantering krävs';
      if (data.result === 'closed') return 'Direktavslag';
      if (data.result === 'bid') return 'Omedelbart bud finns';
      break;

    case 'acceptUpdate':
      if (data.result === 'published') return 'Uppdatering innan accept skickad';
      break;

    case 'acceptUpdateResult':
      if (data.result === 'successful') return 'Uppdatering innan accept mottagen';
      break;

    case 'accept':
      if (data.result === 'published') return 'Accept skickad';
      break;

    case 'decision':
      if (data.result === 'change') return 'Förändring via omskickning begärd';
      break;

    case 'acceptResult':
      if (data.result === 'successful') return 'Accept mottagen';
      break;


    case 'callback':
      switch (data.result) {
        default: return 'Okänd uppdatering (' + data.result + ')';
        case 'offer': return 'Buduppdatering';
        case 'unknown': return data.message || 'Okänd callback';
        case 'closed': return 'Avslagsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
        case 'signed': return 'Kunden har undertecknat avtalet';
        case 'waiting_for_addition':
        case 'waitingforaddition': return 'Inväntar kundens kompletteringar';
      }
  }

  return exports.internalDescription(log);
};

exports.rememberDescription = function (log) {
  const data = log.data;

  if (data.error || data.result === 'error') {
    const errorMessage = get(log, 'data.error.message') || get(log, 'data.error') || get(log, 'data.diagnose.body.Message');
    const statusCode = get(log, 'data.diagnose.statusCode') || 'Okänd kod';
    if (errorMessage) {
      return `Fel: ${errorMessage} (${statusCode})`;
    }
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submit':
      if (data.result === 'published') return 'Ansökan skickad till Remember';
      if (data.result === 'published-with-delay') {
        const delay = data.delay;
        if (delay > 0 && isFinite(delay)) return `Ansökan skickad till Remember med ${Math.floor(delay / 1000)} fördröjning`;
        return 'Ansökan skickad till Remember utan fördröjning';
      }
      break;

    case 'submitResult':
      if (data.result === 'successful') return 'Ansökan mottagen';
      break;

    case 'closeResult':
      if (data.result === 'successful') return 'Stängning mottagen';
      break;

    case 'changeCloseResult':
      if (data.result === 'successful') return 'Stängning innan omskickning mottagen';
      break;

    case 'acceptResult':
      if (data.result === 'successful') return 'Accept mottagen';
      break;

    case 'callback':
      switch (data.result) {
        default: return 'Okänd uppdatering (' + data.result + ')';
        case 'offer': return 'Buduppdatering';
        case 'unknown': return `Okänd status: "${data.diagnose.status}"`;
        case 'closed': return 'Avslagsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
        case 'waitingfordocuments': return 'Väntar på kundens kompletteringar';
      }
  }

  return exports.internalDescription(log);
};

exports.northmillDescription = function (log) {
  const data = log.data;

  if (data.error || data.result === 'error') {
    const errorMessage = get(log, 'data.error') || get(log, 'data.diagnose.body.Message');
    const statusCode = get(log, 'data.diagnose.statusCode') || 'Okänd kod';
    if (errorMessage) {
      return `Fel: ${errorMessage} (${statusCode})`;
    }
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submit':
      switch (data.result) {
        default: break;
        case 'published': return 'Ansökan skickad till Northmill';
      }
      break;

    case 'submitResult':
      switch (data.result) {
        default: break;
        case 'offer': return 'Bud';
        case 'successful': return 'Ansökan mottagen';
        case 'closed': return 'Direktavslag';
      }
      break;

    case 'accept':
      switch (data.result) {
        default: break;
        case 'published': return 'Accept skickad';
      }
      break;

    case 'acceptResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Accept mottagen';
      }
      break;

    case 'callback':
      switch (data.result) {
        default: return 'Okänd uppdatering (' + data.result + ')';
        case 'closed': return 'Avslagsuppdatering';
        case 'signed': return 'Kund har undertecknat skuldebrevet';
        case 'pn_sent': // backwards compat
        case 'accepted': return 'Skuldebrevsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.moneygoDescription = function (log) {
  const data = log.data;
  const result = data.result;

  if (data.error || result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submit':
      switch (data.result) {
        default: break;
        case 'published': return 'Ansökan skickad till MoneyGo';
      }
      break;

    case 'submitResult': {
      const message = get(data, 'diagnose.message');
      switch (data.result) {
        default: break;
        case 'offer': return 'Automatiskt bud';
        case 'pending': return 'Pröv krävs';
        case 'closed': return message ? `Direktavslag (${message})` : 'Direktavslag';
        case 'pending_due_to_fraud': return 'Bedräglig ansökan, utredning pågår';
      }
    } break;

    case 'closeResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Nekande mottaget';
      }
      break;

    case 'acceptResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Accept mottagen';
      }
      break;

    case 'changeResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Förändring mottagen';
      }
      break;

    case 'updateResult':
      switch (data.result) {
        default: return 'Okänd uppdatering (' + data.result + ')';
        case 'offer': return 'Bud finns';
        case 'agreement_process': return 'Skuldebrev skickat till kund';
        case 'registration_process': return 'Signerat skuldebrev mottaget';
        case 'esigned': return 'Skuldebrevet har blivit e-signerat av kunden';
        case 'disbursed': return 'Lånet har blivit utbetalt';
        case 'approval_process': return 'Under behandling';
        case 'information_gathering_process': return 'MoneyGo samlar in information';
        case 'closed': return 'Ansökan avslutad';
        case 'pending': return 'Pröv krävs';
        case 'pending_due_to_fraud': return 'Bedräglig ansökan, utredning pågår';
      }
  }

  return exports.internalDescription(log);
};

exports.credentoDescription = function (log) {
  const data = log.data;
  const result = data.result;

  if (data.error || result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submit':
      switch (data.result) {
        default: break;
        case 'published': return 'Ansökan skickad till Credento';
      }
      break;

    case 'submitResult': {
      const message = get(data, 'diagnose.message');
      switch (data.result) {
        default: break;
        case 'offer': return 'Automatiskt bud';
        case 'pending': return 'Pröv krävs';
        case 'closed': return message ? `Direktavslag (${message})` : 'Direktavslag';
      }
    } break;

    case 'closeResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Nekande mottaget';
      }
      break;

    case 'acceptResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Accept mottagen';
      }
      break;

    case 'updateKycResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Uppdatering av KYC mottagen';
      }
      break;

    case 'changeResult':
      switch (data.result) {
        default: break;
        case 'reject-successful': return 'Nekande av tidigare bud mottaget';
      }
      break;

    case 'notification':
      switch (data.result) {
        default: return 'Okänd uppdatering (' + data.result + ')';
        case 'manualApproval': return 'Manuellt godkännande krävs';
        case 'internalVerification': return 'Intern verifiering krävs';
        case 'contractReceived': return 'Skuldebrev mottaget';
        case 'contractCreated': return 'Skuldebrev skapat';
        case 'cancelled': return 'Nekad';
        case 'rejected': return 'Avslag';
        case 'creditDecisionInformationUpdated': return 'Kreditbeslut uppdaterat';
        case 'disbursed': return 'Utbetalning inledd';
      }
  }

  return exports.internalDescription(log);
};

exports.kknDescription = function (log) {
  const data = log.data;
  const result = data.result;

  if (data.error) {
    return errorLogDiagnose(data);
  }

  if (result === 'error') {
    const reasonText = get(data, 'diagnose.reasonText') || 'Okänt fel/ingen reasonText';
    const emricBrokerFaultValidation = get(data, 'diagnose.emricBrokerFaultValidation');

    if (!emricBrokerFaultValidation) return reasonText;

    // check if we have validation items
    const validationItems = get(emricBrokerFaultValidation, '0.ValidationItems.0.ValidationItem', []).map(function (item) {
      return item.MessageBody[0];
    });

    if (validationItems.length > 0) {
      // when we have validationItems, reasonText is usually an idiotic stack trace that we can ignore
      return 'Valideringsfel från API' + '\n' + validationItems.join('\n');
    }

    return reasonText + '\n' + JSON.stringify(emricBrokerFaultValidation, null, 2);
  }

  switch (data.type) {
    default: break;

    case 'submit':
      switch (data.result) {
        default: break;
        case 'published': return 'Ansökan skickad till KonsumentKredit/Nordnet';
      }
      break;

    case 'submitResult':
      switch (data.result) {
        default: break;
        case 'reject': return 'Automatiskt avslag';
        case 'pending': return 'Manuell hantering krävs';
        case 'successful': return 'Automatiskt bud';
      }
      break;

    case 'acceptResult':
      switch (data.result) {
        default: break;
        case 'successful': return 'Accept godkänd';
      }
      break;


    case 'update':
      switch (data.result) {
        default: return 'Okänd uppdatering (' + data.result + ')';
        case 'disbursed': return 'Utbetalningsuppdatering';
        case 'bid': return 'Buduppdatering';
        case 'rejected': return 'Avslagsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.santanderGeDescription = function (log) {
  const result = get(log, 'data.result');
  const type = get(log, 'data.type');

  switch (type) {
    default: break;

    case 'submit':
      switch (result) {
        default: break;
        case 'published': return 'Ansökan skickad till Santander';
      }
      break;

    case 'remoteResult': {
      const tokens = [];
      const errorMsg = get(log, 'data.error.message');
      const msg = get(log, 'data.message', '');
      switch (result) {
        default: break;
        case 'unknown':
          tokens.push('Fel:', errorMsg || '(Okänt fel)');
          break;
        case 'closed':
          tokens.push(errorMsg ? 'Fel: ' + errorMsg : '', msg);
          break;
        case 'wait':
          tokens.push(msg);
          break;
        case 'ok':
          tokens.push(msg);
          break;
        case 'error':
          tokens.push('Fel:', errorMsg || '(Okänt fel)');
          break;
        case 'disbursed':
          tokens.push(msg);
          break;
        case 'approved':
          tokens.push(msg);
          break;
      }
      return tokens.filter(v => v).join(' ');
    }

  }

  return exports.internalDescription(log);
};

exports.lendifyDescription = function (log) {
  const type = get(log, ['data', 'type']);
  const result = get(log, ['data', 'result']);
  const diagnose = get(log, ['data', 'diagnose']) || {};
  const error = get(log, ['data', 'error']) || {};

  if (result === 'successful') {
    switch (type) {
      default: break;
      case 'submitResult': return 'Ansökan mottagen av Lendify';
      case 'approveResult': // compat
      case 'acceptUpdate': return 'Uppdatering innan accept mottagen av Lendify';
      case 'acceptResult': return 'Accept mottagen av Lendify';
      case 'changeResult': return 'Ändring mottagen av Lendify';
      case 'processResult': return 'Stängning mottagen av Lendify';
    }
  }

  switch (type) {       
    default: break;
    case 'submit':
      if (result === 'published') return 'Ansökan skickad till Lendify';
      break;

    case 'accept':
      if (result === 'published') return 'Accept skickad';
      if (result === 'waiting_for_bid') return  'Inväntar bud för automatisk accept';
      break;

    case 'submitResult':
    case 'acceptResult': // backwards compat
    case 'approveResult':
    case 'changeResult':
    case 'denyResult':
    case 'processResult':
      switch (result) {
        case 'pending': return 'Mottagen';
        case 'offer': return 'Omedelbart bud finns';
        case 'error': return maybeGetErrorDiagnose('Allmänt fel');
        case 'closed': return 'Avslag eller nekande';
        case 'requestError': return maybeGetErrorDiagnose('Anropsfel');
        case 'applicantError': return maybeGetErrorDiagnose('Ansökningsfel');
        default: return result;
      }

    case 'callback':
    case 'statusResult':
      switch (result) {
        default: return result;
        case 'offer': return 'Bud finns';
        case 'waiting_for_user': return 'Acceptmail och SMS skickat från Lendify till sökande';
        case 'waiting_for_information': return 'Sökande har loggat in och laddat upp dokument som väntar på verifiering';
        case 'user_logged_in': return 'Sökande har loggat in';
        case 'updated_offer': return 'Uppdaterat bud finns';
        case 'debt_letter_sent': return 'Skuldebrev har skickats till kunden';
        case 'disbursed': return 'Utbetalningsuppdatering';
        case 'closed': return 'Avslag eller nekande';
      }
  }

  return exports.internalDescription(log);

  function maybeGetErrorDiagnose (prefix) {
    const result = prefix || '';
    const message = diagnose.message || error.message;
    const messages = diagnose.messages;
    if (typeof message === 'string') return result + ': ' + message;
    if (Array.isArray(messages)) return result + '\n' + messages.map(function (msg) {
      return JSON.stringify(msg); 
    }).join('\n');
    if (message) return result + '\n' + JSON.stringify(message);
    if (messages) return result + '\n' + JSON.stringify(messages);
    return result;
  }
};

exports.broccDescription = function (log) {
  const type = get(log, ['data', 'type']);
  const result = get(log, ['data', 'result']);
  const diagnose = get(log, ['data', 'diagnose']) || {};

  switch (type) {       
    default: break;
    case 'decision':
      switch (result) {
        default: break;
        case 'change': return 'Förändring via omskickning till Brocc';
      }
      break;
    case 'submit':
      switch (result) {
        default: break;
        case 'published': return 'Ansökan skickad till Brocc';
      }
      break;

    case 'submitResult':
      switch (result) {
        default: break;
        case 'successful': return 'Ansökan mottagen av Brocc';
        case 'error': return maybeGetErrorDiagnose('Allmänt fel');
      }
      break;

    case 'statusUpdate':
      switch (result) {
        default: break;
        case 'unknown': return log.data.message || 'Okänd status';
        case 'error': return maybeGetErrorDiagnose('Allmänt fel');
        case 'offer': return 'Bud finns';
        case 'accepted': return 'Kund har accepterat';
        case 'disbursed': return 'Utbetalningsuppdatering';
        case 'closed': return 'Avslag från långivare';
        case 'rejected': return 'Nekad av kund';
      }
  }

  return exports.internalDescription(log);

  function maybeGetErrorDiagnose (prefix) {
    const result = prefix || '';
    const body = get(diagnose, 'body');
    const statusCode = diagnose.statusCode;
    const dataError = get(log, 'data.error');
    if (dataError) {
      const message = dataError.message;
      const stack = dataError.stack;
      if (message && stack) return message + '\n' + stack;
      return result + '\n' + JSON.stringify(dataError);
    }
    const error = get(body, 'error', null);
    if (typeof body === 'string') return 'Fel: ' + body;
    if (typeof error === 'string') return result + ': ' + error;
    if (body) return result + '\n' + JSON.stringify(body);
    if (isFinite(statusCode)) return result + ': ' + statusCode;
    return result;
  }
};

exports.sevendayRestDescription = function (log) {
  const { data } = log;
  const type = get(log, ['data', 'type']);
  const result = get(log, ['data', 'result']);

  switch (type) {
    default: break;
    case 'submit':
      if (result === 'published') return 'Ansökan skickad till Sevenday';
      break;

    case 'submitResult':
      if (result === 'successful') return 'Ansökan mottagen av Sevenday';
      break;

    case 'decisionResult':
      if (result === 'successful') return 'Beslut mottaget av Sevenday';
      break;

    case 'callback-ignored':
      if (result === 'closed') return 'Inaktuellt bud tillbakadraget';
      break;

    case 'callback':
      switch (result) {
        default: return result;
        case 'unknown': return data.message;
        case 'closed':
          if (data.reason === 'co_applicant_required') return 'Avslagsuppdatering eftersom medsökande krävs';
          return 'Avslagsuppdatering';
        case 'offer': return 'Buduppdatering';
        case 'bill_of_debt_sent': return 'Skuldebrev skickat till kunden';
        case 'bill_of_debt_received': return 'Signerat skuldebrev mottaget';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.avidaStaccDescription = function (log) {
  const type = get(log, ['data', 'type']);
  const result = get(log, ['data', 'result']);

  switch (result) {
    default: break;
    case 'error': return errorLogDiagnose(log.data);
    case 'successful':
      switch (type) {
        default: return 'Anrop mottaget av Avida';
        case 'submitResult': return 'Ansökan mottagen av Avida';
        case 'changeResult': return 'Förändring mottagen av Avida';
        case 'closeResult': return 'Stängning mottagen av Avida';
        case 'acceptResult': return 'Accept mottagen av Avida';
      }
  }

  switch (type) {       
    default: break;
    case 'submit':
      if (result === 'published') return 'Ansökan skickad till Avida';
      break;
    case 'callback':
      switch (result) {
        default: return result;
        case 'unknown': return log.data.message;
        case 'closed': return 'Avslag';
        case 'canceled': return 'Avbruten av användare';
        case 'declined': return 'Stängd av långivare';
        case 'offer': return 'Bud finns';
        case 'document-sent': return 'Skuldebrev skickat till kund';
        case 'document-signed': return 'Skuldebrev signerat av kund';
        case 'ready-for-disbursement': return 'Redo att utbetalas';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.medmera2022Description = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {       
    default: break;

    case 'fetchResult':
    case 'submitResult':
      switch (result) {
        default: return result;
        case 'created': return 'Ansökan mottagen';
        case 'offer': return 'Bud';
        case 'closed': return 'Avslag';
        case 'disbursed': return 'Utbetalning';
        case 'unknown': return errorLogDiagnose(data);
        case 'in_progress': return 'Under behandling';
        case 'in_progress_kyc': return 'Väntar på ansökarens KYC-svar';
        case 'in_progress_signing': return 'Väntar på e-signering av skuldebrev';
        case 'in_progress_manual_inspection': return 'Manuell hantering';
        case 'in_progress_awaiting_documents': return 'Inväntar kompletteringar';
      }

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottagen';
      break;

    case 'changeCloseResult':
      if (result === 'successful') return 'Nekande innan förändring via omskickning mottaget';
      break;
  }

  return exports.internalDescription(log);
};

exports.santander2024Description = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {       
    default: break;

    case 'changeResult':
    case 'submitResult':
      if (result === 'successful') return 'Ansökan mottagen';
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottagen';
      break;

    case 'callback':
      switch (result) {
        default: break;
        case 'unknown': return data.error || 'Okänd callback';
        case 'pending': return 'Pröv';
        case 'offer': return 'Bud';
        case 'closed': return 'Avslagsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.santander2022Description = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {       
    default: break;

    case 'changeResult':
    case 'submitResult':
      switch (result) {
        default: break;
        case 'closed': return 'Avslag';
        case 'bid': return 'Bud';
        case 'pending': return 'Pröv';
      }
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      if (result === 'closed') return 'Acceptavslag';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottagen';
      break;

    case 'fetchResult':
      switch (result) {
        default: break;
        case 'bid': return 'Buduppdatering';
        case 'closed': return 'Avslagsuppdatering';
        case 'pn_signed': return 'Skuldebrev signerat';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.risicumDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {       
    default: break;

    case 'submitResult':
      if (result === 'offer') return 'Direktbud';
      if (result === 'pending') return 'Avslag på grund av "Pröv"';
      if (result === 'closed') {
        const reason = data.reason;
        const msg = 'Direktavslag';
        return reason ? `${msg}: ${reason}` : msg;
      }
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottagen';
      break;

    case 'changeCloseResult':
      if (result === 'successful') return 'Nekande innan förändring mottaget';
      break;

    case 'callback':
      switch (result) {
        default: break;
        case 'unknown': return errorLogDiagnose(data);
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.saldoDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;
  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submitResult':
      if (result === 'successful') return 'Ansökan mottagen';
      if (result === 'closed') return 'Direktavslag';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottaget';
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'decision':
      if (result === 'change-submit') return 'Förändring via omskickning begärd';
      break;

    case 'statusResult':
    case 'callback': {
      switch (result) {
        default: break;
        case 'approved': return 'Kompletteringar godkända';
        case 'offer': return 'Buduppdatering';
        case 'closed': return 'Stängningsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
    }
  }

  return exports.internalDescription(log);
};

exports.marginalenRestDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;
  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submitResult':
      if (result === 'successful') return 'Ansökan mottagen';
      if (result === 'closed') return 'Direktavslag';
      if (result === 'offer') return 'Direktbud';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottaget';
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'changeResult':
      if (result === 'successful') return 'Förändring mottagen';
      break;

    case 'statusResult':
    case 'callback': {
      switch (result) {
        default: break;
        case 'successful': {
          const applicationStatus = get(data, 'applicationStatus');
          switch (applicationStatus) {
            default: return `Okänd info: "${applicationStatus}"`;
            case 'Signed': return 'Skuldebrev signerat av kund';
            case 'Booked': return 'Bokförd i bankens system';
            case 'Received': return 'Ansökan mottagen';
            case 'Pending': return 'Väntar på beslut';
          }
        }
        case 'accepted': return 'Acceptuppdatering';
        case 'offer': return 'Buduppdatering';
        case 'closed': return 'Stängningsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
    }
  }

  return exports.internalDescription(log);
};

exports.facitDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;
  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submitResult':
      if (result === 'successful') return 'Ansökan mottagen';
      break;

    case 'changeCloseResult':
      if (result === 'successful') return 'Nekande innan omskickning mottaget';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottaget';
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'callback': {
      switch (result) {
        default: break;
        case 'info': {
          const status = get(data, 'diagnose.status');
          switch (status) {
            default: return `Okänd info: "${status}"`;
            case 'ContractSent': return 'Skuldebrev skickat till kund';
            case 'ContractSigned': return 'Skuldebrev signerat av kund';
          }
        }
        case 'offer': return 'Buduppdatering';
        case 'closed': return 'Stängningsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
    }
  }

  return exports.internalDescription(log);
};

exports.bankyDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;
  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submitResult':
      if (result === 'closed') return 'Direktavslag';
      if (result === 'offer') return 'Direktbud';
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'callback': {
      switch (result) {
        default: break;
        case 'closed': return 'Avslagsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
    }
  }

  return exports.internalDescription(log);
};

exports.ferratumDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;
  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submitResult':
      if (result === 'successful') return 'Ansökan mottagen';
      if (result === 'closed') return 'Direktavslag';
      break;

    case 'fetchStatus':
      if (result === 'published') return 'Hämta status publicerad';
      break;

    case 'fetchOffers':
      if (result === 'published') return 'Hämta erbjudanden publicerad';
      break;

    case 'fetchOffersResult':
      if (result === 'offer') return 'Bud hämtat';
      if (result === 'empty') return 'Inga bud finns';
      break;

    case 'fetchStatusResult':
      switch (result) {
        default: break;
        case 'successful': return `Pågående (${data.applicationStatus || '?'})`;
        case 'closed': return 'Avslag';
        case 'disbursed': return 'Utbetald';
      }
      break;

    case 'changeResult':
      if (result === 'successful') return 'Förändring mottagen';
      break;

    case 'changeCloseResult':
      if (result === 'successful') return 'Nekande innan omskickning mottaget';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottaget';
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'callback': {
      switch (result) {
        default: break;
        case 'successful':
          switch (data.actionType) {
            default: return `Callback (${data.actionType || '?'})`;
            case 'status-update': return 'Callback: uppmaning att hämta status';
            case 'offers-ready': return 'Callback: uppmaning att erbjudanden finns';
          }
        case 'offer': return 'Buduppdatering';
        case 'closed': return 'Avslagsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
    }
  }

  return exports.internalDescription(log);
};

exports.gfMoneyDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: break;

    case 'submitResult':
      if (result === 'successful') return 'Ansökan mottagen';
      if (result === 'closed') return 'Direktavslag';
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      if (result === 'closed') return 'Accept direktavslagen';
      break;

    case 'callback': {
      switch (result) {
        default: break;
        case 'offer': return 'Buduppdatering';
        case 'closed': return 'Avslagsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
    }
  }

  return exports.internalDescription(log);
};

exports.moankDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {       
    default: break;

    case 'submitResult':
      if (result === 'offer') return 'Omedelbart bud';
      if (result === 'closed') return 'Direktavslag';
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottagen';
      break;

    case 'changeResult':
      if (result === 'offer') return 'Omedelbart förändringsbud';
      if (result === 'closed') return 'Direktavslag';
      break;

    case 'callback': {
      const prefix = data.previous_bid ? 'Tidigare bud: ' : '';
      switch (result) {
        default: break;
        case 'offer': return prefix + 'Buduppdatering';
        case 'closed': return prefix + 'Avslagsuppdatering';
        case 'debt_notice_sent': return prefix + 'Skuldebrev skickat';
        case 'debt_notice_signed': return prefix + 'Skuldebrev signerat';
        case 'disbursed': return prefix + 'Utbetalningsuppdatering';
      }
    }
  }

  return exports.internalDescription(log);
};

exports.thornDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {       
    default: break;

    case 'submitResult':
      if (result === 'successful') return 'Ansökan mottagen';
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottagen';
      break;

    case 'callback':
      switch (result) {
        default: break;
        case 'offer': return 'Buduppdatering';
        case 'closed': {
          const reason = get(data, 'reason');
          return 'Avslagsuppdatering' + (reason ? ` (${reason})` : '');
        }
        case 'contract_sent': return 'Skuldebrev skickat';
        case 'contract_signed': return 'Skuldebrev signerat';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.sveaDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {       
    default: break;

    case 'submitResult':
      if (result === 'successful') return 'Ansökan mottagen';
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottagen';
      break;

    case 'callback':
      switch (result) {
        default: break;
        case 'offer': return 'Buduppdatering';
        case 'closed': {
          const reason = get(data, 'reason');
          return 'Avslagsuppdatering' + (reason ? ` (${reason})` : '');
        }
        case 'pn_sent': return 'Skuldebrev skickat';
        case 'pn_received': return 'Skuldebrev signerat och mottaget';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.icaDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {       
    default: break;

    case 'submitResult':
      if (result === 'pending') return 'Ansökan mottagen och under behandling';
      if (result === 'closed') {
        const reason = data.reason;
        const msg = 'Direktavslag';
        return reason ? `${msg}: ${reason}` : msg;
      }
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottagen';
      break;

    case 'statusResult':
      switch (result) {
        default: break;
        case 'pending': return 'Under behandling';
        case 'offer': return 'Buduppdatering';
        case 'closed': return 'Avslagsuppdatering';
        case 'additional_information_needed': return 'Långivaren väntar på svar på frågor från kunden';
        case 'awaiting_kdk': return 'SMS skickat till kunden med frågor';
        case 'awaiting_signature': {
          const list = data.customerlist || [];
          const suffix = list && list.length > 1 ? list.map(p => {
            const { personal_number:pnr, signed } = p;
            const signedNatural = signed ? 'Signerat' : 'Ej signerat';
            return `${pnr}: ${signedNatural}`;
          }) : null;
          return 'Inväntar underskrift från kunden' + (suffix ? ` (${suffix.join(', ')})` : '');
        }
        case 'delivery_in_progress': return 'Underskrifter mottagna och väntar på utbetalning';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.norwegianDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {       
    default: break;

    case 'submitResult':
      if (result === 'successful') return 'Ansökan mottagen';
      if (result === 'closed') {
        const reason = data.reason;
        const msg = 'Direktavslag';
        return reason ? `${msg}: ${reason}` : msg;
      }
      break;

    case 'acceptResult':
      if (result === 'successful') return 'Accept mottagen';
      break;

    case 'closeResult':
      if (result === 'successful') return 'Nekande mottagen';
      break;

    case 'changeResult':
      if (result === 'offer') return 'Förändringsbud inlagt';
      if (result === 'closed') {
        const reason = data.reason || '';
        return 'Avslag' + (reason ? ' ' + reason : '');
      }
      break;

    case 'fetchResultChange':
      if (result === 'published') return 'Förändring vid första bud publicerad';
      break;

    case 'fetchResult':
      switch (result) {
        default: break;
        case 'offer': return 'Buduppdatering';
        case 'closed': return 'Avslagsuppdatering';
        case 'accepted': return 'Acceptuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.loanstepDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {       
    default: break;

    case 'submitResult':
      if (result === 'offer') return 'Omedelbart bud';
      if (result === 'closed') return 'Direktavslag';
      break;

    case 'change':
      if (result === 'published') return 'Förändring via omskickning till Loanstep';
      break;

    case 'callback':
      switch (result) {
        default: break;
        case 'unknown': return data.message || 'Okänd callback';
        case 'closed': return 'Avslagsuppdatering';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.klaraDescription = function (log) {
  const { data = {} } = log;
  const { result } = data;

  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {       
    default: break;

    case 'submitApplication':
      if (result === 'published') return 'Inskickning av ansökan publicerad';
      break;

    case 'submitFetchStatusResult':
      if (result === 'closed') return 'Direktavslag på personnummerkontroll';
      if (result === 'successful') return 'Personnummerkontroll OK';
      break;

    case 'submitApplicationResult':
      if (result === 'offer') return 'Omedelbart bud';
      if (result === 'closed') return 'Direktavslag';
      if (result === 'pending') return 'Pröv krävs';
      break;

    case 'fetchStatus':
      if (result === 'published') return 'Personnummerkontroll publicerad';
      break;

    case 'fetchResult':
      if (result === 'offer') return 'Bud';
      if (result === 'closed') return 'Avslag';
      break;

    case 'callback':
      switch (result) {
        default: break;
        case 'unknown': return data.message || 'Okänd callback';
        case 'disbursed': return 'Utbetalningsuppdatering';
      }
  }

  return exports.internalDescription(log);
};

exports.description = function (log) {
  const data = log.data || {};

  // validation errors
  if (data.error === 'validate') {
    const errors = get(data, 'result.errors') || [];
    const tokens = ['Valideringsfel'];
    for (const error of errors) {
      const { reason, field } = (error || {});
      tokens.push(field + ': ' + validateReason(reason));
    }

    return tokens.join('\n');
  }

  switch (data.type) {

    case 'status_changed':
      return `Status ändrad till "${status(data.to_status)}"`;

    case 'created': return 'Skapad';

    case 'fieldchange': // deprecated name
    case 'field_changed':
      return logFieldChange(data, indexColumnName);

    default: {
      const adapter = get(log, 'data.by.bank_adapter');
      switch (adapter) {
        default: {
          if (data.result === 'error') {
            const error = data.error || {};
            const stack = error.stack || '';
            return data.type + '\n' + error.message + '\n' + stack;
          }
          return exports.internalDescription(log);
        }

        case 'bigbank': return exports.internalDescription(log);
        case 'internal': return exports.internalDescription(log);
        case 'swedbank': return exports.internalDescription(log);
        case 'avida_stacc': return exports.avidaStaccDescription(log);
        case 'banky': return exports.bankyDescription(log);
        case 'brocc': return exports.broccDescription(log);
        case 'collector':
        case 'collector_rest': return exports.collectorRestDescription(log);
        case 'credento': return exports.credentoDescription(log);
        case 'facit': return exports.facitDescription(log);
        case 'ferratum': return exports.ferratumDescription(log);
        case 'gf_money': return exports.gfMoneyDescription(log);
        case 'klara': return exports.klaraDescription(log);
        case 'ikano': return exports.ikanoDescription(log);
        case 'kkn': return exports.kknDescription(log);
        case 'lendify': return exports.lendifyDescription(log);
        case 'lsb': return exports.lsbDescription(log);
        case 'loanstep': return exports.loanstepDescription(log);
        case 'marginalen': return exports.marginalenDescription(log);
        case 'marginalen_rest': return exports.marginalenRestDescription(log);
        case 'medmera': return exports.medmeraDescription(log);
        case 'medmera_2022': return exports.medmera2022Description(log);
        case 'moank': return exports.moankDescription(log);
        case 'moneygo': return exports.moneygoDescription(log);
        case 'nordax_soap': return exports.nordaxSoapDescription(log);
        case 'northmill': return exports.northmillDescription(log);
        case 'norwegian': return exports.norwegianDescription(log);
        case 'ica': return exports.icaDescription(log);
        case 'remember': return exports.rememberDescription(log);
        case 'resurs': return exports.resursDescription(log);
        case 'risicum': return exports.risicumDescription(log);
        case 'komplett': return exports.komplettDescription(log);
        case 'wastgota': return exports.wastgotaDescription(log);
        case 'nystartfinans': return exports.nystartfinansDescription(log);
        case 'santander_ge': return exports.santanderGeDescription(log);
        case 'santander_2022': return exports.santander2022Description(log);
        case 'santander_2024': return exports.santander2024Description(log);
        case 'saldo': return exports.saldoDescription(log);
        case 'sevenday_rest': return exports.sevendayRestDescription(log);
        case 'svea': return exports.sveaDescription(log);
        case 'sevenday': return exports.sevendayDescription(log);
        case 'thorn': return exports.thornDescription(log);
      }
    }
  }
};
