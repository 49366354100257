import React from 'react';
import moment from 'moment-timezone';
import useTimezone from 'src/hooks/useTimezone';
import { useDateTimeFormat } from 'src/contexts/DateTimeFormatContext';
import classNames from 'classnames';

const defaultInputFormats = [
  'YYYY-MM-DD',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'YYYY-MM-DDTHH:mm:ss.SSSSSSZ',
  'YYYY-MM-DDTHH:mm:ssZ',
];

interface DateTimeProps extends Omit<React.HTMLProps<HTMLTimeElement>, 'value' | 'defaultValue'> {
  value?: string | Date | number | null;
  defaultValue?: React.ReactNode;
  format?: string;
}

export default function DateTime (props: DateTimeProps) {
  const {
    value,
    defaultValue = '',
    format = 'YYYY-MM-DD HH:mm',
    className,
    ...otherProps
  } = props;

  const dateTimeFormat = useDateTimeFormat();
  const timezone = useTimezone();

  const mom = moment(value, defaultInputFormats, true).tz(timezone);
  if (!mom.isValid()) return <>{defaultValue}</>;

  return (
    <time
      title={mom.format('YYYY-MM-DD HH:mm:ss')}
      {...otherProps}
      className={classNames(className, !dateTimeFormat.disabled ? 'cursor-pointer' : '')}
      dateTime={value as string}
      onClick={!dateTimeFormat.disabled ? dateTimeFormat.cycleShow : undefined}
    >
      {dateTimeFormat.show === 'date' || dateTimeFormat.disabled ? (
        <>{mom.format(format)}</>
      ) : (
        <>{mom.fromNow()}</>
      )}
    </time>
  );
}
