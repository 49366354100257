import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import * as groupFilter from 'shared/filter/group';
import { GroupRow } from 'shared/types/group';

interface NameProps {
  value: Pick<GroupRow, 'id' | 'name'>;
}

export function Name (props: NameProps) {
  const { value:sms } = props;
  if (!isObjectLike(sms)) return null;
  const id = sms.id;
  return (
    <IdShort value={id} />
  );
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: GroupRow['is_active'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={groupFilter.status}
      {...restOfProps}
    />
  );
}
