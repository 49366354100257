import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { EmailRow } from 'shared/types/email';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import * as EmailFormFields from 'src/email/EmailFormFields';
import * as formUtils from 'src/utils/form';
import {Trash} from 'react-feather';

export interface EmailEditFormValues {
  from_name: string;
  from_address: string;
  to_name: string;
  to_address: string;
  subject: string;
  body_html: string;
}

interface EmailEditFormProps {
  className?: string;
  initialValues: EmailEditFormValues;
  onSubmit: (values: EmailEditFormValues, helpers: FormikHelpers<EmailEditFormValues>) => Promise<any>;
  onDelete?: () => void;
  submitError?: Error | null;
  disabled?: boolean;
}

export default function EmailEditForm (props: EmailEditFormProps) {
  const { initialValues, submitError, onDelete, onSubmit, disabled, className } = props;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} className={className}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="mx-3">
            <Row>

              <Col>
                <EmailFormFields.FromName disabled={disabled} />
              </Col>

              <Col>
                <EmailFormFields.ToName disabled={disabled} />
              </Col>

            </Row>

            <Row>

              <Col>
                <EmailFormFields.FromAddress disabled={disabled} />
              </Col>

              <Col>
                <EmailFormFields.ToAddress disabled={disabled} />
              </Col>

            </Row>

            <EmailFormFields.Subject disabled={disabled} />

            <EmailFormFields.BodyHtml disabled={disabled} />

          </div>

          <div className="mt-3 p-3 border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap align-items-center justify-content-between">
              {onDelete ? (
                <ConfirmActionModalButton
                  type="button"
                  className="rounded d-flex gap-1"
                  variant="danger"
                  disabled={!formProps.isValid || formProps.isSubmitting}
                  onConfirm={onDelete}
                  message="Är du säker på att du vill radera e-postmeddelandet?"
                  title="Radera det här e-postmeddelandet"
                >
                  <Trash size={18} />
                  Radera
                </ConfirmActionModalButton>
              ) : <div></div>}
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export function emailToFormValues (email: EmailRow): EmailEditFormValues {
  const { from, to, subject, body_html } = email;
  return {
    from_name: from?.name || '',
    from_address: from?.address || '',
    to_name: to?.name || '',
    to_address: to?.address || '',
    subject: subject || '',
    body_html: body_html || '',
  };
}

export function formValuesToUpdate (initialValues: EmailEditFormValues, values: EmailEditFormValues): Partial<EmailEditFormValues> {
  const changes = formUtils.changes(initialValues, values);
  return changes;
}
