import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { WidgetGroups, WidgetList } from 'src/widgets/types';
import UserAnchor from 'src/user/UserAnchor';
import { WidgetLoader, WidgetTimeline } from 'src/widgets/CommonWidgets';
import { WidgetListCard } from 'src/cards/CardHelpers';
import { CustomerJaycomInsurance } from 'shared/types/customer_jaycom_insurance';
import { CustomerJaycomInsuranceLogWithRelations } from 'shared/types/customer_jaycom_insurance_log';
import * as jaycomInsuranceLogFilters from 'shared/filter/customer_jaycom_insurance_log';
import {Link} from 'react-router-dom';

interface JaycomInsuranceWidgetProps {
  insurance: CustomerJaycomInsurance;
}

export const widgetGroups: WidgetGroups = [
  {id: 'properties', title: 'Egenskaper'},
];

export const allWidgets: WidgetList = {
  log: {
    id: 'log',
    Component: Log,
    title: 'Logg',
    group: 'properties',
  },
};

export function Log (props: JaycomInsuranceWidgetProps) {
  const { insurance } = props;

  const query = useQuery<CustomerJaycomInsuranceLogWithRelations[], Error>({
    queryKey: [`/customer_jaycom_insurance/${insurance.id}/logs`, {params: {limit: 5}}],
  });

  return (
    <WidgetListCard {...props}>
      <WidgetLoader isLoading={query.isLoading} error={query.error}>
        {query.isSuccess && (
          <WidgetTimeline
            className="m-3"
            list={query.data}
            description={jaycomInsuranceLogFilters.description}
            by={(item: any) => item.user_id ? <UserAnchor user={item.User} /> : 'System'}
          />
        )}
        <div className="d-flex justify-content-center mb-2 pt-2 border-top">
          <Link className="btn btn-outline-primary btn-sm" to={`/customer_jaycom_insurance/${insurance.id}/log`}>
            Visa hela loggen
          </Link>
        </div>
      </WidgetLoader>
    </WidgetListCard>
  );
}
