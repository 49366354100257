import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import * as api from 'src/api';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FileRow } from 'shared/types/files';
import * as formUtils from 'src/utils/form';
import FileEditForm, { FileEditFormValues, fileToFormValues, formValuesToUpdate } from 'src/file/FileEditForm';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useEventModelEmitDeletes, useEventModelEmitUpdates, useEventModelSubscribeToQuery } from 'src/hooks/useEventModels';

interface FileModalBodyProps {
  id: string;
}

export default function FileModalBody (props: FileModalBodyProps) {
  const { id } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();

  const emitUpdate = useEventModelEmitUpdates<FileRow>('file');
  const emitDelete = useEventModelEmitDeletes<FileRow>('file');

  const queryKey = [`/file/${id}`];
  const query = useQuery<FileRow, Error>({queryKey});

  useEventModelSubscribeToQuery({queryKey: [queryKey[0]]}, 'file');

  const file = query.data;

  const updateMutation = useMutation<FileRow, Error, Partial<FileEditFormValues>>({
    mutationFn: async vars => {
      const file = await api.request({
        url: `/file/${id}`,
        method: 'PATCH',
        data: vars,
      });

      queryClient.setQueryData([`/file/${id}`], file);
      notyf.success({type: 'default', message: 'Filen uppdaterades'});
      modalStateContext.onHide();
      emitUpdate(file);

      return file;
    },
  });

  const onUploadSuccess = (newFile: FileRow) => {
    queryClient.setQueryData(queryKey, () => newFile);
  };

  const deleteMutation = useMutation<void, Error>({
    mutationFn: async () => {
      await api.request({
        url: `/file/${id}`,
        method: 'DELETE',
      });

      notyf.success({type: 'danger', message: 'Filen raderades'});
      if (query.data) emitDelete(query.data);
      modalStateContext.onHide();
    },
  });

  const onDelete = async () => {
    return deleteMutation.mutateAsync();
  };

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<FileRow, FileEditFormValues, Partial<FileEditFormValues>>({
    queryDataToFormValues: fileToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(fileToFormValues(file), values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading} />
      {file && (
        <>
          <FileEditForm
            file={file}
            className="mt-0"
            onUploadSuccess={onUploadSuccess}
            initialValues={fileToFormValues(file)}
            onSubmit={formCycleHelpers.onSubmit}
            submitError={updateMutation.error}
            onDelete={onDelete}
          />
        </>
      )}
    </div>
  );
}
