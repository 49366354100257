import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import ErrorAlert from 'src/alerts/ErrorAlert';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import * as api from 'src/api';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import { ComplaintRow } from 'shared/types/complaint';
import useNotyf from 'src/hooks/useNotyf';
import * as FormikFormControls from 'src/form/FormikFormControls';
import * as complaintFilter from 'shared/filter/complaint';
import constants from 'shared/constants';

interface ComplaintNoteFormProps {
  complaint: ComplaintRow;
  onReload?: () => void;
}

interface ComplaintFormValues {
  text?: string;
  status: string;
  priority: string;
}

export default function ComplaintNoteForm (props: ComplaintNoteFormProps) {
  const { complaint, onReload } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();

  const mutation = useMutation<ComplaintRow, Error, ComplaintFormValues>({
    mutationFn: data => api.request({
      url: `/complaint/${complaint.id}/note`,
      method: 'POST',
      data,
    }).then(response => {
      return response.form;
    }),
    onSuccess: updatedComplaint => {
      queryClient.setQueryData([`/complaint/${complaint.id}`], () => updatedComplaint);
      notyf.success({type: 'default', message: 'Klagomålet uppdaterades'});
      onReload();
    },
  });

  const initialValues = {
    text: '',
    priority: complaint.priority,
    status: complaint.status,
  };

  const onSubmit = async (values, helpers: FormikHelpers<ComplaintFormValues>) => {
    await mutation.mutateAsync(values);
    helpers.resetForm({values: {...initialValues}});
    helpers.setTouched({});
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form placeholder="LOL">
          <FormikFormControls.FormGroupControl
            label="Notat"
            minLength={0}
            maxLength={5000}
            name="text"
            placeholder="Ange en anteckning"
            as="textarea"
            rows={formProps.values.text.split(/\n/g).length}
            required
          />

          <Row>
            <Col>
              <FormikFormControls.FormGroupSelect
                label="Status"
                name="status"
                required
              >
                {constants.complaints.status.map(status => (
                  <option value={status} key={status}>{complaintFilter.status(status)}</option>
                ))}
              </FormikFormControls.FormGroupSelect>
            </Col>
            <Col>

              <FormikFormControls.FormGroupSelect
                label="Prioritet"
                name="priority"
                required
              >
                {constants.complaints.priority.map(priority => (
                  <option value={priority} key={priority}>{complaintFilter.priority(priority)}</option>
                ))}
              </FormikFormControls.FormGroupSelect>

            </Col>
          </Row>

          <div className="mt-3">
            <ErrorAlert error={mutation.error} />
            <ButtonSpinner
              type="submit"
              className="rounded"
              variant="success"
              isLoading={formProps.isSubmitting}
              disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
            >
              Uppdatera klagomålet
            </ButtonSpinner>
          </div>
        </Form>
      )}
    </Formik>
  );
}
