import React from 'react';
import { ButtonProps } from 'react-bootstrap';
import InspectObjectModal from 'src/modals/InspectObjectModal';
import ModalOpeningButton from 'src/buttons/ModalOpeningButton';
import { Eye } from 'react-feather';
import classNames from 'classnames';

interface InspectObjectModalButtonProps extends ButtonProps {
  className?: string;
  object: object;
  size?: 'sm' | 'lg';
}

const InspectObjectModalButton: React.FC<InspectObjectModalButtonProps> = React.memo(function InspectObjectModalButton (props: InspectObjectModalButtonProps) {
  const { object, size, className, ...restOfButtonProps } = props;
  const modalProps = React.useMemo(() => ({object}), [object]);

  let paddingClassName = 'px-2';
  if (size === 'lg') paddingClassName = 'px-3';
  if (size === 'sm') paddingClassName = 'px-1';

  let iconSize = 16;
  if (size === 'lg') iconSize = 18;
  if (size === 'sm') iconSize = 14;

  return (
    <ModalOpeningButton
      Modal={InspectObjectModal}
      modalProps={modalProps}
      variant="outline-info"
      className={classNames(className, paddingClassName, 'd-inline-flex align-items-center')}
      title="Granska objektet"
      size={size}
      {...restOfButtonProps}
    >
      <Eye size={iconSize} />
    </ModalOpeningButton>
  );
});
export default InspectObjectModalButton;
