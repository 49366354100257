import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import StatusLabelContainer from  'src/components/StatusLabelContainer';
import { StatusLabel } from  'src/group/GroupFormatters';
import { useQuery } from '@tanstack/react-query';
import { GroupRow } from 'shared/types/group';

interface GroupModalBodyProps {
  id: string;
}

export default function GroupModalBody (props: GroupModalBodyProps) {
  const { id } = props;


  const query = useQuery<GroupRow, Error>({
    queryKey: [`/group/${id}`],
  });

  const group = query.data;

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading} />
      {group && (
        <>
          <StatusLabelContainer className="mt-3">
            <StatusLabel value={group.is_active} />
          </StatusLabelContainer>
          <pre>{JSON.stringify(group, null, 2)}</pre>
        </>
      )}
    </div>
  );
}
