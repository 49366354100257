import React from 'react';
import useEventSubscribe from 'src/hooks/useEventSubscribe';
import useEventEmit from 'src/hooks/useEventEmit';
import { QueryFilters } from '@tanstack/react-query';
import useQueryClientUtils, { QueryClientUtilsMethodOptions } from 'src/hooks/useQueryClientUtils';

interface ObjectWithId {
  id: React.Key;
  [key: string]: any;
}

type CreateCallback<T> = (created: T) => void;

type UpdateCallback<T> = (updated: T) => void;

type DeleteCallback<T> = (deleted: T) => void;

export function useEventModelSubscribeUpdates <T extends ObjectWithId> (modelName: string, onUpdate: UpdateCallback<T>) {
  const eventName = `${modelName}_update`;
  useEventSubscribe<T>(eventName, onUpdate);
}

export function useEventModelSubscribeCreates <T extends ObjectWithId> (modelName: string, onCreate: CreateCallback<T>) {
  const eventName = `${modelName}_create`;
  useEventSubscribe<T>(eventName, onCreate);
}

export function useEventModelSubscribeDeletes <T extends ObjectWithId> (modelName: string, onDelete: DeleteCallback<T>) {
  const eventName = `${modelName}_delete`;
  useEventSubscribe<T>(eventName, onDelete);
}

export function useEventModelEmitCreates <T extends ObjectWithId> (modelName: string) {
  const eventName = `${modelName}_create`;
  return useEventEmit<T>(eventName);
}

export function useEventModelEmitUpdates <T extends ObjectWithId> (modelName: string) {
  const eventName = `${modelName}_update`;
  return useEventEmit<T>(eventName);
}

export function useEventModelEmitDeletes <T extends ObjectWithId> (modelName: string) {
  const eventName = `${modelName}_delete`;
  return useEventEmit<T>(eventName);
}

export function useEventModelSubscribeToQuery <T extends ObjectWithId> (queryFilter: QueryFilters, modelName: string, options: QueryClientUtilsMethodOptions = {}) {
  const queryClientUtils = useQueryClientUtils();

  useEventModelSubscribeUpdates<T>(modelName, row => {
    queryClientUtils.applyUpdates(queryFilter, [row], options);
  });

  useEventModelSubscribeCreates<T>(modelName, row => {
    queryClientUtils.applyCreates(queryFilter, [row], options);
  });

  useEventModelSubscribeDeletes<T>(modelName, row => {
    queryClientUtils.applyDeletionsById(queryFilter, [row.id], options);
  });

}
