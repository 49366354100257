import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import * as insuranceFilters from 'shared/filter/customer_jaycom_insurance';
import * as JaycomInsuranceFormatters from 'src/jaycomInsurance/JaycomInsuranceFormatters';
import constants from 'shared/constants';

export function Status () {
  return (
    <FormikFormControls.FormGroupSelect
      label="Status"
      name="status"
      required
    >
      {constants.customerJaycomInsurance.statusTypes.map(status => (
        <option value={status} key={status}>{insuranceFilters.status(status)}</option>
      ))}
    </FormikFormControls.FormGroupSelect>
  );
}

export function FormInsuredAmout () {
  return (
    <FormikFormControls.FormGroupSelect
      label="Försäkrat månadsbelopp"
      name="insured_amount"
      required
    >
      <option value="">Ange önskad summa</option>
      {constants.customerJaycomInsurance.insuredAmounts.map((amount, index) => (
        <option value={amount} key={amount}><JaycomInsuranceFormatters.InsuredAmount value={amount} /></option>
      ))}
    </FormikFormControls.FormGroupSelect>
  );
}

export function FormPaymentMethod () {
  return (
    <FormikFormControls.FormGroupSelect
      label="Betalningsmetod"
      name="payment_method"
      required
    >
      <option value="autogiro">Autogiro</option>
      <option value="invoice">Faktura</option>
    </FormikFormControls.FormGroupSelect>
  );
}

export function FormFreeMonths () {
  return (
    <FormikFormControls.FormGroupSelect
      label="Gratismånader"
      name="free_months"
      explainer="Skaparstyrt innebär 1 gratismånad om offerten är skapad av kunden"
    >
      <option value="">Skaparstyrt</option>
      <option value="0">0 gratismånader</option>
      <option value="1">1 gratismånad</option>
      <option value="2">2 gratismånader</option>
    </FormikFormControls.FormGroupSelect>
  );
}

export function FormClearingNo () {
  return (
    <FormikFormControls.FormGroupControl
      label="Clearingnummer"
      minLength={4}
      maxLength={4}
      name="clearing_no"
      pattern="^\d{4}$"
      required
    />
  );
}

export function FormAccountNo () {
  return (
    <FormikFormControls.FormGroupControl
      label="Kontonummer"
      minLength={1}
      maxLength={100}
      name="account_no"
      pattern="^\d+$"
      required
    />
  );
}
