import React from 'react';
import { Card } from 'react-bootstrap';
import { CustomerRow } from 'shared/types/customer';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import * as api from 'src/api';
import {Form, Formik} from 'formik';
import * as formUtils from 'src/utils/form';
import useNotyf from 'src/hooks/useNotyf';

interface CustomerUpdateFormCardBuilderProps extends React.PropsWithChildren {
  title: string;
  customer: CustomerRow;
  customerToFormValues: (customer: CustomerRow) => Record<string, any>;
  formValuesToUpdate: (initialValues: Record<string, any>, values: Record<string, any>) => Record<string, any>;
}

export default function CustomerUpdateFormCardBuilder (props: CustomerUpdateFormCardBuilderProps) {
  const { title, customer, customerToFormValues, formValuesToUpdate, children } = props;

  const notyf = useNotyf();
  const queryClient = useQueryClient();

  const updateMutation = useMutation<CustomerRow, Error, Partial<CustomerRow>>({
    mutationFn: async vars => Object.keys(vars).length > 0 ? await api.request({
      url: `/customer/${customer.id}`,
      method: 'PATCH',
      data: vars,
    }) : (customer as CustomerRow),
    onSuccess: updatedCustomer => {
      queryClient.setQueryData([`/customer/${updatedCustomer.id}`], updatedCustomer);
      notyf.success({type: 'default', message: 'Kunden uppdaterades'});
    },
  });

  const initialValues = customerToFormValues(customer);

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<CustomerRow, Record<string, any>, Partial<CustomerRow>>({
    queryDataToFormValues: customerToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <Card className="border mb-4">
      <Card.Header className="border-bottom bg-light">
        <Card.Title className="mb-0">{title}</Card.Title>
      </Card.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={formCycleHelpers.onSubmit}
        enableReinitialize
      >
        {children}
      </Formik>
    </Card>
  );
}
