import React from 'react';
import classNames from 'classnames';

interface StatusLabelContainerProps extends React.PropsWithChildren {
  className?: string;
  title?: string;
}

const StatusLabelContainer: React.FC<StatusLabelContainerProps> = React.memo(function StatusLabel (props: StatusLabelContainerProps) {
  const { title = 'Status', children, className } = props;
  return (
    <div className={classNames('px-3', className)}>
      <div className="d-flex justify-content-between">
        <div className="w-25 bg-white border rounded text-center d-flex justify-content-center me-1 align-items-center">
          {title}
        </div>
        <div className="w-75">{children}</div>
      </div>
    </div>
  );
});
export default StatusLabelContainer;
