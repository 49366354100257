import React from 'react';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { Alert, Form } from 'react-bootstrap';
import useAuth from 'src/hooks/useAuth';
import * as yup from 'yup';
import * as FormikFormControls from 'src/form/FormikFormControls';
import * as api from 'src/api';
import FormikTotp from 'src/form/FormikTotp';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import { useMutation } from '@tanstack/react-query';
import { UserSession } from 'shared/types/user';
import ErrorAlert from 'src/alerts/ErrorAlert';

type LoginPasswordStepData = {
  step: 'password' | 'password_email_totp' | 'password_app_totp';
  email?: string;
}

type LoginPasswordFormData = {
  then?: string;
  username: string;
  password: string;
  totp_0: string;
  totp_1: string;
  totp_2: string;
  totp_3: string;
  totp_4: string;
  totp_5: string;
}

type LoginPasswordRequestData = {
  username: string;
  password: string;
  then?: string;
  totp?: string;
}

type LoginPasswordResponse = {
  requireTotp: true;
  source: 'app';
} | {
  requireTotp: true;
  source: 'email';
  email: string;
  expiresAt: string;
} | api.ErrorLimited | UserSession & {ok: true};

const loginPasswordValidationSchema = yup.object({
  username: yup.string().max(255).required('Användarnamn måste anges'),
  password: yup.string().max(255).required('Lösenord måste anges'),
});

const loginPasswordTotpValidationSchema = loginPasswordValidationSchema.concat(yup.object({
  totp_0: yup.string().matches(/\d{1}/).required(),
  totp_1: yup.string().matches(/\d{1}/).required(),
  totp_2: yup.string().matches(/\d{1}/).required(),
  totp_3: yup.string().matches(/\d{1}/).required(),
  totp_4: yup.string().matches(/\d{1}/).required(),
  totp_5: yup.string().matches(/\d{1}/).required(),
}));

export default function LoginPasswordForm () {
  const auth = useAuth();
  const location = useLocation();

  const [stepData, setStepData] = React.useState<LoginPasswordStepData>({step: 'password'});
  const [loginPromise, setLoginPromise] = React.useState<Promise<any> | null>(null);

  const initialValues = {
    username: '',
    password: '',
    totp_0: '',
    totp_1: '',
    totp_2: '',
    totp_3: '',
    totp_4: '',
    totp_5: '',
  };

  const mutation = useMutation<LoginPasswordResponse, Error, LoginPasswordRequestData>({
    mutationFn: data => api.request({
      method: 'post',
      url: '/auth/login',
      data,
    }).catch(err => {
      if (typeof err?.response?.data?.error === 'string' && err?.response?.data?.ok === false) {
        return err.response.data as api.ErrorLimited;
      }
      throw err;
    }),
  });
 
  React.useEffect(() => {
    if (!mutation.isSuccess) return;
    const response = mutation.data;

    if ('requireTotp' in response && response.requireTotp) {
      const { source } = response;
      const stepData = source === 'app' ? {step: 'password_app_totp'} : {step: 'password_email_totp', email: response.email};
      setStepData(stepData as any);
    } else if ('ok' in response && response.ok) {
      // logged in
      const promise = auth.login(response).finally(() => {
        setLoginPromise(null);
      });
      setLoginPromise(promise);
    }
  }, [mutation.isSuccess, auth, mutation.data]);

  const onSubmit = (values: LoginPasswordFormData) => {
    const requestData = formDataToRequestData(values, location);
    return mutation.mutateAsync(requestData);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={stepData.step !== 'password' ? loginPasswordTotpValidationSchema : loginPasswordValidationSchema}
      onSubmit={onSubmit}
    >
      {formikProps => (
        <>
          <Form onSubmit={formikProps.handleSubmit}>

            <FormikFormControls.FormGroupControl
              className="mb-1"
              label="Användarnamn"
              size="lg"
              name="username"
              placeholder="Ange ditt användarnamn"
              autoComplete="username"
              disabled={formikProps.isSubmitting}
            />

            <FormikFormControls.FormGroupControl
              className="mb-1"
              label="Lösenord"
              size="lg"
              type="password"
              name="password"
              autoComplete="current-password"
              placeholder="Ange ditt lösenord"
              disabled={formikProps.isSubmitting}
            />

            {stepData.step === 'password_email_totp' && (
              <div>
                <FormikTotp
                  className="mb-1"
                  baseName="totp_"
                  label="Engångskod från e-postmeddelandet"
                  disabled={formikProps.isSubmitting}
                />
                <small>Koden har skickats till {stepData.email}.</small>
              </div>
            )}

            {stepData.step === 'password_app_totp' && (
              <FormikTotp
                className="mb-1"
                baseName="totp_"
                label="Engångskod från app"
                disabled={formikProps.isSubmitting}
              />
            )}

            <ErrorAlert error={mutation.error} className="mt-3" />

            {'error' in (mutation?.data ?? {}) && (
              <LoginErrorAlert
                error={mutation.data as api.ErrorLimited}
                className="mt-3 p-3 text-danger"
              />
            )}

            <div className="d-flex gap-2 mt-3">
              <ButtonSpinner
                className="px-3"
                type="submit"
                variant="primary"
                size="lg"
                disabled={formikProps.isSubmitting || !formikProps.isValid || loginPromise !== null}
                isLoading={formikProps.isSubmitting || loginPromise !== null}
              >
                Logga in
              </ButtonSpinner>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

interface LoginErrorAlertProps {
  className?: string;
  error: api.ErrorLimited;
}

function LoginErrorAlert (props: LoginErrorAlertProps) {
  const { error, className } = props;
  return (
    <Alert variant="danger" className={className}>
      {error.error}
    </Alert>
  );
}

function formDataToRequestData (values: LoginPasswordFormData, location): LoginPasswordRequestData {
  const { username, password, totp_0, totp_1, totp_2, totp_3, totp_4, totp_5 } = values;

  const data: LoginPasswordRequestData = {
    username,
    password,
  };

  const then = new URLSearchParams(location.search).get('then');
  if (then) data.then = then;

  if (totp_0 && totp_1 && totp_2 && totp_3 && totp_4 && totp_5) {
    data.totp = [totp_0, totp_1, totp_2, totp_3, totp_4, totp_5].join('');
  }

  return data;
}
