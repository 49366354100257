import React, {useState} from 'react';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { useQuery } from '@tanstack/react-query';
import { UserLogRowWithRelations } from 'shared/types/user_log';
import * as userLogFilters from 'shared/filter/user_log';
import { Card } from 'react-bootstrap';
import { Activity } from 'react-feather';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCells from 'src/tables/TableCells';
import CardBodyTable from 'src/tables/CardBodyTable';
import {TableStateOrder} from 'src/tables/types';
import classNames from 'classnames';

interface UserLogCardProps {
  className?: string;
  userId: string;
}

export default function UserLogCard (props: UserLogCardProps) {
  const { userId, className } = props;

  const [order, setOrder] = useState<TableStateOrder>({date: 'desc'});

  const query = useQuery<UserLogRowWithRelations[], Error>({
    queryKey: [`/user/${userId}/log`, {params: {order}}],
    select: (data: any) => data.logs,
  });

  const rows = query.data ?? [];

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="bg-light">
        <CardTitleIcon
          title="Användarlogg"
          Icon={<Activity size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <CardBodyTable
        striped
        order={order}
        columnDefinitions={columnDefs}
        setOrder={setOrder}
        rows={rows}
        isFetched={query.isFetched}
      />
    </Card>
  );
}

const columnDefs = [
  commonColumnDefs.datetime({
    id: 'date',
    title: 'Datum',
    sortable: true,
  }),
  commonColumnDefs.userId({
    id: 'by_user_id',
    title: 'Utförare',
    sortable: true,
    cellProps: {objKey: 'User', value: 'System'},
  }),
  commonColumnDefs.basic({
    id: 'data.ip',
    title: 'IP',
    Cell: TableCells.IpWithCountryFlag,
    cellProps: {ipCountryKey: 'data.ip_country', emptyValue: '-'},
  }),
  commonColumnDefs.basic({
    id: 'description',
    title: 'Beskrivning',
    Cell: props => <>{userLogFilters.description(props.row)}</>,
  }),
  commonColumnDefs.inspect(),
];
