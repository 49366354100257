import React from 'react';

import { LAYOUT, Layout } from 'src/constants';
import useSettingsState from 'src/hooks/useSettingsState';

interface LayoutContextState {
  layout: Layout;
  setLayout: (newLayout: Layout) => void;
}

const initialState: LayoutContextState = {
  layout: LAYOUT.FLUID,
  setLayout: () => {},
};

const LayoutContext = React.createContext<LayoutContextState>(initialState);

function LayoutProvider ({ children }) {
  const [layout, setLayout] = useSettingsState('layout', LAYOUT.FLUID);
  const value: LayoutContextState = {layout, setLayout};
  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  );
}

export { LayoutProvider, LayoutContext };
