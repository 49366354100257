import React from 'react';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import * as api from 'src/api';
import { ErrorAlertCardBody, CardTitleIconStatus, CardTitleIcon } from 'src/cards/CardHelpers';
import { Card, Row, Col } from 'react-bootstrap';
import * as IconButtons from 'src/buttons/IconButtons';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { ComplaintRow } from 'shared/types/complaint';
import Breadcrumbs from 'src/components/Breadcrumbs';
import * as ComplaintFormatters from 'src/complaint/ComplaintFormatters';
import {useMutation, useQuery} from '@tanstack/react-query';
import EmailContactControlCard from 'src/email/EmailContactControlCard';
import BlockSpinner from 'src/spinners/BlockSpinner';
import * as ComplaintBlocks from 'src/complaint/ComplaintBlocks';
import {Cpu, Frown} from 'react-feather';
import {GuardPermission} from 'src/guards/AuthGuards';
import useNotyf from 'src/hooks/useNotyf';
import ComplaintAssociatedObjectList from 'src/complaint/ComplaintAssociatedObjectList';
import ComplaintNoteItem from 'src/complaint/ComplaintNoteItem';
import ComplaintNoteForm from 'src/complaint/ComplaintNoteForm';

interface ComplaintPageParams {
  complaintId: string;
}

const ComplaintPage: React.FC = React.memo(function ComplaintPage () {
  const { complaintId } = useParams() as unknown as ComplaintPageParams;

  const notyf = useNotyf();
  const navigate = useNavigate();

  const readQuery = useQuery<ComplaintRow, Error>({
    queryKey: [`/complaint/${complaintId}`],
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: () => api.request({
      url: `/complaint/${complaintId}`,
      method: 'DELETE',
    }),
    onSuccess: () => {
      navigate('/complaints');
      notyf.success({type: 'danger', message: 'Klagomålet raderades'});
    },
  });

  const onConfirmDelete = () => {
    return deleteMutation.mutateAsync();
  };

  const onReload = () => {
    readQuery.refetch();
  };

  const complaint = readQuery.data;

  const notes = complaint?.notes ?? [];

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Klagomål" />
        <h1>Klagomål</h1>
        <Breadcrumbs
          crumbs={[
            ['/complaints', 'Klagomål'],
            [`/complaints/${complaintId}`, complaint ? <ComplaintFormatters.Name value={complaint} /> : complaintId],
          ]}
        />
        <BlockSpinner isLoading={readQuery.isLoading} />
        <ErrorAlertCardBody error={readQuery.error} className="mx-0 mt-0 mb-3" />
        {complaint && (
          <Card className="border">
            <Card.Header className="border-bottom bg-light">
              <CardTitleIconStatus
                as="h2"
                title={<ComplaintFormatters.Name value={complaint} />}
                Status={<ComplaintFormatters.StatusLabel value={complaint.status} className="px-3" />}
                Icon={<Frown size={16} />}
                spinning={readQuery.isRefetching}
              >
                <GuardPermission permission="complaint_delete">
                  <IconButtons.ConfirmModalDeleteButton
                    onConfirm={onConfirmDelete}
                    message="Är du säker på att du vill radera det här klagomålet permanent?"
                  />
                </GuardPermission>
                <IconButtons.InspectObjectModalButton object={complaint} />
                <IconButtons.CopyObjectIdButton id={complaint.id} />
                <IconButtons.CopyCurrentUrlButton />
                <IconButtons.RefreshObjectButton
                  disabled={readQuery.isRefetching}
                  onClick={onReload}
                />
              </CardTitleIconStatus>
            </Card.Header>
            <ComplaintAssociatedObjectList complaint={complaint} />
            <Card.Body>
              <Row>
                <Col>
                  <Card className="border">
                    <Card.Header className="border-bottom bg-light">
                      <Card.Title className="mb-0">Anteckningslogg</Card.Title>
                    </Card.Header>
                    <Card.Body className="p-3 pb-1">
                      {notes.length > 0 ? (
                        <>
                          {notes.map((note, index) => (
                            <ComplaintNoteItem key={index} note={note} className="mb-3" />
                          ))}
                        </>
                      ) : (
                        <p>Det finns inga notat här</p>
                      )}
                    </Card.Body>
                    <GuardPermission permission="complaint_update">
                      <Card.Footer className="border-top pt-0 p-3">
                        <ComplaintNoteForm
                          complaint={complaint}
                          onReload={onReload}
                        />
                      </Card.Footer>
                    </GuardPermission>
                  </Card>
                </Col>
                <Col xl={6}>

                  <Card className="border">
                    <Card.Header className="border-bottom bg-light">
                      <CardTitleIcon
                        title="Egenskaper"
                        Icon={<Cpu size={18} />}
                      >
                      </CardTitleIcon>
                    </Card.Header>
                    <Card.Body className="pt-3">
                      <ComplaintBlocks.PropertiesTable complaint={complaint} />
                    </Card.Body>
                  </Card>

                  <EmailContactControlCard
                    createPermission="email_create_complaint"
                    searchParams={{complaint_id: complaintId}}
                    searchTemplateParams={{category: 'complaint', is_archived: 'false'}}
                    objectType="complaint"
                    objectId={complaintId}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
      </Content>
    </Main>
  );
});
export default ComplaintPage;
