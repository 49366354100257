import React from 'react';
import TableFilterFormGroup from 'src/tables/TableFilterFormGroup';
import classNames from 'classnames';
import BasicSpinner from 'src/spinners/BasicSpinner';
import TableTd from 'src/tables/TableTd';
import TableHead from 'src/tables/TableHead';
import TableFoot from 'src/tables/TableFoot';
import {
  TableSelectedRows,
  TableStateOrder,
  TableAlign,
  TableHeaderGroups,
  TableSelectedRow,
  TableStateFilterMap,
  TableRow,
} from 'src/tables/types';
import {
  TTableParams,
  TableColumnDefinition,
  TableFilterDefinition,
  TableColumnDefinitionMap,
} from 'src/tables/Table';
import { preventDefaultSubmit } from 'src/misc';
import {
  Table as BsTable,
  Card,
  Form,
} from 'react-bootstrap';

export interface CardBodyTableProps {
  align?: TableAlign;
  filter?: TableStateFilterMap;
  setFilter?: (newFilter: TableStateFilterMap) => void;
  order?: TableStateOrder;
  setOrder?: (newOrder: TableStateOrder) => void;
  filterDefinitions?: TableFilterDefinition[];
  columnDefinitions: TableColumnDefinition[];
  rows: TableRow[];
  footerRows?: TableRow[];
  isFetched?: boolean;
  params?: TTableParams;
  headerGroups?: TableHeaderGroups;
  selectedRow?: TableSelectedRow;
  setSelectedRow?: React.Dispatch<React.SetStateAction<TableSelectedRow>>;
  selectedRows?: TableSelectedRows;
  setSelectedRows?: React.Dispatch<React.SetStateAction<TableSelectedRows>>;
  size?: string;
  striped?: boolean;
}

// a simple table with basic functionality that can be used when Table is too big
// it should work well for tables where all table rows can be kept in memory
const CardBodyTable: React.FC<CardBodyTableProps> = React.memo(function CardBodyTable (props: CardBodyTableProps) {
  const {
    align,
    filter,
    order,
    filterDefinitions,
    columnDefinitions,
    setOrder,
    setFilter,
    rows,
    footerRows,
    params,
    isFetched = false,
    headerGroups,
    selectedRows,
    setSelectedRows,
    selectedRow,
    setSelectedRow,
    size,
    striped,
  } = props;

  const onChangeSelected = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = ev.target;
    if (setSelectedRow) setSelectedRow(checked ? name : null);
    if (setSelectedRows) setSelectedRows(prevRows => ({...prevRows, [name]: checked}));
  }, [setSelectedRows, setSelectedRow]);

  const columnDefinitionsById: TableColumnDefinitionMap = React.useMemo(() => columnDefinitions.reduce((map, def) => {
    map[def.id] = def;
    return map;
  }, {}), [columnDefinitions]);

  return (
    <Card.Body className="p-0">
      {filter && setFilter && filterDefinitions && (
        <CardBodyTableFilterForm
          filter={filter}
          setFilter={setFilter}
          filterDefinitions={filterDefinitions}
        />
      )}
      <div className="table-responsive">
        <BsTable
          striped={striped}
          size={size}
          className={classNames('mb-0', {'border-top': !(filter && setFilter && filterDefinitions)})}
        >
          <TableHead
            headerGroups={headerGroups}
            columnDefinitionsById={columnDefinitionsById}
            order={order}
            setOrder={setOrder}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            rows={rows}
            params={params}
          />
          <tbody>
            {rows && rows.length > 0 ? (
              <>
                {rows.map(row => (
                  <tr key={row.id}>
                    {columnDefinitions.map(columnDefinition => (
                      <TableTd
                        align={align}
                        row={row}
                        key={columnDefinition.id}
                        columnDefinition={columnDefinition}
                        params={params}
                        onChangeSelected={onChangeSelected}
                        selected={selectedRows ? selectedRows[row.id] : selectedRow === String(row.id)}
                      />
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={columnDefinitions.length}>
                  <em className="text-muted">
                    {isFetched ? (
                      <>Det finns ingenting här</>
                    ) : (
                      <>
                        <BasicSpinner isLoading as="span" />{' '}
                        Laddar…
                      </>
                    )}
                  </em>
                </td>
              </tr>
            )}
          </tbody>
          {footerRows && (
            <tfoot className="font-weight-bold bg-light">
              {footerRows.map(row => (
                <tr key={row.id}>
                  {columnDefinitions.map(columnDefinition => (
                    <TableTd
                      row={row}
                      key={columnDefinition.id}
                      columnDefinition={columnDefinition}
                      params={params}
                      onChangeSelected={onChangeSelected}
                      selected={selectedRows ? selectedRows[row.id] : selectedRow === String(row.id)}
                    />
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
          <TableFoot
            columnDefinitionsById={columnDefinitionsById}
            selectedRows={selectedRows}
            rows={rows}
          />
        </BsTable>
      </div>
    </Card.Body>
  );
});
export default CardBodyTable;

interface CardBodyTableFilterFormProps {
  filter: TableStateFilterMap;
  setFilter: (filter: TableStateFilterMap) => void;
  filterDefinitions: TableFilterDefinition[];
}

function CardBodyTableFilterForm (props: CardBodyTableFilterFormProps) {
  const { filterDefinitions, filter, setFilter } = props;

  const onChangeFilterValue = (id: string, value: any) => {
    const newFilter = {...filter, [id]: value};
    setFilter(newFilter);
  };

  return (
    <Form onSubmit={preventDefaultSubmit} className="d-flex pt-3 px-3 pb-0 border-top border-bottom flex-wrap">
      {filterDefinitions.map(filterDefinition => (
        <TableFilterFormGroup
          key={filterDefinition.id}
          id={filterDefinition.id}
          filterDefinition={filterDefinition}
          value={filter[filterDefinition.id]}
          onChangeFilterValue={onChangeFilterValue}
        />
      ))}
    </Form>
  );
}
