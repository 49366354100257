import React from 'react';
import { CustomerRow } from 'shared/types/customer';
import AssociatedObjectList from 'src/components/AssociatedObjectList';
import {oldCrmUrl} from 'src/misc';

interface CustomerAssociatedObjectListProps {
  customer: CustomerRow;
}

function CustomerAssociatedObjectList (props: CustomerAssociatedObjectListProps) {
  const { customer } = props;

  const externalLinks = [
    {url: oldCrmUrl(`/customer/${customer.id}/`), label: 'Gamla CRM'},
  ].filter(v => v);

  const associatedObjects = [
    customer.user_id ? {
      type: 'user',
      value: {id: customer.user_id},
      label: 'Ägare',
    } : null,
    customer.last_application_id ? {
      type: 'application',
      value: {id: customer.last_application_id, customer_id: customer.id},
      label: 'Senaste ansökan',
    } : null,
    customer.last_application_disbursed_bank_id ? {
      type: 'bank',
      value: {id: customer.last_application_disbursed_bank_id},
      label: 'Senaste utbetald långivare',
    } : null,
  ].filter(v => v);


  return (
    <AssociatedObjectList
      associatedObjects={associatedObjects}
      externalLinks={externalLinks}
    />
  );
}
export default React.memo(CustomerAssociatedObjectList);
