import React from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from 'src/api';

interface GroupSelectOption {
  value: string;
  label: string;
}

interface GroupSelectOptionsProps {
  extendQueryWith?: Record<string, string>;
}

export default function GroupSelectOptions (props: GroupSelectOptionsProps) {
  const { extendQueryWith = {} } = props;
  return (
    <GroupSelectOptionsProvider extendQueryWith={extendQueryWith}>
      {options => (
        <>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </>
      )}
    </GroupSelectOptionsProvider>
  );
}

interface GroupSelectOptionsProviderProps {
  extendQueryWith?: Record<string, any>;
  children: (options: GroupSelectOption[]) => React.ReactNode;
}

export function GroupSelectOptionsProvider (props: GroupSelectOptionsProviderProps) {
  const { children, extendQueryWith = {} } = props;

  const query = useQuery<GroupSelectOption[]>({
    queryKey: ['groupSelectOptions', extendQueryWith],
    queryFn: () => api.request({
      url: '/group/selectOptions',
      params: {is_active: true, ...extendQueryWith},
    }).then(groups => {
      const options = groups.map(group => ({value: group.id, label: group.name ?? group.id}));
      return options;
    }),
  });

  if (!Array.isArray(query.data)) {
    return null;
  }

  return children(query.data);
}
