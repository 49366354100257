import React, {ElementType} from 'react';
import classNames from 'classnames';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { Card, Accordion, CardTitleProps, CardProps } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

interface CardTitleIconProps extends Omit<CardTitleProps, 'title'> {
  as?: ElementType<any>;
  Icon?: React.ReactNode;
  title: React.ReactNode;
  spinning?: boolean;
  minHeight?: number;
}

export function CardTitleIcon (props: CardTitleIconProps) {
  const { as = 'h5', Icon, title, spinning, minHeight = 26, children, ...restOfProps } = props;
  return (
    <Card.Title as={as} className="mb-0 d-flex flex-wrap justify-content-between align-items-center" {...restOfProps}>
      <div className="d-flex align-items-center gap-2" style={{minHeight: minHeight}}>
        {Icon}
        <span>{title}</span>
        {spinning && (
          <Spinner
            animation="border"
            size="sm"
            variant="default"
            style={{opacity: 0.5}}
          />
        )}
      </div>
      {children && (
        <div className="d-flex gap-1">{children}</div>
      )}
    </Card.Title>
  );
}

interface CardTitleIconStatusProps extends CardTitleIconProps {
  Status: React.ReactNode;
}

export function CardTitleIconStatus (props: CardTitleIconStatusProps) {
  const { as = 'h5', Status, Icon, title, spinning, children } = props;
  return (
    <Card.Title as={as} className="mb-0 d-flex flex-wrap justify-content-center gap-2 justify-content-md-between align-items-center">
      <div className="d-flex align-items-center gap-2 flex-wrap justify-content-center justify-content-md-start">
        {Icon}
        <span>{title}</span>
        {Status}
        {spinning && (
          <Spinner
            className="ms-2"
            animation="border"
            size="sm"
            variant="default"
            style={{opacity: 0.5}}
          />
        )}
      </div>
      {children && (
        <div className="align-items-end d-flex gap-2">{children}</div>
      )}
    </Card.Title>
  );
}

interface CollapsibleCardProps extends Omit<CardProps, 'title'> {
  open?: boolean;
  title: React.ReactNode;
  Icon?: React.ReactNode;
  onToggle: (open: boolean) => void;
}

export function CollapsibleCard (props: CollapsibleCardProps) {
  const { open = false, title, Icon, onToggle, children, ...restOfProps } = props;

  const onClickToggle = () => {
    onToggle(!open);
  };

  return (
    <Card {...restOfProps}>
      <Card.Header className="border-bottom bg-light">
        <Card.Title
          as="button"
          className="mb-0 d-flex align-items-center gap-2 btn btn-link p-0"
          onClick={onClickToggle}
          style={{minHeight: 26}}
          title={open ? 'Dölj kontrollpanel' : 'Visa kontrollpanel'}
        >
          {Icon}
          {open && <span>{title}</span>}
        </Card.Title>
      </Card.Header>
      {open && children}
    </Card>
  );
}

interface AccordionCardProps extends React.PropsWithChildren {
  className?: string;
  controls?: React.ReactNode;
  title?: React.ReactNode;
  onToggleCallback?: () => void;
  defaultOpen?: boolean;
}

export function AccordionCard (props: AccordionCardProps) {
  const {
    className,
    children,
    title,
    onToggleCallback,
    defaultOpen = false,
  } = props;
  const eventKey = React.useId();
  return (
    <Accordion
      defaultActiveKey={defaultOpen ? eventKey : null}
      className={classNames(className, 'accordion-card')}
    >
      <Accordion.Item eventKey={eventKey} className="bg-white">
        <div className="accordion-header">
          <Accordion.Button className="py-1 px-2" onClick={onToggleCallback}>
            <span style={{fontWeight: 500}}>
              {title}
            </span>
          </Accordion.Button>
        </div>
        <Accordion.Body className="p-0">
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

interface WidgetListCardProps extends React.PropsWithChildren {
  className?: string;
  controls?: React.ReactNode;
  title?: React.ReactNode;
}

export function WidgetListCard (props: WidgetListCardProps) {
  const {
    className,
    children,
    controls,
    title,
  } = props;
  return (
    <Card className={classNames(className)}>
      <Card.Header className="py-1 px-2 d-flex justify-content-between bg-light border-bottom align-items-baseline">
        <div style={{fontWeight: 500}}>
          {title}
        </div>
        {controls && (
          <div className="d-flex">
            {controls}
          </div>
        )}
      </Card.Header>
      <Card.Body className="p-0">
        {children}
      </Card.Body>
    </Card>
  );
}

interface ErrorAlertCardBodyProps {
  error?: null | string | Error;
  className?: string;
}

export function ErrorAlertCardBody (props: ErrorAlertCardBodyProps) {
  const { error, className } = props;
  if (!error) return null;
  return (
    <Card.Body className={className}>
      <ErrorAlert error={error} className="m-0" />
    </Card.Body>
  );
}
