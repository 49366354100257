import React from 'react';
import useQueryClientUtils from 'src/hooks/useQueryClientUtils';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import * as api from 'src/api';
import {
  TableBatchActionMutationDate,
  TableBatchDefinition,
  TableSelectedRows,
  TableUpdateRowMutationVars,
  TableQueryKey,
  TableRow,
} from 'src/tables/types';
import useNotyf from 'src/hooks/useNotyf';
import {errorToMessage} from 'src/utils/error';

interface UseTableUpdateRowMutationOptions {
  queryKey: TableQueryKey;
  subKey?: string; // in the query result cache, the (optional) sub key where the list of rows is found
  onSuccess?: (data: TableRow, vars: TableUpdateRowMutationVars) => void;
}

// returns a mutation to execute an update of a row
export function useTableUpdateRowMutation (options: UseTableUpdateRowMutationOptions): UseMutationResult<TableRow, Error, TableUpdateRowMutationVars> {
  const { queryKey, subKey, onSuccess } = options;
  const [queryKeyBase] = queryKey;

  const notyf = useNotyf();
  const queryClient = useQueryClient();
  const queryClientUtils = useQueryClientUtils();

  const updateRowMutation = useMutation<TableRow, Error, TableUpdateRowMutationVars>({
    mutationFn: async vars => {
      const { method, url, data, rowId, optimistic, deletion, onSuccessNotyf } = vars;

      if (optimistic) {
        if (deletion) {
          queryClientUtils.applyDeletionsById({queryKey: [queryKeyBase]}, [rowId], {subKey});
        } else {
          const newRow = {...data, id: rowId};
          queryClientUtils.applyUpdates({queryKey: [queryKeyBase]}, [newRow], {subKey});
        }
      }
      await queryClient.cancelQueries({queryKey});

      const newRow = await api.request({method, data, url});

      if (deletion) {
        queryClientUtils.applyDeletionsById({queryKey: [queryKeyBase]}, [rowId], {subKey});
      } else {
        queryClientUtils.applyUpdates({queryKey: [queryKeyBase]}, [newRow], {subKey});
      }

      if (onSuccessNotyf) {
        notyf.open(onSuccessNotyf);
      }

      return newRow;
    },
    onSuccess,
    onError: err => {
      notyf.error(errorToMessage(err));
    },
  });

  return updateRowMutation;
}

interface UseTableBatchActionOptions {
  queryKey: TableQueryKey;
  batchDefinition?: TableBatchDefinition;
  selectedRows: TableSelectedRows;
  setSelectedRows: React.Dispatch<React.SetStateAction<TableSelectedRows>>;
  subKey?: string;
  refetch: () => void;
}

// returns a mutation to execute a batch action on the selected rows
export function useTableBatchActionMutation (options: UseTableBatchActionOptions) {
  const {
    queryKey,
    batchDefinition,
    selectedRows,
    setSelectedRows,
    subKey,
    refetch,
  } = options;

  const notyf = useNotyf();
  const queryClientUtils = useQueryClientUtils();

  const batchActionMutation = useMutation({
    mutationFn: async (vars: TableBatchActionMutationDate) => {
      if (!batchDefinition) return;
      const { formToData = defaultBatchActionToData, url } = batchDefinition;
      const ids = Object.keys(selectedRows).filter(id => selectedRows[id]);
      const { action } = vars;
      const data = formToData(action, ids, vars);
      return api.request({url, method: 'post', data});
    },
    onSuccess: (newRows, vars: TableBatchActionMutationDate) => {
      const { action, onSuccessNotyf } = vars;
      if (action === 'delete') {
        refetch();
      } else {
        queryClientUtils.applyUpdates({queryKey: [queryKey[0]]}, newRows, {subKey});
      }
      setSelectedRows({});
      if (onSuccessNotyf) {
        notyf.open(onSuccessNotyf);
      }
    },
  });

  return batchActionMutation;
}

function defaultBatchActionToData (action: string, ids: string[]): TableBatchActionMutationDate {
  return {action, ids};
}
