import React from 'react';
import { Form, Formik } from 'formik';
import {useMutation} from '@tanstack/react-query';
import * as api from 'src/api';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import useNotyf from 'src/hooks/useNotyf';
import * as FormikFormControls from 'src/form/FormikFormControls';
import * as formUtils from 'src/utils/form';
import { errorToMessage } from 'src/utils/error';
import { SettingRow } from 'shared/types/setting';

interface SettingSingleFormProps {
  inputGroupProps?: Partial<FormikFormControls.InputGroupControlProps>;
  settingId: string;
  setting?: SettingRow;
  description?: string;
  onReload?: () => Promise<void>;
}

interface SettingSingleFormValues {
  value: string;
}

export default function SettingSingleForm (props: SettingSingleFormProps) {
  const { settingId, setting, description, onReload, inputGroupProps = {} } = props;

  const notyf = useNotyf();

  const mutation = useMutation<SettingRow, Error, SettingSingleFormValues>({
    mutationFn: async data => {
      const body = await api.request({
        url: `/setting/${settingId}`,
        method: 'PUT',
        data,
      });

      await onReload?.();
      notyf.success({type: 'default', message: 'Inställningen uppdaterades'});

      return body.setting.value;
    },
    onError: err => {
      notyf.error(errorToMessage(err));
    },
  });

  const initialValues = settingToFormValues(setting?.value ?? {});

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<SettingRow, SettingSingleFormValues, SettingSingleFormValues>({
    queryDataToFormValues: settingToFormValues,
    formValuesToMutationVars: values => {
      return {
        value: JSON.parse(values.value),
      };
    },
    mutateAsync: mutation.mutateAsync,
  });

  return (
    <Formik initialValues={initialValues} onSubmit={formCycleHelpers.onSubmit} enableReinitialize>
      {formProps => (
        <Form placeholder="LOL">
          <FormikFormControls.InputGroupControl
            {...inputGroupProps}
            label={settingId}
            name="value"
            required
            explainer={setting?.description ?? description}
            customValidate={validateJSON}
            after={(
              <ButtonSpinner
                type="submit"
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
                variant="success"
                isLoading={formProps.isSubmitting}
              >
                Spara
              </ButtonSpinner>
            )}
          />
        </Form>
      )}
    </Formik>
  );
}

function validateJSON (value: string): string {
  try {
    JSON.parse(value);
  } catch (err) {
    return 'Ogiltigt JSON-värde';
  }
  return '';
}

function settingToFormValues  (value: SettingRow['value']): SettingSingleFormValues {
  return {
    value: JSON.stringify(value ?? {}, null, 2),
  };
}
