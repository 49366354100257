import React from 'react';
import Anchor from  'src/anchors/Anchor';
import QuicklookAnchor from 'src/anchors/QuicklookAnchor';
import ApplicationQuicklook from 'src/application/ApplicationQuicklook';
import * as ApplicationFormatters from 'src/application/ApplicationFormatters';
import { FavoriteButton } from 'src/buttons/IconButtons';

import {ApplicationRow} from 'shared/types/application';

interface ApplicationAnchorProps {
  application: Partial<ApplicationRow>;
}

export default function ApplicationAnchor (props: ApplicationAnchorProps) {
  const { application, ...restOfProps } = props;
  return (
    <QuicklookAnchor
      {...restOfProps}
      url={`/application/${application.id}/form`}
      title={<ApplicationFormatters.Title value={application} />}
      favorite={<FavoriteButton className="px-0" type="application" id={application.id} />}
      name="Ansökan"
      objectId={application.id}
    >
      <ApplicationQuicklook id={application.id} />
    </QuicklookAnchor>
  );
}

export function ApplicationPlainAnchor (props: ApplicationAnchorProps) {
  const { application, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      name="Ansökan"
      url={`/application/${application.id}`}
    >
      <ApplicationFormatters.Title value={application} />
    </Anchor>
  );
}
