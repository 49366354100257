import React, {useId} from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import constants from 'shared/constants';
import userFilters from 'shared/filter/user';
import {FileFolderSelectOptionsProvider} from 'src/file/FileFolderSelectOptions';

export function Name (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Filnamn"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="name"
    />
  );
}

export function Description (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Beskrivning"
      minLength={1}
      maxLength={255}
      {...props}
      name="description"
      explainer="Frivillig extra information som visas under filnamnet"
    />
  );
}

export function Folder (props: Partial<FormikFormControls.FormGroupControlProps>) {
  const id = useId();
  return (
    <div>
      <FileFolderSelectOptionsProvider>
        {options => (
          <datalist id={id}>
            {options.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </datalist>
        )}
      </FileFolderSelectOptionsProvider>
      <FormikFormControls.FormGroupControl
        label="Mapp"
        minLength={1}
        maxLength={255}
        list={id}
        {...props}
        name="folder"
      />
    </div>
  );
}

export function Role (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Behörig roll"
      {...props}
      name="role"
      explainer="Vilken roll som har behörighet för att se filen"
    >
      <option value="">Alla roller</option>
      {constants.user.roles.map(role => (
        <option key={role} value={role}>
          {userFilters.role(role)}
        </option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}
