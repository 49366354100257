import React from 'react';
import ModalAnchor, {ModalAnchorProps} from 'src/anchors/ModalAnchor';
import GroupModalBody from 'src/group/GroupModalBody';
import { Name } from 'src/group/GroupFormatters';
import {GroupRow} from 'shared/types/group';

interface GroupAnchorProps extends Partial<ModalAnchorProps> {
  group: Pick<GroupRow, 'id' | 'name'>;
}

export default function GroupAnchor (props: GroupAnchorProps) {
  const { group, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={group} />}
      name="Grupp"
    >
      <GroupModalBody id={group.id} />
    </ModalAnchor>
  );
}
