import React, {useState} from 'react';
import classNames from 'classnames';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ApplicationTriggerRow } from 'shared/types/application_trigger';
import { Card } from 'react-bootstrap';
import * as TableCells from 'src/tables/TableCells';
import CardBodyTable from 'src/tables/CardBodyTable';
import { Activity } from 'react-feather';
import { TableStateOrder  } from 'src/tables/types';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';

interface ApplicationTriggerTableProps {
  className?: string;
  applicationId: string;
}

const columnDefs = [
  commonColumnDefs.basic({
    id: 'id',
    title: 'ID',
    Cell: TableCells.IdShortTableCell,
  }),
  commonColumnDefs.workTriggerTemplateId({
    id: 'work_trigger_template_id',
    cellProps: {objKey: 'WorkTriggerTemplate'},
    title: 'Mall-ID',
  }),
  commonColumnDefs.basic({
    id: 'WorkTriggerTemplate.description',
    title: 'Beskrivning',
  }),
  commonColumnDefs.basic({
    id: 'status',
    title: 'Status',
    Cell: TableCells.ApplicationTriggerStatusTableCell,
  }),
  commonColumnDefs.datetime({
    id: 'scheduled_at',
    title: 'Schemalagd',
    sortable: true,
  }),
  commonColumnDefs.workId({
    id: 'work_id',
    cellProps: {objKey: 'Work'},
    title: 'Jobb-ID',
  }),
  commonColumnDefs.inspect(),
];

export default function ApplicationTriggerTable (props: ApplicationTriggerTableProps) {
  const { applicationId, className } = props;

  const [order, setOrder] = useState<TableStateOrder>({date: 'desc'});

  const query = useQuery<{applicationTriggers: ApplicationTriggerRow[]}, Error>({
    queryKey: [`/application_trigger/application/${applicationId}`, {params: {order}}],
    placeholderData: keepPreviousData,
  });

  const rows = query.data?.applicationTriggers ?? [];

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="bg-light">
        <CardTitleIcon
          title="Händelsetriggers"
          Icon={<Activity size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <CardBodyTable
        striped
        order={order}
        columnDefinitions={columnDefs}
        setOrder={setOrder}
        rows={rows}
        isFetched={query.isFetched}
      />
    </Card>
  );
}
