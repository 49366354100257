import React, { useMemo } from 'react';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';
import applicationFilter from 'shared/filter/application';
import * as TableCells from 'src/tables/TableCells';
import * as TableCellActions from 'src/tables/TableCellActions';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';

interface ApplicationsTablePageProps {
  type: 'all' | 'own';
  title?: string;
  pageTitle?: string;
}

export default function ApplicationsTablePage (props: ApplicationsTablePageProps) {
  const { type, title = 'Ansökningar', pageTitle = 'Ansökningstabell' } = props;

  const filterDefinitions = useMemo(() => getFilterDefinitions(type), [type]);
  const columnDefinitions = useMemo(() => getColumnDefinitions(type), [type]);

  return (
    <TablePage
      title={title}
      pageTitle={pageTitle}
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl={`/applications/${type}`}
      baseQueryUrl={`/application/table/${type}`}
      baseQueryKey={`ApplicationsTable_${type}`}
      queryResultRowsKey="applications"
    />
  );
}

type FilterDefinitionFactory = (type: 'all' | 'own') => TableFilterDefinition[];
type ColumnDefinitionFactory = (type: 'all' | 'own') => TableColumnDefinition[];

const getFilterDefinitions: FilterDefinitionFactory = type => [
  commonFilterDefs.applicationStatus({id: 'status'}),
  type !== 'own' ? commonFilterDefs.userId({id: 'role'}) : null,
  commonFilterDefs.uuid({id: 'customer_id'}),
  commonFilterDefs.string({
    id: 'id',
    filterProps: {placeholder: 'Ansöknings-ID'},
  }),

  commonFilterDefs.boolean({
    id: 'portal_is_from',
    permission: 'application_search:portal',
    groups: ['portal'],
  }),
  commonFilterDefs.boolean({id: 'ssn_co_exists'}),
  commonFilterDefs.boolean({
    id: 'pep_answers.pep_myself',
    permission: 'application:pep',
    groups: ['pep'],
  }),
  commonFilterDefs.boolean({
    id: 'pep_answers.pep_knows',
    permission: 'application:pep',
    groups: ['pep'],
  }),
  commonFilterDefs.boolean({id: 'has_accepted_process_id'}),
  commonFilterDefs.boolean({id: 'flag_has_waiting_process', groups: ['flags']}),
  commonFilterDefs.boolean({id: 'flag_status_ever_waiting', groups: ['flags']}),
  commonFilterDefs.boolean({id: 'flag_status_ever_accepted', groups: ['flags']}),
  commonFilterDefs.boolean({id: 'flag_accepted_via_portal', groups: ['flags', 'portal']}),
  commonFilterDefs.boolean({id: 'flag_loan_only_new', groups: ['flags']}),
  commonFilterDefs.boolean({id: 'flag_advisor_is_done', groups: ['flags']}),
  commonFilterDefs.boolean({id: 'flag_customer_is_done', groups: ['flags']}),
  commonFilterDefs.boolean({id: 'flag_last_uc_is_approved', groups: ['flags']}),
  commonFilterDefs.booleanNull({id: 'flag_zensum_qualified', groups: ['flags']}),
  commonFilterDefs.booleanNull({id: 'flag_high_value_bids', groups: ['flags']}),
  commonFilterDefs.booleanNull({id: 'flag_wants_tryggsam', groups: ['flags']}),
  type !== 'own' ? commonFilterDefs.userId({id: 'created_by_user_id'}) : null,
  type !== 'own' ? commonFilterDefs.userId({id: 'role_advisor'}) : null,

  commonFilterDefs.json({
    id: 'portal_tracking_match',
    permission: 'application_search:portal',
    groups: ['portal'],
  }),

  commonFilterDefs.string({
    id: 'portal_tracking_code_adtraction',
    permission: 'application_search:portal',
    groups: ['portal'],
  }),
  commonFilterDefs.string({
    id: 'portal_tracking_code_adservice',
    permission: 'application_search:portal',
    groups: ['portal'],
  }),
  commonFilterDefs.string({
    id: 'portal_tracking_code_gclid',
    permission: 'application_search:portal',
    groups: ['portal'],
  }),
  commonFilterDefs.string({
    id: 'portal_tracking_code_ga_id',
    permission: 'application_search:portal',
    groups: ['portal'],
  }),
  commonFilterDefs.string({
    id: 'portal_submitter_ip_address',
    permission: 'application_search:portal',
    groups: ['portal'],
  }),

  commonFilterDefs.string({
    id: 'created_by_channel',
    groups: ['portal'],
  }),

  commonFilterDefs.dateRangeOnField({id: 'date'}, [
    {value: 'created_at', label: 'Skapad'},
    {value: 'closed_at', label: 'Stängd'},
    {value: 'status_updated_at', label: 'Uppdaterad'},
    {value: 'accepted_at', label: 'Accepterad'},
  ]),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'closed_at'}),
  commonFilterDefs.dateRelative({id: 'closed_at_relative'}),
  commonFilterDefs.dateRange({id: 'accepted_at'}),
  commonFilterDefs.dateRelative({id: 'accepted_at_relative'}),
  commonFilterDefs.dateRange({id: 'status_updated_at'}),
  commonFilterDefs.dateRelative({id: 'status_updated_at_relative'}),

  type !== 'own' ? commonFilterDefs.applicationCreatedByRole({
    id: 'created_by_role',
    permission: 'application_table_all:see_everything',
  }) : null,
  commonFilterDefs.applicationClosedReason({id: 'closed_reason'}),
  type !== 'own' ? commonFilterDefs.bankId({
    id: 'accepted_bank_id',
    permission: 'bank_select_options',
  }) : null,

  type !== 'own' ? commonFilterDefs.groupId({
    id: 'created_by_user_groups',
    permission: 'group_select_options',
  }) : null,
  commonFilterDefs.intOp({id: 'disbursed'}),
  commonFilterDefs.intOp({id: 'ordinal'}),
].filter(v => v).map(filterDefinition => ({
  title: applicationFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const getColumnDefinitions: ColumnDefinitionFactory = type => [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.applicationId({
      id: 'id',
      cellProps: {rowAsObject: true},
    }),
    commonColumnDefs.basic({
      Cell: TableCells.CustomerTableCell,
      cellProps: {objKey: 'Customer', idKey: 'customer_id'},
      id: 'customer_id',
      groups: ['customer'],
    }),
    commonColumnDefs.basic({
      Cell: TableCells.CustomerTableCell,
      cellProps: {objKey: 'CustomerCo', idKey: 'customer_id_co'},
      id: 'customer_id_co',
      groups: ['customer'],
    }),
    commonColumnDefs.basic({
      id: 'Customer.telephone_mobile',
      groups: ['customer'],
    }),
    commonColumnDefs.basic({
      id: 'Customer.email',
      groups: ['customer'],
    }),
    commonColumnDefs.basic({
      id: 'Customer.address_street',
      groups: ['customer'],
    }),
    commonColumnDefs.basic({
      id: 'Customer.address_postcode',
      groups: ['customer'],
    }),
    commonColumnDefs.basic({
      id: 'Customer.address_city',
      groups: ['customer'],
    }),
    commonColumnDefs.basic({
      id: 'Customer.address_co',
      groups: ['customer'],
    }),
    commonColumnDefs.cell('portal_submitter_ip_address'),
    commonColumnDefs.cell('portal_tracking_code_adtraction'),
    commonColumnDefs.cell('portal_tracking_code_adservice'),
    commonColumnDefs.cell('portal_tracking_code_gclid'),
    commonColumnDefs.cell('portal_tracking_code_ga_id'),
    type !== 'own' ? commonColumnDefs.cell('created_by_role') : null,
    type !== 'own' ? commonColumnDefs.cell('created_by_user_groups') : null,
    commonColumnDefs.cell('created_by_channel'),
    commonColumnDefs.cell('repayment_years'),
    commonColumnDefs.cell('loan_new'),
    commonColumnDefs.cell('loan_now'),
    commonColumnDefs.cell('total_loan_amount'),
    commonColumnDefs.cell('ssn'),
    commonColumnDefs.cell('ssn_co'),
    commonColumnDefs.cell('closed_reason'),
    commonColumnDefs.inputMutator({
      id: 'note',
      cellProps: {
        rowValueKey: 'note',
        getUrl: row => `/application/${row.id}`,
        getData: (rowValueKey, value) => ({[rowValueKey]: value}),
        mutateProps: {method: 'patch', optimistic: true},
        inputProps: {name: 'note', maxLength: 200},
      },
    }),
    commonColumnDefs.basic({
      Cell: TableCells.AcceptedBankTableCell,
      id: 'accepted_bank_id',
    }),
    commonColumnDefs.basic({
      Cell: TableCells.AcceptedBankProcessTableCell,
      id: 'accepted_process_id',
    }),
    commonColumnDefs.datetime({
      sortable: true,
      id: 'status_updated_at',
    }),
    commonColumnDefs.datetime({
      sortable: true,
      id: 'accepted_at',
    }),
    commonColumnDefs.datetime({
      sortable: true,
      id: 'closed_at',
    }),
    commonColumnDefs.datetime({
      sortable: true,
      id: 'created_at',
    }),
    commonColumnDefs.basic({
      Cell: TableCells.UserTableCell,
      cellProps: {objKey: 'Advisor', idKey: 'role_advisor'},
      id: 'role_advisor',
    }),
    commonColumnDefs.basic({
      Cell: TableCells.UserTableCell,
      cellProps: {objKey: 'Creator', idKey: 'created_by_user_id'},
      id: 'created_by_user_id',
    }),
    commonColumnDefs.basic({
      Cell: TableCells.ApplicationProcessCountersTableCell,
      id: 'process_counters',
    }),
    commonColumnDefs.basic({
      Cell: TableCells.ApplicationStatusTableCell,
      id: 'status',
    }),
    commonColumnDefs.sumCurrency({
      id: 'disbursed',
      rowKey: 'disbursed',
    }),
    commonColumnDefs.boolean({id: 'portal_is_from'}),
    commonColumnDefs.boolean({id: 'has_accepted_process_id'}),
    commonColumnDefs.boolean({id: 'flag_loan_only_new', groups: ['flags']}),
    commonColumnDefs.booleanNull({id: 'flag_zensum_qualified', groups: ['flags']}),
    commonColumnDefs.boolean({id: 'flag_has_waiting_process', groups: ['flags']}),
    commonColumnDefs.boolean({id: 'flag_status_ever_accepted', groups: ['flags']}),
    commonColumnDefs.boolean({id: 'flag_status_ever_waiting', groups: ['flags']}),
    commonColumnDefs.boolean({id: 'flag_accepted_via_portal', groups: ['flags']}),
    commonColumnDefs.boolean({id: 'flag_advisor_is_done', groups: ['flags']}),
    commonColumnDefs.boolean({id: 'flag_customer_is_done', groups: ['flags']}),
    commonColumnDefs.boolean({id: 'flag_last_uc_is_approved', groups: ['flags']}),
    commonColumnDefs.boolean({id: 'flag_customer_prev_disbursed', groups: ['flags']}),
    commonColumnDefs.booleanNull({id: 'flag_wants_tryggsam', groups: ['flags']}),
    commonColumnDefs.booleanNull({id: 'flag_high_value_bids', groups: ['flags']}),
    commonColumnDefs.boolean({id: 'pep_answers.pep_myself'}),
    commonColumnDefs.boolean({id: 'pep_answers.pep_knows'}),
    commonColumnDefs.cell('portal_tracking', TableCells.JSONStringifyTableCell, {cellKey: 'portal_tracking'}),
  ].filter(v => v).map(columnDefinition => {
    const { id } = columnDefinition;
    const title = applicationFilter.indexColumnName(id);
    const cellValue = application => applicationFilter.indexField(application, id);
    return {
      ...columnDefinition,
      cellValue,
      title,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRow,
  ]),
];
