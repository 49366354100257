import React from 'react';
import { ProspectRowWithRelations } from 'shared/types/prospect';
import AssociatedObjectList from 'src/components/AssociatedObjectList';

interface ProspectAssociatedObjectListProps {
  prospect: ProspectRowWithRelations;
}

function ProspectAssociatedObjectList (props: ProspectAssociatedObjectListProps) {
  const { prospect } = props;

  const associatedObjects = [
    prospect.Customer ? {type: 'customer', value: prospect.Customer, label: 'Kund'} : null,
    prospect.Customer?.LastApplication ? {type: 'application', value: prospect.Customer.LastApplication, label: 'Kundens senaste ansökan'} : null,
  ].filter(v => v);

  return (
    <AssociatedObjectList
      associatedObjects={associatedObjects}
    />
  );
}
export default React.memo(ProspectAssociatedObjectList);
