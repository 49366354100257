import React, { forwardRef } from 'react';
import { Badge, Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { SIDEBAR_BEHAVIOR } from 'src/constants';
import useSidebar from 'src/hooks/useSidebar';
import { PermissionDefinition } from 'src/contexts/AuthContext';
import { anyChildHasPermission } from 'src/sidebar/sidebarNavItems';
import useAuth from 'src/hooks/useAuth';

export interface SidebarNavListItemProps {
  title: string;
  depth?: number;
  href?: string;
  color?: string;
  icon?: any;
  badge?: React.ReactNode;
  open?: boolean;
  sub?: SidebarNavListItemProps[];
  permissions?: PermissionDefinition;
}

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = React.memo(function SidebarNavListItem (props: SidebarNavListItemProps) {
  const {
    title,
    href,
    sub,
    color,
    icon: Icon,
    badge,
    permissions,
    open:openProp = false,
  } = props;

  const auth = useAuth();
  const [open, setOpen] = React.useState<boolean>(openProp);
  const { behavior } = useSidebar();

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  const active = openProp || (behavior !== SIDEBAR_BEHAVIOR.COMPACT && open);

  if (permissions && !auth.hasAnyPermissions(permissions)) {
    return null;
  }

  if (sub) {
    if (!anyChildHasPermission(auth, sub)) {
      return null;
    }

    return (
      <li className={classNames('sidebar-item', {active})}>
        <a
          role="menuitem"
          className={classNames(
            'sidebar-link',
            {collapsed: !open},
            color ? `text-${color}` : '',
          )}
          data-bs-toggle="collapse"
          aria-expanded={open ? 'true' : 'false'}
          tabIndex={0}
          onKeyPress={handleToggle}
          onClick={handleToggle}
        >
          {Icon && <Icon className="feather align-middle" />}
          {' '}
          <span className="align-middle">
            {title}
          </span>
          {badge && (
            <Badge className="badge-sidebar-primary" bg="">
              {badge}
            </Badge>
          )}
          {open ? <div /> : <div />}
        </a>
        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">
            {sub.map((props, index) => (
              <SidebarNavListItem
                {...props}
                key={index}
              />
            ))}
          </ul>
        </Collapse>
      </li>
    );
  }

  return (
    <li className="sidebar-item">
      <CustomRouterLink
        to={href}
        title={title}
        className={classNames('sidebar-link', color ? `text-${color}` : '')}
      >
        {Icon && <Icon className="feather align-middle" />}
        {' '}
        <span className="align-middle">
          {title}
        </span>
        {badge && (
          <Badge className="badge-sidebar-primary" bg="">
            {badge}
          </Badge>
        )}
      </CustomRouterLink>
    </li>
  );
});

export default SidebarNavListItem;

const CustomRouterLink: React.FC<any> = forwardRef(function CustomRouterLink (props: any, ref) {
  return (
    <NavLink {...props} />
  );
});
