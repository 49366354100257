import React from 'react';
import { Helmet } from 'react-helmet-async';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import Pluralizer from 'src/formatters/Pluralizer';
import { numberfmt } from 'src/numbers';
import * as api from 'src/api';
import { Link } from 'react-router-dom';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import { PieChart } from 'react-feather';
import { Container, Card } from 'react-bootstrap';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { StatisticBankRow } from 'shared/types/statistic_bank';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import { getLinkToFilteredTable } from 'src/tables/helpers';
import { TableStateOrder, TableHeaderGroups, TableStateFilterMap, TableRow } from 'src/tables/types';
import * as TableCells from 'src/tables/TableCells';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';
import { ErrorAlertCardBody, CardTitleIcon  } from 'src/cards/CardHelpers';
import bankProcessFilter from 'shared/filter/bank_process';
import moment from 'moment';
import { isFiniteNumber } from 'src/numbers';
import useLocalStorage from 'src/hooks/useLocalStorage';
import * as SetFilterButtons from 'src/buttons/SetFilterButton';

interface IStatisticsBanksFilter extends TableStateFilterMap {
  date?: {
    field: 'closed_at' | 'created_at';
    from?: string;
    to?: string;
  };
  portal_is_from?: boolean;
}

export default function StatisticsBanksPage () {
  const [filter, setFilter] = useLocalStorage<TableStateFilterMap>('StatisticsBanksPageFilter', defaultFilter);
  const [order, setOrder] = useLocalStorage<TableStateOrder>('StatisticsBanksPageOrder', {id: 'asc'});

  const query = useQuery<StatisticBankRow, Error>({
    queryKey: ['StatisticsBanksPage', filter, order],
    placeholderData: keepPreviousData,
    queryFn: props => {
      const params = {...(props.queryKey[1] as IStatisticsBanksFilter), order};
      return api.request({
        method: 'POST',
        url:  '/statistic/bank/filter',
        data: {...params},
      });
    },
  });

  const rows: TableRow[] = React.useMemo(() => {
    if (!query.data?.banks) return [];
    return query.data.banks;
  }, [query.data]);

  const footerRows: TableRow[] = React.useMemo(() => {
    if (!query.data?.sum) return [];
    return [query.data.sum];
  }, [query.data]);

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Långivarstatistik" />
        <Container fluid className="p-0">
          <Card className="border mb-1">
            <Card.Header className="pb-2">
              <CardTitleIcon
                title="Långivarstatistik"
                Icon={<PieChart size={16} />}
                spinning={query.isLoading || query.isRefetching}
              >
                <RefreshObjectButton
                  disabled={query.isRefetching}
                  onClick={() => query.refetch()}
                />
              </CardTitleIcon>
              <Card.Subtitle className="mt-1 small d-flex align-items-baseline">
                <Pluralizer
                  count={query.data?.banks?.length ?? 0}
                  zero="Inga långivare"
                  one="Visar 1 långivare"
                  otherwise="Visar %% långivare"
                />
                <SetFilterButtons.ThisYear filter={filter} setFilter={setFilter} />
                <SetFilterButtons.LastYear filter={filter} setFilter={setFilter} />
                <SetFilterButtons.ThisMonth filter={filter} setFilter={setFilter} />
                <SetFilterButtons.LastMonth filter={filter} setFilter={setFilter} />
                <SetFilterButtons.ThisWeek filter={filter} setFilter={setFilter} />
                <SetFilterButtons.LastWeek filter={filter} setFilter={setFilter} />
                <SetFilterButtons.Today filter={filter} setFilter={setFilter} />
                <SetFilterButtons.Yesterday filter={filter} setFilter={setFilter} />
              </Card.Subtitle>
            </Card.Header>
            <ErrorAlertCardBody error={query.error} className="border-top p-3" />
            <CardBodyTable
              size="sm"
              filter={filter}
              order={order}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              headerGroups={headerGroups}
              setFilter={setFilter}
              setOrder={setOrder}
              rows={rows}
              footerRows={footerRows}
              isFetched={query.isFetched}
              params={{filter}}
            />
          </Card>
        </Container>
      </Content>
    </Main>
  );
}

const defaultFilter = {
  date: {
    field: 'created_at',
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().endOf('month').format('YYYY-MM-DD'),
  },
};

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.dateRangeOnField({id: 'date'}, [
    {value: 'created_at', label: 'Skapad'},
    {value: 'closed_at', label: 'Stängd'},
  ]),
  commonFilterDefs.boolean({
    id: 'portal_is_from',
  }),
].map(def => ({
  ...def,
  title: bankProcessFilter.indexColumnName(def.id),
}));

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.basic({
    id: 'id',
    title: 'Långivare',
    Cell: MaybeBankTableCell,
    cellProps: {rowAsObject: true},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.tot',
    title: 'Totalt',
    Cell: CountLinkedCell,
    cellProps: {rowFilter: []},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.pct_tot_over_tot_bank_rejected',
    title: 'Avslag',
    Cell: TableCells.Percentage,
  }),
  commonColumnDefs.basic({
    id: 'values.tot_offered',
    title: 'Totalt',
    Cell: CountLinkedCell,
    cellProps: {rowFilter: [{key: 'flag_status_ever_waiting', value: 'true'}]},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.pct_offered_over_tot',
    title: 'Grad',
    Cell: TableCells.Percentage,
  }),
  commonColumnDefs.basic({
    id: 'values.avg_interest_rate',
    title: 'Medelränta',
    Cell: TableCells.Percentage,
    cellProps: {scaled: true},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.tot_accepted',
    title: 'Totalt',
    Cell: CountLinkedCell,
    cellProps: {rowFilter: [{key: 'flag_status_ever_accepted', value: 'true'}]},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.pct_accepted_over_tot',
    title: 'Grad',
    Cell: TableCells.Percentage,
  }),
  commonColumnDefs.basic({
    id: 'values.tot_disbursed',
    title: 'Totalt',
    Cell: CountLinkedCell,
    cellProps: {rowFilter: [{key: 'status', value: 'disbursed'}]},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.pct_disbursed_over_tot',
    title: 'Grad',
    Cell: TableCells.Percentage,
  }),
  commonColumnDefs.basic({
    id: 'values.pct_disbursed_over_accepted',
    title: 'Grad SB',
    Cell: TableCells.Percentage,
  }),
  commonColumnDefs.basic({
    id: 'values.tot_over_tot_disbursed',
    title: 'In/Utb',
    Cell: TableCells.Count,
  }),
  commonColumnDefs.basic({
    id: 'values.avg_days_created_to_closed',
    title: 'Skapad→Utb.',
    Cell: DayCell,
  }),
  commonColumnDefs.basic({
    id: 'values.sum_disbursed',
    title: 'Summa',
    Cell: TableCells.Currency,
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'values.sum_disbursed_over_months',
    title: 'Per mån',
    Cell: TableCells.Currency,
  }),
  commonColumnDefs.basic({
    id: 'values.avg_sum_disbursed',
    title: 'Medel',
    Cell: TableCells.Currency,
  }),
  commonColumnDefs.basic({
    id: 'values.sum_commission',
    title: 'Ersättning',
    Cell: TableCells.Currency,
    sortable: true,
  }),
];

const headerGroups: TableHeaderGroups = [
  {
    id: 'id',
    title: '',
    columns: ['id'],
  },
  {
    id: 'applications',
    title: 'Ansökningar',
    columns: [
      'values.tot',
      'values.pct_tot_over_tot_bank_rejected',
    ],
  },
  {
    id: 'bids',
    title: 'Bud',
    columns: [
      'values.tot_offered',
      'values.pct_offered_over_tot',
      'values.avg_interest_rate',
    ],
  },
  {
    id: 'accepted',
    title: 'Skuldebrev',
    columns: [
      'values.tot_accepted',
      'values.pct_accepted_over_tot',
    ],
  },
  {
    id: 'disbursed',
    title: 'Utbetalningar',
    columns: [
      'values.tot_disbursed',
      'values.pct_disbursed_over_tot',
      'values.pct_disbursed_over_accepted',
      'values.tot_over_tot_disbursed',
      'values.avg_days_created_to_closed',
    ],
  },
  {
    id: 'sums',
    title: 'Summor',
    columns: [
      'values.sum_disbursed',
      'values.sum_disbursed_over_months',
      'values.avg_sum_disbursed',
      'values.sum_commission',
    ],
  },
];

function DayCell (props) {
  const { value } = props;
  if (!isFiniteNumber(value)) return '-';
  return (
    <>
      {numberfmt(value, {maximumFractionDigits: 1})}
      {' '}dgr
    </>
  );
}

function MaybeBankTableCell (props) {
  const { row } = props;
  if (row.id !== 'sum') {
    return <TableCells.BankTableCell {...props} />;
  } 
  return (
    <strong>Summa</strong>
  );
}

function CountLinkedCell (props) {
  const { value, row, params, rowFilter } = props;
  const { filter } = params;
  const to = getLinkToFilteredTable('/bank_processes', filter, [
    ...rowFilter,
    row.id === 'sum' ? null : {key: 'bank_id', value: row.id},
  ].filter(v => v));
  return (
    <Link to={to}>
      {value} st
    </Link>
  );
}
