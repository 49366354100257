import React from 'react'; // eslint-disable-line
import * as TableFilters from 'src/tables/TableFilters';
import * as api from 'src/api';
import * as constants from 'shared/constants';
import * as translations from 'shared/translations';
import * as applicationFilter from 'shared/filter/application';
import * as customerFilter from 'shared/filter/customer';
import * as customerConsentFilter from 'shared/filter/customer_consent';
import * as applicationTriggerFilter from 'shared/filter/application_trigger';
import * as ucFilter from 'shared/filter/uc';
import * as smsFilter from 'shared/filter/sms';
import * as emailFilter from 'shared/filter/email';
import * as letterFilter from 'shared/filter/letter';
import * as pepQueryFilter from 'shared/filter/pep_query';
import * as workFilter from 'shared/filter/work';
import * as prospectFilter from 'shared/filter/prospect';
import * as cloudInsuranceFilter from 'shared/filter/customer_cloud_insurance';
import * as jaycomInsuranceFilter from 'shared/filter/customer_jaycom_insurance';
import * as complaintFilter from 'shared/filter/complaint';
import * as bankProcessFilter from 'shared/filter/bank_process';
import * as miscFilter from 'shared/filter/misc';
import * as dateFilter from 'shared/filter/date';
import * as workTriggerTemplateFilter from 'shared/filter/work_trigger_template';
import moment from 'moment';

interface TableCommonDefinitionExtendWith {
  id: string;
  [key: string]: any;
}

export const boolean = (extendWith: TableCommonDefinitionExtendWith) => ({
  filterValue: value => miscFilter.yesno(value),
  Filter: TableFilters.BooleanFilter,
  ...extendWith,
});

export const booleanNull = (extendWith: TableCommonDefinitionExtendWith) => ({
  filterValue: value => miscFilter.yesno(value),
  Filter: TableFilters.BooleanFilter,
  filterProps: {hasSelectableNull: true},
  ...extendWith,
});

export const string = (extendWith: TableCommonDefinitionExtendWith) => ({
  Filter: TableFilters.StringFilter,
  ...extendWith,
});

export const json = (extendWith: TableCommonDefinitionExtendWith) => ({
  Filter: TableFilters.JSONFilter,
  ...extendWith,
});

export const select = (extendWith: TableCommonDefinitionExtendWith, options: TableFilters.SelectOptionList = []) => ({
  filterProps: {options},
  Filter: TableFilters.SelectFilter,
  ...extendWith,
});

// TODO: validate ssn format
export const ssn = (extendWith: TableCommonDefinitionExtendWith) => ({
  Filter: TableFilters.StringFilter,
  ...extendWith,
});

export const uuid = (extendWith: TableCommonDefinitionExtendWith) => string({
  filterProps: {
    pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
  },
  ...extendWith,
});

export const applicationId = (extendWith: TableCommonDefinitionExtendWith) => uuid({
  filterProps: {
    pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}_\\d{3}$',
  },
  ...extendWith,
});

export const userId = (extendWith: TableCommonDefinitionExtendWith, extendQueryWith = {}) => ({
  groups: ['user'],
  filterProps: {
    placeholder: 'Oavsett användare',
    queryKey: ['userSelectOptions', extendQueryWith],
    queryFn: () => api.request({
      url: '/user/selectOptions',
      params: {is_active: true, ...extendQueryWith},
    }).then(users => {
      const options = users.map(user => ({value: user.id, label: user.name ?? user.id}));
      return options;
    }),
  },
  Filter: TableFilters.QueriedDatalistFilter,
  ...extendWith,
});

export const bankId = (extendWith: TableCommonDefinitionExtendWith, extendQueryWith = {}) => ({
  groups: ['bank'],
  filterProps: {
    placeholder: 'Oavsett långivare',
    queryKey: ['bankSelectOptions', extendQueryWith],
    queryFn: () => api.request({
      url: '/bank/selectOptions',
      params: {is_active: true, ...extendQueryWith},
    }).then(banks => {
      const options = banks.map(bank => ({value: bank.id, label: bank.name ?? bank.id}));
      return options;
    }),
  },
  Filter: TableFilters.QueriedDatalistFilter,
  ...extendWith,
});

export const groupId = (extendWith: TableCommonDefinitionExtendWith, extendQueryWith = {}) => ({
  filterProps: {
    placeholder: 'Oavsett grupp',
    queryKey: ['groupSelectOptions', extendQueryWith],
    queryFn: () => api.request({
      url: '/group/selectOptions',
      params: {is_active: true, ...extendQueryWith},
    }).then(groups => {
      const options = groups.map(group => ({value: group.id, label: group.name ?? group.id}));
      return options;
    }),
  },
  Filter: TableFilters.QueriedDatalistFilter,
  ...extendWith,
});

export const workTask = (extendWith: TableCommonDefinitionExtendWith, extendQueryWith = {}) => ({
  groups: ['work'],
  filterProps: {
    placeholder: 'Oavsett rutin',
    queryKey: ['workTaskSelectOptions', extendQueryWith],
    queryFn: () => api.request({
      url: '/work/task/selectOptions',
      params: {is_active: true, ...extendQueryWith},
    }).then(tasks => {
      const options = tasks.map(task => ({value: task.id, label: task.name}));
      return options;
    }),
  },
  Filter: TableFilters.QueriedDatalistFilter,
  ...extendWith,
});

export const dateRange = (extendWith: TableCommonDefinitionExtendWith) => ({
  groups: ['date'],
  filterValue: ({from, to}) => {
    return [from, to].filter(v => v).map(d => moment(d).format('YYYY-MM-DD')).join(' - ');
  },
  Filter: TableFilters.DateRangeFilter,
  ...extendWith,
});

export const dateTimeRange = (extendWith: TableCommonDefinitionExtendWith) => ({
  groups: ['date'],
  filterValue: ({from, to}) => {
    return [from, to].filter(v => v).map(d => moment(d).format('YYYY-MM-DDT:HH:mm')).join(' - ');
  },
  Filter: TableFilters.DateTimeRangeFilter,
  ...extendWith,
});

export const dateRelative = (extendWith: TableCommonDefinitionExtendWith) => ({
  groups: ['date'],
  filterValue: value => dateFilter.dateRelative(value),
  Filter: TableFilters.DateRelativeFilter,
  ...extendWith,
});

interface Option {
  value: string;
  label: string;
}

export const dateRangeOnField = (extendWith: TableCommonDefinitionExtendWith, fieldOptions: Option[] = []) => ({
  groups: ['date'],
  filterValue: ({from, to, field}) => {
    const fieldStr = miscFilter.commonDateFields(field || fieldOptions[0].value);
    return [fieldStr, ...[from, to].filter(v => v).map(d => moment(d).format('YYYY-MM-DD'))].filter(v => v).join(' - ');
  },
  Filter: TableFilters.DateRangeOnFieldFilter,
  ...extendWith,
  filterProps: {...(extendWith.filterProps || {}), fieldOptions},
});

export const intOp = (extendWith: any = {}) => ({
  filterValue: ({op, value}) => `${miscFilter.logicalComparisonShort(op)} ${value}`,
  Filter: TableFilters.IntOpFilter,
  ...extendWith,
  filterProps: {
    min: 0,
    step: 1,
    placeholder: 'Oavsett värde',
    ...(extendWith.filterProps || {}),
  },
});

export const complaintStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.complaints.status.map(value => ({
    value,
    label: complaintFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => complaintFilter.status(value),
    ...extendWith,
  }, options);
};

export const ucResponseCode = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.uc.response_codes.map(value => ({
    value,
    label: ucFilter.responseCode(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => ucFilter.responseCode(value),
    ...extendWith,
  }, options);
};

export const prospectResult = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.prospect.results.map(value => ({
    value,
    label: prospectFilter.result(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => prospectFilter.result(value),
    ...extendWith,
  }, options);
};

export const jaycomInsuranceStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.customerJaycomInsurance.statusTypes.map(value => ({
    value,
    label: jaycomInsuranceFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => jaycomInsuranceFilter.status(value),
    ...extendWith,
  }, options);
};

export const insuranceStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.customerCloudInsurance.statusTypes.map(value => ({
    value,
    label: cloudInsuranceFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => cloudInsuranceFilter.status(value),
    ...extendWith,
  }, options);
};

export const workStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.work.statusTypes.map(value => ({
    value,
    label: workFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => workFilter.status(value),
    ...extendWith,
  }, options);
};

export const pepQueryStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.pepQuery.statusTypes.map(value => ({
    value,
    label: pepQueryFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => pepQueryFilter.status(value),
    ...extendWith,
  }, options);
};

export const customerStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.customer.status.map(value => ({
    value,
    label: customerFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => customerFilter.status(value),
    ...extendWith,
  }, options);
};

export const applicationTriggerStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.applicationTrigger.status.map(value => ({
    value,
    label: applicationTriggerFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => applicationTriggerFilter.status(value),
    ...extendWith,
  }, options);
};

export const workTriggerTemplateObjectType = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.workTriggerTemplate.objectTypes.map(value => ({
    value,
    label: workTriggerTemplateFilter.objectType(value),
  }));
  return select({
    filterValue: value => workTriggerTemplateFilter.objectType(value),
    ...extendWith,
  }, options);
};

export const loanPurpose = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.application.loan_purposes.map(value => ({
    value,
    label: translations.application.loan_purposes(value),
  }));
  return select({
    filterValue: value => translations.application.loan_purposes(value),
    ...extendWith,
  }, options);
};

export const smsStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.sms.status_types.map(value => ({
    value,
    label: smsFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => smsFilter.status(value),
    ...extendWith,
  }, options);
};

export const emailStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.email.status_types.map(value => ({
    value,
    label: emailFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => emailFilter.status(value),
    ...extendWith,
  }, options);
};

export const letterStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.letter.status_types.map(value => ({
    value,
    label: letterFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => letterFilter.status(value),
    ...extendWith,
  }, options);
};

export const applicationStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.application.status_types.map(value => ({
    value,
    label: applicationFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => applicationFilter.status(value),
    ...extendWith,
  }, options);
};

export const bankProcessStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.bank_process.status_types.map(value => ({
    value,
    label: bankProcessFilter.status(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => bankProcessFilter.status(value),
    ...extendWith,
  }, options);
};

export const bankProcessSubStatus = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.bank_process.all_sub_status_types.map(value => ({
    value,
    label: bankProcessFilter.subStatus(value),
  }));
  return select({
    groups: ['status'],
    filterValue: value => bankProcessFilter.subStatus(value),
    ...extendWith,
    id: extendWith.id ?? 'sub_status',
  }, options);
};

export const customerConsentMethod = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.customerConsent.methods.map(value => ({
    value,
    label: customerConsentFilter.method(value),
  }));
  return select({
    filterValue: value => customerConsentFilter.method(value),
    ...extendWith,
  }, options);
};

export const applicationCreatedByRole = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.application.created_by_roles.map(value => ({
    value,
    label: applicationFilter.createdByRole(value),
  }));
  return select({
    groups: ['user'],
    filterValue: value => applicationFilter.createdByRole(value),
    ...extendWith,
  }, options);
};

export const applicationClosedReason = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.application.closed_reasons.map(value => ({
    value,
    label: applicationFilter.closedReason(value),
  }));
  return select({
    filterValue: value => applicationFilter.closedReason(value),
    ...extendWith,
  }, options);
};

export const bankProcessClosedReason = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.bank_process.closed_reasons.map(value => ({
    value,
    label: bankProcessFilter.closedReason(value),
  }));
  return select({
    filterValue: value => bankProcessFilter.closedReason(value),
    ...extendWith,
  }, options);
};

export const employmentType = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.application.employment_types.map(value => ({
    value,
    label: translations.application.employment_types(value),
  }));
  return select({
    filterValue: value => translations.application.employment_types(value),
    ...extendWith,
  }, options);
};

export const language = (extendWith: TableCommonDefinitionExtendWith) => {
  const options = constants.languages.map(value => ({
    value,
    label: miscFilter.language(value),
  }));
  return select({
    filterValue: value => miscFilter.language(value),
    ...extendWith,
  }, options);
};
