import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { FileRow } from 'shared/types/files';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import * as FileFormFields from 'src/file/FileFormFields';
import * as formUtils from 'src/utils/form';
import {Download, Trash} from 'react-feather';
import DateTime from 'src/formatters/DateTime';
import FileUploadButton from 'src/file/FileUploadButton';
import {pick} from 'lodash';

export interface FileEditFormValues extends Pick<FileRow, 'name' | 'description' | 'folder' | 'role'> {
}

interface FileEditFormProps {
  file: FileRow;
  className?: string;
  initialValues: FileEditFormValues;
  onUploadSuccess: (newFileRow: FileRow) => void;
  onSubmit: (values: FileEditFormValues, helpers: FormikHelpers<FileEditFormValues>) => Promise<any>;
  onDelete?: () => void;
  submitError?: Error | null;
  disabled?: boolean;
}

export default function FileEditForm (props: FileEditFormProps) {
  const {
    file,
    initialValues,
    submitError,
    onDelete,
    onSubmit,
    onUploadSuccess,
    disabled,
    className,
  } = props;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} className={className}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="mx-3">
            <Row>
              <Col>
                <FileFormFields.Name disabled={disabled} />
              </Col>
            </Row>
            <FileFormFields.Description disabled={disabled} />
            <Row>
              <Col>
                <FileFormFields.Folder disabled={disabled} />
              </Col>
              <Col>
                <FileFormFields.Role disabled={disabled} />
              </Col>
            </Row>
          </div>

          {file.uploaded_at ? (
            <Card className="border m-3">
              <Card.Header className="d-flex flex-wrap gap-2 align-items-center justify-content-between border-bottom bg-light p-3">
                Uppladdad fil
                <div className="d-flex gap-2 flex-wrap align-items-center">
                  <FileUploadButton file={file} onUploadSuccess={onUploadSuccess} />
                  <a href={`/api/file/${file.id}/open`}
                    className="btn btn-outline-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Download size={16} />
                    {' '}Ladda ner
                  </a>
                </div>
              </Card.Header>
              <Card.Body className="p-3">
                <Row>
                  <Col>
                    <dl className="mb-0">
                      <dt>Typ</dt>
                      <dd>{file.mime}</dd>
                      <dt>Filändelse</dt>
                      <dd>{file.ext}</dd>
                    </dl>
                  </Col>
                  <Col>
                    <dl className="mb-0">
                      <dt>Filstorlek</dt>
                      <dd>{file.size} byte</dd>
                      <dt>Uppladdad</dt>
                      <dd><DateTime value={file.uploaded_at} /></dd>
                    </dl>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ) : (
            <Card className="border m-3">
              <Card.Header className="d-flex flex-wrap gap-2 align-items-center justify-content-between border-bottom bg-light p-3">
                Ingen uppladdad fil
                <div className="d-flex gap-2 flex-wrap align-items-center">
                  <FileUploadButton file={file} onUploadSuccess={onUploadSuccess} />
                </div>
              </Card.Header>
            </Card>
          )}

          <div className="mt-3 p-3 border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap align-items-center justify-content-between">
              {onDelete ? (
                <ConfirmActionModalButton
                  type="button"
                  className="rounded d-flex gap-1"
                  variant="danger"
                  disabled={!formProps.isValid || formProps.isSubmitting}
                  onConfirm={onDelete}
                  message="Är du säker på att du vill radera filen?"
                  title="Radera det här filen"
                >
                  <Trash size={18} />
                  Radera
                </ConfirmActionModalButton>
              ) : <div></div>}
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export function fileToFormValues (file: FileRow): FileEditFormValues {
  const keys = ['name', 'description', 'folder', 'role'];
  return {
    ...formUtils.rowToFormValues(pick(file, keys)) as FileEditFormValues,
  };
}

export function formValuesToUpdate (initialValues: FileEditFormValues, values: FileEditFormValues): Partial<FileEditFormValues> {
  const changes = formUtils.changes(initialValues, values);
  if (changes.role === '') changes.role = null;
  if (changes.folder === '') changes.folder = null;
  return changes;
}
