import React from 'react';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import * as IconButtons from 'src/buttons/IconButtons';
import { useQueries } from '@tanstack/react-query';
import { Card } from 'react-bootstrap';
import { Wind } from 'react-feather';
import classNames from 'classnames';
import CardBodyTable from 'src/tables/CardBodyTable';
import * as TableCells from 'src/tables/TableCells';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import {ProspectRow} from 'shared/types/prospect';
import {TableColumnCellProps, TableColumnDefinition} from 'src/tables/Table';
import BooleanIcon from 'src/formatters/BooleanIcon';

interface CustomerProspectSearchListCardProps {
  className?: string;
  customerSsn?: string;
  customerEmail?: string;
  customerTelephone?: string;
}

type UseQueriesResult = {
  data: ProspectRow[];
  refetch: () => Promise<any>;
  error: any;
  isFetched: boolean;
  isRefetching: boolean;
};

export default function CustomerProspectSearchListCard (props: CustomerProspectSearchListCardProps) {
  const { customerSsn, customerEmail, customerTelephone, className } = props;

  const query = useQueries<ProspectRow[], UseQueriesResult>({
    queries: [
      customerSsn ? {queryKey: ['/prospect/search', {params: {ssn: customerSsn}}]} : null,
      customerEmail ? {queryKey: ['/prospect/search', {params: {email: customerEmail}}]} : null,
      customerTelephone ? {queryKey: ['/prospect/search', {params: {telephone: customerTelephone}}]} : null,
    ].filter(v => v),
    combine: results => {
      const data = results.reduce<ProspectRow[]>((out, queryResult) => {
        const list = queryResult.isSuccess ? queryResult.data as ProspectRow[] : [];
        return out.concat(list.filter(prospect => {
          return !out.find(existingProspect => existingProspect.id === prospect.id);
        }));
      }, []);

      return {
        data,
        isFetched: results.some(query => query.isFetched),
        isRefetching: results.some(query => query.isRefetching),
        error: results.find(query => query.error) ?? null,
        refetch: async () => await Promise.all(results.map(r => r.refetch())),
      };
    },
  });

  const rows = query.data ?? [];

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="border-bottom bg-light">
        <CardTitleIcon
          title="Prospektspårning"
          Icon={<Wind size={18} />}
          spinning={query.isRefetching}
        >
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            onClick={query.refetch}
          />
        </CardTitleIcon>
        <Card.Subtitle className="small mt-1">
          Tabellen visar alla prospekter med samma personnummer, e-postadress eller telefonnummer
        </Card.Subtitle>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <CardBodyTable
        params={{customerSsn, customerEmail, customerTelephone}}
        striped
        columnDefinitions={columnDefs}
        rows={rows}
        isFetched={query.isFetched}
      />
    </Card>
  );
}

const columnDefs: TableColumnDefinition[] = [
  commonColumnDefs.prospectId({
    id: 'id',
    title: 'ID',
    cellProps: {rowAsObject: true},
  }),
  {
    id: 'ssn',
    title: 'Personnummer',
    Cell: ProspectCellEquals,
    cellProps: {prospectField: 'ssn', paramsField: 'customerSsn'},
  },
  {
    id: 'email',
    title: 'E-postadress',
    Cell: ProspectCellEquals,
    cellProps: {prospectField: 'email', paramsField: 'customerEmail'},
  },
  {
    id: 'telephone',
    title: 'Telefonnummer',
    Cell: ProspectCellEquals,
    cellProps: {prospectField: 'telephone', paramsField: 'customerTelephone'},
  },
  commonColumnDefs.datetime({
    id: 'created_at',
    title: 'Skapad',
  }),
  commonColumnDefs.basic({
    id: 'result',
    title: 'Avslutskod',
    Cell: TableCells.ProspectResultTableCell,
  }),
  commonColumnDefs.inspect(),
];

interface ProspectCellEqualsProps extends TableColumnCellProps {
  prospectField: string;
  paramsField: string;
}

function ProspectCellEquals (props: ProspectCellEqualsProps) {
  const { paramsField, prospectField, params, row } = props;

  const prospectValue = row[prospectField];
  const customerValue = params[paramsField];
  const isEqual = prospectValue && customerValue && prospectValue === customerValue;

  return (
    <span>
      {prospectValue}{' '}
      <BooleanIcon canBeIndetermined value={isEqual} className="d-inline-block" />
    </span>
  );
}
