import React from 'react';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import * as applicationTriggerFilter from 'shared/filter/application_trigger';
import { ApplicationTriggerRow } from 'shared/types/application_trigger';

interface StatusLabelProps extends StatusBaseLabelProps {
  value: ApplicationTriggerRow['status'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={applicationTriggerFilter.status}
      {...restOfProps}
    />
  );
}
