import React from 'react';
import * as JaycomInsuranceFormFields from 'src/jaycomInsurance/JaycomInsuranceFormFields';
import { CustomerJaycomInsurance } from 'shared/types/customer_jaycom_insurance';
import ConfirmActionModalExecuteButton from 'src/buttons/ConfirmActionModalExecuteButton';
import {GuardPermission} from 'src/guards/AuthGuards';
import FormikSubmitButton from 'src/buttons/FormikSubmitButton';
import {Form, Formik} from 'formik';
import * as api from 'src/api';
import { Card } from 'react-bootstrap';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import useNotyf from 'src/hooks/useNotyf';
import * as formUtils from 'src/utils/form';
import {pick} from 'lodash';

interface JaycomInsuranceAdminFormCardProps {
  insurance: CustomerJaycomInsurance;
  onReload: () => void;
}

type FormValues = Pick<CustomerJaycomInsurance, 'status'>;

export default function JaycomInsuranceAdminFormCard (props: JaycomInsuranceAdminFormCardProps) {
  const { insurance, onReload = () => {} } = props;

  const notyf = useNotyf();
  const initialValues = pick(insurance, 'status');
  const queryClient = useQueryClient();

  const updateMutation = useMutation<CustomerJaycomInsurance, Error, Partial<CustomerJaycomInsurance>>({
    mutationFn: vars => api.request({
      url: `/customer_jaycom_insurance/${insurance.id}`,
      method: 'PATCH',
      data: vars,
    }),
    onSuccess: updatedInsurance => {
      queryClient.setQueryData([`/customer_jaycom_insurance/${updatedInsurance.id}`], updatedInsurance);
      notyf.success({type: 'default', message: 'Försäkringen uppdaterades'});
    },
  });

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<CustomerJaycomInsurance, FormValues, Partial<CustomerJaycomInsurance>>({
    queryDataToFormValues: insuranceToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <Card className="border">
      <Card.Header className="border-bottom bg-light">
        <Card.Title className="mb-0">Inställningar</Card.Title>
      </Card.Header>
      <Formik initialValues={initialValues} onSubmit={formCycleHelpers.onSubmit} enableReinitialize>
        <Form placeholder="LOL">
          <Card.Body className="py-0 mb-3">
            <JaycomInsuranceFormFields.Status />
          </Card.Body>
          <Card.Footer className="border-top d-flex align-items-center gap-2">
            <GuardPermission permission="customer_jaycom_insurance_execute">
              {!insurance.jaycom_insurance_id && !['active', 'queued', 'cancelled'].includes(insurance.status) && (
                <ConfirmActionModalExecuteButton
                  url={`/customer_jaycom_insurance/${insurance.id}/submit`}
                  method="POST"
                  onSuccess={onReload}
                  variant="secondary"
                  message="Är du säker på att du vill lägga upp och aktivera försäkringen i Jaycom?"
                >
                  Aktivera försäkringen
                </ConfirmActionModalExecuteButton>
              )}
            </GuardPermission>
            <FormikSubmitButton />
          </Card.Footer>
        </Form>
      </Formik>
    </Card>
  );
}

function insuranceToFormValues (insurance: CustomerJaycomInsurance): FormValues {
  return pick(insurance, 'status');
}

function formValuesToUpdate (initialValues: FormValues, values: FormValues): Partial<CustomerJaycomInsurance> {
  const changes = formUtils.changes(initialValues, values);
  const update: Partial<CustomerJaycomInsurance> = {};
  if (typeof changes.status !== 'undefined') update.status = changes.status;
  return update;
}
