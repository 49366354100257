import React, {useState} from 'react';
import classNames from 'classnames';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ProspectTriggerAbstractRow, ProspectTriggerWithRelationsRow } from 'shared/types/prospect_trigger';
import { Card } from 'react-bootstrap';
import * as TableCells from 'src/tables/TableCells';
import * as TableCellActions from 'src/tables/TableCellActions';
import CardBodyTable from 'src/tables/CardBodyTable';
import { Activity, Eye, EyeOff, Trash, Zap } from 'react-feather';
import { TableStateOrder  } from 'src/tables/types';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import { TableContextProvider } from 'src/tables/TableContext';
import {useTableUpdateRowMutation} from 'src/hooks/useTableUtils';
import {GuardPermission} from 'src/guards/AuthGuards';
import {TableColumnCellProps} from 'src/tables/Table';
import useAuth from 'src/hooks/useAuth';

interface ProspectTriggerTableProps {
  className?: string;
  prospectId: string;
}

interface ProspectTriggerTableFilter {
  show: string;
}

type ProspectTriggerListed = (ProspectTriggerWithRelationsRow | ProspectTriggerAbstractRow) & {
  original_id: null | string;
};

export default function ProspectTriggerTable (props: ProspectTriggerTableProps) {
  const { prospectId, className } = props;

  const [filter, setFilter] = useState<ProspectTriggerTableFilter>(defaultFilter);
  const [order, setOrder] = useState<TableStateOrder>({date: 'desc'});

  const queryKey = [`/prospect_trigger/prospect/${prospectId}`, {params: {...filter, order}}];
  const query = useQuery<{prospectTriggers: ProspectTriggerListed[]}, Error>({
    queryKey,
    placeholderData: keepPreviousData,
  });

  const tableUpdateRowMutation = useTableUpdateRowMutation({
    queryKey,
    subKey: 'prospectTriggers',
    onSuccess: () => {
      query.refetch();
    },
  });

  const rows = (query.data?.prospectTriggers ?? []).map(row => {
    const id = row.id ?? row.work_trigger_template_id ;
    return {
      ...row,
      original_id: row.id,
      id,
    };
  });

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="bg-light">
        <CardTitleIcon
          title="Händelsetriggers"
          Icon={<Activity size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <TableContextProvider updateRowMutation={tableUpdateRowMutation}>
        <CardBodyTable
          params={{prospectId}}
          striped
          order={order}
          columnDefinitions={columnDefs}
          setOrder={setOrder}
          rows={rows}
          isFetched={query.isFetched}
          filterDefinitions={filterDefs}
          filter={filter}
          setFilter={setFilter}
        />
      </TableContextProvider>
    </Card>
  );
}

const defaultFilter = {
  show: 'concrete',
};

const filterDefs: any[] = [
  commonFilterDefs.select({id: 'show', title: 'Visa', required: true}, [
    {value: 'all', label: 'Alla möjliga triggers (mallar + konkreta)'},
    {value: 'concrete', label: 'Aktuella triggers (konkreta)'},
    {value: 'scheduled', label: 'Schemalagda triggers'},
    {value: 'executed', label: 'Utförda triggers'},
  ]),
];

const columnDefs = [
  commonColumnDefs.workTriggerTemplateId({
    id: 'work_trigger_template_id',
    cellProps: {objKey: 'WorkTriggerTemplate'},
    title: 'Mall-ID',
  }),
  commonColumnDefs.basic({
    id: 'WorkTriggerTemplate.description',
    title: 'Beskrivning',
  }),
  commonColumnDefs.basic({
    id: 'status',
    title: 'Status',
    Cell: TableCells.ProspectTriggerStatusTableCell,
  }),
  commonColumnDefs.datetime({
    id: 'scheduled_at',
    title: 'Schemalagd',
    sortable: true,
    cellProps: {emptyValue: '-'},
  }),
  commonColumnDefs.workId({
    id: 'work_id',
    cellProps: {objKey: 'Work', idKey: 'work_id', value: '-'},
    title: 'Jobb-ID',
  }),
  commonColumnDefs.actions({}, [
    ProspectTriggerMaskAction,
    ProspectTriggerUnmaskAction,
    props => (
      <GuardPermission permission="prospect_trigger_execute_run">
        {props.row.original_id && (
          <TableCellActions.ExecuteButton
            {...props}
            title="Kör triggern"
            mutateVars={{
              rowId: String(props.row.id),
              url: `/prospect_trigger/${props.row.id}/run`,
              method: 'POST',
              onSuccessNotyf: {type: 'default', message: 'Triggern har körts'},
            }}
          >
            <span><Zap size={14} /></span>
          </TableCellActions.ExecuteButton>
        )}
      </GuardPermission>
    ),
    props => (
      <GuardPermission permission="prospect_trigger_delete">
        {props.row.original_id && (
          <TableCellActions.ExecuteModalConfirmButton
            {...props}
            title="Radera triggern"
            variant="outline-danger"
            modalProps={{message: 'Är du säker på att du vill radera triggern?'}}
            mutateVars={{
              rowId: String(props.row.id),
              url: `/prospect_trigger/${props.row.id}`,
              deletion: true,
              method: 'DELETE',
              onSuccessNotyf: {type: 'warning', message: 'Triggern har raderats'},
            }}
          >
            <span><Trash size={14} /></span>
          </TableCellActions.ExecuteModalConfirmButton>
        )}
      </GuardPermission>
    ),
    // TableCellActions.InspectRow,
  ]),
];

function ProspectTriggerMaskAction (props: TableColumnCellProps) {
  const { row:trigger } = props;

  const auth = useAuth();

  const triggerId = trigger.original_id;
  const templateId = trigger.work_trigger_template_id;
  if (!triggerId && !templateId) return null;
  const data = triggerId ? {prospect_trigger_id: triggerId} : {work_trigger_template_id: templateId};

  const isAdmin = auth.hasAnyRole('admin');
  const canMaskPerm = auth.hasAnyPermissions('prospect_trigger_by_prospect_mask');
  const isMaskable = trigger?.WorkTriggerTemplate?.is_maskable === true;

  const canMask = (isAdmin || (canMaskPerm && isMaskable)) && (!trigger.status || ['new', 'scheduled'].includes(trigger.status));
  if (!canMask) {
    return null;
  }

  const confirmMsg = triggerId ? 'Är du säker på att du vill maskera denna schemalagda trigger? Ångrar du dig så behöver den omtriggas för att bli schemalagd igen.' : 'Är du säker på att du vill maskera denna triggermall?';

  return (
    <TableCellActions.ExecuteModalConfirmButton
      {...props}
      title="Maskera triggern"
      modalProps={{message: confirmMsg}}
      mutateVars={{
        rowId: String(trigger.id),
        url: `/prospect_trigger/prospect/${trigger.prospect_id}/mask`,
        data,
        method: 'POST',
        onSuccessNotyf: {type: 'default', message: 'Triggern har maskerats'},
      }}
    >
      <EyeOff size={14} /> Maskera
    </TableCellActions.ExecuteModalConfirmButton>
  );
}

function ProspectTriggerUnmaskAction (props: TableColumnCellProps) {
  const { row:trigger } = props;

  const auth = useAuth();

  const triggerId = trigger.original_id;
  const templateId = trigger.work_trigger_template_id;
  if (!triggerId && !templateId) return null;

  const isAdmin = auth.hasAnyRole('admin');
  const canMaskPerm = auth.hasAnyPermissions('prospect_trigger_by_prospect_mask');
  const isMaskable = trigger?.WorkTriggerTemplate?.is_maskable === true;
  const canUnmask = (isAdmin || (canMaskPerm && isMaskable)) && trigger.status === 'masked';
  if (!canUnmask) {
    return null;
  }

  return (
    <TableCellActions.ExecuteButton
      {...props}
      title="Avmaskera triggern"
      mutateVars={{
        deletion: true,
        rowId: String(trigger.id),
        url: `/prospect_trigger/prospect/${trigger.prospect_id}/${triggerId}`,
        method: 'DELETE',
        onSuccessNotyf: {type: 'default', message: 'Triggern har avmaskerats'},
      }}
    >
      <Eye size={14} /> Avmaskera
    </TableCellActions.ExecuteButton>
  );
}
