import React from 'react';
import emailFilter from 'shared/filter/email';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as commonEmailTableColumnDefs from 'src/email/commonEmailTableColumnDefinitions';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import { useEventModelSubscribeToQuery } from 'src/hooks/useEventModels';
import TablePage from 'src/tables/TablePage';

export default function EmailTablePage () {
  useEventModelSubscribeToQuery({queryKey: ['EmailTable']}, 'email', {
    subKey: 'emails',
  });

  return (
    <TablePage
      title="E-post"
      pageTitle="E-posttabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/email"
      baseQueryUrl="/email/table"
      baseQueryKey="EmailTable"
      queryResultRowsKey="emails"
      batchDefinition={batchDefinition}
    />
  );
}

const batchDefinition = {
  url: '/email/batch',
  permission: 'email_batch',
  actions: [
    {value: 'set_sent', label: 'Markera som "Skickat"'},
    {value: 'requeue', label: 'Köa för skickning'},
    {value: 'delete', label: 'Radera'},
  ],
};

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.emailStatus({id: 'status'}),
  commonFilterDefs.uuid({id: 'id'}),
  commonFilterDefs.string({id: 'from_address'}),
  commonFilterDefs.string({id: 'to_address'}),
  commonFilterDefs.applicationId({id: 'application_id'}),
  commonFilterDefs.uuid({id: 'customer_cloud_insurance_id'}),
  commonFilterDefs.uuid({id: 'customer_jaycom_insurance_id'}),
  commonFilterDefs.uuid({id: 'template_id'}),
  commonFilterDefs.userId({id: 'user_id'}),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'status_at'}),
  commonFilterDefs.dateRelative({id: 'status_at_relative'}),
  commonFilterDefs.dateRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
].map(filterDefinition => ({
  title: emailFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.emailId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'status_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.customerCloudInsuranceId({
      id: 'customer_cloud_insurance_id',
      cellProps: {idKey: 'customer_cloud_insurance_id'},
    }),
    commonColumnDefs.customerJaycomInsuranceId({
      id: 'customer_jaycom_insurance_id',
      cellProps: {idKey: 'customer_jaycom_insurance_id'},
    }),
    commonColumnDefs.cell('application_id', TableCells.ApplicationTableCell, {idKey: 'application_id'}),
    commonColumnDefs.cell('complaint_id', TableCells.ComplaintTableCell, {idKey: 'complaint_id'}),
    commonColumnDefs.cell('user_id', TableCells.UserTableCell, {idKey: 'user_id'}),
    commonColumnDefs.cell('template_id', TableCells.TemplateTableCell, {idKey: 'template_id'}),
    commonColumnDefs.foreignId('foreign_id', {
      complaint_id: {
        Cell: TableCells.ComplaintTableCell,
        cellProps: {idKey: 'complaint_id'},
      },
      customer_cloud_insurance_id: {
        Cell: TableCells.CustomerCloudInsuranceTableCell,
        cellProps: {idKey: 'customer_cloud_insurance_id'},
      },
      customer_jaycom_insurance_id: {
        Cell: TableCells.CustomerJaycomInsuranceTableCell,
        cellProps: {idKey: 'customer_jaycom_insurance_id'},
      },
      application_id: {
        Cell: TableCells.ApplicationTableCell,
        cellProps: {idKey: 'application_id'},
      },
    }),
    commonColumnDefs.cell('from'),
    commonColumnDefs.cell('to'),
    commonColumnDefs.cell('subject'),
    commonColumnDefs.cell('status', TableCells.EmailStatusTableCell),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = emailFilter.indexColumnName(id);
    const cellValue = email => emailFilter.indexField(email, id);
    return {
      ...columnDefinition,
      Cell: columnDefinition.Cell ?? (({value}) => <>{value}</>),
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonEmailTableColumnDefs.actions(),
];
