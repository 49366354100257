import React from 'react';
import { Table } from 'react-bootstrap';
import { CustomerRow } from 'shared/types/customer';
import * as format from 'shared/format';
import DateTime from 'src/formatters/DateTime';
import BooleanFmt from 'src/formatters/Boolean';

interface PropertiesTableProps {
  customer: CustomerRow;
}

export function PropertiesTable (props: PropertiesTableProps) {
  const { customer } = props;
  return (
    <div className="position-relative overflow-auto">
      <Table size="sm" className="mb-0">
        <tbody>
          <tr>
            <th>Personnummer</th>
            <td>{format.ssn('long-dash', customer.ssn)}</td>
          </tr>
          <tr>
            <th>Ålder/Födelsedag</th>
            <td>
              {format.ssn('age', customer.ssn)} år
              / <DateTime value={format.ssn('birthdate', customer.ssn)} format="YYYY-MM-DD" />
            </td>
          </tr>
          <tr>
            <th>Skapad</th>
            <td><DateTime value={customer.created_at} /></td>
          </tr>
          <tr>
            <th>Portalkund</th>
            <td><BooleanFmt value={customer.portal_is_from} colored /></td>
          </tr>
          <tr>
            <th>Senaste inloggning i portal</th>
            <td><DateTime value={customer.portal_last_login_at} defaultValue="Aldrig" /></td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
