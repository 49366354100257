import React from 'react';
import smsFilter from 'shared/filter/sms';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';

export default function SmsTablePage () {
  return (
    <TablePage
      title="SMS"
      pageTitle="SMS-tabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/sms"
      baseQueryUrl="/sms/table"
      baseQueryKey="SmsTable"
      queryResultRowsKey="sms"
      batchDefinition={batchDefinition}
    />
  );
}

const batchDefinition = {
  url: '/sms/batch',
  permission: 'sms_batch',
  actions: [
    {value: 'set_status_delivered', label: 'Markera som "Levererad"'},
    {value: 'send', label: 'Köa för skickning'},
    {value: 'delete', label: 'Radera'},
  ],
};

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.smsStatus({id: 'status'}),
  commonFilterDefs.uuid({id: 'id'}),
  commonFilterDefs.string({id: 'gsm'}),
  commonFilterDefs.applicationId({id: 'application_id'}),
  commonFilterDefs.uuid({id: 'customer_cloud_insurance_id'}),
  commonFilterDefs.uuid({id: 'customer_jaycom_insurance_id'}),
  commonFilterDefs.uuid({id: 'template_id'}),
  commonFilterDefs.userId({id: 'user_id'}),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateRange({id: 'status_at'}),
  commonFilterDefs.dateRelative({id: 'status_at_relative'}),
  commonFilterDefs.dateRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
].map(filterDefinition => ({
  title: smsFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.smsId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'status_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.cell('customer_cloud_insurance_id', TableCells.CustomerCloudInsuranceTableCell, {idKey: 'customer_cloud_insurance_id'}),
    commonColumnDefs.cell('customer_jaycom_insurance_id', TableCells.CustomerJaycomInsuranceTableCell, {idKey: 'customer_cloud_insurance_id'}),
    commonColumnDefs.cell('application_id', TableCells.ApplicationTableCell, {idKey: 'application_id'}),
    commonColumnDefs.cell('user_id', TableCells.UserTableCell, {idKey: 'user_id'}),
    commonColumnDefs.cell('template_id', TableCells.TemplateTableCell, {idKey: 'template_id'}),
    commonColumnDefs.foreignId('foreign_id', {
      customer_jaycom_insurance_id: {
        Cell: TableCells.CustomerJaycomInsuranceTableCell,
        cellProps: {idKey: 'customer_jaycom_insurance_id'},
      },
      customer_cloud_insurance_id: {
        Cell: TableCells.CustomerCloudInsuranceTableCell,
        cellProps: {idKey: 'customer_cloud_insurance_id'},
      },
      application_id: {
        Cell: TableCells.ApplicationTableCell,
        cellProps: {idKey: 'application_id'},
      },
    }),
    commonColumnDefs.basic({id: 'gsm'}),
    commonColumnDefs.basic({
      id: 'status',
      Cell: TableCells.SmsStatusTableCell,
    }),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = smsFilter.indexColumnName(id);
    const cellValue = sms => smsFilter.indexField(sms, id);
    return {
      ...columnDefinition,
      Cell: columnDefinition.Cell ?? (({value}) => <>{value}</>),
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRow,
  ]),
];
