import React from 'react';
import {useField} from 'formik';
import * as FormikFormControls from 'src/form/FormikFormControls';

export function Gsm (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="GSM-nummer"
      type="tel"
      inputMode="numeric"
      pattern="^\d+$"
      required
      {...props}
      name="gsm"
    />
  );
}

export function Body (props: Partial<FormikFormControls.FormGroupControlProps>) {
  const [field] = useField('body');
  const messageCount = Math.ceil(field.value.length / 160);
  const charsLeftInMessage = 160 - (field.value.length % 160);
  return (
    <FormikFormControls.FormGroupControl
      label="Text"
      as="textarea"
      rows={10}
      minLength={0}
      maxLength={10000}
      required
      explainer={`Antal SMS: ${messageCount} (${charsLeftInMessage})`}
      {...props}
      name="body"
    />
  );
}
