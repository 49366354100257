import React from 'react';
import Anchor from  'src/anchors/Anchor';
import { Name } from 'src/user/UserFormatters';
import QuicklookAnchor, { QuicklookAnchorProps } from 'src/anchors/QuicklookAnchor';
import UserQuicklook from 'src/user/UserQuicklook';
import { UserRow } from 'shared/types/user';

interface UserAnchorProps extends Partial<QuicklookAnchorProps> {
  user: Pick<UserRow, 'id'>;
}

export default function UserAnchor (props: UserAnchorProps) {
  const { user, ...restOfProps } = props;
  return (
    <QuicklookAnchor
      {...restOfProps}
      url={`/user/${user.id}/form`}
      title={<Name value={user as any} />}
      name="Användare"
      objectId={user.id}
    >
      <UserQuicklook id={user.id} />
    </QuicklookAnchor>
  );
}

export function UserPlainAnchor (props: UserAnchorProps) {
  const { user, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/user/${user.id}/form`}
      name="Användaren"
    >
      <Name value={user as any} />
    </Anchor>
  );
}
