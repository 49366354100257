import React, { useState } from 'react';

import {
  SIDEBAR_POSITION,
  SIDEBAR_BEHAVIOR,
  SidebarBehavior,
  SidebarPosition,
} from 'src/constants';
import useSettingsState from 'src/hooks/useSettingsState';

interface SidebarContextState {
  isOpen: boolean;
  position: SidebarPosition;
  behavior: SidebarBehavior;
  setIsOpen: (value: boolean) => void;
  setPosition: (value: SidebarPosition) => void;
  setBehavior: (value: SidebarBehavior) => void;
}

const initialState = {
  isOpen: true,
  position: SIDEBAR_POSITION.LEFT,
  behavior: SIDEBAR_BEHAVIOR.STICKY,
  setPosition: () => {},
  setBehavior: () => {},
  setIsOpen: () => {},
};

const SidebarContext = React.createContext<SidebarContextState>(initialState);

function SidebarProvider ({ children }) {
  const [isOpen, setIsOpen] = useState(true);
  const [position, setPosition] = useSettingsState(
    'sidebarPosition',
    SIDEBAR_POSITION.LEFT,
  );
  const [behavior, setBehavior] = useSettingsState(
    'sidebarBehavior',
    SIDEBAR_BEHAVIOR.COMPACT,
  );

  const value = {
    isOpen,
    setIsOpen,
    position,
    setPosition,
    behavior,
    setBehavior,
  };

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  );
}

export { SidebarProvider, SidebarContext };
