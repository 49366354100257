import React from 'react';
import TableBatchActionForm from 'src/tables/TableBatchActionForm';
import TableExport from 'src/tables/TableExport';
import TablePaginationForm from 'src/tables/TablePaginationForm';
import TablePageSizeForm from 'src/tables/TablePageSizeForm';
import { GuardPermission } from 'src/guards/AuthGuards';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import {
  TableState,
  TableBatchDefinition,
  TableSelectedRows,
  TableBatchActionMutationDate,
} from 'src/tables/types';

interface TableControlPanelFootProps {
  state: TableState;
  count: number | null;
  updateState: (newState: TableState) => void;
  selectedRows: TableSelectedRows;
  exportUrl?: string;
  batchDefinition?: TableBatchDefinition;
  batchActionMutateAsync: UseMutateAsyncFunction<unknown, unknown, TableBatchActionMutationDate>;
}

const TableControlPanelFoot: React.FC<TableControlPanelFootProps> = React.memo(function TableControlPanelFoot (props: TableControlPanelFootProps) {
  const {
    state,
    count,
    updateState,
    selectedRows,
    exportUrl,
    batchActionMutateAsync,
    batchDefinition,
  } = props;

  return (
    <div className="d-flex p-2 justify-content-center justify-content-md-between navbar sticky-bottom navbar-bg border-top flex-wrap">
      {batchDefinition ? (
        <GuardPermission permission={batchDefinition.permission}>
          <TableBatchActionForm
            className="m-2"
            selectedRows={selectedRows}
            batchDefinition={batchDefinition}
            batchActionMutateAsync={batchActionMutateAsync}
          />
        </GuardPermission>
      ) : null}
      <TableExport
        state={state}
        updateState={updateState}
        exportUrl={exportUrl}
        count={count}
      />
      <TablePageSizeForm
        className="m-2"
        state={state}
        updateState={updateState}
      />
      <TablePaginationForm
        className="m-2"
        state={state}
        count={count}
        updateState={updateState}
      />
    </div>
  );
});
export default TableControlPanelFoot;
