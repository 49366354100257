import React from 'react';
import classNames from 'classnames';
import { Badge, BadgeProps } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface AnchorProps extends Partial<BadgeProps> {
  name?: string;
  url: string;
}

export default function Anchor (props: AnchorProps) {
  const { children, name = 'objektet', url, className, ...restOfProps } = props;
  return (
    <Badge
      bg="white"
      className={classNames(className, 'd-inline-flex align-items-center border p-0')}
      {...restOfProps}
    >
      <span className="p-1 d-inline-block">
        <Link to={url} title={`Öppna ${name}`}>
          {children}
        </Link>
      </span>
    </Badge>
  );
}
