import React, { createContext, useContext, PropsWithChildren } from 'react';
import {UseMutationResult} from '@tanstack/react-query';
import { TableRow, TableUpdateRowMutationVars } from 'src/tables/types';

interface TableContextValue {
  updateRowMutation?: UseMutationResult<TableRow, Error, TableUpdateRowMutationVars>;
}

interface TableContextProviderProps extends TableContextValue, PropsWithChildren {

}

export const TableContext = createContext<TableContextValue | undefined>(undefined);

export function TableContextProvider (props: TableContextProviderProps) {
  const { children, ...rest } = props;
  return (
    <TableContext.Provider value={rest}>
      {children}
    </TableContext.Provider>
  );
}

export function useTableContext (): TableContextValue {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error('useTableContext must be used within a TableContextProvider');
  }
  return context;
}

export function useMaybeTableContext (): TableContextValue | undefined {
  return useContext(TableContext);
}
