import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import { FileRow } from 'shared/types/files';

interface NameProps {
  value?: FileRow;
}

export function Name (props: NameProps) {
  const { value:file } = props;
  if (!isObjectLike(file)) return null;
  const { id, name } = file as FileRow;
  if (name) return <>{name}</>;
  return (
    <IdShort value={id} />
  );
}
