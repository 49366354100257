import React, {useState} from 'react';
import { Button, ButtonProps, Modal } from 'react-bootstrap';
import ModalStateContext from 'src/contexts/ModalStateContext';

interface SimpleModalOpeningButtonProps extends ButtonProps {
  label: React.ReactNode;
  modalTitle?: React.ReactNode;
  children: React.ReactNode;
  modalBodyClassName?: string;
}

const SimpleModalOpeningButton: React.FC<SimpleModalOpeningButtonProps> = React.memo(function ModalOpeningButton (props: SimpleModalOpeningButtonProps) {
  const {
    children,
    label,
    modalTitle,
    modalBodyClassName = 'm-2',
    ...buttonProps
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  const onHide = () => {
    setShowModal(false);
  };

  const onShow = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button {...buttonProps} onClick={onShow}>
        {label}
      </Button>
      <Modal show={showModal} onHide={onHide}>
        {modalTitle && (
          <Modal.Header>
            <Modal.Title>
              {modalTitle}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className={modalBodyClassName}>
          <ModalStateContext.Provider value={{onHide}}>
            {children}
          </ModalStateContext.Provider>
        </Modal.Body>
      </Modal>
    </>
  );
});
export default SimpleModalOpeningButton;
