import React from 'react';

interface TemplateModalBodyProps {
  id: string;
}

export default function TemplateModalBody (props: TemplateModalBodyProps) {
  return (
    <div>template modal body here</div>
  );
}

