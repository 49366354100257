import React from 'react';
import { Card } from 'react-bootstrap';
import ExternalLinkAnchor from 'src/anchors/ExternalLinkAnchor';
import ObjectTypeAnchor from 'src/anchors/ObjectTypeAnchor';

type AssociatedObjectExternalLink = {
  url: string;
  label: string;
}

// type AssociatedObject = ObjectTypeAnchorProps & {label: string};

interface AssociatedObjectListProps {
  associatedObjects?: any[]; // TODO fix
  externalLinks?: AssociatedObjectExternalLink[];
}


function AssociatedObjectList (props: AssociatedObjectListProps) {
  const { associatedObjects = [], externalLinks = [] } = props;

  const hasAnything = associatedObjects.length > 0 || externalLinks.length > 0;
  if (!hasAnything) return null;

  return (
    <Card.Body className="py-2 px-3 border-bottom d-flex justify-content-center justify-content-md-start align-items-center gap-2 flex-wrap small">
      {associatedObjects.map((object, index) => (
        (object ? (
          <div key={index}>
            <strong>{object.label}: </strong>
            <ObjectTypeAnchor type={object.type} value={object.value} />
          </div>
        ) : null)
      ))}
      {externalLinks.map((link, index) => (
        <ExternalLinkAnchor key={index} href={link.url} className="border rounded px-1">
          {link.label}
        </ExternalLinkAnchor>
      ))}
    </Card.Body>
  );
}
export default React.memo(AssociatedObjectList);
