import React from 'react';
import { Helmet } from 'react-helmet-async';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import * as IconButtons from 'src/buttons/IconButtons';
import useWebsocketModelUpdates from 'src/hooks/useWebsocketModelUpdates';
import useQueryClientUtils from 'src/hooks/useQueryClientUtils';
import Pluralizer from 'src/formatters/Pluralizer';
import * as bankFilter from 'shared/filter/bank';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as TableCells from 'src/tables/TableCells';
import { ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { DollarSign } from 'react-feather';
import {
  Container,
  Card,
} from 'react-bootstrap';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { BankRow } from 'shared/types/bank';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import { TableStateOrder, TableStateFilterMap } from 'src/tables/types';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';

interface BankRowsTablePageFilter extends TableStateFilterMap {
  is_active?: boolean | null;
  searchTerm?: string;
}

const defaultFilter = {
  is_active: true,
  searchTerm: '',
};

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.boolean({
    id: 'is_active',
    title: 'Status',
    filterProps: {labels: {false: 'Inaktiv', true: 'Aktiv'}},
  }),
  commonFilterDefs.string({
    id: 'searchTerm',
    title: 'Sök',
  }),
];

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.bankId({
    id: 'id',
    cellProps: {rowAsObject:  true},
    title: 'ID',
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'adapter',
    title: 'Adapter',
  }),
  commonColumnDefs.basic({
    id: 'create_process_roles',
    title: 'Förhindra ny process',
    Cell: ({row}) => <>{bankFilter.createProcessRoles(row.settings.create_process_disabled)}</>,
  }),
  commonColumnDefs.basic({
    id: 'submit_delay',
    title: 'Fördröjd inskickning',
    Cell: ({row}) => <>{bankFilter.submitDelay(row.settings)}</>,
  }),
  commonColumnDefs.basic({
    id: 'expires_process_days',
    title: 'Automatisk stängning',
    Cell: ({row}) => <>{bankFilter.expireProcessDays(row.settings)}</>,
  }),
  commonColumnDefs.basic({
    id: 'is_active',
    Cell: TableCells.BankStatusTableCell,
    title: 'Status',
  }),
];

export default function BanksTablePage () {
  const [filter, setFilter] = React.useState<BankRowsTablePageFilter>(defaultFilter);
  const [order, setOrder] = React.useState<TableStateOrder>({id: 'asc'});

  const queryClientUtils = useQueryClientUtils();

  const query = useQuery<BankRow[], Error>({
    queryKey: ['/bank/search', {params: {...filter, order}}],
    placeholderData: keepPreviousData,
    meta: {
      onSuccess: data => {
        const banks = (data || []) as BankRow[];
        const ids = banks.map(bank => bank.id);
        wsModelUpdates.setIds(ids);
      },
    },
  });

  const wsModelUpdates = useWebsocketModelUpdates<BankRow>('bank', function (id, update) {
    const row = {...update, id};
    queryClientUtils.applyUpdates({queryKey: ['/bank/search']}, [row]);
  });

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Långivare" />
        <Container fluid className="p-0">
          <Card className="border mb-1">
            <Card.Header>
              <CardTitleIcon
                title="Långivare"
                Icon={<DollarSign size={16} />}
                spinning={query.isLoading || query.isRefetching}
              >
                <IconButtons.CreateLinkButton to="/bank/create" />
                <IconButtons.RefreshObjectButton
                  disabled={query.isRefetching}
                  onClick={() => query.refetch()}
                />
              </CardTitleIcon>
              <Card.Subtitle className="mt-1">
                <small>
                  <Pluralizer
                    count={query.data?.length ?? 0}
                    zero="Inga långivare"
                    one="Visar 1 långivare"
                    otherwise="Visar %% långivare"
                  />
                </small>
              </Card.Subtitle>
            </Card.Header>
            <ErrorAlertCardBody error={query.error} className="border-top p-3" />
            <CardBodyTable
              filter={filter}
              order={order}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              setFilter={setFilter}
              setOrder={setOrder}
              rows={query?.data || []}
              isFetched={query.isFetched}
            />
          </Card>
        </Container>
      </Content>
    </Main>
  );
}
