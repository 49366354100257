import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import { Table } from 'react-bootstrap';
import * as customerConsentFilters from 'shared/filter/customer_consent';
import Boolean from 'src/formatters/Boolean';
import DateTime from 'src/formatters/DateTime';
import { CustomerConsentRow, CustomerConsentRowWithRelations } from 'shared/types/customer_consent';
import { nl2br } from 'shared/format';

interface ConsentFormatterProps {
  value: CustomerConsentRow;
}

export function Name (props: ConsentFormatterProps) {
  const { value:consent } = props;
  if (!isObjectLike(consent)) return null;
  const id = consent.id;
  return (
    <IdShort value={id} />
  );
}

interface ConsentDumpProps {
  consent: CustomerConsentRowWithRelations;
}

export function ConsentDump (props: ConsentDumpProps) {
  const { consent } = props;
  const { User } = consent;
  return (
    <div className="m-2">
      <Table bordered>
        <tbody>
          <tr>
            <th>Kundnamn</th>
            <td>{consent.Customer.name}</td>
          </tr>
          <tr>
            <th>Personnummer</th>
            <td>{consent.Customer.ssn}</td>
          </tr>
          <tr>
            <th>Samtycke för</th>
            <td>{consent.subject}</td>
          </tr>
          <tr>
            <th>Registrerat</th>
            <td><DateTime value={consent.created_at} format="YYYY-MM-DD HH:mm:ssZ" /></td>
          </tr>
          {consent.User && (
            <>
              <tr>
                <th>Systemanvändare</th>
                <td>{consent.User.name} <small>({consent.user_id})</small></td>
              </tr>
            </>
          )}
          <tr>
            <th>Samtyckesmetod</th>
            <td>{customerConsentFilters.method(consent.method)}</td>
          </tr>
          {consent.method_parts[consent.method] && (
            <>
              <tr>
                <th colSpan={2}>Metodspecifika beståndsdelar</th>
              </tr>
              <tr>
                <td colSpan={2}>
                  <CustomerMethodPart
                    method={consent.method as any}
                    part={consent.method_parts[consent.method]}
                    user={User}
                  />
                </td>
              </tr>
            </>
          )}
          {consent.consent_parts.length > 0 && (
            <>
              <tr>
                <th colSpan={2}>Samtyckets beståndsdelar</th>
              </tr>
              {consent.consent_parts.map((part, index) => (
                <tr key={index}>
                  <td colSpan={2}>
                    <CustomerConsentPart part={part} index={index} user={User} />
                  </td>
                </tr>
              ))}
            </>
          )}
          <tr>
            <th>Metadata</th>
            <td>
              <CustomerConsentMetadata metadata={consent.metadata} />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

interface CustomerMethodPartProps {
  method: CustomerConsentRowWithRelations['method_parts'];
  part: any;
  user: CustomerConsentRowWithRelations['User'];
}

function CustomerMethodPart (props: CustomerMethodPartProps) {
  const { method, part, user } = props;
  return (
    <>
      <div>
        {customerConsentFilters.method(method)}
        {' '}{part.subject && <span>- {part.subject}</span>}
      </div>
      <div className="small">
        {part.user_id && <span><strong>Utfört av: </strong> <UserNameIfSame userId={part.user_id} user={user} />{' - '}</span>}
        {part.created_at && <DateTime value={part.created_at} format="YYYY-MM-DD HH:mm:ssZ" />}
      </div>
      {part.metadata && (
        <>
          <h5 className="mt-2 mb-1">Metadata</h5>
          <CustomerConsentMetadata metadata={part.metadata} showAll />
        </>
      )}
    </>
  );
}

interface CustomerConsentPartProps {
  index: number;
  part: any;
  user: CustomerConsentRowWithRelations['User'];
}

function CustomerConsentPart (props: CustomerConsentPartProps) {
  const { index, part, user } = props;
  return (
    <>
      <div>{index + 1}. {part.subject}</div>
      <div className="small">
        {part.user_id && <span><strong>Intygat av: </strong> <UserNameIfSame userId={part.user_id} user={user} />{' - '}</span>}
        {part.created_at && <DateTime value={part.created_at} format="YYYY-MM-DD HH:mm:ssZ" />}
      </div>
      {part.metadata && (
        <>
          <h5 className="mt-2 mb-1">Metadata</h5>
          <CustomerConsentMetadata metadata={part.metadata} showAll />
        </>
      )}
    </>
  );
}

interface CustomerConsentMetadataProps {
  showAll?: boolean;
  metadata?: CustomerConsentRowWithRelations['metadata'];
}

function CustomerConsentMetadata (props: CustomerConsentMetadataProps) {
  const { metadata, showAll = false } = props;
  if (!metadata) return null;
  const { done, ip, ua, url, personalNumber, orderRef, uhi, signData, ...restOfMetadata } = metadata;
  return (
    <dl className="mb-0">
      {typeof done === 'boolean' && (
        <>
          <dt>Utfört</dt>
          <dd><Boolean value={done} /></dd>
        </>
      )}
      {url && (
        <>
          <dt>URL</dt>
          <dd>{url}</dd>
        </>
      )}
      {ip && (
        <>
          <dt>IP</dt>
          <dd>{ip}</dd>
        </>
      )}
      {ua && (
        <>
          <dt>User-Agent</dt>
          <dd>{ua}</dd>
        </>
      )}
      {personalNumber && (
        <>
          <dt>BankID personnummer</dt>
          <dd>{personalNumber}</dd>
        </>
      )}
      {orderRef && (
        <>
          <dt>BankID Order-ID</dt>
          <dd>{orderRef}</dd>
        </>
      )}
      {uhi && (
        <>
          <dt>BankID Hårdvaru-ID</dt>
          <dd>{uhi}</dd>
        </>
      )}
      {signData && (
        <>
          <dt>Kunden har signerat följande text med BankID</dt>
          {signData.userVisibleData ? (
            <dd  dangerouslySetInnerHTML={{__html: nl2br(signData.userVisibleData)}} >
            </dd>
          ) : (
            <dd>
              {JSON.stringify(signData, null, 2)}
            </dd>
          )}
        </>
      )}
      {Object.keys(restOfMetadata).length > 0 && showAll && (
        <>
          {Object.keys(restOfMetadata).map(key => (
            <React.Fragment key={key}>
              <dt>{key}</dt>
              <dd>{JSON.stringify(restOfMetadata[key], null, 2)}</dd>
            </React.Fragment>
          ))}
        </>
      )}
    </dl>
  );
}

interface UserNameIfSameProps {
  userId: string;
  user?: CustomerConsentRowWithRelations['User'];
}

function UserNameIfSame (props: UserNameIfSameProps) {
  const { userId, user } = props;
  if (!user || user.id !== userId) return <>{userId}</>;
  return <>{user.name}</>;
}
