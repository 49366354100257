import React from 'react';
import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import { numberfmt, currency, percentage, isFiniteNumber } from 'src/numbers';
import IndeterminateCheckbox from 'src/inputs/IndeterminateCheckbox';
import DateTime from 'src/formatters/DateTime';
import Interval from 'src/formatters/Interval';
import FileAnchor from 'src/file/FileAnchor';
import BankAnchor from 'src/bank/BankAnchor';
import UserAnchor from 'src/user/UserAnchor';
import GroupAnchor from 'src/group/GroupAnchor';
import ApplicationAnchor from 'src/application/ApplicationAnchor';
import TaskAnchor from 'src/task/TaskAnchor';
import BankProcessAnchor from 'src/bankProcess/BankProcessAnchor';
import BooleanIcon from 'src/formatters/BooleanIcon';
import IdShort from 'src/formatters/IdShort';
import CustomerConsentAnchor from 'src/customerConsent/CustomerConsentAnchor';
import CustomerCloudInsuranceAnchor from 'src/cloudInsurance/CloudInsuranceAnchor';
import CustomerJaycomInsuranceAnchor from 'src/jaycomInsurance/JaycomInsuranceAnchor';
import WorkTriggerTemplateAnchor from 'src/workTriggerTemplate/WorkTriggerTemplateAnchor';
import CustomerAnchor from 'src/customer/CustomerAnchor';
import ProspectAnchor from 'src/prospect/ProspectAnchor';
import PepQueryAnchor from 'src/pepQuery/PepQueryAnchor';
import WorkAnchor from 'src/work/WorkAnchor';
import WorkScheduledAnchor from 'src/workScheduled/WorkScheduledAnchor';
import UcAnchor from 'src/uc/UcAnchor';
import SmsAnchor from 'src/sms/SmsAnchor';
import EmailAnchor from 'src/email/EmailAnchor';
import LetterAnchor from 'src/letter/LetterAnchor';
import TemplateAnchor from 'src/template/TemplateAnchor';
import ComplaintAnchor from 'src/complaint/ComplaintAnchor';
import FormControlSpinner from 'src/spinners/FormControlSpinner';
import CountryFlag from 'src/formatters/CountryFlag';
import {useTableContext} from 'src/tables/TableContext';
import useFocusControl from 'src/hooks/useFocusControl';
import {
  Badge,
  OverlayTrigger,
  Tooltip,
  Form,
} from 'react-bootstrap';
import {
  AcceptedProcessTitle,
  StatusLabel as ApplicationStatusLabel,
} from 'src/application/ApplicationFormatters';
import { TemporalStatusLabel as TaskTemporalStatusLabel } from 'src/task/TaskFormatters';
import { StatusLabel as UcStatusLabel } from 'src/uc/UcFormatters';
import { StatusLabel as BankProcessStatusLabel } from 'src/bankProcess/BankProcessFormatters';
import { StatusLabel as BankStatusLabel } from 'src/bank/BankFormatters';
import { StatusLabel as WorkTriggerTemplateStatusLabel } from 'src/workTriggerTemplate/WorkTriggerTemplateFormatters';
import { StatusLabel as ApplicationTriggerStatusLabel } from 'src/applicationTrigger/ApplicationTriggerFormatters';
import { ResultLabel as ProspectResultLabel } from 'src/prospect/ProspectFormatters';
import { StatusLabel as ProspectTriggerStatusLabel } from 'src/prospectTrigger/ProspectTriggerFormatters';
import { StatusLabel as CustomerStatusLabel } from 'src/customer/CustomerFormatters';
import { StatusLabel as CustomerCloudInsuranceStatusLabel } from 'src/cloudInsurance/CloudInsuranceFormatters';
import { StatusLabel as CustomerJaycomInsuranceStatusLabel } from 'src/jaycomInsurance/JaycomInsuranceFormatters';
import { StatusLabel as ComplaintStatusLabel } from 'src/complaint/ComplaintFormatters';
import { StatusLabel as PepQueryStatusLabel } from 'src/pepQuery/PepQueryFormatters';
import { StatusLabel as SmsStatusLabel } from 'src/sms/SmsFormatters';
import { StatusLabel as EmailStatusLabel } from 'src/email/EmailFormatters';
import { StatusLabel as LetterStatusLabel } from 'src/letter/LetterFormatters';
import { StatusLabel as UserStatusLabel } from 'src/user/UserFormatters';
import { StatusLabel as WorkStatusLabel } from 'src/work/WorkFormatters';
import { StatusLabel as GroupStatusLabel } from 'src/group/GroupFormatters';
import { TableColumnCellProps, IColumnHeaderProps } from 'src/tables/Table';

export const BasicCell: React.FC<TableColumnCellProps> = React.memo(function BasicCell (props: TableColumnCellProps) {
  const { value } = props;
  return <>{value}</>;
});

export const GroupTableCell: React.FC<TableColumnCellProps> = React.memo(function GroupTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const group = rowToRelatedObject(props);
  if (group) {
    return <GroupAnchor group={group as any} />;
  }
  return <>{value}</>;
});

export const UserTableCell: React.FC<TableColumnCellProps> = React.memo(function UserTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const user = rowToRelatedObject(props);
  if (user) {
    return <UserAnchor user={user} />;
  }
  return <>{value}</>;
});

export const FileTableCell: React.FC<TableColumnCellProps> = React.memo(function FileTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const file = rowToRelatedObject(props);
  if (file) {
    return <FileAnchor file={file} />;
  }
  return <>{value}</>;
});

export const BankTableCell: React.FC<TableColumnCellProps> = React.memo(function BankTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const bank = rowToRelatedObject(props);
  if (bank) {
    return <BankAnchor bank={bank as any} />;
  }
  return <>{value}</>;
});

// cell for the column "accepted_bank_id" on an application
export const AcceptedBankTableCell: React.FC<TableColumnCellProps> = React.memo(function AcceptedBankTableCell (props: TableColumnCellProps) {
  return <BankTableCell {...props} idKey="accepted_bank_id" />;
});

// cell for the column "accepted_process_id" on an application
export const AcceptedBankProcessTableCell: React.FC<TableColumnCellProps> = React.memo(function AcceptedBankProcessTableCell (props: TableColumnCellProps) {
  const { value, row } = props;
  const { id, accepted_bank_id, accepted_process_id } = row;
  if (accepted_process_id) {
    const bankProcess = {id: accepted_process_id, application_id: id};
    const application = {id, accepted_bank_id, accepted_process_id};
    return (
      <BankProcessAnchor
        bankProcess={bankProcess as any}
        title={<AcceptedProcessTitle value={application as any} />}
      />
    );
  }
  return <>{value}</>;
});

export const BankProcessTableCell: React.FC<TableColumnCellProps> = React.memo(function BankProcessTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const bankProcess = rowToRelatedObject(props);
  if (bankProcess) {
    return <BankProcessAnchor bankProcess={bankProcess as any} />;
  }
  return <>{value}</>;
});

export const TaskTableCell: React.FC<TableColumnCellProps> = React.memo(function TaskTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const task = rowToRelatedObject(props);
  if (task) {
    return <TaskAnchor task={task} />;
  }
  return <>{value}</>;
});

// TODO add badge with search icon, that can set the filter to the cell value
export const WrapSearchTableCell: React.FC<TableColumnCellProps> = React.memo(function WrapSearchTableCell (props: TableColumnCellProps) {
  const InnerCell = props.InnerCell as React.ComponentType<TableColumnCellProps>;
  return (
    <>
      <InnerCell {...props} InnerCell={undefined} />
    </>
  );
});

export const WorkTriggerTemplateTableCell: React.FC<TableColumnCellProps> = React.memo(function WorkTriggerTemplateTableCell (props: TableColumnCellProps) {
  const { value, onlyId } = props;
  const template = rowToRelatedObject(props);
  if (template) {
    return (
      <WorkTriggerTemplateAnchor
        workTriggerTemplate={template as any}
        onlyId={onlyId}
      />
    );
  }
  return <>{value}</>;
});

export const ApplicationTableCell: React.FC<TableColumnCellProps> = React.memo(function ApplicationTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const application = rowToRelatedObject(props);
  if (application) {
    return <ApplicationAnchor application={application as any} />;
  }
  return <>{value}</>;
});

export const ApplicationProcessCountersTableCell: React.FC<TableColumnCellProps> = React.memo(function ApplicationProcessCountersTableCell (props: TableColumnCellProps) {
  const { row } = props;
  const { process_total, process_waiting, process_answered } = row;
  const style = {width: '3.5ex', paddingLeft: 0, paddingRight: 0};
  return (
    <div className="text-nowrap font-monospace">
      <Badge style={style} bg="trygga-yellow" title="Väntande processer">{process_waiting}</Badge>
      {' '}<Badge style={style} bg="trygga-grey" title="Besvarade processer">{process_answered}</Badge>
      {' '}<Badge style={style} bg="trygga-grey" title="Totalt antal processer">{process_total}</Badge>
    </div>
  );
});

export const JSONStringifyTableCell: React.FC<TableColumnCellProps> = React.memo(function JSONStringifyTableCell (props: TableColumnCellProps) {
  const { row, cellKey } = props;
  const value = row[cellKey];
  if (!isPlainObject(value) && !Array.isArray(value)) return <></>;
  const style = {paddingTop: '3px', paddingBottom: '2px'};
  return (
    <pre className="mb-0 border rounded small bg-white px-2 text-wrap" style={style}>
      <span>
        {JSON.stringify(value, null, 2)}
      </span>
    </pre>
  );
});

export interface IForeignIdTableCellKeyMap {
  [key: string]: {
    Cell: React.ComponentType<TableColumnCellProps>;
    cellProps: ({[key: string]: any});
  },
}

export interface IForeignIdTableCell extends TableColumnCellProps {
  cellProps: {
    foreignKeyMap: IForeignIdTableCellKeyMap;
  };
}

export const ForeignIdTableCell: React.FC<IForeignIdTableCell> = React.memo(function ForeignIdTableCell (props: IForeignIdTableCell) {
  const { row, foreignKeyMap = {} } = props;
  const children = Object.keys(foreignKeyMap).filter(key => row[key]).map(key => {
    const { Cell, cellProps } = foreignKeyMap[key];
    const value = row[key];
    return <Cell {...props} {...cellProps} idKey={key} key={key} value={value} />;
  });
  return <>{children}</>;
});

export const ComplaintTableCell: React.FC<TableColumnCellProps> = React.memo(function ComplaintTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const complaint = rowToRelatedObject(props);
  if (complaint) {
    return <ComplaintAnchor complaint={complaint as any} />;
  }
  return <>{value}</>;
});

export const CustomerConsentTableCell: React.FC<TableColumnCellProps> = React.memo(function CustomerConsentTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const consent = rowToRelatedObject(props);
  if (consent) {
    return <CustomerConsentAnchor consent={consent as any} />;
  }
  return <>{value}</>;
});

export const CustomerCloudInsuranceTableCell: React.FC<TableColumnCellProps> = React.memo(function CustomerCloudInsuranceTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const insurance = rowToRelatedObject(props);
  if (insurance) {
    return <CustomerCloudInsuranceAnchor insurance={insurance} />;
  }
  return <>{value}</>;
});

export const CustomerJaycomInsuranceTableCell: React.FC<TableColumnCellProps> = React.memo(function CustomerJaycomInsuranceTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const insurance = rowToRelatedObject(props);
  if (insurance) {
    return <CustomerJaycomInsuranceAnchor insurance={insurance as any} />;
  }
  return <>{value}</>;
});

export const CustomerTableCell: React.FC<TableColumnCellProps> = React.memo(function CustomerTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const customer = rowToRelatedObject(props);
  if (customer) {
    return <CustomerAnchor customer={customer as any} />;
  }
  return <>{value}</>;
});

export const TemplateTableCell: React.FC<TableColumnCellProps> = React.memo(function TemplateTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const template = rowToRelatedObject(props);
  if (template) {
    return <TemplateAnchor template={template} />;
  }
  return <>{value}</>;
});

export const LetterTableCell: React.FC<TableColumnCellProps> = React.memo(function LetterTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const letter = rowToRelatedObject(props);
  if (letter) {
    return <LetterAnchor letter={letter} />;
  }
  return <>{value}</>;
});

export const PepQueryTableCell: React.FC<TableColumnCellProps> = React.memo(function PepQueryTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const pepQuery = rowToRelatedObject(props);
  if (pepQuery) {
    return <PepQueryAnchor pepQuery={pepQuery} />;
  }
  return <>{value}</>;
});

export const WorkTableCell: React.FC<TableColumnCellProps> = React.memo(function WorkTableCell (props: TableColumnCellProps) {
  const { value, showStatus } = props;
  const work = rowToRelatedObject(props);
  if (work) {
    return <WorkAnchor work={work as any} showStatus={showStatus} />;
  }
  return <>{value}</>;
});

export const WorkScheduledTableCell: React.FC<TableColumnCellProps> = React.memo(function WorkScheduledTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const workScheduled = rowToRelatedObject(props);
  if (workScheduled) {
    return <WorkScheduledAnchor workScheduled={workScheduled as any} />;
  }
  return <>{value}</>;
});

export const EmailTableCell: React.FC<TableColumnCellProps> = React.memo(function EmailTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const email = rowToRelatedObject(props);
  if (email) {
    return <EmailAnchor email={email} />;
  }
  return <>{value}</>;
});

export const SmsTableCell: React.FC<TableColumnCellProps> = React.memo(function SmsTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const sms = rowToRelatedObject(props);
  if (sms) {
    return <SmsAnchor sms={sms} />;
  }
  return <>{value}</>;
});

export const UcTableCell: React.FC<TableColumnCellProps> = React.memo(function UcTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const uc = rowToRelatedObject(props);
  if (uc) {
    return <UcAnchor uc={uc} />;
  }
  return <>{value}</>;
});

export const ProspectResultTableCell: React.FC<TableColumnCellProps> = React.memo(function ApplicationStatusTableCell (props: TableColumnCellProps) {
  const { row, rowKey } = props;
  const value = get(row, rowKey || 'result');
  return (
    <ProspectResultLabel
      value={value}
      short
    />
  );
});

export const ProspectTableCell: React.FC<TableColumnCellProps> = React.memo(function ProspectTableCell (props: TableColumnCellProps) {
  const { value } = props;
  const prospect = rowToRelatedObject(props);
  if (prospect) {
    return <ProspectAnchor prospect={prospect} />;
  }
  return <>{value}</>;
});

export const DateTimeTableCell: React.FC<TableColumnCellProps> = React.memo(function DateTimeTableCell (props: TableColumnCellProps) {
  const { value, format, emptyValue } = props;
  return (
    <DateTime
      value={value}
      format={format}
      defaultValue={emptyValue}
    />
  );
});

export const IntervalTableCell: React.FC<TableColumnCellProps> = React.memo(function IntervalTableCell (props: TableColumnCellProps) {
  const { value } = props;
  return (
    <Interval value={value} />
  );
});

export const WorkStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function WorkStatusTableCell (props: TableColumnCellProps) {
  const { row } = props;
  return (
    <WorkStatusLabel value={row.status} short />
  );
});

export const PepQueryStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function PepQueryStatusTableCell (props: TableColumnCellProps) {
  const { row } = props;
  return (
    <PepQueryStatusLabel value={row.status} short />
  );
});

export const TaskTemporalStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function TaskTemporalStatusTableCell (props: TableColumnCellProps) {
  const { row } = props;
  return (
    <TaskTemporalStatusLabel value={row} short />
  );
});

export const GroupStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function GroupStatusTableCell (props: TableColumnCellProps) {
  const { row } = props;
  return (
    <GroupStatusLabel value={row.is_active} short />
  );
});

export const UserStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function UserStatusTableCell (props: TableColumnCellProps) {
  const { row } = props;
  return (
    <UserStatusLabel value={row.is_active} short />
  );
});

export const ComplaintStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function ComplaintStatusTableCell (props: TableColumnCellProps) {
  const { row } = props;
  return (
    <ComplaintStatusLabel value={row.status} short />
  );
});

export const WorkTriggerTemplateStatusCell: React.FC<TableColumnCellProps> = React.memo(function BankStatusTableCell (props: TableColumnCellProps) {
  const { row } = props;
  return (
    <WorkTriggerTemplateStatusLabel value={row.is_active} short />
  );
});

export const BankStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function BankStatusTableCell (props: TableColumnCellProps) {
  const { row } = props;
  return (
    <BankStatusLabel value={row.is_active} short />
  );
});

export const BankProcessStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function BankProcessStatusTableCell (props: TableColumnCellProps) {
  const { row } = props;
  return (
    <BankProcessStatusLabel value={row.status} short />
  );
});

export const EmailStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function EmailStatusTableCell (props: TableColumnCellProps) {
  const { row, rowKey } = props;
  const value = get(row, rowKey || 'status');
  return (
    <EmailStatusLabel
      value={value}
      short
    />
  );
});

export const LetterStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function LetterStatusTableCell (props: TableColumnCellProps) {
  const { row, rowKey } = props;
  const value = get(row, rowKey || 'status');
  return (
    <LetterStatusLabel
      value={value}
      short
    />
  );
});

export const SmsStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function SmsStatusTableCell (props: TableColumnCellProps) {
  const { row, rowKey } = props;
  const value = get(row, rowKey || 'status');
  return (
    <SmsStatusLabel
      value={value}
      short
    />
  );
});

export const UcStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function UcStatusTableCell (props: TableColumnCellProps) {
  const { row, rowKey } = props;
  const value = get(row, rowKey || 'status');
  return (
    <UcStatusLabel
      value={value}
      short
    />
  );
});

export const ApplicationTriggerStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function ApplicationTriggerStatusTableCell (props: TableColumnCellProps) {
  const { row, rowKey } = props;
  const value = get(row, rowKey || 'status');
  return (
    <ApplicationTriggerStatusLabel value={value} short />
  );
});

export const ProspectTriggerStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function ProspectTriggerStatusTableCell (props: TableColumnCellProps) {
  const { row, rowKey } = props;
  const value = get(row, rowKey || 'status');
  return (
    <ProspectTriggerStatusLabel value={value} short />
  );
});

export const ApplicationStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function ApplicationStatusTableCell (props: TableColumnCellProps) {
  const { row, rowKey, rowKeyAcceptedProcessId } = props;
  const value = get(row, rowKey || 'status');
  const hasAcceptedProcess = Boolean(get(row, rowKeyAcceptedProcessId || 'accepted_process_id'));
  return (
    <ApplicationStatusLabel
      value={value}
      hasAcceptedProcess={hasAcceptedProcess}
      short
    />
  );
});

export const CustomerStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function CustomerStatusTableCell (props: TableColumnCellProps) {
  const { row, rowKey } = props;
  const value = get(row, rowKey || 'status');
  return (
    <CustomerStatusLabel
      value={value}
      short
    />
  );
});

export const CustomerCloudInsuranceStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function InsuranceStatusTableCell (props: TableColumnCellProps) {
  const { row, rowKey } = props;
  const value = get(row, rowKey || 'status');
  return (
    <CustomerCloudInsuranceStatusLabel
      value={value}
      short
    />
  );
});

export const CustomerJaycomInsuranceStatusTableCell: React.FC<TableColumnCellProps> = React.memo(function InsuranceStatusTableCell (props: TableColumnCellProps) {
  const { row, rowKey } = props;
  const value = get(row, rowKey || 'status');
  return (
    <CustomerJaycomInsuranceStatusLabel
      value={value}
      short
    />
  );
});

export const TooltipTableCell: React.FC<TableColumnCellProps> = React.memo(function TooltipTableCell (props: TableColumnCellProps) {
  const { value, hideWhenFalse = false, title } = props;
  const tooltipId = React.useId();
  if (!value && hideWhenFalse) return '';
  return (
    <OverlayTrigger
      placement="auto"
      overlay={
        <Tooltip id={tooltipId}>
          {value}
        </Tooltip>
      }
    >
      {title}
    </OverlayTrigger>
  );
});

export const IdShortTableCell: React.FC<TableColumnCellProps> = React.memo(function IdShortTableCell (props: TableColumnCellProps) {
  const { value } = props;
  return <IdShort value={value} />;
});

export const Number: React.FC<TableColumnCellProps> = React.memo(function Number (props: TableColumnCellProps) {
  const { value, format } = props;
  return <>{isFiniteNumber(value) ? numberfmt(value, format) : '-'}</>;
});

export const Count: React.FC<TableColumnCellProps> = React.memo(function Number (props: TableColumnCellProps) {
  const { value } = props;
  return <>{isFiniteNumber(value) ? numberfmt(value, {maximumFractionDigits: 2}) + ' st' : '-'}</>;
});

export const Currency: React.FC<TableColumnCellProps> = React.memo(function Currency (props: TableColumnCellProps) {
  const { value, format } = props;
  return <>{isFiniteNumber(value) ? currency(value, format) : '-'}</>;
});

export const Percentage: React.FC<TableColumnCellProps> = React.memo(function Percentage (props: TableColumnCellProps) {
  const { value, format, scaled } = props;
  return <>{isFiniteNumber(value) ? percentage(scaled ? value / 100 : value, format) : '-'}</>;
});

export const BooleanTableCell: React.FC<TableColumnCellProps> = React.memo(function BooleanTableCell (props: TableColumnCellProps) {
  const { value, strict, original } = props;
  return (
    <span className="d-inline-flex align-items-center gap-1" style={{position: 'relative', top: '2px'}}>
      <BooleanIcon
        canBeIndetermined={strict}
        size={22}
        value={original}
      />
      <span>{value}</span>
    </span>
  );
});

export const InputMutator: React.FC<TableColumnCellProps> = React.memo(function InputMutator (props: TableColumnCellProps) {
  const {
    row,
    inputProps = {},
    mutateProps = {},
    getData,
    getUrl,
    rowValueKey,
  } = props;

  const [isUpdating, setIsUpdating] = React.useState(false);

  const tableContext = useTableContext();

  const value = row[rowValueKey];

  const inputControlProps = useFocusControl(value, function (newValue: any) {
    if (newValue === value) return;
    const data = getData(rowValueKey, newValue, row);
    const url = getUrl(row);
    const mutateData = {...mutateProps, url, data, rowId: row.id};
    setIsUpdating(true);
    return tableContext.updateRowMutation.mutateAsync(mutateData).finally(() => {
      setIsUpdating(false);
    });
  });

  return (
    <Form.Group>
      <div className="position-relative">
        <Form.Control
          style={{minWidth: '100px'}}
          {...inputControlProps}
          {...inputProps}
        />
        <FormControlSpinner isLoading={isUpdating} />
      </div>
    </Form.Group>
  );
});

export const TableSelectOneRowCell: React.FC<TableColumnCellProps> = React.memo(function TableSelectOneCell (props: TableColumnCellProps) {
  const { row, selected, onChangeSelected } = props;
  return (
    <div className="px-1">
      <Form.Check
        type="radio"
        checked={selected}
        onChange={onChangeSelected}
        name={String(row.id)}
      />
    </div>
  );
});

export const TableSelect: React.FC<TableColumnCellProps> = React.memo(function TableSelect (props: TableColumnCellProps) {
  const { row, selected, onChangeSelected } = props;
  return (
    <div className="px-1">
      <IndeterminateCheckbox
        checked={selected}
        onChange={onChangeSelected}
        name={row.id}
      />
    </div>
  );
});

export const TableSelectHeader: React.FC<IColumnHeaderProps> = React.memo(function TableSelectHeader (props: IColumnHeaderProps) {
  const { onChangeAllSelected, allRowsSelected, anyRowsSelected } = props;
  return (
    <div className="px-1">
      <IndeterminateCheckbox
        checked={allRowsSelected}
        indeterminate={anyRowsSelected}
        onChange={onChangeAllSelected}
      />
    </div>
  );
});

export const IpWithCountryFlag: React.FC<TableColumnCellProps> = React.memo(function IpWithCountryFlag (props: TableColumnCellProps) {
  const { value:ip, ipCountryKey, emptyValue = null, row } = props;
  const ipCountry = ipCountryKey ? get(row, ipCountryKey) : null;
  if (!ip) return emptyValue;
  return (
    <>
      {ipCountry && (
        <CountryFlag countryCode={ipCountry} />
      )}
      {' '}{ip}
    </>
  );
});

type RelatedObject = {
  id: string;
  [key: string]: any;
}

// a lot of anchors components should be fed a complete object to render with all info.
// a "complete object" might be an entire "application" database row or something similar.
// they might also be fed a partial object such as "{id: application_id}" to render.
// partial objects might only be available in some rows that do not SQL-join in the
// complete objects. the following function uses the optional cell props idKey and objKey
// to either return the complete object (with objKey) or a mock partial object (with idKey)
function rowToRelatedObject (props: TableColumnCellProps): (RelatedObject | null) {
  const { row, idKey, objKey, rowAsObject = false } = props;

  if (objKey) {
    const got = get(row, objKey);
    if (got) return got;
  }

  if (idKey) {
    const got = get(row, idKey);
    if (got) return {id: got};
  }

  if (rowAsObject && typeof row.id !== 'undefined') {
    return {id: '', ...(row as any)}; // lol at typescript for forcing this
  }

  return null;
}

