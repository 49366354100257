import React from 'react';
import * as TableCellActions from 'src/tables/TableCellActions';
import {GuardPermission} from 'src/guards/AuthGuards';
import { Trash } from 'react-feather';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';

export function actions () {
  return commonColumnDefs.actions({}, [
    props => (
      <GuardPermission permission="email_execute">
        {props.row.status === 'new' && (
          <TableCellActions.ExecuteButton
            {...props}
            title="Skicka e-postmeddelandet"
            mutateVars={{
              rowId: String(props.row.id),
              url: `/email/${props.row.id}/submit`,
              method: 'POST',
              onSuccessNotyf: {type: 'default', message: 'E-postmeddelandet har köats för skickning'},
            }}
          >
            Skicka
          </TableCellActions.ExecuteButton>
        )}
      </GuardPermission>
    ),
    props => (
      <GuardPermission permission="email_delete">
        {props.row.status === 'new' && (
          <TableCellActions.ExecuteModalConfirmButton
            {...props}
            title="Radera e-postmeddelandet"
            variant="outline-danger"
            modalProps={{message: 'Är du säker på att du vill radera e-postmeddelandet?'}}
            mutateVars={{
              rowId: String(props.row.id),
              url: `/email/${props.row.id}`,
              deletion: true,
              method: 'DELETE',
              onSuccessNotyf: {type: 'warning', message: 'E-postmeddelandet har raderats'},
            }}
          >
            <span><Trash size={14} /></span>
          </TableCellActions.ExecuteModalConfirmButton>
        )}
      </GuardPermission>
    ),
    TableCellActions.InspectRow,
  ]);
}
