import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { QueryKey, QueryFunction, useQuery } from '@tanstack/react-query';

interface PlaceholderCardProps {
  queryKey: QueryKey;
  queryFn: QueryFunction;
}

// loads something from the db and displays the formatted result in a pre-tag
const PlaceholderCard: React.FC<PlaceholderCardProps> = React.memo(function PlaceholderCard (props: PlaceholderCardProps) {
  const { queryKey, queryFn } = props;
  const query = useQuery<any>({
    queryKey,
    queryFn,
  });

  const result = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3 my-0" />
      <BlockSpinner isLoading={query.isLoading} className="mx-3" />
      {result && (
        <pre className="border m-3 p-3">
          {JSON.stringify(result, null, 2)}
        </pre>
      )}
    </div>
  );
});
export default PlaceholderCard;
