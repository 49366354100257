import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import * as api from 'src/api';
import ErrorAlert from 'src/alerts/ErrorAlert';
import StatusLabelContainer from  'src/components/StatusLabelContainer';
import { StatusLabel } from  'src/sms/SmsFormatters';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SmsRow } from 'shared/types/sms';
import * as formUtils from 'src/utils/form';
import SmsEditForm, { SmsEditFormValues, smsToFormValues, formValuesToUpdate } from 'src/sms/SmsEditForm';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useEventModelEmitDeletes, useEventModelEmitUpdates } from 'src/hooks/useEventModels';

interface SmsModalBodyProps {
  id: string;
}

export default function SmsModalBody (props: SmsModalBodyProps) {
  const { id } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();

  const emitUpdate = useEventModelEmitUpdates<SmsRow>('sms');
  const emitDelete = useEventModelEmitDeletes<SmsRow>('sms');

  const query = useQuery<SmsRow, Error>({
    queryKey: [`/sms/${id}`],
  });

  const sms = query.data;

  const updateMutation = useMutation<SmsRow, Error, Partial<SmsEditFormValues>>({
    mutationFn: async vars => {
      const sms = await api.request({
        url: `/sms/${id}`,
        method: 'PATCH',
        data: vars,
      });

      queryClient.setQueryData([`/sms/${id}`], sms);
      notyf.success({type: 'default', message: 'SMS:et uppdaterades'});
      modalStateContext.onHide();
      emitUpdate(sms);

      return sms;
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: async () => {
      await api.request({
        url: `/sms/${id}`,
        method: 'DELETE',
      });

      notyf.success({type: 'danger', message: 'SMS:et raderades'});
      if (query.data) emitDelete(query.data);
      modalStateContext.onHide();
    },
  });

  const onDelete = async () => {
    return deleteMutation.mutateAsync();
  };

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<SmsRow, SmsEditFormValues, Partial<SmsEditFormValues>>({
    queryDataToFormValues: smsToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(smsToFormValues(sms), values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading} />
      {sms && (
        <>
          <StatusLabelContainer className="mt-3">
            <StatusLabel value={sms.status} />
          </StatusLabelContainer>
          <SmsEditForm
            className="mt-0"
            disabled={sms.status !== 'new'}
            initialValues={smsToFormValues(sms)}
            onSubmit={formCycleHelpers.onSubmit}
            submitError={updateMutation.error}
            onDelete={sms.status === 'new' ? onDelete : undefined}
          />
        </>
      )}
    </div>
  );
}
