import React from 'react';
import Anchor from  'src/anchors/Anchor';
import QuicklookAnchor, { QuicklookAnchorProps } from 'src/anchors/QuicklookAnchor';
import CustomerQuicklook from 'src/customer/CustomerQuicklook';
import { Name } from 'src/customer/CustomerFormatters';
import {CustomerRow} from 'shared/types/customer';
import { FavoriteButton } from 'src/buttons/IconButtons';

interface CustomerAnchorProps extends Partial<QuicklookAnchorProps> {
  customer: Pick<CustomerRow, 'id' | 'name' | 'first_name' | 'last_name'>;
}

export default function CustomerAnchor (props: CustomerAnchorProps) {
  const { customer, ...restOfProps } = props;
  return (
    <QuicklookAnchor
      {...restOfProps}
      url={`/customer/${customer.id}/form`}
      title={<Name value={customer} />}
      favorite={<FavoriteButton className="px-0" type="customer" id={customer.id} />}
      name="Kund"
      objectId={customer.id}
    >
      <CustomerQuicklook id={customer.id} />
    </QuicklookAnchor>
  );
}

export function CustomerPlainAnchor (props: CustomerAnchorProps) {
  const { customer, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/customer/${customer.id}/form`}
      name="Kunden"
    >
      <Name value={customer} />
    </Anchor>
  );
}
