import React from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from 'src/api';

interface FileFolderSelectOption {
  value: string;
  label: string;
}

interface FileFolderSelectOptionsProviderProps {
  children: (options: FileFolderSelectOption[]) => React.ReactNode;
}

export function FileFolderSelectOptionsProvider (props: FileFolderSelectOptionsProviderProps) {
  const { children } = props;

  const query = useQuery<FileFolderSelectOption[]>({
    queryKey: ['fileFolderSelectOptions'],
    queryFn: () => api.request({
      url: '/file/folders/selectOptions',
      params: {},
    }).then(list => {
      const options = list.map(folder => ({value: folder, label: folder}));
      return options;
    }),
  });

  if (!Array.isArray(query.data)) {
    return null;
  }

  return children(query.data);
}
