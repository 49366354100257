import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { LetterRow } from 'shared/types/letter';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import * as LetterFormFields from 'src/letter/LetterFormFields';
import * as formUtils from 'src/utils/form';
import {Trash} from 'react-feather';

export interface LetterEditFormValues {
  first_name: string;
  last_name: string;
  street: string;
  co: string;
  postcode: string;
  city: string;
  body: string;
}

interface LetterEditFormProps {
  className?: string;
  initialValues: LetterEditFormValues;
  onSubmit: (values: LetterEditFormValues, helpers: FormikHelpers<LetterEditFormValues>) => Promise<any>;
  onDelete?: () => void;
  submitError?: Error | null;
  disabled?: boolean;
}

export default function LetterEditForm (props: LetterEditFormProps) {
  const { initialValues, submitError, onDelete, onSubmit, disabled, className } = props;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} className={className}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="mx-3">
            <Row>

              <Col>
                <LetterFormFields.FirstName disabled={disabled} />
              </Col>

              <Col>
                <LetterFormFields.LastName disabled={disabled} />
              </Col>

            </Row>

            <Row>

              <Col>
                <LetterFormFields.Street disabled={disabled} />
              </Col>

              <Col>
                <LetterFormFields.CO disabled={disabled} />
              </Col>

            </Row>

            <Row>

              <Col>
                <LetterFormFields.Postcode disabled={disabled} />
              </Col>

              <Col>
                <LetterFormFields.City disabled={disabled} />
              </Col>

            </Row>

            <LetterFormFields.Body disabled={disabled} />

          </div>

          <div className="mt-3 p-3 border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap align-items-center justify-content-between">
              {onDelete ? (
                <ConfirmActionModalButton
                  type="button"
                  className="rounded d-flex gap-1"
                  variant="danger"
                  disabled={!formProps.isValid || formProps.isSubmitting}
                  onConfirm={onDelete}
                  message="Är du säker på att du vill radera brevet?"
                  title="Radera det här brevet"
                >
                  <Trash size={18} />
                  Radera
                </ConfirmActionModalButton>
              ) : <div></div>}
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export function letterToFormValues (letter: LetterRow): LetterEditFormValues {
  const { first_name, last_name, street, co, postcode, city, body } = letter;
  return {
    first_name: first_name || '',
    last_name: last_name || '',
    street: street || '',
    co: co || '',
    postcode: postcode || '',
    city: city || '',
    body: body || '',
  };
}

export function formValuesToUpdate (initialValues: LetterEditFormValues, values: LetterEditFormValues): Partial<LetterEditFormValues> {
  const changes = formUtils.changes(initialValues, values);
  return changes;
}
