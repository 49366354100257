import React from 'react';
import * as api from 'src/api';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BlockSpinner from 'src/spinners/BlockSpinner';
import { UserRow } from 'shared/types/user';
import { ErrorAlertCardBody, CardTitleIconStatus  } from 'src/cards/CardHelpers';
import { Card, Tab, Nav } from 'react-bootstrap';
import { Activity, Edit2, Image, Lock, User, Users } from 'react-feather';
import * as UserFormatters from 'src/user/UserFormatters';
import * as IconButtons from 'src/buttons/IconButtons';
import Breadcrumbs from 'src/components/Breadcrumbs';
import UserLogCard from 'src/user/UserLogCard';
import UserEditForm, { UserEditFormValues, userToFormValues, formValuesToUpdate } from 'src/user/UserEditForm';
import * as formUtils from 'src/utils/form';
import useNotyf from 'src/hooks/useNotyf';

interface UserPageParams {
  userId: string;
  tab?: string;
}

export default function UserPage () {
  const { userId, tab = 'form' } = useParams() as unknown as UserPageParams;

  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const navigate = useNavigate();

  const readQuery = useQuery<UserRow, Error>({
    queryKey: [`/user/${userId}`],
  });

  const user = readQuery.data;

  const updateMutation = useMutation<UserRow, Error, Partial<UserRow>>({
    mutationFn: async vars => {
      const result = await api.request({
        url: `/user/${userId}`,
        method: 'PATCH',
        data: vars,
      });
      queryClient.setQueryData([`/user/${userId}`], result);
      notyf.success({type: 'default', message: 'Användaren uppdaterades'});
      return result;
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: () => api.request({
      url: `/user/${userId}`,
      method: 'DELETE',
    }),
    onSuccess: () => {
      navigate('/user');
      notyf.success({type: 'danger', message: 'Användaren raderades'});
    },
  });

  const initialValues = user ? userToFormValues(user) : {};

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<UserRow, UserEditFormValues, Partial<UserRow>>({
    queryDataToFormValues: userToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues as any, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  const onClickDelete = () => {
    return deleteMutation.mutateAsync();
  };

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Användare" />
        <h1>Redigera användare</h1>
        <Breadcrumbs
          crumbs={[
            ['/users', 'Användare'],
            [`/user/${userId}/form`, user ? <UserFormatters.Name value={user} /> : userId],
          ]}
        />
        <BlockSpinner isLoading={readQuery.isLoading} />
        <ErrorAlertCardBody error={readQuery.error} className="mx-0 mt-0 mb-3" />
        {user && (
          <Card className="border">
            <Card.Header className="border-bottom bg-light">
              <CardTitleIconStatus
                as="h2"
                title={<UserFormatters.Name value={user} />}
                Status={<UserFormatters.StatusLabel value={user.is_active} className="px-3" />}
                Icon={<User size={16} />}
                spinning={readQuery.isRefetching}
              >
                <IconButtons.ConfirmModalDeleteButton
                  onConfirm={onClickDelete}
                  message="Är du säker på att du vill radera den här användaren?"
                />
                <IconButtons.InspectObjectModalButton object={user} />
                <IconButtons.CopyObjectIdButton id={user.id} />
                <IconButtons.CopyCurrentUrlButton />
                <IconButtons.RefreshObjectButton
                  disabled={readQuery.isRefetching}
                  onClick={() => readQuery.refetch()}
                />
              </CardTitleIconStatus>
            </Card.Header>
            <Tab.Container
              activeKey={tab}
              transition={false}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              <Nav className="nav-tabs pt-3 px-3">
                <Nav.Item>
                  <NavLink className="nav-link" to={`/user/${userId}/form`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Edit2 size={12} />{' '}
                      Formulär
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/user/${userId}/photo`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Image size={12} />{' '}
                      Porträtt
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/user/${userId}/permissions`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Lock size={12} />{' '}
                      Behörigheter
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/user/${userId}/groups`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Users size={12} />{' '}
                      Gruppmedlemskap
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/user/${userId}/log`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Activity size={12} />{' '}
                      Logg
                    </span>
                  </NavLink>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="form">
                  <UserEditForm
                    initialValues={userToFormValues(user)}
                    onSubmit={formCycleHelpers.onSubmit}
                    submitError={updateMutation.error}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="log">
                  <Card.Body>
                    <UserLogCard userId={userId} />
                  </Card.Body>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
        )}
      </Content>
    </Main>
  );
}
