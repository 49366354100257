import React from 'react';
import { Spinner, SpinnerProps } from 'react-bootstrap';

interface BasicSpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: string;
  isLoading?: boolean;
  spinnerProps?: SpinnerProps;
}

export default function BasicSpinner (props: BasicSpinnerProps) {
  const { as = 'div', isLoading, spinnerProps = {}, ...otherProps } = props;
  if (!isLoading) return null;
  return React.createElement(as, otherProps, (
    <Spinner
      className="position-relative"
      animation="border"
      variant="default"
      size="sm"
      style={{top: '2px'}}
      {...spinnerProps}
    />
  ));
}


