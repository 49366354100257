const settings = {
  restart: {
    enabled: true,
    submittable: false,
  },
  bankInterface: {
    index: {
      defaultFilter: {
        status: 'open',
      },
    },
  },
  hasVerifications: true,
  hasVerificationsInBid: true,
  loanClamps: {
    minYears: 2,
    maxYears: 15,
    minTotal: 10000,
    maxTotal: 500000,
  },
  change: {
    amountNewInput: true,
  },
};
export default settings;
