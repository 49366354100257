import React from 'react';
import { Helmet } from 'react-helmet-async';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import Pluralizer from 'src/formatters/Pluralizer';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { Trash, Users } from 'react-feather';
import * as TableCells from 'src/tables/TableCells';
import { Container, Card } from 'react-bootstrap';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { GroupRowWithRelations } from 'shared/types/group';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import { TableStateOrder, TableQueryKey, TableStateFilterMap } from 'src/tables/types';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';
import { ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { useTableUpdateRowMutation } from 'src/hooks/useTableUtils';
import { TableContextProvider } from 'src/tables/TableContext';
import UserAnchor from 'src/user/UserAnchor';

const defaultFilter = {
  searchTerm: '',
};

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.string({
    id: 'searchTerm',
    title: 'Sök',
  }),
  commonFilterDefs.boolean({
    id: 'is_active',
    title: 'Aktiv',
  }),
];

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.groupId({
    id: 'id',
    title: 'ID',
    cellProps: {rowAsObject: true},
    sortable: true,
  }),
  commonColumnDefs.groupId({
    id: 'parent_group_id',
    title: 'Överordnad',
    cellProps: {objKey: 'Parent'},
  }),
  commonColumnDefs.basic({
    id: 'name',
    title: 'Namn',
    sortable: true,
  }),
  {
    id: 'members',
    title: 'Medlemmar',
    Cell: props => (
      <span className="d-flex flex-wrap gap-1">
        {(props.row.Users || []).map(user => (
          <UserAnchor key={user.id} user={user} />
        ))}
      </span>
    ),
  },
  {
    id: 'members_count',
    title: 'Antal medl.',
    Cell: props => (
      <>{props.row.Users?.length} st</>
    ),
  },
  {
    id: 'status',
    title: 'Status',
    Cell: TableCells.GroupStatusTableCell,
  },
  commonColumnDefs.datetime({
    id: 'created_at',
    sortable: true,
    title: 'Skapad',
  }),
  commonColumnDefs.datetime({
    id: 'updated_at',
    sortable: true,
    title: 'Uppdaterad',
  }),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRow,
    props => (
      <TableCellActions.ExecuteModalConfirmButton
        {...props}
        title="Radera gruppen"
        variant="outline-danger"
        modalProps={{message: 'Är du säker på att du vill radera gruppen?'}}
        mutateVars={{
          optimistic: true,
          rowId: String(props.row.id),
          url: `/group/${props.row.id}`,
          deletion: true,
          method: 'DELETE',
          onSuccessNotyf: {type: 'warning', message: 'Gruppen har raderats'},
        }}
      >
        <Trash size={14} />
      </TableCellActions.ExecuteModalConfirmButton>
    ),
  ]),
];

export default function GroupTablePage () {
  const [filter, setFilter] = React.useState<TableStateFilterMap>(defaultFilter);
  const [order, setOrder] = React.useState<TableStateOrder>({id: 'asc'});

  const queryKey: TableQueryKey = React.useMemo(() => {
    return ['/group/search', {params: {...filter, order, include: 'users'}}];
  }, [filter, order]);

  const query = useQuery<GroupRowWithRelations[], Error>({
    queryKey,
    placeholderData: keepPreviousData,
  });

  const tableUpdateRowMutation = useTableUpdateRowMutation({
    queryKey,
  });

  const rows = query.data ?? [];

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Grupper" />
        <Container fluid className="p-0">
          <Card className="border mb-1">
            <Card.Header>
              <CardTitleIcon
                title="Grupper"
                Icon={<Users size={16} />}
                spinning={query.isLoading || query.isRefetching}
              >
                <RefreshObjectButton
                  disabled={query.isRefetching}
                  onClick={() => query.refetch()}
                />
              </CardTitleIcon>
              <Card.Subtitle className="mt-1">
                <small>
                  <Pluralizer
                    count={query.data?.length ?? 0}
                    zero="Inga grupper"
                    one="Visar 1 grupp"
                    otherwise="Visar %% grupper"
                  />
                </small>
              </Card.Subtitle>
            </Card.Header>
            <ErrorAlertCardBody error={query.error} className="border-top p-3" />
            <TableContextProvider updateRowMutation={tableUpdateRowMutation}>
              <CardBodyTable
                filter={filter}
                order={order}
                filterDefinitions={filterDefinitions}
                columnDefinitions={columnDefinitions}
                setFilter={setFilter}
                setOrder={setOrder}
                rows={rows}
                isFetched={query.isFetched}
                striped
              />
            </TableContextProvider>
          </Card>
        </Container>
      </Content>
    </Main>
  );
}
