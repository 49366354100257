import React, {useCallback, useContext, useMemo, useState} from 'react';

type Show = 'date' | 'relative';

interface DateTimeFormatContextState {
  show: Show;
  disabled: boolean;
  setShow: (newShow: Show) => void;
  cycleShow: () => void;
}

const initialState: DateTimeFormatContextState = {
  show: 'date',
  disabled: false,
  setShow: () => {},
  cycleShow: () => {},
};

const DateTimeFormatContext = React.createContext<DateTimeFormatContextState>(initialState);

interface DateTimeFormatProviderProps extends React.PropsWithChildren {
  show?: DateTimeFormatContextState['show'];
  disabled?: boolean;
}

function DateTimeFormatProvider (props: DateTimeFormatProviderProps) {
  const { children, show:initialShow = 'date', disabled = false } = props;

  const [show, setShow] = useState<Show>(initialShow);

  const cycleShow = useCallback(() => {
    if (disabled) return;
    switch (show) {
      default: return;
      case 'relative':
        setShow('date');
        break;
      case 'date':
        setShow('relative');
        break;
    }
  }, [show, setShow, disabled]);

  const value = useMemo(() => {
    return {show, setShow, cycleShow, disabled};
  }, [show, setShow, cycleShow, disabled]);

  return (
    <DateTimeFormatContext.Provider value={value}>
      {children}
    </DateTimeFormatContext.Provider>
  );
}

function useDateTimeFormat () {
  return useContext(DateTimeFormatContext);
}

export { DateTimeFormatProvider, DateTimeFormatContext, useDateTimeFormat };
