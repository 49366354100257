import {
  Activity,
  AtSign,
  Cpu,
  CreditCard,
  DollarSign,
  Edit3,
  File,
  Frown,
  Heart,
  Home,
  LifeBuoy,
  Mail,
  Phone,
  Wind,
  PieChart,
  Repeat,
  Scissors,
  Send,
  Settings,
  Smartphone,
  Smile,
  Table,
  Tool,
  User,
  Users,
  Zap,
} from 'react-feather';
import { AuthState } from 'src/contexts/AuthContext';
import { SidebarNavSectionProps } from 'src/sidebar/SidebarNavSection';
import { SidebarNavListItemProps } from 'src/sidebar/SidebarNavListItem';

const homeSection = [
  {
    href: '/',
    icon: Home,
    title: 'Översikt',
  },
];

const prospectSection = [
  {
    title: 'Prospekter',
    icon: Wind,
    sub: [
      {
        href: '/prospects',
        icon: Table,
        title: 'Prospekttabell',
        permissions: 'prospect_table',
      },
      {
        href: '/prospect_triggers',
        icon: Activity,
        title: 'Triggerkörningar',
        permissions: 'prospect_trigger_table',
      },
    ],
  },
];

const applicationsSection = [
  {
    title: 'Ansökningar',
    icon: File,
    sub: [
      {
        href: '/applications/all',
        icon: Table,
        title: 'Ansökningstabell',
        permissions: 'application_table_all',
      },
      {
        href: '/applications/own',
        icon: Smile,
        title: 'Mina ansökningar',
        permissions: 'application_table_own',
      },
      {
        href: '/application_triggers',
        icon: Activity,
        title: 'Triggerkörningar',
        permissions: 'application_trigger_table',
      },
      {
        href: '/task_lottery',
        icon: Zap,
        title: 'Lotteriet',
        permissions: 'task_lottery_info',
      },
    ],
  },
];

const customersSection = [
  {
    title: 'Kunder',
    icon: Heart,
    sub: [
      {
        href: '/task',
        icon: Phone,
        title: 'Återkomster',
        permissions: 'task_table',
      },
      {
        href: '/customers',
        icon: Table,
        title: 'Kundtabell',
        permissions: 'customer_table',
      },
      {
        href: '/customer_consent',
        icon: Edit3,
        title: 'Samtycken',
        permissions: 'customer_consent_table',
      },
      {
        href: '/complaints',
        icon: Frown,
        title: 'Klagomål',
        permissions: 'complaint_table',
      },
      {
        href: '/customer_cloud_insurances',
        icon: LifeBuoy,
        title: 'Försäkringar (Cloud)',
        permissions: 'customer_cloud_insurance_table',
      },
      {
        href: '/customer_jaycom_insurances',
        icon: LifeBuoy,
        title: 'Försäkringar (Jaycom)',
        permissions: 'customer_jaycom_insurance_table',
      },
      {
        href: '/uc',
        icon: CreditCard,
        title: 'Kreditupplysningar',
        permissions: 'uc_table',
      },
      {
        href: '/pep_query',
        icon: CreditCard,
        title: 'PEP-omfrågningar',
        permissions: 'pep_query',
      },
    ],
  },
];

const contactSection = [
  {
    title: 'Kontakt',
    icon: Send,
    sub: [
      {
        href: '/email',
        icon: AtSign,
        title: 'E-post',
        permissions: 'email_table',
      },
      {
        href: '/letter',
        icon: Mail,
        title: 'Brev',
        permissions: 'letter_table',
      },
      {
        href: '/sms',
        icon: Smartphone,
        title: 'SMS',
        permissions: 'sms_table',
      },
      {
        href: '/templates',
        icon: Scissors,
        title: 'Mallar',
        color: 'danger',
        // FIXME there is no "template index" permission
        // and generally everyone has access to template_search
        permissions: 'template_create',
      },
    ],
  },
];

const banksSection = [
  {
    title: 'Långivare',
    icon: DollarSign,
    sub: [
      {
        href: '/banks',
        icon: Table,
        title: 'Långivartabell',
        permissions: 'bank_search',
      },
      {
        href: '/bank_processes',
        icon: Repeat,
        title: 'Processer',
        permissions: 'bank_process_table',
      },
    ],
  },
];

const usersSection = [
  {
    icon: User,
    title: 'Användare',
    sub: [
      {
        href: '/users',
        icon: User,
        title: 'Användartabell',
        permissions: ['user_table'],
      },
      {
        href: '/groups',
        icon: Users,
        title: 'Grupper',
        permissions: ['group_search'],
      },
    ],
  },
];

const statisticsSection = [
  {
    icon: PieChart,
    title: 'Statistik',
    sub: [
      {
        href: '/statistics/salesmen',
        icon: PieChart,
        title: 'Handläggare',
        permissions: 'statistic_user_salesman',
      },
      {
        href: '/statistics/advisors',
        icon: PieChart,
        title: 'Rådgivare',
        permissions: 'statistic_user_advisor',
      },
      {
        href: '/statistics/banks',
        icon: PieChart,
        title: 'Långivare',
        permissions: 'statistic_bank',
      },
      {
        href: '/statistics/disbursed',
        icon: DollarSign,
        title: 'Utbetalningar',
        permissions: 'statistic_disbursed',
      },
    ],
  },
];

const adminSection = [
  {
    icon: Settings,
    title: 'Inställningar',
    sub: [
      {
        href: '/files',
        icon: File,
        title: 'Filer',
        permissions: ['file_index'],
      },
      {
        href: '/settings',
        icon: Settings,
        title: 'Systeminställningar',
        permissions: ['setting_index'],
      },
      {
        href: '/work',
        icon: Cpu,
        title: 'Jobblogg',
        permissions: ['work_table'],
      },
      {
        href: '/work_scheduled',
        icon: Cpu,
        title: 'Schemalagda jobb',
        permissions: ['work_scheduled_table'],
      },
      {
        href: '/work_trigger_templates',
        icon: Tool,
        title: 'Triggermallar',
        permissions: ['work_trigger_template_read'],
      },
    ],
  },
];

const navItems: SidebarNavSectionProps[] = [
  {
    pages: homeSection,
  },
  {
    title: 'Ansökningar & Kunder',
    pages: [
      ...prospectSection,
      ...applicationsSection,
      ...customersSection,
      ...banksSection,
      ...contactSection,
      ...statisticsSection,
    ],
  },
  {
    title: 'Administratör',
    pages: [...usersSection, ...adminSection],
  },
];

export default navItems;

export function anyChildHasPermission (auth: AuthState, sub: SidebarNavListItemProps[]): boolean {
  if (!sub) return false;
  return sub.some(child => {
    const { permissions, sub } = child;
    if (permissions && auth.hasAnyPermissions(permissions)) return true;
    if (Array.isArray(sub) && sub.length > 0) return anyChildHasPermission(auth, sub);
    return false;
  });
}
