

const settings = {
  restart: {
    enabled: false,
    //enabled: true, disabled 2025-01-27
    submittable: true,
  },
  hasVerifications: false,
  hasVerificationsInBid: false,
  loanClamps: {
    minYears: 1,
    maxYears: 8,
    minTotal: 5000,
    maxTotal: 30000,
    maxTotalClamp: true,
  },
  accept: {
    canHaveCoApplicant: false,
  },
  change: {
    enabled: false,
  },
  extraActions: ['fetch'],
};
export default settings;
