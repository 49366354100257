import React, {useId} from 'react';
import { useField } from 'formik';
import { FormCheckType } from 'react-bootstrap/esm/FormCheck';
import BasicSpinner from 'src/spinners/BasicSpinner';
import {
  Form,
  FormCheckProps,
  FormControlProps,
  FormSelectProps,
  InputGroup,
} from 'react-bootstrap';
import {
  checkPropsToFormikValidate,
  inputPropsToFormikValidate,
  inputPropsToValue,
  selectPropsToFormikValidate,
  useFieldExtended,
} from 'src/utils/form';
import { useFormikOnUpdate } from 'src/form/FormikOnUpdateContext';

export interface FormGroupControlProps extends FormControlProps {
  emptyValue?: any;
  error?: string;
  label?: React.ReactNode;
  className?: string;
  name: string;
  type?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  step?: number;
  min?: number | string;
  max?: number | string;
  rows?: number | string;
  helpText?: React.ReactNode;
  isLoading?: boolean;
  explainer?: React.ReactNode;
  customValidate?: (value: string) => string;
}

export function FormGroupControl (props: FormGroupControlProps) {
  const {
    name,
    error:outerError,
    isLoading,
    type,
    className,
    label,
    emptyValue,
    explainer,
    customValidate,
    ...restOfProps
  } = props; // eslint-disable-line no-unused-vars

  const [field, meta] = useFieldExtended({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
    emptyValue,
  }) as any;

  const formikOnUpdate = useFormikOnUpdate();
  const onBlur = formikOnUpdate.wrapOnBlur(field, meta);

  const error = meta.error || outerError;

  const id = useId();

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3 d-flex gap-1 align-items-center" htmlFor={id}>
          {label}
          <BasicSpinner isLoading={isLoading} />
        </Form.Label>
      )}
      <Form.Control
        {...restOfProps}
        {...field}
        onBlur={onBlur}
        value={inputPropsToValue(props, field.value)}
        id={id}
        name={name}
        type={type}
        isInvalid={Boolean(error)}
      />
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
      {explainer && <Form.Text>{explainer}</Form.Text>}
    </Form.Group>
  );
}

export interface InputGroupControlProps extends FormGroupControlProps {
  error?: string;
  before?: React.ReactNode;
  after?: React.ReactNode;
}

export function InputGroupControl (props: InputGroupControlProps) {
  const {
    name,
    error:outerError,
    isLoading,
    label,
    className,
    type,
    before,
    emptyValue,
    after,
    explainer,
    customValidate,
    ...restOfProps
  } = props; // eslint-disable-line no-unused-vars

  const [field, meta] = useFieldExtended({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
    emptyValue,
  });

  const formikOnUpdate = useFormikOnUpdate();
  const onBlur = formikOnUpdate.wrapOnBlur(field, meta);

  const error = outerError || meta.error;

  const id = useId();

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3 d-flex gap-1 align-items-center" htmlFor={id}>
          {label}
          <BasicSpinner isLoading={isLoading} />
        </Form.Label>
      )}
      <InputGroup hasValidation>
        {before}
        <Form.Control
          {...restOfProps}
          {...field}
          onBlur={onBlur}
          value={inputPropsToValue(props, field.value) as any}
          type={type}
          id={id}
          name={name}
          isInvalid={Boolean(error)}
        />
        {after}
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </InputGroup>
      {explainer && <Form.Text>{explainer}</Form.Text>}
    </Form.Group>
  );
}

export interface FormGroupSelectProps extends FormSelectProps, React.PropsWithChildren {
  emptyValue?: any;
  error?: string;
  label?: React.ReactNode;
  className?: string;
  name: string;
  required?: boolean;
  isLoading?: boolean;
  explainer?: React.ReactNode;
  customValidate?: (value: string) => string;
}

export function FormGroupSelect (props: FormGroupSelectProps) {
  const {
    name,
    isLoading,
    error:outerError,
    children,
    className,
    emptyValue,
    label,
    explainer,
    customValidate,
    ...restOfProps
  } = props; // eslint-disable-line no-unused-vars

  const [field, meta] = useFieldExtended({
    name,
    validate: selectPropsToFormikValidate(props),
    emptyValue,
  }) as any;

  const formikOnUpdate = useFormikOnUpdate();
  const onBlur = formikOnUpdate.wrapOnBlur(field, meta);

  const error = outerError || meta.error;

  const id = useId();

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3 d-flex gap-1 align-items-center" htmlFor={id}>
          {label}
          <BasicSpinner isLoading={isLoading} />
        </Form.Label>
      )}
      <Form.Select
        {...restOfProps}
        {...field}
        onBlur={onBlur}
        id={id}
        name={name}
        isInvalid={Boolean(error)}
      >
        {children}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
      {explainer && <Form.Text>{explainer}</Form.Text>}
    </Form.Group>
  );
}

export interface InputGroupSelectProps extends FormGroupSelectProps {
  before?: React.ReactNode;
  after?: React.ReactNode;
}

export function InputGroupSelect (props: InputGroupSelectProps) {
  const {
    name,
    error:outerError,
    label,
    before,
    after,
    emptyValue,
    className,
    explainer,
    customValidate,
    ...restOfProps
  } = props;

  const [field, meta] = useFieldExtended({
    name,
    validate: selectPropsToFormikValidate(props),
    emptyValue,
  });

  const formikOnUpdate = useFormikOnUpdate();
  const onBlur = formikOnUpdate.wrapOnBlur(field, meta);

  // TODO check field.value type

  const error = meta.error || outerError;
  const id = useId();

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3 d-flex gap-1 align-items-center" htmlFor={id}>
          {label}
          <BasicSpinner isLoading={false} />
        </Form.Label>
      )}
      <InputGroup hasValidation>
        {before}
        <Form.Select
          {...field}
          value={field.value as string}
          {...restOfProps}
          onBlur={onBlur}
          id={id}
          name={name}
          isInvalid={Boolean(error)}
        />
        {after}
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
        {explainer && <Form.Text>{explainer}</Form.Text>}
      </InputGroup>
    </Form.Group>
  );
}

export interface FormGroupCheckProps extends FormCheckProps {
  error?: string;
  name: string;
  value?: string;
  type?: FormCheckType;
  className?: string;
  innerClassName?: string;
  isLoading?: boolean;
  explainer?: React.ReactNode;
  customValidate?: (value: string) => string;
}

export function FormGroupCheck (props: FormGroupCheckProps) {
  const {
    error:outerError,
    name,
    label:outerLabel,
    type,
    className,
    innerClassName,
    isLoading,
    explainer,
    customValidate,
    ...restOfProps
  } = props;

  const id = useId();

  const [field, meta] = useField({
    name,
    type,
    validate: checkPropsToFormikValidate(props),
  });

  const formikOnUpdate = useFormikOnUpdate();
  const onChange = formikOnUpdate.wrapOnChange(field, meta, ev => ev.target.checked);

  const error = outerError || meta.error;

  const label = (
    <div className="d-inline-flex gap-2 align-items-center">
      {outerLabel}
      <BasicSpinner isLoading={isLoading} />
    </div>
  );

  return (
    <Form.Group className={className}>
      <Form.Check
        {...field}
        {...restOfProps}
        onChange={onChange}
        label={label}
        className={innerClassName}
        type={type}
        id={id}
        name={name}
        isInvalid={Boolean(error)}
      />
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
      {explainer && <Form.Text>{explainer}</Form.Text>}
    </Form.Group>
  );
}
