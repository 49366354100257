import React from 'react';
import {portalUrl} from 'src/misc';
import ExternalLinkAnchor from 'src/anchors/ExternalLinkAnchor';
import { Row, Col, Table, Form, InputGroup } from 'react-bootstrap';
import { ProspectRow } from 'shared/types/prospect';
import DateTime from 'src/formatters/DateTime';
import BooleanFmt from 'src/formatters/Boolean';
import IdProvider from 'src/components/IdProvider';
import * as NumberFormatters from 'src/formatters/NumberFormatters';
import * as translations from 'shared/translations';

interface FormInfoTableProps {
  prospect: ProspectRow;
}

export function FormInfoTable (props: FormInfoTableProps) {
  const { prospect } = props;
  return (
    <Table size="sm" className="mb-0">
      <tbody>
        <tr>
          <th>Någonsin uppdaterat</th>
          <td><BooleanFmt value={Boolean(prospect.form_updated_at)} colored /></td>
        </tr>
        <tr>
          <th>Senast uppdaterat</th>
          <td><DateTime value={prospect.form_updated_at} defaultValue="Aldrig" /></td>
        </tr>
        <tr>
          <th>Antal ifyllda fält</th>
          <td>{Object.keys(prospect.form ?? {}).length} st</td>
        </tr>
      </tbody>
    </Table>
  );
}

interface DataInfoTableProps {
  prospect: ProspectRow;
}

export function DataInfoTable (props: DataInfoTableProps) {
  const { prospect } = props;
  return (
    <Table size="sm" className="mb-0">
      <tbody>
        <tr>
          <th>Lån att lösa</th>
          <td><NumberFormatters.Currency value={prospect.data.loan.amount.now} /></td>
        </tr>
        <tr>
          <th>Nylån</th>
          <td><NumberFormatters.Currency value={prospect.data.loan.amount.new} /></td>
        </tr>
        <tr>
          <th>Total lånesumma</th>
          <td>
            <NumberFormatters.Currency
              value={(prospect.data.loan.amount.new ?? 0) + (prospect.data.loan.amount.now ?? 0)}
            />
          </td>
        </tr>
        <tr>
          <th>Återbetalningstid</th>
          <td>{prospect.data.loan.repayment_years ?? 0} år</td>
        </tr>
        <tr>
          <th>Lånesyfte</th>
          <td>{translations.application.loan_purposes(prospect.data.loan.purpose)}</td>
        </tr>
      </tbody>
    </Table>
  );
}

interface PropertiesTableProps {
  prospect: ProspectRow;
}

export function PropertiesTable (props: PropertiesTableProps) {
  const { prospect } = props;
  return (
    <div className="position-relative overflow-auto">
      <Table size="sm" className="mb-0">
        <tbody>
          <tr>
            <th>Personnummer</th>
            <td>{prospect.ssn}</td>
          </tr>
          <tr>
            <th>Skapad via kanal</th>
            <td>{prospect.created_by_channel ?? '-'}</td>
          </tr>
          <tr>
            <th>Skapad</th>
            <td><DateTime value={prospect.created_at} /></td>
          </tr>
          <tr>
            <th>Uppdaterad</th>
            <td><DateTime value={prospect.updated_at} /></td>
          </tr>
          <tr>
            <th>Uppdaterad (formulär)</th>
            <td><DateTime value={prospect.form_updated_at} /></td>
          </tr>
          <tr>
            <th colSpan={2}>
              <h4 className="mb-0 px-0 pb-2 pt-3">Kontakt</h4>
            </th>
          </tr>
          <tr>
            <th>Markerad som ointressant</th>
            <td><BooleanFmt value={prospect.flag_customer_is_done} colored /></td>
          </tr>
          <tr>
            <th>E-postadress</th>
            <td>{prospect.email}</td>
          </tr>
          <tr>
            <th>E-postblockering</th>
            <td><BooleanFmt value={prospect.email_blocked} colored /></td>
          </tr>
          <tr>
            <th>Telefonnummer</th>
            <td>{prospect.telephone}</td>
          </tr>
          <tr>
            <th>Telefonnummerblockering</th>
            <td><BooleanFmt value={prospect.telephone_blocked} colored /></td>
          </tr>
          <tr>
            <th colSpan={2}>
              <h4 className="mb-0 px-0 pb-2 pt-3">Metadata</h4>
            </th>
          </tr>
          <tr>
            <th>IP-adress vid inskickning</th>
            <td>{prospect.metadata.submitter_ip_address ?? '-'}</td>
          </tr>
          <tr>
            <th>User-Agent vid inskickning</th>
            <td>
              <code style={{wordBreak: 'break-word'}}>{prospect.metadata.submitter_user_agent ?? '-'}</code>
            </td>
          </tr>
          <tr>
            <th>Analytics-kod</th>
            <td>
              <code style={{wordBreak: 'break-word'}}>{prospect.metadata.tracking_code_ga_client_id ?? '-'}</code>
            </td>
          </tr>
          <tr>
            <th>Adwords-kod</th>
            <td>
              <code style={{wordBreak: 'break-word'}}>{prospect.metadata.tracking_code_gclid ?? '-'}</code>
            </td>
          </tr>
          <tr>
            <th>Adtraction-kod</th>
            <td>
              <code style={{wordBreak: 'break-word'}}>{prospect.metadata.tracking_code_adtraction ?? '-'}</code>
            </td>
          </tr>
          <tr>
            <th>Adservice-kod</th>
            <td>
              <code style={{wordBreak: 'break-word'}}>{prospect.metadata.tracking_code_adservice ?? '-'}</code>
            </td>
          </tr>
          <tr>
            <th>Frågesträng vid inskickning</th>
            <td>
              <code style={{wordBreak: 'break-word'}}>{prospect.metadata.querystring ?? '-'}</code>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

interface FormExternaLinkProps {
  prospect: ProspectRow;
}

export function FormExternaLink (props: FormExternaLinkProps) {
  const { prospect } = props;
  return (
    <ExternalLinkAnchor href={portalUrl(`/apply/${prospect.id}`)}>
      Öppna formulär
    </ExternalLinkAnchor>
  );
}

interface DataDisabledFormProps {
  prospect: ProspectRow;
}

export function DataDisabledForm (props: DataDisabledFormProps) {
  const { prospect } = props;
  return (
    <Row>

      <Col lg={4} sm={6}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Lån att lösa
              </Form.Label>
              <InputGroup>
                <Form.Control
                  id={id}
                  name="loan.amount.now"
                  value={prospect.data?.loan?.amount?.now ?? ''}
                  disabled
                />
                <InputGroup.Text>kr</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          )}
        </IdProvider>
      </Col>

      <Col lg={4} sm={6}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Nylån
              </Form.Label>
              <InputGroup>
                <Form.Control
                  id={id}
                  name="loan.amount.new"
                  value={prospect.data?.loan?.amount?.new ?? ''}
                  disabled
                />
                <InputGroup.Text>kr</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          )}
        </IdProvider>
      </Col>

      <Col lg={4} sm={6}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Total lånesumma
              </Form.Label>
              <InputGroup>
                <Form.Control
                  id={id}
                  name="loan.amount.total"
                  value={(prospect.data?.loan?.amount?.new ?? 0) + (prospect.data?.loan?.amount?.now ?? 0)}
                  disabled
                />
                <InputGroup.Text>kr</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          )}
        </IdProvider>
      </Col>

      <Col lg={4} sm={6}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Återbetalningstid
              </Form.Label>
              <InputGroup>
                <Form.Control
                  id={id}
                  name="loan.repayment_years"
                  value={prospect.data?.loan?.repayment_years ?? ''}
                  disabled
                />
                <InputGroup.Text>år</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          )}
        </IdProvider>
      </Col>

      <Col lg={4} sm={6}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Lånesyfte
              </Form.Label>
              <Form.Control
                id={id}
                name="loan.purpose"
                value={translations.application.loan_purposes(prospect.data?.loan?.purpose)}
                disabled
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>

    </Row>
  );
}
