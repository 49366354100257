import {useMutation} from '@tanstack/react-query';
import React, {useRef, useState} from 'react';
import { Collapse, Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';
import * as api from 'src/api';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import { CustomerNoteRowWithRelations } from 'shared/types/customer_note';
import useNotyf from 'src/hooks/useNotyf';

interface CustomerNoteFormProps {
  customerId: string;
  onReload?: () => void;
}

export default function CustomerNoteForm (props: CustomerNoteFormProps) {
  const { customerId, onReload } = props;

  const notyf = useNotyf();

  const [showControls, setShowControls] = useState(false);
  const [text, setText] = useState('');

  const mutationCreate = useMutation<CustomerNoteRowWithRelations[], Error, {text: string}>({
    mutationFn: (data: any) => api.request({
      method: 'POST',
      url: `/customer/${customerId}/notes`,
      data,
    }),
    onSuccess: () => {
      notyf.success({message: 'Notatet skapades på kunden'});
      setText('');
      setShowControls(false);
      onReload?.();
    },
  });

  const onSubmit: React.FormEventHandler<HTMLFormElement> = ev => {
    ev.preventDefault();
    mutationCreate.mutateAsync({text});
  };

  const onChange = ev => {
    setText(ev.target.value);
  };

  const onBlur = () => {
    if (!text) setShowControls(false);
  };

  const onFocus = () => {
    setShowControls(true);
  };

  const getOnPaste: (text: string) => React.MouseEventHandler<HTMLAnchorElement> = (text: string) => () => {
    setText(text);
    const el = ref.current;
    if (el) {
      let firstLineEnd = text.indexOf('\n');
      if (firstLineEnd === -1) firstLineEnd = text.length;
      el.focus();
      el.setSelectionRange(0, firstLineEnd);
    }
  };

  const ref = useRef<HTMLTextAreaElement>(null);

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className="mb-0">
        <InputGroup>
          <DropdownButton
            variant="outline-info"
            title="Infoga text"
          >
            <Dropdown.Item onClick={getOnPaste('Inget svar')}>Inget svar</Dropdown.Item>
            <Dropdown.Item onClick={getOnPaste('Nuvarande: \nBehov/önskemål: \nÖvrigt: ')}>Behovanalys</Dropdown.Item>
          </DropdownButton>
          <Form.Control
            as="textarea"
            placeholder="Skriv ett nytt notat här"
            rows={text.split(/\n/g).length}
            name="text"
            value={text}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            ref={ref}
            style={{resize: 'none'}}
          />
        </InputGroup>
      </Form.Group>
      <Collapse in={showControls}>
        <div className="text-center">
          <ButtonSpinner
            type="submit"
            className="rounded mt-3"
            size="sm"
            variant="success"
            isLoading={mutationCreate.isPending}
            disabled={!text || mutationCreate.isPending}
          >
            Skapa notat
          </ButtonSpinner>
        </div>
      </Collapse>
    </Form>
  );
}

