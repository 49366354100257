import React, {useMemo} from 'react';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import * as IconButtons from 'src/buttons/IconButtons';
import * as api from 'src/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApplicationRow } from 'shared/types/application';
import { Card } from 'react-bootstrap';
import { Briefcase } from 'react-feather';
import classNames from 'classnames';
import CardBodyTable from 'src/tables/CardBodyTable';
import * as TableCells from 'src/tables/TableCells';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import {useNavigate} from 'react-router';
import useNotyf from 'src/hooks/useNotyf';
import {GuardPermission} from 'src/guards/AuthGuards';

interface CustomerApplicationListCardProps {
  customerId: string;
  asRole: 'main' | 'co';
  className?: string;
  customerSsn?: string;
}

export default function CustomerApplicationListCard (props: CustomerApplicationListCardProps) {
  const { customerId, customerSsn, asRole, className } = props;

  const query = useQuery<ApplicationRow[], Error>({
    queryKey: [`/customer/${customerId}/applications/${asRole}`],
  });

  const navigate = useNavigate();
  const notyf = useNotyf();

  const createMutation = useMutation<ApplicationRow, Error>({
    mutationFn: () => api.request({
      url: `/application/${customerSsn}`,
      method: 'POST',
    }),
    onSuccess: newApplication => {
      navigate(`/application/${newApplication.id}/form`);
      notyf.success({type: 'success', message: 'Ansökan skapades'});
    },
  });

  const onConfirmCreate = () => {
    return createMutation.mutateAsync();
  };

  const rows = query.data ?? [];

  const columnDefs = useMemo(() => {
    return columnFactory(asRole === 'co');
  }, [asRole]);

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="border-bottom bg-light">
        <CardTitleIcon
          title={asRole === 'main' ? 'Ansökningar' : 'Ansökningar som medsökande'}
          Icon={<Briefcase size={18} />}
          spinning={query.isRefetching}
        >
          <GuardPermission permission="application_create">
            {customerSsn && (
              <IconButtons.ConfirmModalCreateButton
                message="Är du säker på att du vill skapa en ny ansökan på kunden?"
                onConfirm={onConfirmCreate}
              />
            )}
          </GuardPermission>
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <CardBodyTable
        striped
        columnDefinitions={columnDefs}
        rows={rows}
        isFetched={query.isFetched}
      />
    </Card>
  );
}

const columnFactory = (asCo: boolean) => [
  commonColumnDefs.applicationId({
    id: 'id',
    title: 'Nr',
    cellProps: {rowAsObject: true},
  }),
  asCo && commonColumnDefs.customerId({
    id: 'customer_id',
    title: 'Huvudsökande',
    cellProps: {objKey: 'Customer'},
  }),
  commonColumnDefs.datetime({
    id: 'created_at',
    title: 'Skapad',
  }),
  commonColumnDefs.basic({
    id: 'status',
    title: 'Status',
    Cell: TableCells.ApplicationStatusTableCell,
  }),
  commonColumnDefs.inspect(),
].filter(v => v);
