import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import { EmailRow, Address as AddressType } from 'shared/types/email';
import * as emailFilter from 'shared/filter/email';

interface NameProps {
  value: Pick<EmailRow, 'id'>;
}

export function Name (props: NameProps) {
  const { value:email } = props;
  if (!isObjectLike(email)) return null;
  const id = email.id;
  return (
    <IdShort value={id} />
  );
}

interface AddressProps {
  value?: null | AddressType;
}

export function Address (props: AddressProps) {
  const { value } = props;
  if (!value || !('address' in value)) return null;
  const { name, address } = value;
  return (
    <>
      {name && <>{name}</>}{' '}
      <a href={`mailto:${address}`}>&lt;{address}&gt;</a>
    </>
  );
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: EmailRow['status'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={emailFilter.status}
      {...restOfProps}
    />
  );
}

