import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';

export function Subject () {
  return (
    <FormikFormControls.FormGroupControl
      label="Subjekt"
      minLength={1}
      maxLength={255}
      name="subject"
      explainer="Ange vad kunden samtycker till"
      required
    />
  );
}
