import React from 'react';
import * as tableHelpers from 'src/tables/helpers';
import classNames from 'classnames';
import { preventDefaultSubmit } from 'src/misc';
import useFocusControl from 'src/hooks/useFocusControl';
import {
  Form,
  Button,
  InputGroup,
} from 'react-bootstrap';
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'react-feather';
import { TableState } from 'src/tables/types';

interface TablePaginationFormProps {
  className?: string;
  count: number | null;
  state: TableState;
  updateState: (newState: TableState) => void;
}

export default function TablePaginationForm (props: TablePaginationFormProps) {
  const {
    className,
    count,
    state,
    updateState,
  } = props;

  const { page = 1, limit = 0 } = state;

  const pageInputProps = useFocusControl(page, strPage => {
    const page = parseInt(strPage, 10);
    updateState({page});
  });

  return (
    <Form className={classNames('d-flex', className)} onSubmit={preventDefaultSubmit}>
      <InputGroup>
        <InputGroup.Text className="px-2">
          <small>Sida</small>
        </InputGroup.Text>
        <Form.Control
          {...pageInputProps}
          className="text-center"
          type="text"
          pattern="\d*"
          inputMode="numeric"
          required
          style={{width: '75px'}}
        />
        {!tableHelpers.canLastPage(state, count) ? null : (
          <InputGroup.Text className="px-2">
            <small>av {tableHelpers.totalNumberOfPages(count, limit)}</small>
          </InputGroup.Text>
        )}
      </InputGroup>
      <Button
        className="px-1 bg-gradient ms-1"
        variant="outline-primary"
        onClick={() => updateState({page: 1})}
        disabled={!tableHelpers.canFirstPage(state)}
        title="Gå till sida 1"
      >
        <ChevronsLeft size={23} />
      </Button>
      <Button
        className="px-1 ms-1 bg-gradient"
        variant="outline-primary"
        onClick={() => updateState({page: page - 1})}
        disabled={!tableHelpers.canPrevPage(state)}
        title={`Gå till sida ${page - 1}`}
      >
        <ChevronLeft size={23} />
      </Button>
      <Button
        className="px-1 ms-1 bg-gradient"
        variant="outline-primary"
        onClick={() => updateState({page: page + 1})}
        disabled={!tableHelpers.canNextPage(state, count)}
        title={`Gå till sida ${page + 1}`}
      >
        <ChevronRight size={23} />
      </Button>
      <Button
        className="px-1 ms-1 bg-gradient"
        variant="outline-primary"
        onClick={() => updateState({page: tableHelpers.totalNumberOfPages(count, limit)})}
        disabled={!tableHelpers.canLastPage(state, count)}
        title={`Gå till sida ${tableHelpers.totalNumberOfPages(count, limit)}`}
      >
        <ChevronsRight size={23} />
      </Button>
    </Form>
  );
}
