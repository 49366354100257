import React from 'react';
import { TableColumnDefinitionMap } from 'src/tables/Table';
import {
  TableSelectedRows,
  TableStateColumns,
  TableRow, 
} from 'src/tables/types';
import TableTd from 'src/tables/TableTd';
import BasicSpinner from 'src/spinners/BasicSpinner';

interface TableBodyProps {
  rows: TableRow[];
  selectedRows: TableSelectedRows;
  setSelectedRows?: React.Dispatch<React.SetStateAction<TableSelectedRows>>;
  columnDefinitionsById: TableColumnDefinitionMap;
  columns?: TableStateColumns;
  isInitialLoading: boolean;
}
const TableBody: React.FC<TableBodyProps> = React.memo(function TableBody (props: TableBodyProps) {
  const {
    rows,
    selectedRows,
    setSelectedRows,
    columnDefinitionsById,
    columns = [],
    isInitialLoading,
  } = props;

  const handleChangeSelected = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    if (!setSelectedRows) return;
    const { name, checked } = ev.target;
    setSelectedRows(prevRows => ({...prevRows, [name]: checked}));
  }, [setSelectedRows]);

  return (
    <tbody>
      {rows && rows.length > 0 ? rows.map(row => (
        <tr key={row.id} className="border-bottom">
          {columns.map(columnId => (
            <TableTd
              key={columnId}
              columnDefinition={columnDefinitionsById[columnId]}
              row={row}
              selected={selectedRows[row.id] || false}
              onChangeSelected={handleChangeSelected}
            />
          ))}
        </tr>
      )) : (
        <tr>
          <td colSpan={columns?.length ?? 1} className="text-muted">
            {isInitialLoading ? (
              <>
                <BasicSpinner isLoading as="span" />{' '}
                Laddar…
              </>
            ) : (
              <>Det finns ingenting här</>
            )}
          </td>
        </tr>
      )}
    </tbody>
  );
});
export default TableBody;
