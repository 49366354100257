import React from 'react';
import moment from 'moment';

export interface IntervalValue {
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
  weeks?: number;
  months?: number;
  years?: number;
}

export interface IntervalProps {
  value: IntervalValue;
}

export default function Interval (props: IntervalProps) {
  const { value } = props;
  const dur = moment.duration(value);
  const minutes = dur.minutes();
  const seconds = dur.seconds();
  const milliseconds = dur.milliseconds();
  return (
    <span className="font-monospace">
      {String(minutes).padStart(2, '0')}:
      {String(seconds).padStart(2, '0')}:
      {String(milliseconds).padStart(3, '0')}
    </span>
  );
}

