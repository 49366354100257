const settings = {
  loanClamps: {
    minYears: 2,
    maxYears: 8,
    minTotal: 10000,
    maxTotal: 50000,
  },
  restart: {
    enabled: true,
    submittable: false,
  },
  change: {
    enabled: false,
  },
  warningHighInterestRate: true,
  accept: {
    canHaveCoApplicant: false,
  },
};
export default settings;
