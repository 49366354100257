import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import StatusBaseLabel, { StatusBaseLabelProps } from 'src/formatters/StatusBaseLabel';
import { ProspectRow } from 'shared/types/prospect';
import * as prospectFilter from 'shared/filter/prospect';

interface NameProps {
  value?: null | Pick<ProspectRow, 'id'>;
}

export function Name (props: NameProps) {
  const { value:prospect } = props;
  if (!isObjectLike(prospect)) return null;
  const id = prospect?.id ?? '';
  return (
    <IdShort value={id} />
  );
}

interface ResultLabelProps extends StatusBaseLabelProps {
  value: ProspectRow['result'];
}

export function ResultLabel (props: ResultLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={prospectFilter.result}
      labelClassName={prospectFilter.resultLabelClassName(value)}
      {...restOfProps}
    />
  );
}
