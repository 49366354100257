import { AxiosError } from 'axios';
import striptags from 'striptags';

export type ErrorType = Error | string | true;

type ErrorMap = Record<string, ErrorType>;

export interface IErrorDetails {
  message: string;
}

interface YupErrorLike {
  errors: string[];
  inner: YupErrorLike[];
  name: 'ValidationError';
  path: string;
  message: string;
}

type YupErrorBodyType = {
  errors: string[];
  inner: YupErrorLike[];
  name: 'ValidationError';
}

export function errorToDetails (error: ErrorType): IErrorDetails {
  const message = errorToMessage(error);
  return {
    message,
  };
}

export function errorToMessage (error: any | ErrorType): string {
  if (!error) return 'Okänt fel';
  if (error instanceof AxiosError) {
    const axiosMessage = error.message;
    const responseData = error.response?.data ?? {};
    const responseMessage = striptags(errorToMessage(responseData));
    return `${axiosMessage}: ${responseMessage}`;
  }
  if (typeof error === 'boolean') return 'Fel'; // TODO should probably not be like this
  if (typeof error === 'string') return error;
  if (typeof error.message === 'string') return error.message;
  if (typeof error?.error === 'string') return error.error;
  return JSON.stringify(error, null, 2);
}

export function maybeAxiosErrorToErrorMap (axiosError: any | AxiosError): ErrorMap | null {
  const status = axiosError.response?.status;

  // response body looks like this for errors, when using laravel Request classes
  const body = axiosError?.response?.data;
  const isYupErrorBody = Boolean(body?.errors && Array.isArray(body?.inner));
  if (isYupErrorBody) {
    const yupErrorBody = body as YupErrorBodyType;
    return yupErrorBody.inner.reduce((map, error) => {
      map[error.path] = error.message;
      return map;
    }, {});
  }

  // TODO, for now, we assume only status 400 can return validation errors
  // in the future we should probably designate a special response key in the server instead
  // of relying on a specific status code
  if (status !== 400) return null;
  return axiosError.response?.data as ErrorMap;
}
