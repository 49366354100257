import React from 'react';
import { Scatter } from 'react-chartjs-2';
import { sekThousandsScale, currency } from 'src/numbers';
import usePalette from 'src/hooks/usePalette';
import * as api from 'src/api';
import { useQuery } from '@tanstack/react-query';
import ErrorAlert from 'src/alerts/ErrorAlert';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import { ChartOptions } from 'chart.js';
import { BankProcessBidRow } from 'shared/types/bank_process_bid';
import { LoanRow } from 'shared/types/loan';
import { set } from 'lodash';

interface ChartDataPoint {
  y: number;
  x: number;
  bank: string;
}

function InterestRateAndAmountConcreteChart (props) {
  const { bids, appliedAmount, amountToRefinance } = props;
  const palette = usePalette();

  const data: ChartDataPoint[] = bids.map(bid => {
    const bank = bid?.BankProcess?.Bank?.name || bid.bank_id || '-';
    const { interest_rate, amount } = bid;
    return {
      y: amount,
      x: interest_rate,
      bank,
    };
  });

  const chartData = {
    type: 'scatter',
    datasets: [
      {
        label: 'Bank',
        backgroundColor: palette['primary-light'],
        borderColor: palette['primary-light'],
        hoverBackgroundColor: palette['primary-light'],
        hoverBorderColor: palette['primary-light'],
        data,
      },
    ],
  };

  const chartOptions: ChartOptions = {
    maintainAspectRatio: true,
    animation: false,
    plugins: {
      annotation: {
        annotations: {
          appliedAmount: {
            type: 'line',
            yMin: appliedAmount,
            yMax: appliedAmount,
            borderColor: palette['primary-dark'],
            borderWidth: 1,
            borderDash: [5, 2],
          },
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: context => {
            const { raw } = context;
            const { x:interest, y:amount, bank } = raw as ChartDataPoint;
            return `${bank}: ${currency(amount)} - ${interest} %`;
          },
        },
      },
    },
    elements: {
      point: {
        radius: 3,
      },
    },
    scales: {
      y: {
        min: 0,
        suggestedMax: 800000,
        ticks: {
          callback: value => sekThousandsScale(value),
        },
      },
      x: {
        suggestedMin: 3,
        suggestedMax: 20,
        ticks: {
          callback: value => `${value} %`,
        },
      },
    },
  };

  if (amountToRefinance > 0 && amountToRefinance !== appliedAmount) {
    set(chartOptions, 'plugins.annotation.annotations.amountToRefinance', {
      type: 'line',
      yMin: amountToRefinance,
      yMax: amountToRefinance,
      borderColor: palette['gray-600'],
      borderWidth: 1,
      borderDash: [5, 2],
    });
  }

  return (
    <Scatter height={140} data={chartData} options={chartOptions as any} />
  );
}

interface InterestRateAndAmountChartProps {
  applicationId: string;
  appliedAmount: number;
  amountToRefinance: number;
}

interface ApplicationBidsResponseData {
  bids: BankProcessBidRow[];
  bidsAsLoans: LoanRow[];
}

export function InterestRateAndAmountChart (props: InterestRateAndAmountChartProps) {
  const { applicationId, appliedAmount, amountToRefinance } = props;

  const query = useQuery<ApplicationBidsResponseData, Error>({
    queryKey: ['interestRateAndAmountChart', {applicationId}],
    queryFn: () => api.request({
      url: `/application/${applicationId}/bids`,
      params: {show: 'selectable'},
    }),
  });

  return (
    <div className="p-2 position-relative">
      <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
      <OverlaySpinner isLoading={query.isLoading} />
      {query.isSuccess && query.data && (
        <InterestRateAndAmountConcreteChart
          bids={query.data.bids || []}
          appliedAmount={appliedAmount}
          amountToRefinance={amountToRefinance}
        />
      )}
    </div>
  );
}
