import React from 'react';

interface PepQueryModalBodyProps {
  id: string;
}

export default function PepQueryModalBody (props: PepQueryModalBodyProps) {
  return (
    <div>pep query modal body here</div>
  );
}
