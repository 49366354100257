import React, {useState} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { Alert, Card, Form } from 'react-bootstrap';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { BankIdPhoneCallInitiator } from 'shared/types/bankid';
import {
  CustomerConsentBankIdStartRequestBody,
  CustomerConsentBankIdStartResponseBody,
  CustomerConsentBankIdCollectResponseBody,
  CustomerConsentBankIdCollectResponseBodyComplete,
  CustomerConsentRow,
} from 'shared/types/customer_consent';
import * as api from 'src/api';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import classNames from 'classnames';
import {CustomerRow} from 'shared/types/customer';
import IdProvider from 'src/components/IdProvider';
import BlockSpinner from 'src/spinners/BlockSpinner';
import useNotyf from 'src/hooks/useNotyf';
import { maybeAxiosErrorToErrorMap } from 'src/utils/error';
import { AxiosError } from 'axios';
import {Formik, FormikErrors, FormikHelpers} from 'formik';
import * as CustomerConsentFormFields from 'src/customerConsent/CustomerConsentFormFields';
import useAuth from 'src/hooks/useAuth';

interface CustomerConsentCreateAuthModalBodyProps {
  customerId: string;
  method: 'customer_bankid_auth' | 'customer_bankid_sign';
}

interface FormValues {
  subject: string;
}

interface CreateConsentBody {
  customer_id: string;
  subject: string;
  method: 'customer_bankid_auth' | 'customer_bankid_sign';
  method_parts: {
    customer_bankid_auth: {
      done: true;
      user_id: string;
      created_at: string;
      metadata: {
        ip: string;
        uhi: string;
        personalNumber: string;
        orderRef: string;
      };
    };
  };
}

interface IdentifyingData {
  orderRef: string;
  data: CustomerConsentBankIdCollectResponseBodyComplete['completionData'];
}

export default function CustomerConsentCreateAuthModalBody (props: CustomerConsentCreateAuthModalBodyProps) {
  const { customerId, method } = props;

  const notyf = useNotyf();
  const auth = useAuth();
  const modalStateContext = useModalStateContext();

  const [identifyingData, setIdentifyingData] = useState<IdentifyingData | null>(null);

  const createMutation = useMutation<CustomerConsentRow, Error, CreateConsentBody>({
    mutationFn: formValues => api.request({
      url: '/customer_consent/template/customer',
      method: 'POST',
      data: formValues,
    }),
    onSuccess: () => {
      notyf.success({type: 'success', message: 'Samtycket registrerades på kunden'});
      modalStateContext.onHide();
    },
  });

  const onSubmitCreate = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    try {
      await createMutation.mutateAsync({
        ...values,
        customer_id: customerId,
        method,
        method_parts: {
          customer_bankid_auth: {
            done: true,
            user_id: auth.userId,
            created_at: (new Date()).toISOString(),
            metadata: {
              ip: identifyingData.data.device.ipAddress,
              uhi: identifyingData.data.device.uhi,
              personalNumber: identifyingData.data.user.personalNumber,
              orderRef: identifyingData.orderRef,
            },
          },
        },
      });
    } catch (err) {
      helpers.setSubmitting(false);
      const errorMap = maybeAxiosErrorToErrorMap(err as AxiosError);
      if (errorMap) {
        helpers.setErrors(errorMap as FormikErrors<FormValues>);
        return;
      }
      throw err;
    }
  };

  const query = useQuery<CustomerRow>({
    queryKey: [`/customer/${customerId}`],
  });

  const onBankIdPhoneAuthComplete = (data: CustomerConsentBankIdCollectResponseBodyComplete) => {
    setIdentifyingData({orderRef: data.orderRef, data: data.completionData});
  };

  const initialValues = {
    subject: '',
  };

  const customer = query.data;

  return (
    <div>
      <BlockSpinner className="my-3" isLoading={query.isLoading} />
      {customer && (
        <BankIdPhoneAuth
          method={method === 'customer_bankid_sign' ? 'sign' : 'auth'}
          personalNumber={customer.ssn}
          onComplete={onBankIdPhoneAuthComplete}
        />
      )}
      {identifyingData && (
        <Formik initialValues={initialValues} onSubmit={onSubmitCreate} className="border-top">
          {formProps => (
            <Card className="border">
              <Form onSubmit={formProps.handleSubmit}>
                <Card.Body className="pt-1 pb-2">
                  <CustomerConsentFormFields.Subject />
                </Card.Body>
                <Card.Footer className="border-top">
                  <ErrorAlert error={createMutation.error} />
                  <div className="d-flex gap-2 flex-wrap">
                    <ButtonSpinner
                      type="submit"
                      className="rounded"
                      variant="success"
                      isLoading={formProps.isSubmitting}
                      disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
                    >
                      Registrera samtycke
                    </ButtonSpinner>
                  </div>
                </Card.Footer>
              </Form>
            </Card>
          )}
        </Formik>
      )}
    </div>
  );
}

interface BankIdPhoneAuthProps {
  personalNumber: string;
  method: 'sign' | 'auth';
  onComplete: (data: CustomerConsentBankIdCollectResponseBodyComplete) => void;
}

interface BankIdPhoneAuthState {
  lastCollectResponse: null | CustomerConsentBankIdCollectResponseBody;
  polling: boolean;
  orderRef: null | string;
}

function BankIdPhoneAuth (props: BankIdPhoneAuthProps) {
  const { personalNumber, method, onComplete } = props;

  const [callInitiator, setCallInitiator] = useState<BankIdPhoneCallInitiator>('RP');

  const onChangeCallInitiator: React.ChangeEventHandler<HTMLInputElement> = ev => {
    setCallInitiator(ev.target.value as BankIdPhoneCallInitiator);
  };

  const [state, setState] = useState<BankIdPhoneAuthState>({
    lastCollectResponse: null,
    polling: false,
    orderRef: null,
  });

  const startMutation = useMutation<CustomerConsentBankIdStartResponseBody, Error, CustomerConsentBankIdStartRequestBody>({
    mutationFn: async data => {
      const response = await api.request({
        url: `/customer_consent/bankid/phone/${method}`,
        timeout: 10000,
        method: 'POST',
        data,
      });

      const { orderRef } = response;

      setState({
        lastCollectResponse: null,
        polling: true,
        orderRef,
      });

      return response;
    },
  });

  const collectQuery = useQuery<CustomerConsentBankIdCollectResponseBody, Error>({
    enabled: state.polling,
    queryKey: [`/customer_consent/bankid/${state.orderRef}`],
    refetchInterval: 2000,
    retry: 3,
    retryDelay: 2000,
    queryFn: async ctx => {
      const lastCollectResponse = await api.request({
        url: `/customer_consent/bankid/${state.orderRef}`,
        signal: ctx.signal,
        method: 'GET',
        timeout: 10000,
      });

      setState(prevState => ({
        ...prevState,
        lastCollectResponse,
      }));

      if (lastCollectResponse.status !== 'pending') {
        setState(prevState => ({
          ...prevState,
          polling: false,
          orderRef: null,
        }));

        if (lastCollectResponse.status === 'complete') {
          onComplete(lastCollectResponse);
        }
      }

      return lastCollectResponse;
    },
  });

  const onClickStart = () => {
    setState({
      polling: false,
      lastCollectResponse: null,
      orderRef: null,
    });
    startMutation.mutateAsync({
      callInitiator,
      personalNumber,
    });
  };

  const startDisabled = startMutation.isPending || state.polling;

  return (
    <div>
      <div className="border-bottom pb-3 mb-3">
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">Personnummer</Form.Label>
          <Form.Control
            name="ssn"
            value={personalNumber}
            disabled
          />
        </Form.Group>
        <Form.Group>
          <div className="form-label">Välj vem som ringde upp vem</div>
          <IdProvider>
            {id => (
              <Form.Check
                id={id}
                type="radio"
                name="callInitiator"
                value="RP"
                label="Du som användare ringde kunden"
                checked={callInitiator === 'RP'}
                onChange={onChangeCallInitiator}
              />
            )}
          </IdProvider>
          <IdProvider>
            {id => (
              <Form.Check
                id={id}
                type="radio"
                name="callInitiator"
                value="user"
                label="Kunden ringde in"
                checked={callInitiator === 'user'}
                onChange={onChangeCallInitiator}
              />
            )}
          </IdProvider>
        </Form.Group>
      </div>
      <ErrorAlert className="mb-1" error={startMutation.error} />
      <ButtonSpinner
        className="d-flex align-items-center gap-1 flex-wrap justify-content-center"
        variant="secondary"
        onClick={onClickStart}
        disabled={startDisabled}
        isLoading={startDisabled}
      >
        Påbörja identifiering
      </ButtonSpinner>
      <ErrorAlert error={collectQuery.error} className="mt-3 mb-1" />
      {state.lastCollectResponse && (
        <>
          {'error' in state.lastCollectResponse ? (
            <Alert variant="danger" className="text-danger mt-3 mb-0 p-3 justify-content-center">
              {state.lastCollectResponse.error as any}
            </Alert>
          ) : (
            <BankIdStatusAlert response={state.lastCollectResponse} />
          )}
          {'status' in state.lastCollectResponse && state.lastCollectResponse.status === 'complete' && (
            <BankIdCompleteCard
              completeCollectResponse={state.lastCollectResponse}
            />
          )}
        </>
      )}
    </div>
  );
}

interface BankIdCompleteCardProps {
  completeCollectResponse: CustomerConsentBankIdCollectResponseBodyComplete;
}

function BankIdCompleteCard (props: BankIdCompleteCardProps) {
  const { completeCollectResponse:response } = props;
  const { user, device } = response.completionData;
  return (
    <Card className="rounded border mt-3">
      <Card.Header className="p-3 border-bottom bg-light">
        <Card.Title as="h6" className="mb-0">Identifiering lyckades</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex flex-wrap justify-content-between py-3">
        <dl className="mb-0 w-50">
          <dt>Personnummer</dt>
          <dd>{user.personalNumber}</dd>
          <dt>Namn</dt>
          <dd className="mb-0">{user.name}</dd>
        </dl>
        <dl className="mb-0 w-50">
          <dt>IP-adress</dt>
          <dd><code>{device.ipAddress}</code></dd>
          <dt>Hårdvaru-ID</dt>
          <dd className="mb-0"><code>{device.uhi}</code></dd>
        </dl>
      </Card.Body>
    </Card>
  );
}

interface BankIdStatusAlertProps {
  response: CustomerConsentBankIdCollectResponseBody;
}

export function BankIdStatusAlert (props: BankIdStatusAlertProps) {
  const { response } = props;
  if ('status' in response && response.status === 'complete') return null;

  const hintCode = 'hintCode' in response ? response.hintCode : null;
  const message = getMessage(response, hintCode);
  const variant = getVariant(response);
  const textColorClassName = `text-${variant}`;
  return (
    <Alert variant={variant} className={classNames('mt-3 mb-0 p-3 justify-content-center', textColorClassName)}>
      <span>{message}</span>
    </Alert>
  );
}

function getVariant (response: CustomerConsentBankIdCollectResponseBody): string {
  if ('errorCode' in response) return 'danger';
  switch (response.status) {
    default: return 'info';
    case 'pending': return 'info';
    case 'failed': return 'danger';
  }
}

function getMessage (response: CustomerConsentBankIdCollectResponseBody, hintCode?: null | string): string {
  if ('errorCode' in response) {
    const { errorCode, details } = response;
    return `Ett fel inträffade: ${errorCode} - ${details}`;
  }
  const { status } = response;
  if (status === 'started') {
    return 'Väntar på att kunden startar BankID-appen.';
  } else if (status === 'pending') {
    switch (hintCode) {
      default: return `Signering pågår. (${hintCode})`;
      case 'userCallConfirm': return 'Väntar på att kunden bekräftar att ett telefonsamtal pågår.';
      case 'noClient':
      case 'outstandingTransaction':
        return 'Väntar på att kunden ska starta BankID-appen.';
      case 'started':
        return 'Söker efter BankID, det kan ta en liten stund…';
      case 'userSign': return 'Väntar på att kunden skriver in sin säkerhetskod och väljer "Identifiera".';
    }
  } else if (status === 'failed') {
    switch (hintCode) {
      default: return `Okänt fel. Försök igen. (${hintCode})`;
      case 'expiredTransaction': return 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Försök sedan igen.';
      case 'certificateErr': return 'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.';
      case 'userCancel': return 'Åtgärden avbruten.';
      case 'cancelled': return 'Åtgärden avbruten. Försök igen.';
      case 'startFailed':
        return 'BankID-start misslyckades.';
      case 'notSupportedByUserApp': return 'Anropet stöds inte av din BankID-app. Var god uppdatera appen.';
    }
  }
  return `${status}: ${hintCode}`;
}
