import React from 'react';
import * as api from 'src/api';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import * as IconButtons from 'src/buttons/IconButtons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ComplaintRow } from 'shared/types/complaint';
import { Card } from 'react-bootstrap';
import { Frown } from 'react-feather';
import CardBodyTable from 'src/tables/CardBodyTable';
import * as TableCells from 'src/tables/TableCells';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as complaintFilter from 'shared/filter/complaint';
import {useNavigate} from 'react-router';
import useNotyf from 'src/hooks/useNotyf';
import {GuardPermission} from 'src/guards/AuthGuards';
import classNames from 'classnames';

interface ComplaintListCardProps {
  customerId?: string;
  searchParams: Record<string, any>;
  className?: string;
}

export default function ComplaintListCard (props: ComplaintListCardProps) {
  const { searchParams, customerId, className } = props;

  const query = useQuery<ComplaintRow[], Error>({
    queryKey: ['/complaint/search', {params: searchParams}],
  });

  const navigate = useNavigate();
  const notyf = useNotyf();

  const createMutation = useMutation<ComplaintRow, Error>({
    mutationFn: () => api.request({
      url: `/complaint/customer/${customerId}`,
      method: 'POST',
    }),
    onSuccess: newComplaint => {
      navigate(`/complaint/${newComplaint.id}`);
      notyf.success({type: 'success', message: 'Klagomålet skapades'});
    },
  });

  const onConfirmCreate = () => {
    return createMutation.mutateAsync();
  };

  const rows = query.data ?? [];

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="border-bottom bg-light">
        <CardTitleIcon
          title="Klagomål"
          Icon={<Frown size={18} />}
          spinning={query.isRefetching}
        >
          {customerId && (
            <GuardPermission permission="complaint_create_by_customer">
              <IconButtons.ConfirmModalCreateButton
                message="Är du säker på att du vill skapa ett nytt klagomål på kunden?"
                onConfirm={onConfirmCreate}
              />
            </GuardPermission>
          )}
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <CardBodyTable
        striped
        columnDefinitions={columnDefs}
        rows={rows}
        isFetched={query.isFetched}
      />
    </Card>
  );
}

const columnDefs = [
  commonColumnDefs.complaintId({
    id: 'id',
    title: 'Nr',
    cellProps: {rowAsObject: true},
  }),
  commonColumnDefs.datetime({
    id: 'created_at',
    title: 'Skapad',
  }),
  commonColumnDefs.basic({
    id: 'priority',
    title: 'Prioritet',
    Cell: props => <>{complaintFilter.priority(props.row.priority)}</>,
  }),
  commonColumnDefs.basic({
    id: 'status',
    title: 'Status',
    Cell: TableCells.ComplaintStatusTableCell,
  }),
  commonColumnDefs.inspect(),
].filter(v => v);
