import React from 'react';
import moment from 'moment';
import { TableStateFilterMap } from 'src/tables/types';
import { Button } from 'react-bootstrap';

interface SetFilterButtonProps {
  setFilter?: (newFilter: TableStateFilterMap) => void;
  from?: (format: string) => string;
  to?: (format: string) => string;
  format?: string;
  filter?: TableStateFilterMap;
  filterDateKey?: string;
  title?: string;
}

export const SetFilterButton: React.FC<SetFilterButtonProps> = React.memo(function SetFilterButton (props: SetFilterButtonProps) {
  const {
    title,
    filter,
    filterDateKey = 'date',
    format = 'YYYY-MM-DD',
    setFilter = () => {},
    from,
    to,
  } = props;

  const onClick = React.useCallback(() => {
    const newFilter = {
      ...filter,
      [filterDateKey]: {...(filter[filterDateKey] || {}), from: from(format), to: to(format)},
    };
    setFilter(newFilter);
  }, [filter, setFilter, filterDateKey, from, to, format]);

  return (
    <Button
      className="ms-1 py-0 px-1"
      size="sm"
      variant="outline-primary"
      onClick={onClick}
    >
      {title}
    </Button>
  );
});
export default SetFilterButtonProps;

export const ThisQuarter = (props: Partial<SetFilterButtonProps>) => (
  <SetFilterButton
    {...props}
    title="Detta kvartal"
    from={(format: string) => moment().startOf('quarter').format(format)}
    to={(format: string) => moment().endOf('quarter').format(format)}
  />
);

export const LastQuarter = (props: Partial<SetFilterButtonProps>) => (
  <SetFilterButton
    {...props}
    title="Föregående kvartal"
    from={(format: string) => moment().subtract(1, 'quarter').startOf('quarter').format(format)}
    to={(format: string) => moment().subtract(1, 'quarter').endOf('quarter').format(format)}
  />
);

export const ThisYear = (props: Partial<SetFilterButtonProps>) => (
  <SetFilterButton
    {...props}
    title="I år"
    from={(format: string) => moment().startOf('year').format(format)}
    to={(format: string) => moment().endOf('year').format(format)}
  />
);

export const LastYear = (props: Partial<SetFilterButtonProps>) => (
  <SetFilterButton
    {...props}
    title="Föregående år"
    from={(format: string) => moment().subtract(1, 'year').startOf('year').format(format)}
    to={(format: string) => moment().subtract(1, 'year').endOf('year').format(format)}
  />
);

export const ThisMonth = (props: Partial<SetFilterButtonProps>) => (
  <SetFilterButton
    {...props}
    title="Denna månad"
    from={(format: string) => moment().startOf('month').format(format)}
    to={(format: string) => moment().endOf('month').format(format)}
  />
);

export const LastMonth = (props: Partial<SetFilterButtonProps>) => (
  <SetFilterButton
    {...props}
    title="Föregående månad"
    from={(format: string) => moment().subtract(1, 'month').startOf('month').format(format)}
    to={(format: string) => moment().subtract(1, 'month').endOf('month').format(format)}
  />
);

export const ThisWeek = (props: Partial<SetFilterButtonProps>) => (
  <SetFilterButton
    {...props}
    title="Denna vecka"
    from={(format: string) => moment().startOf('week').format(format)}
    to={(format: string) => moment().endOf('week').format(format)}
  />
);

export const LastWeek = (props: Partial<SetFilterButtonProps>) => (
  <SetFilterButton
    {...props}
    title="Föregående vecka"
    from={(format: string) => moment().subtract(1, 'week').startOf('week').format(format)}
    to={(format: string) => moment().subtract(1, 'week').endOf('week').format(format)}
  />
);

export const Today = (props: Partial<SetFilterButtonProps>) => (
  <SetFilterButton
    {...props}
    title="Idag"
    from={(format: string) => moment().startOf('day').format(format)}
    to={(format: string) => moment().endOf('day').format(format)}
  />
);

export const Yesterday = (props: Partial<SetFilterButtonProps>) => (
  <SetFilterButton
    {...props}
    title="Igår"
    from={(format: string) => moment().subtract(1, 'day').startOf('day').format(format)}
    to={(format: string) => moment().subtract(1, 'day').endOf('day').format(format)}
  />
);
