import React from 'react';
import { CardTitleIcon, ErrorAlertCardBody  } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import LinkButton from 'src/buttons/LinkButton';
import { useQuery } from '@tanstack/react-query';
import { CustomerConsentRow } from 'shared/types/customer_consent';
import { Card } from 'react-bootstrap';
import { Edit3, Phone, Printer } from 'react-feather';
import * as customerConsentFilter from 'shared/filter/customer_consent';
import * as TableCellActions from 'src/tables/TableCellActions';
import classNames from 'classnames';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';
import SimpleModalOpeningButton from 'src/buttons/SimpleModalOpeningButton';
import CustomerConsentCreateModalBody from 'src/customerConsent/CustomerConsentCreateModalBody';

interface CustomerConsentListCardProps {
  customerId: string;
  searchParams: Record<string, any>;
  className?: string;
}

export default function CustomerConsentListCard (props: CustomerConsentListCardProps) {
  const { customerId, searchParams, className } = props;

  const query = useQuery<CustomerConsentRow[], Error>({
    queryKey: ['/customer_consent/search', {params: {...searchParams, customer_id: customerId}}],
  });

  const rows = query.data ?? [];

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="border-bottom bg-light">
        <CardTitleIcon
          title="Samtycken"
          Icon={<Edit3 size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <Card.Body className="p-3 d-flex flex-wrap align-items-center gap-2">
        <strong>Nytt samtycke:</strong>
        <SimpleModalOpeningButton
          modalBodyClassName=""
          className="d-flex gap-1 align-items-center"
          label={<><Phone size={14} />{' '}BankID-identifiering i telefonsamtal</>}
          variant="outline-success"
          size="sm"
          modalTitle="Nytt samtycke via BankID-identifiering i telefonsamtal"
        >
          <CustomerConsentCreateModalBody
            method="customer_bankid_auth"
            customerId={customerId}
          />
        </SimpleModalOpeningButton>
      </Card.Body>
      <ErrorAlertCardBody error={query.error} />
      <CardBodyTable
        striped
        columnDefinitions={columnDefs}
        rows={rows}
        isFetched={query.isFetched}
      />
    </Card>
  );
}

const columnDefs = [
  commonColumnDefs.customerConsentId({
    id: 'id',
    title: 'ID',
    cellProps: {rowAsObject: true},
  }),
  commonColumnDefs.userId({
    id: 'user_id',
    title: 'Användare',
    cellProps: {objKey: 'User', value: '-'},
  }),
  commonColumnDefs.basic({
    id: 'subject',
    title: 'Samtycke för',
  }),
  commonColumnDefs.basic({
    id: 'method',
    title: 'Samtyckesmetod',
    Cell: props => <>{customerConsentFilter.method(props.row.method)}</>,
  }),
  commonColumnDefs.datetime({
    id: 'created_at',
    title: 'Registrerat',
  }),
  commonColumnDefs.actions({}, [
    props => (
      <LinkButton
        to={`/customer_consent/${props.row.id}/print`}
        size="sm"
        variant="outline-primary"
        className="px-1 py-0"
        target="_blank"
        title="Öppna utskriftsvänlig sida"
      >
        <Printer size={14} />
      </LinkButton>
    ),
    TableCellActions.InspectRow,
  ]),
];
