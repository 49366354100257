const settings = {
  bankInterface: {
    index: {
      defaultFilter: {status: 'open'},
    },
  },
  loanClamps: {
    minYears: 1,
    maxYears: 12,
    minTotal: 20000,
    maxTotal: 350000,
  },
  accept: {
    canHaveCoApplicant: true,
  },
  change: {
  },
};
export default settings;
